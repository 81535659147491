/* eslint-disable import/no-cycle */
import { Serializable } from 'app/core/interfaces';
import { Territory, TerritoryDTO } from './territory';
import { Consumer, ConsumerDTO } from './consumer';
import { Recording, RecordingDTO } from './recording';
import { Claim, ClaimDTO } from './claim';
import { UsageTypes } from './usage';

export class RecordingUsageDTO {
  id: string;
  datetime: string;
  amount: number;
  duration: number;
  artistName: string;
  status: string;
  legacyId: string;
  dateFrom: string;
  dateTo: string;
  type: UsageTypes;
  territory: TerritoryDTO;
  territoryId: string;
  recording: RecordingDTO;
  recordingId: string;
  consumer: ConsumerDTO;
  consumerId: string;
  claim: ClaimDTO;
  invoiceId: string;
  updatedAt: string;
  createdAt: string;
}

export class RecordingUsage implements Serializable<RecordingUsage> {
  id: string;
  datetime: string;
  amount: number;
  duration: number;
  artistName: string;
  type: UsageTypes;
  status: string;
  legacyId: string;
  dateFrom: string;
  dateTo: string;
  territory: Territory;
  territoryId: string;
  recording: Recording;
  recordingId: string;
  consumer: Consumer;
  consumerId: string;
  claim: Claim;
  invoiceId: string;
  updatedAt: string;
  createdAt: string;

  deserialize(input: RecordingUsageDTO): RecordingUsage {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.datetime = input.datetime || '';
    this.amount = input.amount || 0;
    this.duration = input.duration || 0;
    this.artistName = input.artistName || '';
    this.status = input.status || '';
    this.legacyId = input.legacyId || '';
    this.dateFrom = input.dateFrom || '';
    this.dateTo = input.dateTo || '';
    this.consumerId = input.consumerId || '';
    this.invoiceId = input.invoiceId || '';
    this.updatedAt = input.updatedAt || '';
    this.createdAt = input.createdAt || '';

    this.type = input.type || UsageTypes.DIGITAL;
    this.claim = new Claim().deserialize(input.claim);
    this.territory = new Territory().deserialize(input.territory);
    this.territoryId = input.territoryId || '';
    this.recording = new Recording().deserialize(input.recording);
    this.recordingId = input.recordingId || '';
    this.consumer = new Consumer().deserialize(input.consumer);

    return this;
  }

  deserializeArray(inputArray: Array<RecordingUsageDTO>): Array<RecordingUsage> {
    const workUsages: RecordingUsage[] = inputArray.map((input) =>
      new RecordingUsage().deserialize(input),
    );

    return workUsages;
  }

  clone(): RecordingUsage {
    const clonedInstance: RecordingUsage = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this,
    );

    return clonedInstance;
  }
}
