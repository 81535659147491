import { RightHolderDTO } from 'app/shared/models';

export const RIGHTHOLDERS_MOCK: RightHolderDTO[] = [
  {
    name: 'Administrator from unison',
    ipi: 856292576,
    userId: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
    unisonId: 'RA548VCJ4',
    id: '775673d3-b0d8-4ec8-a14a-58e0fdf7ae9d',
    user: {
      id: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
      email: 'dev@nakima.es',
      firstName: 'Administrator',
      lastName: 'from nakima',
      businessName: null,
      role: 'admin',
      nif: null,
      address: null,
      city: null,
      postalCode: null,
      country: null,
      stripeAccountId: null,
      commissionDigital: 10,
      commissionRadioTV: 10,
      commissionLive: 9,
      commissionBackground: 20,
      status: 'active',
      financialAdminId: null,
    },
  },
  {
    name: 'Songs of freedom',
    ipi: 282430576,
    userId: '18ce044c-a6ab-11e8-98d0-529269fb1459',
    unisonId: 'R9T7DW1Q6',
    id: 'a7243b18-20e4-4f5d-8131-d7fd0cb4be44',
    user: {
      id: '18ce044c-a6ab-11e8-98d0-529269fb1459',
      email: 'productions@freedom.org',
      firstName: 'Songs',
      lastName: 'of freedom',
      businessName: 'Songs of Freedom SL',
      role: 'right_holder',
      nif: null,
      address: null,
      city: null,
      postalCode: null,
      country: null,
      stripeAccountId: null,
      commissionDigital: 10,
      commissionRadioTV: 10,
      commissionLive: 9,
      commissionBackground: 20,
      status: 'active',
      financialAdminId: null,
    },
  },
  {
    name: 'JGrau productions',
    ipi: 244969234,
    userId: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
    unisonId: 'RJXUFQGNU',
    id: 'e7dfb2c2-9681-4388-8622-8c6d1d505d57',
    user: {
      id: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
      email: 'jgrau@nakima.es',
      firstName: 'JGrau',
      lastName: 'Productions',
      businessName: 'JGrau Productions SL',
      role: 'right_holder',
      nif: null,
      address: null,
      city: null,
      postalCode: null,
      country: null,
      stripeAccountId: null,
      commissionDigital: 10,
      commissionRadioTV: 10,
      commissionLive: 9,
      commissionBackground: 20,
      status: 'active',
      financialAdminId: null,
    },
  },
  {
    name: 'Los ceros music inc',
    ipi: 196616827,
    userId: '2a52c716-a6ab-11e8-98d0-529269fb1459',
    unisonId: 'R0IX9VQ1N',
    id: '63b639d8-a411-4901-816c-3cffe0c5e698',
    user: {
      id: '2a52c716-a6ab-11e8-98d0-529269fb1459',
      email: 'producciones@losceros.es',
      firstName: 'Los ceros',
      lastName: 'music inc',
      businessName: 'Los ceros music inc SL',
      role: 'right_holder',
      nif: null,
      address: null,
      city: null,
      postalCode: null,
      country: null,
      stripeAccountId: null,
      commissionDigital: 10,
      commissionRadioTV: 10,
      commissionLive: 9,
      commissionBackground: 20,
      status: 'active',
      financialAdminId: null,
    },
  },
  {
    name: 'Trance school',
    ipi: 599540102,
    userId: '2185f474-be05-46cb-a45e-dd267f195830',
    unisonId: 'R0QL0RQI3',
    id: '1c4a3e69-03d8-4103-bb75-ad745ff41aa0',
    user: {
      id: '2185f474-be05-46cb-a45e-dd267f195830',
      email: 'support@tranceschool.com',
      firstName: 'Trance',
      lastName: 'School',
      businessName: 'Trance School SL',
      role: 'right_holder',
      nif: null,
      address: null,
      city: null,
      postalCode: null,
      country: null,
      stripeAccountId: null,
      commissionDigital: 10,
      commissionRadioTV: 10,
      commissionLive: 9,
      commissionBackground: 20,
      status: 'active',
      financialAdminId: null,
    },
  },
  {
    name: 'Trance School Kids',
    ipi: 1963856652,
    userId: '2185f474-be05-46cb-a45e-dd267f195830',
    unisonId: 'RBHG70HOJ',
    id: '09334959-6812-4aee-984d-780c60a09e74',
    user: {
      id: '2185f474-be05-46cb-a45e-dd267f195830',
      email: 'support@tranceschool.com',
      firstName: 'Trance',
      lastName: 'School',
      businessName: 'Trance School SL',
      role: 'right_holder',
      nif: null,
      address: null,
      city: null,
      postalCode: null,
      country: null,
      stripeAccountId: null,
      commissionDigital: 10,
      commissionRadioTV: 10,
      commissionLive: 9,
      commissionBackground: 20,
      status: 'active',
      financialAdminId: null,
    },
  },
  {
    name: 'Norman cleric',
    ipi: 185782901,
    userId: null,
    unisonId: 'R48XPKNR7',
    id: 'c26b2a33-2e69-4872-937f-4720e8eac524',
  },
  {
    name: 'Rosa Flynn',
    ipi: 168618681,
    userId: null,
    unisonId: 'RH0ZWF44F',
    id: 'bfa5e27b-5771-4c5d-a178-0dfbd7b45c1a',
  },
  {
    name: 'Manuel Cebollo',
    ipi: 864975981,
    userId: null,
    unisonId: 'RPFN5O1HG',
    id: 'e6b48091-0014-47b8-97fe-9140d8c330d6',
  },
  {
    name: 'Sioned Marin',
    ipi: 437219252,
    userId: null,
    unisonId: 'RGLKATRPY',
    id: '89c3d995-96f9-41e2-aae3-f59e6447436c',
  },
  {
    name: 'Derek Andrade',
    ipi: 355932144,
    userId: null,
    unisonId: 'RMNI64ULS',
    id: 'ae738506-1d90-4e12-a1dc-b063c7c6167b',
  },
  {
    name: 'Raw talent publisher',
    ipi: 521982334,
    userId: null,
    unisonId: 'R30IHDTHX',
    id: '44cebd06-a674-42ab-af98-48f67503d5f1',
  },
  {
    name: 'LOUD',
    ipi: 955938923,
    userId: null,
    unisonId: 'RM5LZ3D7G',
    id: '444a29e4-63aa-4b8f-a749-acaf36000490',
  },
  {
    name: 'UNKNOWN NAME',
    ipi: 0,
    userId: null,
    unisonId: 'R2HG70HOJ',
    id: '09720058-b35f-4431-8fec-1c22938d1a3a',
  },
];
