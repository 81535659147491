import { AlternateTitleDTO } from 'app/shared/models';

export const ALTERNATE_TITLES_MOCK: Array<AlternateTitleDTO> = [
  {
    name: 'FLEEING CHANCES LIVE ONORA',
    type: null,
    language: null,
    compositionId: '45edb24c-a6ab-11e8-98d0-529269fb1459',
    id: '610edc72-80da-42b4-ab2f-3cdc852f1c6c',
  },
  {
    name: 'FLEEING CHANCES WITH SUDOSU',
    type: null,
    language: null,
    compositionId: '45edb24c-a6ab-11e8-98d0-529269fb1459',
    id: '94a983d2-555f-46c6-abee-4be289e51c36',
  },
];
