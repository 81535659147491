import { CueSheetDTO } from 'app/shared/models';

export const CUE_SHEETS_WORK_MOCK: Array<CueSheetDTO> = [
  {
    id: 'cue-01',
    title: 'Unge lovende 2 x 01',
    productionType: 'TV Series',
    productionName: 'Unge lovende',
    usedWorks: 2,
  },
  {
    id: 'cue-02',
    title: 'Pure 1 x 03',
    productionType: 'TV Series',
    productionName: 'Pure',
    usedWorks: 1,
  },
  {
    id: 'cue-03',
    title: 'Una vez más',
    productionType: 'Feature Film',
    productionName: 'Una vez más',
    usedWorks: 8,
  },
  {
    id: 'cue-04',
    title: 'Pol·len (short)',
    productionType: 'Short Film',
    productionName: 'Po·len',
    usedWorks: 3,
  },
  {
    id: 'cue-05',
    title: 'Apple iPhone XR',
    productionType: 'Advertisement',
    productionName: 'CO21R3455N5C',
    usedWorks: 1,
  },
];
