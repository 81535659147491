import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule, DecimalPipe } from '@angular/common';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxSpinnerModule } from 'ngx-spinner';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ChartsModule } from 'ng2-charts';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbTimepickerModule, NgbTypeaheadModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxFilesizeModule } from 'ngx-filesize';

import { NgSelectModule } from '@ng-select/ng-select';

import { LoggedInGuard, IsAdminLoggedGuard, AuthDisclaimerGuard } from 'app/modules/auth/guards';
import { MaintenanceGuard, MaintenanceAllowedAdminGuard } from 'app/modules/maintenance/guards';
import { AuthService } from 'app/modules/auth/services';

import { TruncatePipe, RemoveUnderscoreAndCapitalizePipe } from 'app/shared/pipes';

import { Subscription } from 'rxjs';
import { HighchartsChartModule } from 'highcharts-angular';
import { AngularIbanModule } from 'angular-iban';

import {
  EarningsService,
  WorkService,
  TicketService,
  UserService,
  UsageService,
  TokenInterceptorService,
  UsageReportService,
  RecordingService,
  LiquidationService,
  PlatformService,
  CsvParserService,
  CwrParserService,
  CountryService,
  PerformerService,
  RightHolderService,
  SocietyService,
  DocumentService,
  TerritoryService,
  ResourceService,
  LanguageService,
  ConsumerService,
  MustShowFinancialDataBarService,
  CancelHttpService,
  CancelHttpInterceptor,
} from './services';

import {
  AppHeaderComponent,
  AppSidebarComponent,
  AppFooterComponent,
  AppBreadcrumbsComponent,
  AppHeaderMenuComponent,
  APP_SIDEBAR_NAV,
  AppHeaderUnitedComponent,
  ModalProgressComponent,
  OpenTicketsComponent,
  AdminBarComponent,
  AppLegalDisclaimerComponent,
  EmptyFinancialDataComponent,
} from './components';
import { SidebarToggleDirective, NAV_DROPDOWN_DIRECTIVES } from './directives';
import { P404PageComponent } from './pages';
import { PlatformLanguageService } from './services/platform-language.service';
import { CanDeactivateGuard } from './guards';

const PAGES: any[] = [P404PageComponent];

const COMPONENTS: any[] = [
  AppHeaderComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  AppFooterComponent,
  AppBreadcrumbsComponent,
  AppHeaderMenuComponent,
  ...APP_SIDEBAR_NAV,
  AppHeaderUnitedComponent,
  ModalProgressComponent,
  OpenTicketsComponent,
  AdminBarComponent,
  AppLegalDisclaimerComponent,
  EmptyFinancialDataComponent,
];

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const PROVIDERS: Provider[] = [
  AuthService,
  IsAdminLoggedGuard,
  AuthDisclaimerGuard,
  LoggedInGuard,
  MaintenanceGuard,
  MaintenanceAllowedAdminGuard,
  UserService,
  WorkService,
  TicketService,
  EarningsService,
  UsageService,
  RecordingService,
  UsageReportService,
  LiquidationService,
  PlatformService,
  CsvParserService,
  CwrParserService,
  CountryService,
  PerformerService,
  RightHolderService,
  SocietyService,
  DocumentService,
  TerritoryService,
  ResourceService,
  LanguageService,
  ConsumerService,
  MustShowFinancialDataBarService,
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
  CancelHttpService,
  { provide: HTTP_INTERCEPTORS, useClass: CancelHttpInterceptor, multi: true },
];

const MODULES: any[] = [
  RouterModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  FontAwesomeModule,
  NgxSpinnerModule,
  ChartsModule,
  NgxPaginationModule,
  NgbTimepickerModule,
  NgbTypeaheadModule,
  NgSelectModule,
  NgxFilesizeModule,
  NgbModule,
  HighchartsChartModule,
  AngularIbanModule,
];

const DIRECTIVES: Provider[] = [SidebarToggleDirective, ...NAV_DROPDOWN_DIRECTIVES];

const PIPES: any[] = [TruncatePipe, RemoveUnderscoreAndCapitalizePipe];

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  imports: [
    ...(MODULES as []),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [...(PAGES as []), ...(COMPONENTS as []), ...(DIRECTIVES as []), ...(PIPES as [])],
  exports: [
    ...(PAGES as []),
    ...(COMPONENTS as []),
    ...(DIRECTIVES as []),
    ...(MODULES as []),
    ...(PIPES as []),
    TranslateModule,
  ],
  providers: [DecimalPipe, CanDeactivateGuard],
})
export class SharedModule {
  private LANGUAGES = ['es', 'en'];
  private subscription: Subscription;
  private regExp = new RegExp(this.LANGUAGES.join('|'));
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [...PROVIDERS, ...DIRECTIVES],
    } as ModuleWithProviders<SharedModule>;
  }

  constructor(
    private translate: TranslateService,
    private platformLanguageService: PlatformLanguageService,
  ) {
    this.initTranslateService();

    this.subscription = this.platformLanguageService.platformLanguage$.subscribe(
      this.platformLanguage.bind(this),
    );
  }

  private initTranslateService(): void {
    this.translate.addLangs(this.LANGUAGES);
    this.translate.setDefaultLang('es');
  }

  private platformLanguage(language: string): void {
    if (!language) {
      return;
    }

    this.translate.use(language.toLowerCase().match(this.regExp) ? language.toLowerCase() : 'es');
  }
}
