<h1 class="page-title text-center">
  <div class="giant-text">
    {{ "404" }}
    <div class="text">
      {{ "Page not found" | translate }}
    </div>
  </div>
</h1>
<h4 class="claim text-center">
  {{ "Sorry, we couldn't find the page you were looking for." | translate }}
</h4>
<div class="action text-center mt-5">
  <a
    routerLink="{{ info.url }}"
    class="btn- btn-unison primary"
    *ngIf="info && info.text && info.url"
  >
    {{ info.text | translate }}
  </a>
</div>
