// import { UsageReportDTO } from 'app/shared/models';

export const USAGE_REPORTS_MOCK = {
  usageReports: [
    {
      id: 'abcd-abccd-abcd-abcd-1',
      name: 'O2 Londow Show',
      useType: 'Live performance',
      rightHolders: [
        {
          name: 'LOUD',
          ipi: 955938923,
          userId: null,
          unisonId: 'RM5LZ3D7G',
          performanceSocietyId: null,
          mechanicalSocietyId: null,
          synchronizationSocietyId: null,
          id: '444a29e4-63aa-4b8f-a749-acaf36000490',
        },
        {
          name: 'Derek Andrade',
          ipi: 355932144,
          userId: null,
          unisonId: 'RMNI64ULS',
          performanceSocietyId: null,
          mechanicalSocietyId: null,
          synchronizationSocietyId: null,
          id: 'ae738506-1d90-4e12-a1dc-b063c7c6167b',
        },
      ],
      usedWorks: 2,
    },
    {
      id: 'abcd-abccd-abcd-abcd-2',
      name: 'Pure 1 x 03',
      useType: 'Directo en streaming',
      rightHolders: [
        {
          name: 'JOHN BENEDICT',
          ipi: null,
          userId: null,
          unisonId: 'R8GI0SVS3',
          performanceSocietyId: null,
          mechanicalSocietyId: null,
          synchronizationSocietyId: null,
          id: 'f28ec6d5-df26-4477-8fef-9d70430e193f',
        },
      ],
      usedWorks: 1,
    },
    {
      id: 'abcd-abccd-abcd-abcd-3',
      name: 'Una vez más',
      useType: 'Cue-Sheet',
      rightHolders: [
        {
          name: 'Sioned Marin',
          ipi: 437219252,
          userId: null,
          unisonId: 'RGLKATRPY',
          performanceSocietyId: null,
          mechanicalSocietyId: null,
          synchronizationSocietyId: null,
          id: '89c3d995-96f9-41e2-aae3-f59e6447436c',
        },
        {
          name: 'Derek Andrade',
          ipi: 355932144,
          userId: null,
          unisonId: 'RMNI64ULS',
          performanceSocietyId: null,
          mechanicalSocietyId: null,
          synchronizationSocietyId: null,
          id: 'ae738506-1d90-4e12-a1dc-b063c7c6167b',
        },
      ],
      usedWorks: 8,
    },
    {
      id: 'abcd-abccd-abcd-abcd-4',
      name: 'Pol·len (Short)',
      useType: 'Cue-Sheet',
      rightHolders: [
        {
          name: 'LOUD',
          ipi: 955938923,
          userId: null,
          unisonId: 'RM5LZ3D7G',
          performanceSocietyId: null,
          mechanicalSocietyId: null,
          synchronizationSocietyId: null,
          id: '444a29e4-63aa-4b8f-a749-acaf36000490',
        },
        {
          name: 'Derek Andrade',
          ipi: 355932144,
          userId: null,
          unisonId: 'RMNI64ULS',
          performanceSocietyId: null,
          mechanicalSocietyId: null,
          synchronizationSocietyId: null,
          id: 'ae738506-1d90-4e12-a1dc-b063c7c6167b',
        },
      ],
      usedWorks: 3,
    },
    {
      id: 'abcd-abccd-abcd-abcd-5',
      name: 'Apple iPjone XR',
      useType: 'Cue-Sheet',
      rightHolders: [
        {
          name: 'JOHN BENEDICT',
          ipi: null,
          userId: null,
          unisonId: 'R8GI0SVS3',
          performanceSocietyId: null,
          mechanicalSocietyId: null,
          synchronizationSocietyId: null,
          id: 'f28ec6d5-df26-4477-8fef-9d70430e193f',
        },
      ],
      usedWorks: 1,
    },
  ],
  info: {
    count: 5,
    offset: '0',
    limit: '10',
  },
};
