import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { TerritoriesAPIResponse } from 'app/shared/interfaces';
import { CollectionShareTerritory, RepresentedTerritory, Territory } from 'app/shared/models';
import { TERRITORIES_MOCK } from '../mocks';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  async getCountries(offset = 0, limit = 999999, country = 'country'): Promise<Array<Territory>> {
    const filterCountry = `?type=${country}`;
    const filters = `&offset=${offset}&limit=${limit}`;

    const url = `${this.apiUrl}/territory${filterCountry}${filters}`;
    const response: TerritoriesAPIResponse = await this.http
      .get<TerritoriesAPIResponse>(url)
      .toPromise();
    const countriesFetched = new Territory().deserializeArray(response.territories);
    return countriesFetched;
  }

  async getTerritories(offset = 0, limit = 9999): Promise<Territory[]> {
    let territoriesFetched: Territory[] = [];
    if (environment.useMocks) {
      territoriesFetched = new Territory().deserializeArray(TERRITORIES_MOCK.territories);
    } else {
      const filters = `?offset=${offset}&limit=${limit}`;
      const url = `${this.apiUrl}/territory/${filters}`;
      const response: TerritoriesAPIResponse = await this.http
        .get<TerritoriesAPIResponse>(url)
        .toPromise();
      territoriesFetched = new Territory().deserializeArray(response.territories);
    }
    return territoriesFetched;
  }

  async getAvailableTerritoriesFromCollectionShareFatherToSon(
    workId: string,
    collectionShareId: string,
  ): Promise<Territory[]> {
    let territoriesFetched: Territory[] = [];
    const url = `${this.apiUrl}/composition/${workId}/collection-share/${collectionShareId}/son-territories`;
    const response: TerritoriesAPIResponse = await this.http
      .get<TerritoriesAPIResponse>(url)
      .toPromise();
    territoriesFetched = new Territory().deserializeArray(response.territories);

    return territoriesFetched;
  }

  async getRightsTerritoryInclusions(
    representedTerritories: Array<RepresentedTerritory | CollectionShareTerritory>,
  ): Promise<Territory[]> {
    const url = `${this.apiUrl}/territory/composite/get-inclusions`;
    const { territories }: TerritoriesAPIResponse = await this.http
      .post<TerritoriesAPIResponse>(url, { compositeTerritory: representedTerritories })
      .toPromise();

    return new Territory().deserializeArray(territories);
  }

  async getRightsTerritoryExclusions(
    representedTerritories: Array<RepresentedTerritory | CollectionShareTerritory>,
  ): Promise<Territory[]> {
    const url = `${this.apiUrl}/territory/composite/get-exclusions`;
    const { territories }: TerritoriesAPIResponse = await this.http
      .post<TerritoriesAPIResponse>(url, { compositeTerritory: representedTerritories })
      .toPromise();

    return new Territory().deserializeArray(territories);
  }
}
