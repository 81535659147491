import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Society, SocietyDTO } from '../models';

export interface SocietyResponse {
  societies: Society[];
}

@Injectable({
  providedIn: 'root',
})
export class SocietyService {
  readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  async getAllSocieties(name: string = '', code: number = -1): Promise<Society[]> {
    let filters = '';
    let amp = '?';
    if (name) {
      filters = `${amp}name=${name}`;
      amp = '&';
    }
    if (code >= 0) {
      filters = `${amp}code=${code}`;
    }

    const url = `${this.apiUrl}/society/${filters}`;
    const { societies: societiesApi } = await this.http
      .get<{ societies: Array<SocietyDTO> }>(url)
      .toPromise();
    return new Society().deserializeArray(societiesApi);
  }

  async createSocietyBatch(societies: Society[]): Promise<void> {
    const url = `${this.apiUrl}/society/batch`;

    await this.http.post(url, { societies }).toPromise();
  }
}
