<div class="tickets-table">
  <div class="table-responsive">
    <table
      id="repertoire-tickets-table"
      class="table repertoire-tickets-table mt-3"
      *ngIf="tickets?.length > 0; else noTickets"
    >
      <!-- <thead> -->
      <!-- <tr> -->
      <!-- <th class="table-column">
            <div class="font-weight-normal d-flex align-items-center">
              {{ "Ticket ID" | translate }}
            </div>
          </th>
          <th class="table-column-5">
            <div class="font-weight-normal">
              {{ "Title" | translate }}
            </div>
          </th>
          <th class="table-column">
            <div class="font-weight-normal">
              {{ "Type" | translate }}
            </div>
          </th> -->
      <!-- <th class="table-column-2">
            <div class="font-weight-normal">
              {{ "Date" | translate }}
            </div>
          </th> -->
      <!-- <th class="table-column-1">
            <div class="font-weight-normal">
              {{ "Status" | translate }}
            </div>
          </th> -->
      <!-- </tr> -->
      <!-- </thead> -->
      <tbody>
        <tr *ngFor="let ticket of tickets" class="table-row" [routerLink]="['/help/tickets', ticket.id]">
          <td class="table-column">
            <div class="date">
              {{ ticket.updatedAt | date: 'dd/MM/yyyy' }}
            </div>
            <div class="unison-id">
              {{ ticket.unisonId }}
            </div>
          </td>
          <!-- <td class="table-column">

          </td> -->
          <td class="table-column-5">
            <div class="title">
              <span *ngIf="ticket.work"> [{{ ticket.work.title }}] - </span>
              <!-- {{ ticket.description | truncate: 50 }} -->
            </div>
          </td>
          <td class="table-column">
            <div class="description">
              {{ ticket.description | truncate: '50' }}
              <!-- {{ ticket.topic }} -->
            </div>
          </td>

          <!-- <td class="table-column">
            <div class="text-capitalize">
              {{ ticket.status | translate }}
            </div>
          </td> -->
        </tr>
      </tbody>
    </table>
  </div>
  <!-- <nav class="d-flex justify-content-center">
    <pagination-template
      class="d-flex flex-column"
      #p="paginationApi"
      (pageChange)="changePage($event)"
    >
      <ul class="pagination justify-content-center">
        <li class="page-item" *ngIf="!p.isFirstPage()">
          <a class="page-link d-none d-md-block" (click)="p.previous()">
            {{ "Previous" | translate }}
          </a>
          <a class="page-link d-block d-md-none" (click)="p.previous()">
            <fa-icon [icon]="faChevronLeft" class="mr-2"></fa-icon>
          </a>
        </li>
        <li
          class="page-item"
          *ngFor="let page of p.pages; let i = index"
          [class.active]="p.getCurrent() === page.value"
        >
          <a
            class="page-link"
            (click)="p.setCurrent(page.value)"
            *ngIf="p.getCurrent() !== page.value"
          >
            <span>{{ page.label }}</span>
          </a>
          <a class="page-link" *ngIf="p.getCurrent() === page.value">
            <span>{{ page.label }}</span>
          </a>
        </li>
        <li class="page-item" *ngIf="!p.isLastPage()">
          <a class="page-link d-none d-md-block" (click)="p.next()">
            {{ "Next" | translate }}
          </a>
          <a class="page-link d-block d-md-none" (click)="p.next()">
            <fa-icon [icon]="faChevronRight" class="mr-2"></fa-icon>
          </a>
        </li>
      </ul>
      <div class="pagination d-flex justify-content-center">
        <div class="go-to-page d-flex align-items-center">
          <span class="jump-text">
            {{ "Jump to page" | translate }}
          </span>
          <div class="form-group mb-0 mx-2">
            <div class="input-group">
              <input
                type="number"
                class="form-control px-1"
                placeholder="{{ 'page' | translate }}"
                autocomplete="off"
                [(ngModel)]="pageToGo"
                name="go-to-page"
                id="go-to-page"
                (keyup.enter)="p.setCurrent(pageToGo)"
              />
            </div>
          </div>
          <a class="" (click)="p.setCurrent(pageToGo)">
            <span class="go-text">
              {{ "Go" | translate }}
              <fa-icon [icon]="faChevronRight" class="ml-2"></fa-icon>
            </span>
          </a>
        </div>
      </div>
    </pagination-template>
  </nav> -->
</div>

<ng-template #noTickets>
  <div class="no-tickets text-center">
    <p>{{ 'No open tickets' | translate }}</p>
  </div>
</ng-template>
