import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Document, DocumentDTO, DocumentTypes } from 'app/shared/models';
import { InfoResponseAPI } from 'app/shared/interfaces';
import { Observable } from 'rxjs';

export interface DocumentsResponse {
  documents: Document[];
  info: InfoResponseAPI;
}

interface DocumentFilters {
  type?: DocumentTypes | '';
  text: string;
}

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  private readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  async getDocuments(
    offset: number,
    limit: number,
    filters?: DocumentFilters,
  ): Promise<DocumentsResponse> {
    const params = {
      offset: offset ? offset.toString() : '',
      limit: limit ? limit.toString() : '',
      type: filters?.type || '',
      text: filters?.text || '',
    };

    const url = `${this.apiUrl}/resource/me/`;
    const { resources, info } = await this.http
      .get<{ resources: DocumentDTO[]; info: InfoResponseAPI }>(url, { params })
      .toPromise();

    return {
      documents: new Document().deserializeArray(resources),
      info,
    };
  }

  async getDocument(documentId: string): Promise<Document> {
    const url = `${this.apiUrl}/resource/${documentId}`;
    const { resource } = await this.http.get<{ resource: DocumentDTO }>(url).toPromise();

    return new Document().deserialize(resource);
  }

  async createDocument(document: Document, file: File): Promise<Document> {
    const url = `${this.apiUrl}/resource/`;
    const formData = new FormData();

    formData.append('file', file);
    formData.append('type', document.type);
    formData.append('userId', document.user ? document.user.id : document.userId);

    if (document.visible !== null && document.visible !== undefined) {
      formData.append('visible', `${document.visible}`);
    }

    formData.append('name', document.name);
    formData.append('description', document.description);

    const { resource: resourceAPI } = await this.http
      .post<{ resource: DocumentDTO }>(url, formData)
      .toPromise();

    return new Document().deserialize(resourceAPI);
  }

  async deleteDocument(documentId: string): Promise<void> {
    const url = `${this.apiUrl}/resource/${documentId}/`;

    await this.http.delete(url).toPromise();
  }

  downloadDocument(documentId: string): Observable<any> {
    const url = `${this.apiUrl}/resource/${documentId}/download`;

    return this.http.get(url, {
      observe: 'events', // 'body'
      // params: { cShares: 'true' },
      responseType: 'blob',
      reportProgress: true,
    });
  }

  async updateDocument(document: Document): Promise<Document> {
    const url = `${this.apiUrl}/resource/${document.id}`;
    const { resource: documentAPI } = await this.http
      .put<{ resource: DocumentDTO }>(url, document)
      .toPromise();

    return new Document().deserialize(documentAPI);
  }
}
