import { Serializable } from 'app/core/interfaces';
// eslint-disable-next-line import/no-cycle
import { UserDTO, User } from 'app/shared/models/user';

export interface RightHolderDTO {
  id?: string;
  name: string;
  ipi?: number;
  unisonId?: string;
  user?: UserDTO;
  userId?: string;
  // collectionShares: CollectionShare[];
  // ownershipShares: OwnershipShare[];
  updatedAt?: string;
  createdAt?: string;
  performanceSocietyId?: string;
  mechanicalSocietyId?: string;
  synchronizationSocietyId?: string;
}

export class RightHolder implements Serializable<RightHolderDTO> {
  id?: string;
  name: string;
  ipi?: number;
  unisonId?: string;
  user?: User;
  userId?: string;
  // collectionShares: CollectionShare[];
  // ownershipShares: OwnershipShare[];
  updatedAt?: string;
  createdAt?: string;

  deserialize(input: RightHolderDTO): RightHolder {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.name = input.name || '';
    this.ipi = input.ipi || 0;
    this.unisonId = input.unisonId || '';
    if (input.user) {
      this.user = new User().deserialize(input.user);
    } else {
      this.user = null;
    }
    this.userId = input.userId || '';
    // this.collectionShares = input.CollectionScollectionShares;
    // this.ownershipShares =input.OwnershipSownershipShares;
    this.updatedAt = input.updatedAt || '';
    this.createdAt = input.createdAt || '';

    return this;
  }

  deserializeArray(inputArray: Array<RightHolderDTO>): Array<RightHolder> {
    const rightHolders: Array<RightHolder> = inputArray.map((input) =>
      new RightHolder().deserialize(input),
    );

    return rightHolders;
  }
}
