<div class="disclaimer pb-3 mb-2">
  <div class="disclaimer-text">
    <h2 class="section-title">
      {{ "AUTH_DISCLAIMER.TC_UNISON_WEBSITE" | translate }}
    </h2>
    <!-- <h4 class="disclaimer-subtitle">
      {{ "AUTH_DISCLAIMER.TERMS_AND_CONDITIONS" | translate }}
    </h4> -->
    <h5 class="disclaimer-subtitle">
      {{ "AUTH_DISCLAIMER.SUMMARY_AND_PURPOSE" | translate }}
    </h5>
    <p>
      {{ "AUTH_DISCLAIMER.SUMMARY_AND_PURPOSE_TEXT" | translate }}
    </p>
    <h5 class="disclaimer-subtitle">
      {{ "AUTH_DISCLAIMER.ACCEPTANCE_OF_TC" | translate }}
    </h5>
    <p>
      {{ "AUTH_DISCLAIMER.ACCEPTANCE_OF_TC_TEXT" | translate }}
    </p>
    <h5 class="disclaimer-subtitle">
      {{ "AUTH_DISCLAIMER.PERSONAL_DATA" | translate }}
    </h5>
    <p>
      {{ "AUTH_DISCLAIMER.PERSONAL_DATA_TEXT" | translate }}
    </p>
    <h5 class="disclaimer-subtitle">
      {{ "AUTH_DISCLAIMER.ACCURACY_OF_USE_INFORMATION" | translate }}
    </h5>
    <p>
      {{ "AUTH_DISCLAIMER.ACCURACY_OF_USE_INFORMATION_TEXT" | translate }}
    </p>
    <h5 class="disclaimer-subtitle">
      {{ "AUTH_DISCLAIMER.EXCLUSION_OF_LIABILITY" | translate }}
    </h5>
    <p>
      {{ "AUTH_DISCLAIMER.EXCLUSION_OF_LIABILITY_TEXT" | translate }}
    </p>
    <h5 class="disclaimer-subtitle">
      {{ "AUTH_DISCLAIMER.UNISON_CUSTOMER_OBLIGATIONS" | translate }}
    </h5>
    <p>
      {{ "AUTH_DISCLAIMER.UNISON_CUSTOMER_OBLIGATIONS_TEXT" | translate }}
    </p>
    <ul>
      <li>{{ "AUTH_DISCLAIMER.UNISON_CUSTOMER_OBLIGATIONS_BULLET_1" | translate }}</li>
      <li>{{ "AUTH_DISCLAIMER.UNISON_CUSTOMER_OBLIGATIONS_BULLET_2" | translate }}</li>
      <li>{{ "AUTH_DISCLAIMER.UNISON_CUSTOMER_OBLIGATIONS_BULLET_3" | translate }}</li>
      <li>{{ "AUTH_DISCLAIMER.UNISON_CUSTOMER_OBLIGATIONS_BULLET_4" | translate }}</li>
      <li>{{ "AUTH_DISCLAIMER.UNISON_CUSTOMER_OBLIGATIONS_BULLET_5" | translate }}</li>
      <li>{{ "AUTH_DISCLAIMER.UNISON_CUSTOMER_OBLIGATIONS_BULLET_6" | translate }}</li>
      <li>{{ "AUTH_DISCLAIMER.UNISON_CUSTOMER_OBLIGATIONS_BULLET_7" | translate }}</li>
    </ul>
    <h5 class="disclaimer-subtitle">
      {{ "AUTH_DISCLAIMER.USE_BY_THIRD_PARTIES" | translate }}
    </h5>
    <p>
      {{ "AUTH_DISCLAIMER.USE_BY_THIRD_PARTIES_TEXT" | translate }}
    </p>
    <h5 class="disclaimer-subtitle">
      {{ "AUTH_DISCLAIMER.TRADE_SECRET" | translate }}
    </h5>
    <p>
      {{ "AUTH_DISCLAIMER.TRADE_SECRET_TEXT" | translate }}
    </p>
    <h5 class="disclaimer-subtitle">
      {{ "AUTH_DISCLAIMER.CONFIDENTIALITY" | translate }}
    </h5>
    <p>
      {{ "AUTH_DISCLAIMER.CONFIDENTIALITY_TEXT_A" | translate }}
    </p>
    <p>
      {{ "AUTH_DISCLAIMER.CONFIDENTIALITY_TEXT_B" | translate }}
    </p>
    <h5 class="disclaimer-subtitle">
      {{ "AUTH_DISCLAIMER.WAIVER_OF_CLAIMS" | translate }}
    </h5>
    <p>
      {{ "AUTH_DISCLAIMER.WAIVER_OF_CLAIMS_TEXT" | translate }}
    </p>
    <h5 class="disclaimer-subtitle">
      {{ "AUTH_DISCLAIMER.JURISDICTION_AND_APPLICABLE_LAW" | translate }}
    </h5>
    <p>
      {{ "AUTH_DISCLAIMER.JURISDICTION_AND_APPLICABLE_LAW_TEXT" | translate }}
    </p>
  </div>
  <div class="actions text-center mt-4" *ngIf="!isAuthenticated()">
    <div>
      <p class="text-center">{{ "AUTH_DISCLAIMER.ACCEPTATION" | translate }}</p>
    </div>
    <a class="btn btn-unison" routerLink="/">
      {{ "AUTH_DISCLAIMER.CANCEL" | translate }}
    </a>
    <button class="btn btn-unison primary" (click)="acceptDisclaimer()">
      {{ "AUTH_DISCLAIMER.ACCEPT" | translate }}
    </button>
  </div>
</div>
