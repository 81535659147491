import { WorkDTO } from 'app/shared/models';

export const WORKS_USAGES_DIGITAL_MOCK_Y: Array<WorkDTO> = [
  // Apple Music
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Apple Music',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 80000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 70000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Apple Music',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 65000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 60000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Apple Music',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 55000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 50000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Apple Music',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 35000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 25000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Apple Music',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 15000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  // Beatport
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step BEAT',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Beatport',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad BEAT",
    usages: 80000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Beatport',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices BEAT',
    usages: 70000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Beatport',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos BEAT',
    usages: 65000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Beatport',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step BEAT',
    usages: 60000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Beatport',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad BEAT",
    usages: 55000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Beatport',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices BEAT',
    usages: 50000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Beatport',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos BEAT',
    usages: 35000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Beatport',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step BEAT',
    usages: 25000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Beatport',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad BEAT",
    usages: 15000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Beatport',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  // Deezer
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step DEEZER',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Deezer',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad DEEZER",
    usages: 80000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Deezer',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices DEEZER',
    usages: 70000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Deezer',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos DEEZER',
    usages: 65000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Deezer',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step DEEZER',
    usages: 60000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Deezer',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad DEEZER",
    usages: 55000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Deezer',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices DEEZER',
    usages: 50000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Deezer',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos DEEZER',
    usages: 35000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Deezer',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step DEEZER',
    usages: 25000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Deezer',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad DEEZER",
    usages: 15000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Deezer',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  // Spotify
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step SPOTIFY',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Spotify',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad SPOTIFY",
    usages: 80000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices SPOTIFY',
    usages: 70000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Spotify',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos SPOTIFY',
    usages: 65000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step SPOTIFY',
    usages: 60000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Spotify',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad SPOTIFY",
    usages: 55000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices SPOTIFY',
    usages: 50000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Spotify',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos SPOTIFY',
    usages: 35000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step SPOTIFY',
    usages: 25000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Spotify',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad SPOTIFY",
    usages: 15000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  // Others
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step OTHERS',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Others',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad OTHERS",
    usages: 80000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Others',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices OTHERS',
    usages: 70000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Others',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos OTHERS',
    usages: 65000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Others',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step OTHERS',
    usages: 60000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Others',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad OTHERS",
    usages: 55000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Others',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices OTHERS',
    usages: 50000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Others',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos OTHERS',
    usages: 35000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Others',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step OTHERS',
    usages: 25000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Others',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad OTHERS",
    usages: 15000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Others',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
];
export const WORKS_USAGES_DIGITAL_MOCK_QT: Array<WorkDTO> = [
  // Apple Music
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 25000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Apple Music',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 20000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 17500,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Apple Music',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 16250,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 15000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Apple Music',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 13750,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 12500,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Apple Music',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 8750,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 6250,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Apple Music',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 3750,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  // Beatport
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step BEAT',
    usages: 25000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Beatport',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad BEAT",
    usages: 20000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Beatport',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices BEAT',
    usages: 17500,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Beatport',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos BEAT',
    usages: 16250,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Beatport',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step BEAT',
    usages: 15000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Beatport',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad BEAT",
    usages: 13750,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Beatport',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices BEAT',
    usages: 12500,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Beatport',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos BEAT',
    usages: 8750,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Beatport',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step BEAT',
    usages: 6250,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Beatport',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad BEAT",
    usages: 3750,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Beatport',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  // Deezer
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step DEEZER',
    usages: 25000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Deezer',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad DEEZER",
    usages: 20000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Deezer',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices DEEZER',
    usages: 17500,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Deezer',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos DEEZER',
    usages: 16250,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Deezer',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step DEEZER',
    usages: 15000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Deezer',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad DEEZER",
    usages: 13750,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Deezer',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices DEEZER',
    usages: 12500,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Deezer',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos DEEZER',
    usages: 8750,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Deezer',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step DEEZER',
    usages: 6250,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Deezer',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad DEEZER",
    usages: 3750,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Deezer',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  // Spotify
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step SPOTIFY',
    usages: 25000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Spotify',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad SPOTIFY",
    usages: 20000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices SPOTIFY',
    usages: 17500,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Spotify',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos SPOTIFY',
    usages: 16250,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step SPOTIFY',
    usages: 15000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Spotify',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad SPOTIFY",
    usages: 13750,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices SPOTIFY',
    usages: 12500,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Spotify',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos SPOTIFY',
    usages: 8750,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step SPOTIFY',
    usages: 6250,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Spotify',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad SPOTIFY",
    usages: 3750,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  // Others
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step OTHERS',
    usages: 25000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Others',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad OTHERS",
    usages: 20000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Others',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices OTHERS',
    usages: 17500,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Others',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos OTHERS',
    usages: 16250,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Others',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step OTHERS',
    usages: 15000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Others',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad OTHERS",
    usages: 13750,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Others',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices OTHERS',
    usages: 12500,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Others',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos OTHERS',
    usages: 8750,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Others',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step OTHERS',
    usages: 6250,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Others',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad OTHERS",
    usages: 3750,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Others',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
];
export const WORKS_USAGES_DIGITAL_MOCK_M: Array<WorkDTO> = [
  // Apple Music
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 8300,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Apple Music',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 6600,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 5800,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Apple Music',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 5400,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 5000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Apple Music',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 4580,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 4160,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Apple Music',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 2900,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 2000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Apple Music',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 1250,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  // Beatport
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step BEAT',
    usages: 8300,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Beatport',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad BEAT",
    usages: 6600,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Beatport',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices BEAT',
    usages: 5800,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Beatport',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos BEAT',
    usages: 5400,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Beatport',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step BEAT',
    usages: 5000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Beatport',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad BEAT",
    usages: 4580,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Beatport',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices BEAT',
    usages: 4160,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Beatport',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos BEAT',
    usages: 2900,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Beatport',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step BEAT',
    usages: 2000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Beatport',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad BEAT",
    usages: 1250,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Beatport',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  // Deezer
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step DEEZER',
    usages: 8300,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Deezer',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad DEEZER",
    usages: 6600,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Deezer',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices DEEZER',
    usages: 5800,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Deezer',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos DEEZER',
    usages: 5400,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Deezer',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step DEEZER',
    usages: 5000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Deezer',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad DEEZER",
    usages: 4580,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Deezer',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices DEEZER',
    usages: 4160,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Deezer',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos DEEZER',
    usages: 2900,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Deezer',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step DEEZER',
    usages: 2000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Deezer',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad DEEZER",
    usages: 1250,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Deezer',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  // Spotify
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step SPOTIFY',
    usages: 8300,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Spotify',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad SPOTIFY",
    usages: 6600,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices SPOTIFY',
    usages: 5800,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Spotify',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos SPOTIFY',
    usages: 5400,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step SPOTIFY',
    usages: 5000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Spotify',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad SPOTIFY",
    usages: 4580,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices SPOTIFY',
    usages: 4160,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Spotify',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos SPOTIFY',
    usages: 2900,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step SPOTIFY',
    usages: 2000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Spotify',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad SPOTIFY",
    usages: 1250,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  // Others
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step OTHERS',
    usages: 8300,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Others',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad OTHERS",
    usages: 6600,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Others',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices OTHERS',
    usages: 5800,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Others',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos OTHERS',
    usages: 5400,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Others',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step OTHERS',
    usages: 5000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Others',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad OTHERS",
    usages: 4580,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Others',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices OTHERS',
    usages: 4160,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Others',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos OTHERS',
    usages: 2900,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Others',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step OTHERS',
    usages: 2000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Others',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad OTHERS",
    usages: 1250,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Others',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
];

export const WORKS_USAGES_DIGITAL_MOCK: Array<WorkDTO> = [
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Deezer',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 85000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Youtube',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 75000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Deezer',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Youtube',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Deezer',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Youtube',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Deezer',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Youtube',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Deezer',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Youtube',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Deezer',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Youtube',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Deezer',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Youtube',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Deezer',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Youtube',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Deezer',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Youtube',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Deezer',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Youtube',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Deezer',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Youtube',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Deezer',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Youtube',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Deezer',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Spotify',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Youtube',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Apple Music',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Download',
  },
];
