<div class="row">
  <div class="col d-flex justify-content-start align-items-center">
    <img
      class="logo m-2"
      src="/assets/images/logo-unison-white.png"
      alt="Unison"
    />
  </div>
  <div class="col d-flex justify-content-end align-items-center">
    <span>
      {{ "UNITED FOR MUSIC" | translate }}
    </span>
  </div>
</div>
