import { Serializable } from 'app/core/interfaces';

interface RecordingPerformer {
  id: string;
  createdAt: string;
  updatedAt: string;
  recordingId: string;
  performerId: string;
}

export interface PerformerDTO {
  id?: string;
  firstName?: string;
  lastName?: string;
  ipi?: number;
  ipiBase?: number;
  updatedAt?: string;
  createdAt?: string;
  RecordingPerformer?: RecordingPerformer;
}

export class Performer implements Serializable<Performer> {
  id: string;
  firstName: string;
  lastName: string;
  ipi: number;
  ipiBase: number;
  updatedAt: string;
  createdAt: string;
  RecordingPerformer?: RecordingPerformer;

  deserialize(input: PerformerDTO): Performer {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.firstName = input.firstName || '';
    this.lastName = input.lastName || '';
    this.ipi = input.ipi || null;
    this.ipiBase = input.ipiBase || null;
    this.updatedAt = input.updatedAt || '';
    this.createdAt = input.createdAt || '';
    this.RecordingPerformer = input.RecordingPerformer ? input.RecordingPerformer : null;

    return this;
  }

  deserializeArray(inputArray: Array<PerformerDTO>): Array<Performer> {
    const Performers: Performer[] = inputArray.map((input) => new Performer().deserialize(input));

    return Performers;
  }

  clone(): Performer {
    const clonedInstance: Performer = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this,
    );

    return clonedInstance;
  }
}
