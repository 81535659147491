/* eslint-disable import/no-cycle */
import { Serializable } from 'app/core/interfaces';
import { Statement, StatementDTO } from './statement';

export enum TaxSign {
  PLUS = '+',
  MINUS = '-',
}

export class TaxDTO {
  id: string;
  sign: TaxSign;
  name: string;
  percentage: number;
  statementId: string;
  priority: number;
  createdAt: string;
  updatedAt: string;
  statement: StatementDTO;
}

export class Tax implements Serializable<Tax> {
  id: string;
  sign: TaxSign;
  name: string;
  percentage: number;
  statementId: string;
  priority: number;
  createdAt: string;
  updatedAt: string;
  statement: Statement;

  deserialize(input: TaxDTO): Tax {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.sign = input.sign || TaxSign.PLUS;
    this.name = input.name || '';
    this.percentage = input.percentage || 0;
    this.statementId = input.statementId || '';
    this.priority = input.priority || 0;
    this.createdAt = input.createdAt || '';
    this.updatedAt = input.updatedAt || '';
    this.statement = new Statement().deserialize(input.statement);

    return this;
  }

  deserializeArray(inputArray: Array<TaxDTO>): Array<Tax> {
    const taxes: Tax[] = inputArray.map((input) => new Tax().deserialize(input));

    return taxes;
  }

  clone(): Tax {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const clonedInstance: Tax = Object.assign(Object.create(Object.getPrototypeOf(this)), this);

    return clonedInstance;
  }
}
