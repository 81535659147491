import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResourceService {
  readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  downloadResource(resourceId: string): Observable<any> {
    const url = `${this.apiUrl}/resource/${resourceId}/download`;

    return this.http.get(url, {
      observe: 'events', // 'body'
      responseType: 'blob',
      reportProgress: false,
    });
  }
}
