import { RoyaltiesPlatformI } from 'app/shared/interfaces';

export const ROYALTIES_SOURCE_Y: RoyaltiesPlatformI[] = [
  {
    data: [25000, 40000, 46000, 48000, 50000, 52000, 55000, 58000, 60000, 64000, 65000, 75000],
    dataLabels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    label: 'Live',
  },
  {
    data: [
      28000,
      48000,
      56000,
      75000,
      86000,
      95000,
      102000,
      119000,
      138000,
      145700,
      150000,
      120000,
    ],
    dataLabels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    label: 'Digital',
  },
  {
    data: [38000, 48000, 65000, 76000, 80000, 88000, 92000, 105000, 110000, 120000, 125000, 145000],
    dataLabels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    label: 'Mechanical',
  },
  {
    data: [18000, 29000, 35000, 40000, 40000, 41000, 42000, 43000, 44000, 45000, 46000, 56000],
    dataLabels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    label: 'Radio/TV',
  },
  {
    data: [13000, 22000, 31000, 34000, 36000, 39000, 40000, 41000, 42000, 44000, 45000, 50000],
    dataLabels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    label: 'Background music',
  },
];

export const ROYALTIES_SOURCE_QT: RoyaltiesPlatformI[] = [
  // TODO not 12 values, 3 months?
  {
    data: [10000, 12000, 5000, 7000, 4000, 2000, 1000, 7000, 3000, 5600, 6500, 200],
    dataLabels: [0, 1, 2, 3],
    label: 'Live',
  },
  {
    data: [2000, 4080, 500, 750, 860, 950, 1000, 1000, 1380, 1457, 5000, 200],
    dataLabels: [0, 1, 2, 3],
    label: 'Digital',
  },
  {
    data: [8000, 5000, 5000, 7000, 800, 5000, 6500, 7800, 1200, 1200, 5000, 200],
    dataLabels: [0, 1, 2, 3],
    label: 'Mechanical',
  },
  {
    data: [1100, 1400, 2900, 4200, 1900, 3800, 4200, 4700, 4000, 4500, 4600, 200],
    dataLabels: [0, 1, 2, 3],
    label: 'Radio/TV',
  },
  {
    data: [900, 1200, 2300, 3800, 1600, 3300, 3800, 4300, 3500, 3800, 4000, 300],
    dataLabels: [0, 1, 2, 3],
    label: 'Background music',
  },
];

export const ROYALTIES_SOURCE_M: RoyaltiesPlatformI[] = [
  // TODO not 12 values, daily..
  {
    data: [250, 400, 460, 480, 500, 520, 550, 580, 600, 640, 650, 200],
    label: 'Live',
    dataLabels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
  {
    data: [280, 480, 560, 750, 860, 950, 1020, 1190, 1380, 1457, 150, 200],
    label: 'Digital',
    dataLabels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
  {
    data: [380, 480, 650, 760, 8, 880, 920, 1050, 11, 12, 1250, 200],
    label: 'Mechanical',
    dataLabels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
  {
    data: [180, 290, 350, 4, 4, 410, 420, 430, 440, 450, 460, 200],
    label: 'Radio/TV',
    dataLabels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
  {
    data: [130, 240, 310, 2, 2, 280, 350, 380, 390, 420, 410, 100],
    label: 'Background music',
    dataLabels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
];
