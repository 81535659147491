import { ValidatorFn } from '@angular/forms';

// export interface BooleanFn {
//   (): boolean;
// }
export type BooleanFn = () => boolean;

export function conditionalValidator(predicate: BooleanFn, validator: ValidatorFn): ValidatorFn {
  return (formControl) => {
    if (!formControl.parent) {
      return null;
    }
    if (predicate()) {
      return validator(formControl);
    }
    return null;
  };
}
