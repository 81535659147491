<!-- Default dropup button -->
<footer class="app-footer">
  <div class="btn-group dropup app-help btn-circle">
    <button
      type="button"
      class="btn"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <fa-icon [icon]="faQuestion"></fa-icon>
    </button>
    <div class="dropdown-menu dropdown-menu-right mb-3">
      <a class="dropdown-item" routerLink="/help/tickets">
        {{ "Need help" | translate }}
      </a>
      <a class="dropdown-item" routerLink="/help/faq">
        {{ "FAQ" | translate }}
      </a>
    </div>
  </div>
</footer>
