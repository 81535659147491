import type { Serializable } from 'app/core/interfaces';

import { Platform, PlatformDTO } from './platform';
import { Territory, TerritoryDTO } from './territory';

export interface UsageTerritoryPlatformDTO {
  plays?: number;
  platform?: PlatformDTO;
  territory?: TerritoryDTO;
}

export class UsageTerritoryPlatform implements Serializable<UsageTerritoryPlatform> {
  plays?: number;
  platform?: Platform;
  territory?: Territory;

  deserialize(input: UsageTerritoryPlatformDTO): UsageTerritoryPlatform {
    if (!input) {
      return this;
    }

    this.platform = input.platform ? new Platform().deserialize(input.platform) : undefined;
    this.plays = input.plays || 0;
    this.territory = input.territory ? new Territory().deserialize(input.territory) : undefined;

    return this;
  }

  deserializeArray(inputArray: Array<UsageTerritoryPlatformDTO>): Array<UsageTerritoryPlatform> {
    const usageTerritoryPlatforms: Array<UsageTerritoryPlatform> = inputArray.map((input) =>
      new UsageTerritoryPlatform().deserialize(input),
    );

    return usageTerritoryPlatforms;
  }

  clone(): UsageTerritoryPlatform {
    const clonedInstance: UsageTerritoryPlatform = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this,
    );

    return clonedInstance;
  }
}
