import { Serializable } from 'app/core/interfaces';
import { Territory, TerritoryDTO } from './territory';

export enum InclusionExclusionIndicator {
  INCLUDE = 'I',
  EXCLUDE = 'E',
}

export interface RepresentedTerritoryDTO {
  territoryId: string;
  sequenceNumber: number;
  inclusionExclusionIndicator: InclusionExclusionIndicator;
  id: string;
  territory: TerritoryDTO;
}

export class RepresentedTerritory implements Serializable<RepresentedTerritory> {
  territoryId: string;
  sequenceNumber: number;
  inclusionExclusionIndicator: InclusionExclusionIndicator;
  id: string;
  territory: TerritoryDTO;

  constructor() {}

  deserialize(input: RepresentedTerritoryDTO): RepresentedTerritory {
    if (!input) {
      return this;
    }

    this.territoryId = input.territoryId || '';
    this.sequenceNumber = input.sequenceNumber || 1;
    this.inclusionExclusionIndicator =
      input.inclusionExclusionIndicator || InclusionExclusionIndicator.INCLUDE;
    this.id = input.id || '';
    this.territory = new Territory().deserialize(input.territory);

    return this;
  }

  deserializeArray(inputArray: Array<RepresentedTerritoryDTO>): Array<RepresentedTerritory> {
    const RepresentedTerritoryArray: Array<RepresentedTerritory> = inputArray.map((input) =>
      new RepresentedTerritory().deserialize(input),
    );

    return RepresentedTerritoryArray;
  }

  clone(): RepresentedTerritory {
    const clonedInstance: RepresentedTerritory = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this,
    );

    return clonedInstance;
  }
}
