import { Serializable } from 'app/core/interfaces';

export interface PlatformDTO {
  id: string;
  name: string;
  updatedAt?: string;
  createdAt?: string;
}

export class Platform implements Serializable<Platform> {
  id: string;
  name: string;
  updatedAt?: string;
  createdAt?: string;

  deserialize(input: PlatformDTO): Platform {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.name = input.name || '';
    this.createdAt = input.createdAt || '';
    this.updatedAt = input.updatedAt || '';

    return this;
  }

  deserializeArray(inputArray: Array<PlatformDTO>): Array<Platform> {
    const currencies: Array<Platform> = inputArray.map((input) =>
      new Platform().deserialize(input),
    );

    return currencies;
  }

  clone(): Platform {
    const clonedInstance: Platform = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this,
    );

    return clonedInstance;
  }
}
