import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from 'app/modules/auth/services';

@Injectable()
export class IsAdminLoggedGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(): boolean {
    if (this.authService.isAdminLogged()) {
      return true;
    }

    void this.router.navigate(['/']);
    return false;
  }
}
