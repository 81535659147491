<div class="empty-financial-data-bar" *ngIf="mustShowBar">
  <div class="container">
    <div class="row">
      <div class="col-8">
        <p class="mb-0">{{ 'SORRY, YOUR FINANCIAL DATA IS INCOMPLETE, Please fill your data' | translate }}</p>
      </div>
      <div class="col-4 text-right" *ngIf="!isInFinancialData">
        <a routerLink="/profile/financial-data">{{ 'Complete financial data' | translate }}</a>
      </div>
    </div>
  </div>
</div>
