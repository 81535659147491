import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Recording, RecordingDTO } from 'app/shared/models';
import { RECORDINGS_WORK_MOCK } from 'app/shared/mocks';
import { InfoResponseAPI } from '../interfaces';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class RecordingService {
  private readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  async getRecordingsFromWork(
    workId: string,
    offset = 0,
    limit = 10,
  ): Promise<{ recordings: Array<Recording>; info: InfoResponseAPI }> {
    const filters = `?offset=${offset}&limit=${limit}`;
    const url = `${this.apiUrl}/composition/${workId}/recording/${filters}`;
    const response = await this.http
      .get<{ recordings: Array<RecordingDTO>; info: InfoResponseAPI }>(url, httpOptions)
      .toPromise();
    const recordings = new Recording().deserializeArray(response.recordings);

    return {
      recordings,
      info: response.info,
    };
  }

  async getRecording(recordingId: string): Promise<Recording> {
    let recordingFetched: Recording;
    if (environment.useMocks) {
      const recordingMock = RECORDINGS_WORK_MOCK.filter(
        (recording: RecordingDTO) => recording.id === recordingId,
      );
      recordingFetched = new Recording().deserialize(recordingMock[0]);
    } else {
      const url = `${this.apiUrl}/recording/${recordingId}/`;
      const response = await this.http.get<{ recording: RecordingDTO }>(url).toPromise();
      recordingFetched = new Recording().deserialize(response.recording);
    }
    return recordingFetched;
  }

  async addRecordingToWork(workId: string, recording: Recording): Promise<Recording> {
    const url = `${this.apiUrl}/composition/${workId}/recording/`;
    const response = await this.http.post<{ recording: RecordingDTO }>(url, recording).toPromise();
    return new Recording().deserialize(response.recording);
  }

  async updateRecording(recording: Recording): Promise<Recording> {
    const url = `${this.apiUrl}/recording/${recording.id}/`;
    const response = await this.http.put<{ recording: RecordingDTO }>(url, recording).toPromise();
    return new Recording().deserialize(response.recording);
  }

  async deleteRecordingFromWork(workId: string, recordingId: string): Promise<void> {
    const url = `${this.apiUrl}/composition/${workId}/recording/${recordingId}`;
    await this.http.delete(url).toPromise();
  }
}
