export const SEQUENCES_MOCK = [
  {
    id: '2136-2101-2120',
    name: 'WORLD-AMERICA-EUROPE',
  },
  {
    id: '4',
    name: 'AFGHANISTAN',
  },
  {
    id: '2101',
    name: 'AMERICA',
  },
  {
    id: '2120',
    name: 'EUROPE',
  },
];
