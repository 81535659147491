import { WorkSearchByTextResponseAPII } from 'app/shared/interfaces';
// import { WorkDTO } from 'app/shared/models';

export const WORKS_ALL_MOCK_2: WorkSearchByTextResponseAPII = {
  compositions: [
    {
      id: '45edb24c-a6ab-11e8-98d0-529269fb1459',
      unisonId: 'CO1V029Q184C',
      title: 'FLEEING CHANCES',
      iswc: 'T2649413281',
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-25T10:43:18.354Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-06-25T10:43:20.378Z',
      updatedAt: '2020-06-25T10:43:20.378Z',
      createdBy: null,
      language: 'EN',
    },
    {
      id: '96276ef0-6ad9-48bd-af2d-c18a87a5ee77',
      unisonId: 'COGDX06BM9KM',
      title: 'THE MAD SOUNDS OF CONFLICT   THE CONFRATERNITY OF',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:34:55.509Z',
      submitterWorkN: '0001226',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:34:55.510Z',
      updatedAt: '2020-06-26T11:34:55.558Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '316ba330-6d0c-4f1d-917e-b1ac8a8e4626',
      unisonId: 'COM4JBN2OEDA',
      title: 'I WANNA LIE  WHERE THE FLOWERS GROW',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:34:56.208Z',
      submitterWorkN: '0001236',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:34:56.209Z',
      updatedAt: '2020-06-26T11:34:56.277Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '38b57c44-2670-4cb1-870e-e2dd24011f17',
      unisonId: 'CONRA5CCBURU',
      title: 'WHEN A GHOST FLIES YOUNG',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:34:56.508Z',
      submitterWorkN: '0001240',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:34:56.509Z',
      updatedAt: '2020-06-26T11:34:56.565Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '7dec9c49-906b-4a9f-a832-853029ff2917',
      unisonId: 'COGFB7ZZSKZ2',
      title: 'FLYING MACHINE BLUES',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:34:56.688Z',
      submitterWorkN: '0001243',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:34:56.690Z',
      updatedAt: '2020-06-26T11:34:56.725Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '06b6ab38-b68a-4d41-bc06-53ff6195410f',
      unisonId: 'COACG5FQ2H5T',
      title: 'BEIJA FLOR APAIXONADO',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:29.914Z',
      submitterWorkN: '0001055',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:29.916Z',
      updatedAt: '2020-06-26T11:35:29.957Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'ba9fea2f-1a11-4bb3-97ba-68e47e0d5b8e',
      unisonId: 'COARWDH6C2BZ',
      title: 'BEIJANDO A FLOR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:29.965Z',
      submitterWorkN: '0001056',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:29.966Z',
      updatedAt: '2020-06-26T11:35:30.007Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'afffe2d6-3016-4b22-a505-e6b0705a07ec',
      unisonId: 'COUXBJNVLNRR',
      title: 'FLOR DE TANGERINA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:33.307Z',
      submitterWorkN: '0001116',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:33.308Z',
      updatedAt: '2020-06-26T11:35:33.349Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '931736af-6a8d-408b-8144-d377aa2044a0',
      unisonId: 'COHNEIGSF92T',
      title: 'A TELEFONISTA NA FLORESTA PREDIAL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:33.042Z',
      submitterWorkN: '0069861',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:33.044Z',
      updatedAt: '2020-06-26T11:35:33.088Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'ee47415f-f08c-4916-9c96-ed2424e9ed03',
      unisonId: 'CO8FLFOGQA71',
      title: 'A ALMA AFOITA DA REVOLUCAO',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:32.626Z',
      submitterWorkN: '0069749',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:32.628Z',
      updatedAt: '2020-06-26T11:35:32.678Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '04318f69-bb60-4753-95d5-24b374e86002',
      unisonId: 'COHUQV4PS16A',
      title: 'A BUNDA E A FLOR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:33.215Z',
      submitterWorkN: '0069758',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:33.216Z',
      updatedAt: '2020-06-26T11:35:33.260Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'e59be95c-dfff-4ea3-9f75-2c94dbbddde3',
      unisonId: 'COEYFLOW943I',
      title: 'TRISTE COMEDIA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:36.131Z',
      submitterWorkN: '0001205',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:36.132Z',
      updatedAt: '2020-06-26T11:35:36.170Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '168207ea-12a4-4be1-9dbf-2a0e7c2cd34c',
      unisonId: 'COYD7KMZ5RWL',
      title: 'A FLOR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:35.178Z',
      submitterWorkN: '0069792',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:35.181Z',
      updatedAt: '2020-06-26T11:35:35.259Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'cdaea2a4-049c-4a97-8772-4123d70522b0',
      unisonId: 'COJ8VI96CY9B',
      title: 'A FLOR DO SERTAO',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:35.271Z',
      submitterWorkN: '0069793',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:35.272Z',
      updatedAt: '2020-06-26T11:35:35.327Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '3a7231ee-1322-4b66-89e1-2af1fb4d1a51',
      unisonId: 'COCDKPXFBKDK',
      title: 'A GRANDE FLORESTA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:35.762Z',
      submitterWorkN: '0069802',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:35.763Z',
      updatedAt: '2020-06-26T11:35:35.799Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'b99dc3bc-73bb-45c4-8bb2-53fe3d613cf1',
      unisonId: 'COGY9V0ZXAWW',
      title: 'A JOGADA DA FLOR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:35.893Z',
      submitterWorkN: '0069805',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:35.895Z',
      updatedAt: '2020-06-26T11:35:35.933Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '64d120a3-d109-4ef7-9751-8e8aa2df49e6',
      unisonId: 'COTS4NFLSCX2',
      title: 'AIYE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:37.277Z',
      submitterWorkN: '0069938',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:37.279Z',
      updatedAt: '2020-06-26T11:35:37.342Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '5fd9d979-3fa0-49e1-be4e-8a6f58c1bea7',
      unisonId: 'COS5VFR5OCMT',
      title: 'CANCAO PARA O BEIJA FLOR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:42.499Z',
      submitterWorkN: '0070363',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:42.500Z',
      updatedAt: '2020-06-26T11:35:42.557Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '2d349453-0329-4fc8-9b85-183876fd61e3',
      unisonId: 'COGBCU70IN13',
      title: 'AS FLORES',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:40.323Z',
      submitterWorkN: '0070081',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:40.326Z',
      updatedAt: '2020-06-26T11:35:40.373Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '128988a6-f96d-4083-ab65-5d8a64f65eca',
      unisonId: 'CO15E4N7FL4S',
      title: 'BALANCA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:40.899Z',
      submitterWorkN: '0070161',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:40.901Z',
      updatedAt: '2020-06-26T11:35:40.951Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'c9363d7e-322a-4060-972c-eb7c2fba8684',
      unisonId: 'COSS46UA7ULU',
      title: 'BANDA DA FLORESTA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:41.443Z',
      submitterWorkN: '0070172',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:41.444Z',
      updatedAt: '2020-06-26T11:35:41.489Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '53f101f4-98c8-434e-9c24-c28c124e3b40',
      unisonId: 'COZ4H9DKDCC9',
      title: 'ASTRONAUTA NO ASFLATO',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:41.565Z',
      submitterWorkN: '0070103',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:41.566Z',
      updatedAt: '2020-06-26T11:35:41.594Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '14ef25d6-734b-4f7c-adc9-d98c1d254d58',
      unisonId: 'COHNF9W9FHJ7',
      title: 'BEIJA FLOR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:42.922Z',
      submitterWorkN: '0070203',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:42.923Z',
      updatedAt: '2020-06-26T11:35:42.984Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'e208420f-6550-472c-89e9-d036a6ed3e4f',
      unisonId: 'COPRQ0B12RZO',
      title: 'BEIJANDO A FLORA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:43.000Z',
      submitterWorkN: '0070204',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:43.001Z',
      updatedAt: '2020-06-26T11:35:43.044Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'dcca5306-92c6-48f4-8622-81c4ba7686fb',
      unisonId: 'COV9ABLQJAJH',
      title: 'BELA FLOR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:43.382Z',
      submitterWorkN: '0070212',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:43.384Z',
      updatedAt: '2020-06-26T11:35:43.416Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '4fc57796-b974-40a9-a7f7-cb0636aafd04',
      unisonId: 'COF0I1QPRMW5',
      title: 'CAATINGA FLORESTA BRANCA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:43.882Z',
      submitterWorkN: '0070306',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:43.883Z',
      updatedAt: '2020-06-26T11:35:43.915Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '7bb1856b-b3d6-4d1b-b7ca-4e5f70adbc49',
      unisonId: 'COG3FL42HRTP',
      title: 'CHAPADAO',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:52.459Z',
      submitterWorkN: '0070469',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:52.460Z',
      updatedAt: '2020-06-26T11:35:52.512Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'e0e5bc9e-5bbc-41a4-8343-0e4119566991',
      unisonId: 'COOO9JFFL25L',
      title: 'DIAS ASSIM',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:54.130Z',
      submitterWorkN: '0070818',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:54.133Z',
      updatedAt: '2020-06-26T11:35:54.174Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '6c08f10d-a1a5-49e2-8142-e648cd779d66',
      unisonId: 'COZXNVSNBH0S',
      title: 'CHEIRO DE FLOR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:52.953Z',
      submitterWorkN: '0070479',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:52.955Z',
      updatedAt: '2020-06-26T11:35:52.987Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '56dfe890-f0f3-4263-97f9-021fed63dfac',
      unisonId: 'CON1FL4JPWFG',
      title: 'CHEIRO DE TERRA MOLHADA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:52.992Z',
      submitterWorkN: '0070480',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:52.994Z',
      updatedAt: '2020-06-26T11:35:53.039Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '2176978b-7e53-4fa1-b1bd-accd4e43fda1',
      unisonId: 'COYVT3RS6SVY',
      title: 'ESPUMAS FLUTUANTES',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:00.225Z',
      submitterWorkN: '0071054',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:00.226Z',
      updatedAt: '2020-06-26T11:36:00.253Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '5fbccec7-1681-4ff7-b757-7b2db63f4be9',
      unisonId: 'COTSKP402FLV',
      title: 'DOAR UM TEMPO',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:56.903Z',
      submitterWorkN: '0070863',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:56.905Z',
      updatedAt: '2020-06-26T11:35:56.950Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '2f973929-2631-46fa-b4a7-f2491d339483',
      unisonId: 'COEUDMFWLEQN',
      title: 'DA UMA FLOR PRO SEU AMOR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:58.990Z',
      submitterWorkN: '0070696',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:58.992Z',
      updatedAt: '2020-06-26T11:35:59.032Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'd90c8be8-422e-4da4-8d8d-3bcbdcd9063c',
      unisonId: 'COKYYFLVT8V7',
      title: 'DUAS FACES',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:35:59.944Z',
      submitterWorkN: '0070913',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:35:59.946Z',
      updatedAt: '2020-06-26T11:35:59.973Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '1f873ce3-f80a-4e2f-8d73-1e20b84f0e05',
      unisonId: 'COQJQMM3RXE8',
      title: 'FLAUTA DA DESPEDIDA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:10.004Z',
      submitterWorkN: '0071210',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:10.007Z',
      updatedAt: '2020-06-26T11:36:10.056Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '85590204-5b36-4f7c-ab9e-9d8f03a6565e',
      unisonId: 'COH8YHM7LFUD',
      title: 'ESSENCIA DE UMA FLOR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:00.964Z',
      submitterWorkN: '0071070',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:00.965Z',
      updatedAt: '2020-06-26T11:36:01.008Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '850b2d92-3511-44e2-97bf-28fe93c06eb8',
      unisonId: 'CORY1DFV0K37',
      title: 'EU NAO QUERO VIRAR COUVE FLOR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:03.150Z',
      submitterWorkN: '0071105',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:03.151Z',
      updatedAt: '2020-06-26T11:36:03.203Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'b7bea467-be3b-4fb0-8daa-9a3e397edf64',
      unisonId: 'COQO1M1PMUV5',
      title: 'FLAUTA DO ABANDONO',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:10.063Z',
      submitterWorkN: '0071211',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:10.065Z',
      updatedAt: '2020-06-26T11:36:10.116Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'c8b25801-5007-4501-be55-03c7e589daec',
      unisonId: 'CO2S2FV4EOO8',
      title: 'FLIPPA RIDDIM',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:10.125Z',
      submitterWorkN: '0071212',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:10.127Z',
      updatedAt: '2020-06-26T11:36:10.173Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '60b4787e-50c4-4272-a869-0f0bf4442929',
      unisonId: 'COSP08I0JF63',
      title: 'FLOR D AGUA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:10.181Z',
      submitterWorkN: '0071213',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:10.182Z',
      updatedAt: '2020-06-26T11:36:10.229Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '167f34cd-b674-4aa9-bcad-665663a47b03',
      unisonId: 'COQQG228REWO',
      title: 'FLOR DA CANA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:10.240Z',
      submitterWorkN: '0071214',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:10.242Z',
      updatedAt: '2020-06-26T11:36:10.288Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '25f075f9-00b1-4697-a18f-7ae8125d99ae',
      unisonId: 'CO5PUNV4NV2M',
      title: 'FLOR DA PAIXAO',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:10.301Z',
      submitterWorkN: '0071215',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:10.303Z',
      updatedAt: '2020-06-26T11:36:10.363Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '1b1bc110-ac7d-474b-8ec3-f2fabf5609d6',
      unisonId: 'COM5S53VJQZ1',
      title: 'FLOR DE CAMALOTE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:10.368Z',
      submitterWorkN: '0071216',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:10.370Z',
      updatedAt: '2020-06-26T11:36:10.407Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '97a97cad-329c-4245-acad-40ecbb52f860',
      unisonId: 'COVEOZG2W2LK',
      title: 'FLOR DE LIRA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:10.413Z',
      submitterWorkN: '0071217',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:10.415Z',
      updatedAt: '2020-06-26T11:36:10.460Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'ddd43b81-af5e-41c7-a44b-cf42eb5d186b',
      unisonId: 'CO8N5VCQJPZK',
      title: 'FLOR DE LIS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:10.472Z',
      submitterWorkN: '0071218',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:10.474Z',
      updatedAt: '2020-06-26T11:36:10.585Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'debae3d6-8f38-4b28-96da-c4d9f4a4ece6',
      unisonId: 'CODZKSWOWI5T',
      title: 'FLOR DE ROSA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:10.598Z',
      submitterWorkN: '0071219',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:10.600Z',
      updatedAt: '2020-06-26T11:36:10.642Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '30c18a35-b665-467e-add7-7d26fd25f840',
      unisonId: 'CO5EGWFKDAMD',
      title: 'FLOR DO DIA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:10.650Z',
      submitterWorkN: '0071220',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:10.652Z',
      updatedAt: '2020-06-26T11:36:10.693Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '05a66666-31a0-411a-9901-820aaa345c5a',
      unisonId: 'COBZXHFL39OR',
      title: 'EMANUEL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:03.422Z',
      submitterWorkN: '0070989',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:03.423Z',
      updatedAt: '2020-06-26T11:36:03.446Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '8680d4d9-b8fe-4624-a2da-5f7e0f2c5b33',
      unisonId: 'CO6ERFLF3S30',
      title: 'EU QUERO VER GOL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:03.509Z',
      submitterWorkN: '0071112',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:03.510Z',
      updatedAt: '2020-06-26T11:36:03.554Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'e72bb431-9825-4b5a-8fc9-00c38ad198ff',
      unisonId: 'COQA0NMW8AWS',
      title: 'FLOR E ESPINHO',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:10.701Z',
      submitterWorkN: '0071221',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:10.703Z',
      updatedAt: '2020-06-26T11:36:10.742Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '7e77ec8d-a958-4093-9b7b-0ad72435512a',
      unisonId: 'COZKNT0K7FTR',
      title: 'FLOR VOADEIRA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:10.758Z',
      submitterWorkN: '0071222',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:10.760Z',
      updatedAt: '2020-06-26T11:36:10.829Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '2f4c3f82-38a1-4e3c-8209-1057ffd2b9a2',
      unisonId: 'CO1ZNHRQB1M9',
      title: 'FLORES',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:10.839Z',
      submitterWorkN: '0071223',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:10.841Z',
      updatedAt: '2020-06-26T11:36:10.881Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'd3f64f86-4f91-4360-94d2-b77ded263f47',
      unisonId: 'COB9BNWDZBKS',
      title: 'FLORES DE SAMBA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:10.891Z',
      submitterWorkN: '0071224',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:10.892Z',
      updatedAt: '2020-06-26T11:36:10.940Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'f0f7cee5-f05a-4f0d-aac8-df1285260dda',
      unisonId: 'CO7ZDV6MZBQ5',
      title: 'FLORES DO CERRADO',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:10.951Z',
      submitterWorkN: '0071225',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:10.954Z',
      updatedAt: '2020-06-26T11:36:11.002Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'e6422bf9-8610-4e9f-8a04-6d4be3b2276e',
      unisonId: 'COXBRN554SJ4',
      title: 'FLORESTA DOS ENCANTOS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:11.016Z',
      submitterWorkN: '0071226',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:11.019Z',
      updatedAt: '2020-06-26T11:36:11.064Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '1f1a12d1-d624-4d01-9620-4ac93ad07615',
      unisonId: 'COWBLGHH17FL',
      title: 'AMOR ROXO',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:05.516Z',
      submitterWorkN: '0069999',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:05.518Z',
      updatedAt: '2020-06-26T11:36:05.569Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '81812cd8-810c-4d39-98fc-09e68ec396a1',
      unisonId: 'CO4M03ZTD754',
      title: 'INFLAMAVEL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:11.940Z',
      submitterWorkN: '0071466',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:11.941Z',
      updatedAt: '2020-06-26T11:36:11.999Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '0b807420-cdea-4eb5-9ebe-102789521fb1',
      unisonId: 'CO1BZFLLE10F',
      title: 'FOLGA NEGO BRANCO NAO VEM CA SE VIE PAU E DE LEVA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:11.665Z',
      submitterWorkN: '0071237',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:11.675Z',
      updatedAt: '2020-06-26T11:36:11.704Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '6f57c265-4195-44c6-a63f-aab0b323778b',
      unisonId: 'CO9M6OEFLXJ1',
      title: 'INQUIETUDE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:12.270Z',
      submitterWorkN: '0071471',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:12.272Z',
      updatedAt: '2020-06-26T11:36:12.313Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '17a88783-f59e-4a32-8187-6062bf500c29',
      unisonId: 'CO7HDQXB5D3G',
      title: 'FURIA E FLOR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:15.775Z',
      submitterWorkN: '0071312',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:15.776Z',
      updatedAt: '2020-06-26T11:36:15.805Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'd943de3c-d624-41a5-9e14-5cec8e27d341',
      unisonId: 'COUZ326CPFLO',
      title: 'GARGULAS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:16.369Z',
      submitterWorkN: '0071325',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:16.370Z',
      updatedAt: '2020-06-26T11:36:16.426Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '8f0f30b4-66a3-4b54-b36b-5629a87335ea',
      unisonId: 'COHYZXLTP14Y',
      title: 'JIMI SHUFFLER',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:15.215Z',
      submitterWorkN: '0071525',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:15.217Z',
      updatedAt: '2020-06-26T11:36:15.244Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'd29db9c0-0021-4939-ba01-8ffcda68b98f',
      unisonId: 'COFLCQT05K9R',
      title: 'MEU JEITO DE CANTAR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:23.622Z',
      submitterWorkN: '0071851',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:23.623Z',
      updatedAt: '2020-06-26T11:36:23.658Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '4bc3d615-abc3-4334-8163-f389537d6551',
      unisonId: 'CO0M35NBR1PB',
      title: 'MARIA FLOR DIVINA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:19.635Z',
      submitterWorkN: '0071753',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:19.638Z',
      updatedAt: '2020-06-26T11:36:19.667Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '50a14c36-23f1-40f1-bbb5-de8552bd697a',
      unisonId: 'CO8FLLMZU4PQ',
      title: 'ME DEVORA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:21.098Z',
      submitterWorkN: '0071779',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:21.099Z',
      updatedAt: '2020-06-26T11:36:21.140Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '80767c7f-812e-46e0-a356-c52cd6fafdbe',
      unisonId: 'COXXRUXOEU12',
      title: 'MYSTERIOUS FLING',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:25.310Z',
      submitterWorkN: '0071976',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:25.311Z',
      updatedAt: '2020-06-26T11:36:25.349Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'a0a92094-589d-4ecb-8fa8-f0c600e99632',
      unisonId: 'CO32ALCXFLUC',
      title: 'O SABIA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:28.376Z',
      submitterWorkN: '0072270',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:28.377Z',
      updatedAt: '2020-06-26T11:36:28.411Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '9775680c-8915-4f27-b80c-efb1f8d119c5',
      unisonId: 'CO2UWFLZFH6J',
      title: 'PE DE CAJUEIRO',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:32.099Z',
      submitterWorkN: '0072486',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:32.102Z',
      updatedAt: '2020-06-26T11:36:32.139Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'fe9a8a68-f778-46df-8bf2-698022fa6862',
      unisonId: 'COYJVFLKMAJD',
      title: 'PRENDAS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:33.986Z',
      submitterWorkN: '0072647',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:33.987Z',
      updatedAt: '2020-06-26T11:36:34.040Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '542705e7-ead2-45be-a9cc-736f1599c853',
      unisonId: 'COW1V6NXVFLT',
      title: 'PAINHO',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:33.234Z',
      submitterWorkN: '0072425',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:33.236Z',
      updatedAt: '2020-06-26T11:36:33.277Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '425818ae-c16f-4264-bab4-987caa4d1014',
      unisonId: 'CO3WU6FIM0Z3',
      title: 'PALAVRAS COMO FLECHAS ENVENENADAS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:33.439Z',
      submitterWorkN: '0072430',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:33.441Z',
      updatedAt: '2020-06-26T11:36:33.467Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'c9696a7d-66af-48b4-9f2c-f34ea0e2b38e',
      unisonId: 'CO1B19IFL0IS',
      title: 'POR ELA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:32.682Z',
      submitterWorkN: '0072581',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:32.683Z',
      updatedAt: '2020-06-26T11:36:32.722Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '760194ef-fb88-41d1-ac8e-7ff77c370742',
      unisonId: 'COV797W3K3E7',
      title: 'PANFLETO',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:33.740Z',
      submitterWorkN: '0072436',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:33.741Z',
      updatedAt: '2020-06-26T11:36:33.769Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '811f78c6-b25b-41e3-bc99-3a1bd60f7daf',
      unisonId: 'COJJ6Q1TFL54',
      title: 'SAPO CURURU',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:46.825Z',
      submitterWorkN: '0072952',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:46.826Z',
      updatedAt: '2020-06-26T11:36:46.878Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'ab0b7961-a6b7-41b9-b542-977f8e05d321',
      unisonId: 'CO1W3W9TDWLS',
      title: 'REFLEXO DAS HORAS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:38.353Z',
      submitterWorkN: '0072789',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:38.354Z',
      updatedAt: '2020-06-26T11:36:38.403Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '02cabb74-9cf7-429b-90f2-0eb99b599d83',
      unisonId: 'COXN7FAO7UYS',
      title: 'REFLORESTA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:38.412Z',
      submitterWorkN: '0072790',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:38.414Z',
      updatedAt: '2020-06-26T11:36:38.467Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '888b5dcd-aa1a-40fc-8dca-5b5900a65214',
      unisonId: 'CO1WO7DH0WT7',
      title: 'RUFLAR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:39.419Z',
      submitterWorkN: '0072887',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:39.422Z',
      updatedAt: '2020-06-26T11:36:39.464Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '14e91e2c-aa03-4ef1-9cb8-3c9e18f6d873',
      unisonId: 'CO8FLP3B3S1F',
      title: 'SALVE OS MESTRES',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:40.760Z',
      submitterWorkN: '0072907',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:40.763Z',
      updatedAt: '2020-06-26T11:36:40.821Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '47aad917-fee8-405c-ac67-e7574e75079a',
      unisonId: 'COLUZEAVAKXO',
      title: 'SE VOCE QUISER FLORES',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:49.258Z',
      submitterWorkN: '0072991',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:49.260Z',
      updatedAt: '2020-06-26T11:36:49.298Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '5dbf0f5a-01c1-4c06-ad54-bf492d18900d',
      unisonId: 'CO5USFLF3RH2',
      title: 'SEGREDOS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:49.868Z',
      submitterWorkN: '0073002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:49.869Z',
      updatedAt: '2020-06-26T11:36:49.903Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '0c782d68-4601-4237-8da1-2c667c0ea6bf',
      unisonId: 'COK1FLN6VJZN',
      title: 'SEI QUE VOU CHORAR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:50.272Z',
      submitterWorkN: '0073009',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:50.274Z',
      updatedAt: '2020-06-26T11:36:50.319Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '6d4535ec-ef1f-4d8d-b733-ed1f9a52cdec',
      unisonId: 'COVL1NRQYVTV',
      title: 'THE RIVER FLOWS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:57.292Z',
      submitterWorkN: '0073350',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:57.295Z',
      updatedAt: '2020-06-26T11:36:57.354Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '55dc6510-5079-48eb-a93d-b4a197a0a0cb',
      unisonId: 'COYL9TFL8EYY',
      title: 'SENTIR E AMAR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:57.360Z',
      submitterWorkN: '0073051',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:57.364Z',
      updatedAt: '2020-06-26T11:36:57.419Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'b0266723-4575-4da2-8180-8ec909926f25',
      unisonId: 'COZ4MFLA2Z39',
      title: 'SOBREVIVENTES',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:36:57.468Z',
      submitterWorkN: '0073149',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:36:57.469Z',
      updatedAt: '2020-06-26T11:36:57.502Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '1966dd44-fe74-4412-85eb-401000cc3541',
      unisonId: 'COMVTCJX4JVY',
      title: 'UM SORRISO  UM BEIJO E UMA FLOR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:37:00.857Z',
      submitterWorkN: '0073514',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:37:00.858Z',
      updatedAt: '2020-06-26T11:37:00.887Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '2b902f2c-b975-46f6-a8fc-1517b2d71da8',
      unisonId: 'COFLS2KPC8L5',
      title: 'SKYLAB',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:37:01.383Z',
      submitterWorkN: '0073128',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:37:01.385Z',
      updatedAt: '2020-06-26T11:37:01.426Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'da5a0c61-bcaa-469f-9031-dfa594e3bf7f',
      unisonId: 'COOIWQMDN7L2',
      title: 'TRILHAS DA FLORESTA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:37:01.661Z',
      submitterWorkN: '0073436',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:37:01.662Z',
      updatedAt: '2020-06-26T11:37:01.698Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'b2a9c45a-8942-46fd-ab99-adc2517c415c',
      unisonId: 'CO8HJC8FL6SL',
      title: 'VAIDADES',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:37:06.721Z',
      submitterWorkN: '0073551',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:37:06.723Z',
      updatedAt: '2020-06-26T11:37:06.784Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '8e4303aa-575e-43a4-9fce-6495c916ff6c',
      unisonId: 'CO46FLH3IF85',
      title: 'VOCE SABIA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:37:08.188Z',
      submitterWorkN: '0073680',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:37:08.190Z',
      updatedAt: '2020-06-26T11:37:08.230Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'f0856e95-2b30-438e-9899-bbafc341ba2e',
      unisonId: 'COP9ZHFLQ6KV',
      title: 'VIAS TORTAS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:37:11.057Z',
      submitterWorkN: '0073616',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:37:11.060Z',
      updatedAt: '2020-06-26T11:37:11.113Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '2c8d609d-34d0-405e-99fe-c64842051fc6',
      unisonId: 'CO7XHFWUV65S',
      title: 'O CARA DA FLOR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:37:26.059Z',
      submitterWorkN: '0072175',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:37:26.061Z',
      updatedAt: '2020-06-26T11:37:26.112Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '01995c49-ef26-4c17-b7cc-78fd6768a593',
      unisonId: 'CORVQ92TXRIV',
      title: 'O GRITO DA FLORESTA NA PEDRA TALHADA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:37:28.518Z',
      submitterWorkN: '0072211',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:37:28.520Z',
      updatedAt: '2020-06-26T11:37:28.562Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '023e4aa1-c1a8-4415-a03b-faa8ee7c2514',
      unisonId: 'COYX2E7PLD09',
      title: 'O OVO E A FLORA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:37:30.735Z',
      submitterWorkN: '0072243',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:37:30.737Z',
      updatedAt: '2020-06-26T11:37:30.800Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'a52d415f-7418-42ec-8264-6698a871a2d4',
      unisonId: 'COCNK4EB7SB8',
      title: '2ND FLASHBACK',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:39:00.575Z',
      submitterWorkN: '0000948',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:03:55',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:39:00.576Z',
      updatedAt: '2020-06-29T15:04:13.860Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '58fba6b7-3ad8-45d7-9deb-e3309093897b',
      unisonId: 'CO7FLF1PYPR3',
      title: 'ENTER THE DRAGON',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:39:03.199Z',
      submitterWorkN: '0000991',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:08:30',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:39:03.201Z',
      updatedAt: '2020-06-29T15:04:15.423Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'b7df52b5-a34b-4935-824b-6a1a0709c89e',
      unisonId: 'COXB2OK7FLEJ',
      title: 'I WILL NEVER WALK THE LINE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:39:02.563Z',
      submitterWorkN: '0033589',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:39:02.565Z',
      updatedAt: '2020-06-29T15:04:15.173Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'a9360e46-1ac2-4e6f-a4cf-3412d5690bb5',
      unisonId: 'COGUGBFLVYMX',
      title: 'KAYABA HILLS 12',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2016-02-23T00:00:00.000Z',
      submitterWorkN: '0059718',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:33',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:39:02.060Z',
      updatedAt: '2020-06-29T15:04:15.925Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '858a13f4-4837-4b22-9491-f0bf32bbb580',
      unisonId: 'CO2FLQOGPC1B',
      title: 'BACK OFF RINA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:39:05.153Z',
      submitterWorkN: '0001022',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:05:56',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:39:05.155Z',
      updatedAt: '2020-06-29T15:04:16.449Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '92a83f95-410e-42fe-9a2c-6407d1fe6edb',
      unisonId: 'COO864VXFL9I',
      title: 'A FUGA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:39:05.374Z',
      submitterWorkN: '0001026',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:06:20',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:39:05.376Z',
      updatedAt: '2020-06-29T15:04:16.568Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '4f760773-0767-4638-9353-3a7755ed097a',
      unisonId: 'COXVIWMFLLPO',
      title: 'THE COGNITIVE SWAY 42',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2016-08-24T00:00:00.000Z',
      submitterWorkN: '0060146',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:33',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:39:14.626Z',
      updatedAt: '2020-06-29T15:04:23.159Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'bd5f0202-5296-4400-a9af-3fc916e50663',
      unisonId: 'COFLAO44MOMI',
      title: 'MISS CONVERSATION',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2013-01-01T00:00:00.000Z',
      submitterWorkN: '0135131',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:03:33',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:39:20.327Z',
      updatedAt: '2020-06-29T15:04:25.437Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '75e04a4f-f77d-4a92-a1a8-a2db5902d455',
      unisonId: 'CO4LZTEZRPDY',
      title: 'THUNDERCLAPS FLOWING DOWNSTREAM',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2016-12-07T00:00:00.000Z',
      submitterWorkN: '0060295',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:03:32',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:39:15.971Z',
      updatedAt: '2020-06-29T15:04:24.166Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'e50b37ef-6764-4c80-9006-c11e3a878767',
      unisonId: 'COSFLEAWNBYE',
      title: 'RAFA POLICIA',
      iswc: 'T0456108272',
      type: 'MTX',
      versionType: 'ORI',
      status: 'CONFLICT',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:39:22.391Z',
      submitterWorkN: '0154677',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:39:22.392Z',
      updatedAt: '2020-06-29T15:04:28.203Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '228b177d-5d13-42c4-8b5d-eb1746eeca49',
      unisonId: 'COVGL1GFL3NM',
      title: 'WAVE 52   FEKE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2017-02-21T00:00:00.000Z',
      submitterWorkN: '0060360',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:01:06',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:39:19.511Z',
      updatedAt: '2020-06-29T15:04:26.195Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'f1044970-fc3c-479c-a375-1e3fe15d5f4b',
      unisonId: 'COXMU47NZFLV',
      title: 'CAKES AND ADVICES',
      iswc: 'T0456491841',
      type: 'MTX',
      versionType: 'ORI',
      status: 'CONFLICT',
      musicArrangement: 'ARR',
      lyricAdaptation: 'UNS',
      copyrightDate: '2020-06-26T11:39:24.441Z',
      submitterWorkN: '0154727',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:39:24.442Z',
      updatedAt: '2020-06-29T15:04:27.186Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '851a252c-fde7-4520-a80a-04d5c726df52',
      unisonId: 'CONXFV6R33LW',
      title: 'STOLEN FLOWERS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:39:26.998Z',
      submitterWorkN: '0154765',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:03:15',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:39:27.001Z',
      updatedAt: '2020-06-29T15:04:28.283Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'ba9e760d-737c-4726-a0e2-fb3ca55ffcd8',
      unisonId: 'COGE4VHSSQ20',
      title: 'FLOWER IN MY HAIR THE',
      iswc: 'T0456492004',
      type: 'MTX',
      versionType: 'ORI',
      status: 'CONFLICT',
      musicArrangement: 'ARR',
      lyricAdaptation: 'UNS',
      copyrightDate: '2020-06-26T11:39:25.004Z',
      submitterWorkN: '0154737',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:39:25.005Z',
      updatedAt: '2020-06-29T15:04:27.414Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '7e78c9a7-a18f-4d22-a550-4f4035f63e08',
      unisonId: 'COCXKLCQ8W6T',
      title: 'SUNFLOWER',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:39:27.744Z',
      submitterWorkN: '0154777',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:02:40',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:39:27.750Z',
      updatedAt: '2020-06-29T15:04:28.802Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '0361defd-9040-40bb-97ca-928ad84f5f28',
      unisonId: 'CO6Q630HFLD9',
      title: 'POR DEUS',
      iswc: 'T0456535048',
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:39:29.790Z',
      submitterWorkN: '0174530',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:39:29.792Z',
      updatedAt: '2020-06-29T15:04:31.078Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '4d968aa7-3876-4a12-97da-819f3e13025e',
      unisonId: 'CO5L4UK4A01D',
      title: 'THE FLOOD',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:39:30.826Z',
      submitterWorkN: '0155384',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:04:13',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:39:30.828Z',
      updatedAt: '2020-06-29T15:04:31.610Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '0cff83cb-db3f-461e-a7bc-aacc4d8343ed',
      unisonId: 'COWO0SYU278S',
      title: 'FLOR DE SAL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:39:33.166Z',
      submitterWorkN: '0174368',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:39:33.166Z',
      updatedAt: '2020-06-29T15:04:31.951Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '423b4973-4928-416d-ab50-fa7b1345d502',
      unisonId: 'CO5LFLJEZYY9',
      title: 'RUIDO DO SILENCIO',
      iswc: 'T0456133166',
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:39:33.580Z',
      submitterWorkN: '0174268',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:39:33.581Z',
      updatedAt: '2020-06-29T15:04:33.056Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '0a956a65-71b8-4f90-a608-38aec60e22d5',
      unisonId: 'COFH7VYXYFLN',
      title: 'EVENING STROLL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:39:35.196Z',
      submitterWorkN: '0179796',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:01:41',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:39:35.197Z',
      updatedAt: '2020-06-29T15:04:32.519Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'f4d4a8fe-0404-4c96-ab0d-8ffa627622b7',
      unisonId: 'CO3XIXB0FLAN',
      title: 'SETTING SAIL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-06-26T11:39:35.728Z',
      submitterWorkN: '0179805',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'UNC',
      duration: '00:03:22',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'Y',
      createdAt: '2020-06-26T11:39:35.731Z',
      updatedAt: '2020-06-29T15:04:30.364Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'd0fc2fd5-d64b-4fe8-8ec2-92757885bab4',
      unisonId: 'COQPL06ZLWA6',
      title: '42ND FLOOR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:41:22.612Z',
      submitterWorkN: '42NDFLOOR',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:02:46',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:41:22.614Z',
      updatedAt: '2020-07-02T13:41:22.660Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '2547b9c7-95e1-4560-ba99-86281605828f',
      unisonId: 'COWTMK4UMZ6L',
      title: 'FLOATING STEPS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:41:23.277Z',
      submitterWorkN: 'FLOATINGS',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:03:16',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:41:23.279Z',
      updatedAt: '2020-07-02T13:41:23.343Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '700d5e04-08d1-4c47-84cf-79179bb805f8',
      unisonId: 'COQQSGY6GLGK',
      title: 'FLIGHT SCHEDULE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:41:37.239Z',
      submitterWorkN: 'FLIGHTSCH',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:07:39',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:41:37.241Z',
      updatedAt: '2020-07-02T13:41:37.307Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'bf1efae3-2e41-4950-a589-c1ddb2cffc2e',
      unisonId: 'COD5MDTVVB7G',
      title: 'FLIGHT SCHEDULE (GREGOR TRESHER REMIX)',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:41:37.316Z',
      submitterWorkN: 'FLIGHTSCH001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:07:55',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:41:37.318Z',
      updatedAt: '2020-07-02T13:41:37.379Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '43c6cf4c-dd97-4f18-8a99-c717f5a69a06',
      unisonId: 'COK61XABEACU',
      title: 'FLYING HIGH',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:41:37.721Z',
      submitterWorkN: 'FLYINGHIG',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:01:52',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:41:37.725Z',
      updatedAt: '2020-07-02T13:41:37.803Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '2f3398ce-5eb7-4020-b426-d8bff901045e',
      unisonId: 'CO7PXHI36CWX',
      title: 'CONFLICT OF LIFE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:41:44.124Z',
      submitterWorkN: 'CONFLICTO',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:07:17',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:41:44.127Z',
      updatedAt: '2020-07-02T13:41:44.195Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '0c3aa2d8-6159-4db8-8f82-ce9a8695aa93',
      unisonId: 'CO5PYRAER6FE',
      title: 'FLY DESIGN',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:41:43.921Z',
      submitterWorkN: 'FLYDESIGN',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:03:13',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:41:43.924Z',
      updatedAt: '2020-07-02T13:41:44.012Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '66bbb101-5ac0-4aef-9d96-35ffddf9eb02',
      unisonId: 'CO73ZHXTIAZM',
      title: 'FLAVORTOWN',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:41:44.847Z',
      submitterWorkN: 'FLAVORTOW',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:02:19',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:41:44.849Z',
      updatedAt: '2020-07-02T13:41:44.912Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'df009d16-7d78-4f1e-9e5f-afdee997ff4b',
      unisonId: 'COI1VH1NOWB5',
      title: 'SIX FOOT SHUFFLE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:41:45.438Z',
      submitterWorkN: 'SIXFOOTSH',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:02:25',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:41:45.441Z',
      updatedAt: '2020-07-02T13:41:45.506Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'ea7d181f-f905-4538-8fd2-8bfee23b6b11',
      unisonId: 'CORL2RA3W41W',
      title: 'FLUFFY FINGERS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:41:45.608Z',
      submitterWorkN: 'FLUFFYFIN',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:02:04',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:41:45.609Z',
      updatedAt: '2020-07-02T13:41:45.673Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'de2b1968-6ee3-458c-923b-08e07aa70cf3',
      unisonId: 'CO8LK18UJB4V',
      title: 'LIGHTNING FLASHES (BLACK ASTEROID MIX)',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:41:47.109Z',
      submitterWorkN: 'LIGHTNING005',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:41:47.111Z',
      updatedAt: '2020-07-02T13:41:47.161Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '50d1a9bf-0ee8-4359-b5d2-30630c586c0d',
      unisonId: 'CODM463NGTO5',
      title: 'EBB AND FLOW',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:41:48.321Z',
      submitterWorkN: 'EBBANDFLO',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:02:29',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:41:48.324Z',
      updatedAt: '2020-07-02T13:41:48.383Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'a39b1228-1afa-496d-acbc-6bdfa09b27c6',
      unisonId: 'COMWJ91AQ9QD',
      title: 'THE REFLECTING',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:41:50.035Z',
      submitterWorkN: 'REFLECTIN001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:02:32',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:41:50.037Z',
      updatedAt: '2020-07-02T13:41:50.106Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '53cc5d6c-867c-402d-ad3f-905fee7ec830',
      unisonId: 'COBJD4H6V1FL',
      title: 'INTRO (EXTENDED MIX)',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:41:54.785Z',
      submitterWorkN: 'INTROEXTE',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:16:11',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:41:54.787Z',
      updatedAt: '2020-07-02T13:41:54.844Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '7ad0bb38-b49a-4970-8576-267f394cba67',
      unisonId: 'COZOGCOFLENN',
      title: 'MY FAVORITE GAME',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:41:56.006Z',
      submitterWorkN: 'MYFAVORIT002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:07:45',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:41:56.012Z',
      updatedAt: '2020-07-02T13:41:56.103Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '86afef91-57be-4b11-a76a-222c52cc969a',
      unisonId: 'COOII2UFLMG1',
      title: 'GLOBAL MIND',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:41:59.482Z',
      submitterWorkN: 'GLOBALMIN',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:06:36',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:41:59.484Z',
      updatedAt: '2020-07-02T13:41:59.554Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '52b50ced-5564-44dc-995d-dccabe2abc32',
      unisonId: 'COLVEB5FAOTJ',
      title: 'A DUSTY HEART (MARCO FARAONE FLOORKILLER REMIX)',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:41:59.865Z',
      submitterWorkN: 'DUSTYHEAR',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:07:42',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:41:59.866Z',
      updatedAt: '2020-07-02T13:41:59.947Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '30b48898-94c5-4ecf-b7f8-06c5c4b417e6',
      unisonId: 'COU68KK982BG',
      title: 'CONFLICT OF LIFE DJ TOOL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:42:02.189Z',
      submitterWorkN: 'CONFLICTO001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:02:16',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:42:02.191Z',
      updatedAt: '2020-07-02T13:42:02.256Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '3f36f71c-84a4-413f-b774-6dc3985b322a',
      unisonId: 'COYBTUGX3K4H',
      title: 'INFLUENTIAL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:42:11.307Z',
      submitterWorkN: 'INFLUENTI',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:06:06',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:42:11.309Z',
      updatedAt: '2020-07-02T13:42:11.396Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'bb09ec1b-f9f5-4bba-9ad5-92f406c2a900',
      unisonId: 'COBFL2EN917H',
      title: 'DWELL ORIGINAL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:42:14.157Z',
      submitterWorkN: 'DWELLORIG',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:06:43',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:42:14.159Z',
      updatedAt: '2020-07-02T13:42:14.220Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '173d2c07-f845-41a8-8e54-a8f92fc67bd8',
      unisonId: 'COUUQ8GFLA29',
      title: 'SUPERMAN',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:42:14.463Z',
      submitterWorkN: 'SUPERMAN002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:03:44',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:42:14.465Z',
      updatedAt: '2020-07-02T13:42:14.572Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '7171bc7d-02c1-4e6c-ac4f-e53df54c51dd',
      unisonId: 'CO8MTH21B9QX',
      title: 'DIRTY FLAMINGO',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:42:16.438Z',
      submitterWorkN: 'DIRTYFLAM',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:02:10',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:42:16.441Z',
      updatedAt: '2020-07-02T13:42:16.511Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '70e9a9c4-5e6e-4178-91da-f32a18e1ecf0',
      unisonId: 'COFLTWCFEVMI',
      title: 'SHAPESHIFTER',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:42:15.436Z',
      submitterWorkN: 'SHAPESHIF003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:06:12',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:42:15.439Z',
      updatedAt: '2020-07-02T13:42:15.511Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '2de4b2a4-946b-4aa7-823a-619ae6fc3770',
      unisonId: 'COQJQ7TJK6WR',
      title: 'LIGHTNING FLASHES (ORIGINAL MIX)',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:42:18.152Z',
      submitterWorkN: 'LIGHTNING006',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:06:50',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:42:18.154Z',
      updatedAt: '2020-07-02T13:42:18.215Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '7d2dd5e3-80f7-4b9f-a356-7da77f2d4296',
      unisonId: 'COX57SXWLAVR',
      title: '2 FLY',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:54:51.263Z',
      submitterWorkN: '2FLY',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:54:51.267Z',
      updatedAt: '2020-07-02T13:54:51.323Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '491a63df-76cd-487b-ab46-8667b7095d11',
      unisonId: 'CONU3MQLS8U1',
      title: 'ACTIVITYFLOW FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:54:56.504Z',
      submitterWorkN: 'ACTIVITYF',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:54:56.505Z',
      updatedAt: '2020-07-02T13:54:56.573Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '829ed069-c956-4840-b109-b28162ef8545',
      unisonId: 'CO61DI2XW0W9',
      title: 'ACTIVITYFLOW NOBSS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:54:56.648Z',
      submitterWorkN: 'ACTIVITYF002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:54:56.650Z',
      updatedAt: '2020-07-02T13:54:56.701Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '6b6af1d5-887f-41da-a7fb-838a2012bd03',
      unisonId: 'COJVR6WKLJK4',
      title: 'ACTIVITYFLOW NYLGTRBSSAMBGTR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:54:56.768Z',
      submitterWorkN: 'ACTIVITYF004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:54:56.770Z',
      updatedAt: '2020-07-02T13:54:56.851Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'a7d80cf4-7a34-4470-b581-4689331fa20c',
      unisonId: 'COW0HQUW61JS',
      title: 'ACTIVITYFLOW LESSSLDGTR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:54:56.580Z',
      submitterWorkN: 'ACTIVITYF001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:54:56.581Z',
      updatedAt: '2020-07-02T13:54:56.637Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'acf70618-a22d-4c9b-8a72-c7174fb94595',
      unisonId: 'COVU76EFT74T',
      title: 'ACTIVITYFLOW NOSLIDE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:54:56.709Z',
      submitterWorkN: 'ACTIVITYF003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:54:56.711Z',
      updatedAt: '2020-07-02T13:54:56.764Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '420c289b-9809-448f-aafb-73a3e71e10eb',
      unisonId: 'COL4KVUZPDMH',
      title: 'ARABIK NOSTRFL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:54:58.890Z',
      submitterWorkN: 'ARABIKNOS',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:54:58.892Z',
      updatedAt: '2020-07-02T13:54:58.966Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '6b3f8a51-f6bb-4ba6-92f0-60963b737612',
      unisonId: 'COFLVIEXGFEU',
      title: 'ALLEGROQUARTZ NOEFXBASS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:54:57.694Z',
      submitterWorkN: 'ALLEGROQU003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:54:57.695Z',
      updatedAt: '2020-07-02T13:54:57.755Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '4b390806-ba99-4bb5-bf6a-d89f3755aa71',
      unisonId: 'CO88FLD6387M',
      title: 'ALLRIGHT PNO',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:54:58.763Z',
      submitterWorkN: 'ALLRIGHTP001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:54:58.764Z',
      updatedAt: '2020-07-02T13:54:58.815Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'd02d5f80-040d-4715-ad77-cea3220b1ac3',
      unisonId: 'COFL8Q4XDF94',
      title: 'BEENNICE NODRM',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:04.428Z',
      submitterWorkN: 'BEENNICEN',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:04.429Z',
      updatedAt: '2020-07-02T13:55:04.486Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '93bdc8b3-f7f5-41a0-929f-f9b140c9e426',
      unisonId: 'CO7GE7FLUNKV',
      title: 'AUREOLINGALAXY PERC',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:02.465Z',
      submitterWorkN: 'AUREOLING006',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:02.467Z',
      updatedAt: '2020-07-02T13:55:02.561Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'c1e893d3-a484-41e2-adc9-3d4054577395',
      unisonId: 'CO9TGHWZ13W8',
      title: 'AURORAFLAME FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:02.845Z',
      submitterWorkN: 'AURORAFLA',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:02.848Z',
      updatedAt: '2020-07-02T13:55:02.894Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '8719e306-68fa-4ce3-be30-98bbb46bf36a',
      unisonId: 'COANJ8Q6SAAR',
      title: 'AURORAFLAME LESSGTR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:02.901Z',
      submitterWorkN: 'AURORAFLA001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:02.904Z',
      updatedAt: '2020-07-02T13:55:02.941Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '15834efd-8e0a-4bda-ad09-457596d2205f',
      unisonId: 'COKPVEBDRY1N',
      title: 'AURORAFLAME NOELGGT',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:02.957Z',
      submitterWorkN: 'AURORAFLA002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:02.960Z',
      updatedAt: '2020-07-02T13:55:03.004Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '2b6f540b-60a6-4f92-9d74-dbcb6239fbe9',
      unisonId: 'COLPG2QIWYFL',
      title: 'AURORAOCEAN FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:03.139Z',
      submitterWorkN: 'AURORAOCE002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:03.144Z',
      updatedAt: '2020-07-02T13:55:03.189Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '9a062542-db37-4f06-9918-74547bb816cd',
      unisonId: 'COZ99WRR4DPU',
      title: 'BALLROOMFLAMINGO FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:05.667Z',
      submitterWorkN: 'BALLROOMF006',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:05.668Z',
      updatedAt: '2020-07-02T13:55:05.733Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'a91fd876-4326-44c6-9456-841f8d0981aa',
      unisonId: 'COWGWQZ0EDI0',
      title: 'BALLROOMFLAMINGO DMSBSS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:05.744Z',
      submitterWorkN: 'BALLROOMF007',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:05.745Z',
      updatedAt: '2020-07-02T13:55:05.791Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '7d83e090-e6d9-40cb-8e09-bb60bc60593c',
      unisonId: 'COV8C7NKX0QB',
      title: 'BALLROOMFLAMINGO NODMSBSS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:05.799Z',
      submitterWorkN: 'BALLROOMF008',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:05.801Z',
      updatedAt: '2020-07-02T13:55:05.861Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '6415bae7-d21c-41b2-a98b-68c22389a03e',
      unisonId: 'CO1OLOE1GONL',
      title: 'BALLROOMFLAMINGO NOLEADGTR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:05.870Z',
      submitterWorkN: 'BALLROOMF009',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:05.871Z',
      updatedAt: '2020-07-02T13:55:05.919Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '6bb1d93f-23c8-4658-89d2-ced87eb8b688',
      unisonId: 'CORIOC9FO59C',
      title: 'BALBOAFLUORESCENT FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:07.054Z',
      submitterWorkN: 'BALBOAFLU',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:07.056Z',
      updatedAt: '2020-07-02T13:55:07.109Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'dee71303-f896-44cc-9889-79bff0d239a6',
      unisonId: 'COTO0GCWUJ7C',
      title: 'BALBOAFLUORESCENT DRMS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:07.117Z',
      submitterWorkN: 'BALBOAFLU001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:07.119Z',
      updatedAt: '2020-07-02T13:55:07.178Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'e3d86e57-6ea7-41a0-95c8-da60a50b8ae4',
      unisonId: 'CO0LZ57ON811',
      title: 'BALBOAFLUORESCENT DRMSBSS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:07.186Z',
      submitterWorkN: 'BALBOAFLU002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:07.187Z',
      updatedAt: '2020-07-02T13:55:07.243Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '7f88aac1-b891-453f-8150-906ea17756f8',
      unisonId: 'COQ64YFKIPF8',
      title: 'BALBOAFLUORESCENT GLITCH',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:07.249Z',
      submitterWorkN: 'BALBOAFLU003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:07.252Z',
      updatedAt: '2020-07-02T13:55:07.308Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '7c50fac2-d9ec-4be9-baaa-e0a45ed8bb10',
      unisonId: 'COGWBRTPD0VS',
      title: 'BALBOAFLUORESCENT GTRS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:07.318Z',
      submitterWorkN: 'BALBOAFLU004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:07.320Z',
      updatedAt: '2020-07-02T13:55:07.384Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '09cd6aba-7c13-4122-894e-b8e61943e195',
      unisonId: 'CO0285425XLM',
      title: 'BALBOAFLUORESCENT NOGLITCH',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:07.391Z',
      submitterWorkN: 'BALBOAFLU005',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:07.393Z',
      updatedAt: '2020-07-02T13:55:07.444Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '1ae82956-9664-4716-93f6-1bb33181418f',
      unisonId: 'CONTFKRUEOFL',
      title: 'BLACK MOON',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:21.776Z',
      submitterWorkN: 'BLACKMOON001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:21.778Z',
      updatedAt: '2020-07-02T13:55:21.857Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '367e5404-0f78-4379-83a2-0081972ba508',
      unisonId: 'CONBFLH8S0QW',
      title: 'CALIENTE LITE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:17.683Z',
      submitterWorkN: 'CALIENTEL',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:17.685Z',
      updatedAt: '2020-07-02T13:55:17.747Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '1e250fac-da6f-40dd-a2da-8209fabf5d70',
      unisonId: 'COYBHPTFLB8P',
      title: 'BREAKFAST DUB MIX',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:19.847Z',
      submitterWorkN: 'BREAKFAST009',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:06:47',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:19.849Z',
      updatedAt: '2020-07-02T13:55:19.894Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'ddf6af9f-8714-4325-96bc-8d09b06fa8a8',
      unisonId: 'CO5LIO8TTVBS',
      title: 'BOLERODRAB STRNGSFLT',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:23.298Z',
      submitterWorkN: 'BOLERODRA005',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:23.299Z',
      updatedAt: '2020-07-02T13:55:23.358Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'e4eff219-95e0-4d93-87cb-6ea616782904',
      unisonId: 'COOCDGS7FLY9',
      title: 'BLASTOFF FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:22.926Z',
      submitterWorkN: 'BLASTOFFF',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:22.927Z',
      updatedAt: '2020-07-02T13:55:23.010Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '44a9816d-f03b-45a8-8539-efb7f31f4bac',
      unisonId: 'CO7WKV57S99V',
      title: 'BLUEFLAMES FULLF',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:24.985Z',
      submitterWorkN: 'BLUEFLAME',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:24.988Z',
      updatedAt: '2020-07-02T13:55:25.069Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'd3ffe841-648c-4f17-b07d-ec0cb96c84ae',
      unisonId: 'COJZT2GJ5EQ1',
      title: 'BLUEFLAMES NODMSF',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:25.075Z',
      submitterWorkN: 'BLUEFLAME001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:25.076Z',
      updatedAt: '2020-07-02T13:55:25.125Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '791b78cb-f9b5-4eb4-8290-f755774343f4',
      unisonId: 'CO6VVDCAI0TF',
      title: 'BLUEFLAMES NOFXF',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:25.129Z',
      submitterWorkN: 'BLUEFLAME002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:25.131Z',
      updatedAt: '2020-07-02T13:55:25.197Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '0959021e-2cd2-4267-8042-e22b2a5b2675',
      unisonId: 'COJ6704SINS1',
      title: 'BLUEFLAMES NOSTRSF',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:25.204Z',
      submitterWorkN: 'BLUEFLAME003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:25.206Z',
      updatedAt: '2020-07-02T13:55:25.266Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '55b22715-d18f-4bc4-b849-e32cb6a6ebaf',
      unisonId: 'COBXBEQ1KS4L',
      title: 'BLUEFLAMES PERCF',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:25.274Z',
      submitterWorkN: 'BLUEFLAME004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:25.275Z',
      updatedAt: '2020-07-02T13:55:25.324Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'a1386ec8-a3d0-4575-9be9-673623e1b95a',
      unisonId: 'COXW6JBVCZ3O',
      title: 'BLUEFLAMES STRSF',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:25.331Z',
      submitterWorkN: 'BLUEFLAME005',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:25.333Z',
      updatedAt: '2020-07-02T13:55:25.397Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '22477919-925b-4236-9bd9-c3b596114d71',
      unisonId: 'COW1FLPFX51M',
      title: 'BOARDBECOME NODMSBSS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:27.294Z',
      submitterWorkN: 'BOARDBECO002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:27.295Z',
      updatedAt: '2020-07-02T13:55:27.347Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'a0ccca90-f441-4846-a972-84976bfbf477',
      unisonId: 'CO2EQFLUEQ0X',
      title: 'BOSSAIRRESISTIBLE DRMSSFX',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:27.046Z',
      submitterWorkN: 'BOSSAIRRE002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:27.048Z',
      updatedAt: '2020-07-02T13:55:27.093Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '42ed08dc-7f58-4cd0-90ab-82c66b6d9d0c',
      unisonId: 'CONQUOOTBN4P',
      title: 'CLOUDSHAPES NOBSNFLUTE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:28.049Z',
      submitterWorkN: 'CLOUDSHAP002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:28.051Z',
      updatedAt: '2020-07-02T13:55:28.117Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '7ad9309f-a5de-466e-99b5-ec9325eebcae',
      unisonId: 'CO2VUVFLW95Z',
      title: 'CHAMPAGNESCHOLAR NOCHOIRFX',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:28.774Z',
      submitterWorkN: 'CHAMPAGNE007',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:28.776Z',
      updatedAt: '2020-07-02T13:55:28.829Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'cace3125-ad82-430d-b79b-82ec5064b815',
      unisonId: 'CO5MUQU9K7AS',
      title: 'CHOREOGRAPHYAMBER NOFLUTE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:31.668Z',
      submitterWorkN: 'CHOREOGRA015',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:31.669Z',
      updatedAt: '2020-07-02T13:55:31.739Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'e5e71155-54d1-439f-82e3-9ae0b8c95e12',
      unisonId: 'COCAMWQ0WFLX',
      title: 'CRAFTASSESS DMSBSS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:35.067Z',
      submitterWorkN: 'CRAFTASSE007',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:35.069Z',
      updatedAt: '2020-07-02T13:55:35.116Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'f05d099a-3a25-4c47-b599-a4e0b67ee823',
      unisonId: 'COV05FLTFL8K',
      title: 'CRICKET ON A LINE (REMIX)',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:38.334Z',
      submitterWorkN: 'CRICKETON001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:38.336Z',
      updatedAt: '2020-09-02T13:12:14.548Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '7a09ff3f-c2e2-4d93-be8d-b1be90673201',
      unisonId: 'CO6UHFRE2QFI',
      title: 'CROSSEDLINE NOFLUTE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:40.280Z',
      submitterWorkN: 'CROSSEDLI006',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:40.282Z',
      updatedAt: '2020-07-02T13:55:40.338Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '5039d92a-7f1a-4659-bfc1-a770a13da02b',
      unisonId: 'CO27QVNQFN8B',
      title: 'CONGRESSCRITICIZE NOFLUTE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:41.085Z',
      submitterWorkN: 'CONGRESSC006',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:41.087Z',
      updatedAt: '2020-07-02T13:55:41.154Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '1a1575df-b0f1-4a61-9b38-3a1185f542b5',
      unisonId: 'CO54TZ6PFLDC',
      title: 'DEEPWATER NOGTRS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:42.982Z',
      submitterWorkN: 'DEEPWATER004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:42.984Z',
      updatedAt: '2020-07-02T13:55:43.050Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'bfb39993-947b-4ab0-8a98-6aa68134910f',
      unisonId: 'CON3INZVY8CY',
      title: 'FLASH NOORG',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:16.311Z',
      submitterWorkN: 'FLASHNOOR',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:16.313Z',
      updatedAt: '2020-07-02T13:56:16.396Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '95791283-8c2a-4f8a-a52f-84eb8aa29d46',
      unisonId: 'COQU2FL390O3',
      title: 'DIZZY',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:52.812Z',
      submitterWorkN: 'DIZZY004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:52.813Z',
      updatedAt: '2020-07-02T13:55:52.883Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '6be9d7cb-7d27-44eb-930b-b7680bfde51d',
      unisonId: 'CO9MFL1J5AKB',
      title: 'DOWNSHRUG NOLEADSYNTH',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:52.432Z',
      submitterWorkN: 'DOWNSHRUG005',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:52.433Z',
      updatedAt: '2020-07-02T13:55:52.484Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'e3100cf0-32b7-40d3-9236-e2d060f246d4',
      unisonId: 'CO8RUP24FL2L',
      title: 'CORROSIVEDIFFIDENCE NOKEYS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:55:54.899Z',
      submitterWorkN: 'CORROSIVE005',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:55:54.901Z',
      updatedAt: '2020-07-02T13:55:54.966Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '69aa003f-b8ef-487d-b3fe-8f11697ce3b0',
      unisonId: 'CO7MR8ZUQZ6C',
      title: 'FAIRMAIDEN NOFLUTE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:01.069Z',
      submitterWorkN: 'FAIRMAIDE002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:01.071Z',
      updatedAt: '2020-07-02T13:56:01.132Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '3d542519-eb08-4420-acce-e8a768eaf746',
      unisonId: 'COPFUS14QFLK',
      title: 'EVERWAS NOGTR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:01.918Z',
      submitterWorkN: 'EVERWASNO',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:01.920Z',
      updatedAt: '2020-07-02T13:56:01.989Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '7fee13e7-1876-4d91-9d58-820edcd60a94',
      unisonId: 'COYBYOVQ8ECH',
      title: 'FRIGHTENINFLUENCE DMSBS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:07.164Z',
      submitterWorkN: 'FRIGHTENI',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:07.166Z',
      updatedAt: '2020-07-02T13:56:07.235Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '29b8735d-f760-42d3-b1be-2d7900c3a4c1',
      unisonId: 'CO4Z49VEEMFF',
      title: 'FRIGHTENINFLUENCE DMSSYNTH',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:07.243Z',
      submitterWorkN: 'FRIGHTENI001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:07.245Z',
      updatedAt: '2020-07-02T13:56:07.310Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '0964ff5b-6c7b-498f-ba78-044eee723b5e',
      unisonId: 'COHF66NKSCII',
      title: 'FIERY FLAMENCO',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:04.567Z',
      submitterWorkN: 'FIERYFLAM',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:04.569Z',
      updatedAt: '2020-07-02T13:56:04.626Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '4f38ee04-b541-4094-854d-afa026c0e8a9',
      unisonId: 'CODISBFLN8QB',
      title: 'FINISHSAIL NOPIZZICATO',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:08.577Z',
      submitterWorkN: 'FINISHSAI006',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:08.579Z',
      updatedAt: '2020-07-02T13:56:08.660Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '736a279f-154f-4ec1-b824-45e4debf01cb',
      unisonId: 'CO8Z7PKF0LEA',
      title: 'FRIGHTENINFLUENCE FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:07.320Z',
      submitterWorkN: 'FRIGHTENI002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:07.322Z',
      updatedAt: '2020-07-02T13:56:07.413Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'bb20db0b-e391-45c6-8ac9-89fb6dfb7796',
      unisonId: 'COH54PUASD9W',
      title: 'FRIGHTENINFLUENCE GTRHARMPERCSYNTH',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:07.422Z',
      submitterWorkN: 'FRIGHTENI003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:07.425Z',
      updatedAt: '2020-07-02T13:56:07.488Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'd58c88b5-a9e3-42ac-8774-395d512521d6',
      unisonId: 'COH9EUVY3J39',
      title: 'FRIGHTENINFLUENCE GTRPERCBS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:07.495Z',
      submitterWorkN: 'FRIGHTENI004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:07.496Z',
      updatedAt: '2020-07-02T13:56:07.553Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'd5aa8472-77bf-4039-9b07-50b23e605528',
      unisonId: 'COO3SZ7QT1T9',
      title: 'FRIGHTENINFLUENCE LITEMIX',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:07.562Z',
      submitterWorkN: 'FRIGHTENI005',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:07.565Z',
      updatedAt: '2020-07-02T13:56:07.632Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '2dc73da1-1d4f-4d9c-90f7-8cbf698d4978',
      unisonId: 'CO3055QOSO5E',
      title: 'FRIGHTENINFLUENCE NOPERC',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:07.640Z',
      submitterWorkN: 'FRIGHTENI006',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:07.644Z',
      updatedAt: '2020-07-02T13:56:07.723Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'f54a119d-2811-44e1-8f72-e8e3bedd190a',
      unisonId: 'COM6PX2T051F',
      title: 'FRIGHTENINFLUENCE NOSYNTHLTPERC',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:07.735Z',
      submitterWorkN: 'FRIGHTENI007',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:07.736Z',
      updatedAt: '2020-07-02T13:56:07.799Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '67bcca5f-f500-4b81-9295-fbc78fe90e74',
      unisonId: 'CO27FL0V9L6W',
      title: 'FROSTBITTENRUBBER NOGTRS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:09.666Z',
      submitterWorkN: 'FROSTBITT004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:09.667Z',
      updatedAt: '2020-07-02T13:56:09.718Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '974df806-6059-49cf-bd09-850ce7ca367e',
      unisonId: 'COOYY8LX3LMF',
      title: 'FUZZYFLOUT PERCSFX',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:12.906Z',
      submitterWorkN: 'FUZZYFLOU009',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:12.909Z',
      updatedAt: '2020-07-02T13:56:12.964Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '44829a27-6a41-4259-bd2c-25c51f9a8295',
      unisonId: 'COU7NL70PTCP',
      title: 'FUZZYFLOUT SFX',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:12.971Z',
      submitterWorkN: 'FUZZYFLOU010',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:12.972Z',
      updatedAt: '2020-07-02T13:56:13.065Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '6b705a73-c316-4e2b-8348-64fd6757914c',
      unisonId: 'COJ664FRTR2D',
      title: 'FUZZYFLOUT SFXCYMBLHITS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:13.071Z',
      submitterWorkN: 'FUZZYFLOU011',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:13.073Z',
      updatedAt: '2020-07-02T13:56:13.133Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'e27f0ae2-67e6-4d8e-9aaf-7e15eded4722',
      unisonId: 'CO0KIPUV9URK',
      title: 'FUZZYFLOUT CYMBLDWN',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:14.260Z',
      submitterWorkN: 'FUZZYFLOU',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:14.262Z',
      updatedAt: '2020-07-02T13:56:14.345Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'bda1e56d-4865-48fa-81e1-2a82dc4f65c9',
      unisonId: 'COGPFMLRPSBQ',
      title: 'FUZZYFLOUT FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:14.351Z',
      submitterWorkN: 'FUZZYFLOU001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:14.353Z',
      updatedAt: '2020-07-02T13:56:14.423Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '89b54cfb-f9ca-494b-89ba-6daf7b6a4335',
      unisonId: 'CO9TPX5A3V3R',
      title: 'FUZZYFLOUT MUSICBOX',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:14.431Z',
      submitterWorkN: 'FUZZYFLOU002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:14.432Z',
      updatedAt: '2020-07-02T13:56:14.500Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '5838f407-9380-4a14-b88a-8752ed79a9cc',
      unisonId: 'COIHBA2KPKWA',
      title: 'FUZZYFLOUT MUSICBOXSFX',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:14.511Z',
      submitterWorkN: 'FUZZYFLOU003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:14.514Z',
      updatedAt: '2020-07-02T13:56:14.584Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'ba4e1aaf-5a2e-49c9-b257-cda8f491a73b',
      unisonId: 'CO2OCBWJYOCU',
      title: 'FUZZYFLOUT NOBRASS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:14.590Z',
      submitterWorkN: 'FUZZYFLOU004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:14.591Z',
      updatedAt: '2020-07-02T13:56:14.640Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'e45a8e40-cd93-42c4-8364-429cd255e37a',
      unisonId: 'COOVUP1MJXA9',
      title: 'FLAGEMERGE FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:14.430Z',
      submitterWorkN: 'FLAGEMERG',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:14.432Z',
      updatedAt: '2020-07-02T13:56:14.487Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'b0570008-1b82-4a33-be54-65a7c0a6dea5',
      unisonId: 'CO9H6950UWAL',
      title: 'FLAGEMERGE NOACGTR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:14.496Z',
      submitterWorkN: 'FLAGEMERG001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:14.497Z',
      updatedAt: '2020-07-02T13:56:14.567Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '23b17c42-3f9e-4198-9814-d5d063bd93fe',
      unisonId: 'CO5V1ELH2Q9Y',
      title: 'FLAGEMERGE NOBSS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:14.572Z',
      submitterWorkN: 'FLAGEMERG002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:14.573Z',
      updatedAt: '2020-07-02T13:56:14.624Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '51151efb-85e3-4370-a359-3ddaf89091ef',
      unisonId: 'COGFNFBFFKCS',
      title: 'FLAGEMERGE NOGTR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:14.629Z',
      submitterWorkN: 'FLAGEMERG003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:14.630Z',
      updatedAt: '2020-07-02T13:56:14.672Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '7df7a077-b178-487f-a567-46e8240f42cc',
      unisonId: 'CO26T9W38W0E',
      title: 'FLAGEMERGE NOPICKGTR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:14.683Z',
      submitterWorkN: 'FLAGEMERG004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:14.685Z',
      updatedAt: '2020-07-02T13:56:14.744Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '1fd5ee42-d82f-44f4-ad3d-46b35ca21c1f',
      unisonId: 'CO07NF36DAFS',
      title: 'FLAKYSPOT DMSBS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:14.764Z',
      submitterWorkN: 'FLAKYSPOT',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:14.765Z',
      updatedAt: '2020-07-02T13:56:14.837Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'be00f424-f53e-44cd-bbf3-2bc0cecc6ba3',
      unisonId: 'CON0KOQZXSVN',
      title: 'FLAKYSPOT FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:14.843Z',
      submitterWorkN: 'FLAKYSPOT001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:14.844Z',
      updatedAt: '2020-07-02T13:56:14.923Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '4420ed84-b651-4dca-ac22-b12a0bda6cdd',
      unisonId: 'CONGTTU5BXE8',
      title: 'FLAKYSPOT LEADGTRONLY',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:14.927Z',
      submitterWorkN: 'FLAKYSPOT002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:14.928Z',
      updatedAt: '2020-07-02T13:56:14.999Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '7c50213e-b8f5-43d5-9a8a-788ec012703f',
      unisonId: 'CO0QWVLKQ8MI',
      title: 'FLAKYSPOT LITEMIX',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:15.007Z',
      submitterWorkN: 'FLAKYSPOT003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:15.009Z',
      updatedAt: '2020-07-02T13:56:15.105Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'bb690131-bc53-4ac2-af3e-82cf365ee655',
      unisonId: 'CO0UN9RVNYQ9',
      title: 'FLAKYSPOT NOACOUSTICGTRS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:15.115Z',
      submitterWorkN: 'FLAKYSPOT004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:15.117Z',
      updatedAt: '2020-07-02T13:56:15.208Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'c58b30c1-d959-46d2-bda4-57dd30285799',
      unisonId: 'CO8MALYJIDSU',
      title: 'FLAKYSPOT NODMS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:15.216Z',
      submitterWorkN: 'FLAKYSPOT005',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:15.218Z',
      updatedAt: '2020-07-02T13:56:15.301Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '1cb43ed5-b125-4cfa-9c09-f27359f1a966',
      unisonId: 'COT1YZN9NRF4',
      title: 'FLAKYSPOT NOELECTRICGTRS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:15.310Z',
      submitterWorkN: 'FLAKYSPOT006',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:15.312Z',
      updatedAt: '2020-07-02T13:56:15.403Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '1a2689b3-2339-4c38-bb35-2fee33d0bce7',
      unisonId: 'COAQ0UEJLGSU',
      title: 'FLAMENCOSAGE FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:15.412Z',
      submitterWorkN: 'FLAMENCOS',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:15.414Z',
      updatedAt: '2020-07-02T13:56:15.477Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '7b07d8ea-99a5-4c0d-82d4-a1bc75eaee51',
      unisonId: 'COJJCGRWBZLV',
      title: 'FLAMENCOSAGE NOBASS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:15.486Z',
      submitterWorkN: 'FLAMENCOS001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:15.487Z',
      updatedAt: '2020-07-02T13:56:15.542Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'b88aca79-2b53-442a-a93b-68e53bd5419c',
      unisonId: 'COYWRD2QIKFE',
      title: 'FLAMENCOSAGE NOFX',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:15.550Z',
      submitterWorkN: 'FLAMENCOS002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:15.552Z',
      updatedAt: '2020-07-02T13:56:15.636Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '4def129d-bb79-46fc-a9d5-620d08ca0aca',
      unisonId: 'COEQYCWY4YQ4',
      title: 'FLAMENCOSAGE NOSYNTHLEAD',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:15.644Z',
      submitterWorkN: 'FLAMENCOS003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:15.646Z',
      updatedAt: '2020-07-02T13:56:15.709Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '6636cc9c-d0ee-4937-85c9-4ed5c570c3c4',
      unisonId: 'COGJCHIGZBZP',
      title: 'FLAMES BSSDRMS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:15.715Z',
      submitterWorkN: 'FLAMESBSS',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:15.716Z',
      updatedAt: '2020-07-02T13:56:15.760Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '8b922167-18f8-4fc4-ae6c-9a41ca357554',
      unisonId: 'COEIPIPN94LT',
      title: 'FLAMES DRUMS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:15.766Z',
      submitterWorkN: 'FLAMESDRU',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:15.767Z',
      updatedAt: '2020-07-02T13:56:15.823Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '0719dc57-4dd6-4e12-b079-110983e56a34',
      unisonId: 'COIZEJCAWSWJ',
      title: 'FLAMES FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:15.830Z',
      submitterWorkN: 'FLAMESFUL',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:15.832Z',
      updatedAt: '2020-07-02T13:56:15.878Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '8b7b0770-b9ea-4adf-99dc-d6dcf278cad8',
      unisonId: 'COM76489NNJS',
      title: 'FLAMES NOACGTR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:15.885Z',
      submitterWorkN: 'FLAMESNOA',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:15.887Z',
      updatedAt: '2020-07-02T13:56:15.933Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '15ad1451-0bce-4dcb-903f-f1d70b7dcfe1',
      unisonId: 'COQ485KYYS51',
      title: 'FLAMES NOGTR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:15.941Z',
      submitterWorkN: 'FLAMESNOG',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:15.944Z',
      updatedAt: '2020-07-02T13:56:15.998Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'ec7b4f3a-35db-41c6-be86-6cd6fb8e2944',
      unisonId: 'CO8G1SYBI3CS',
      title: 'FLASH DMSBS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:16.009Z',
      submitterWorkN: 'FLASHDMSB',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:16.011Z',
      updatedAt: '2020-07-02T13:56:16.080Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '7c5f87bf-2b09-43fb-9928-33ea53f4a7c8',
      unisonId: 'COH6LLJKB99G',
      title: 'FLASH FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:16.085Z',
      submitterWorkN: 'FLASHFULL',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:16.086Z',
      updatedAt: '2020-07-02T13:56:16.145Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '4ca2b40e-4ed6-4f14-b198-e96c0f737088',
      unisonId: 'CO6HVWK2DT2N',
      title: 'FLASH LITE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:16.150Z',
      submitterWorkN: 'FLASHLITE',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:16.151Z',
      updatedAt: '2020-07-02T13:56:16.214Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'a9ed3812-aa8c-4f61-8ffe-7521b1069df0',
      unisonId: 'COQ0E50O8USA',
      title: 'FLASH NOHNS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:16.219Z',
      submitterWorkN: 'FLASHNOHN',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:16.221Z',
      updatedAt: '2020-07-02T13:56:16.299Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'f362bd21-df5d-4dba-aa43-de8990e532aa',
      unisonId: 'COLGXSMFGCTX',
      title: 'FUZZYFLOUT NOCYMBL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:14.645Z',
      submitterWorkN: 'FUZZYFLOU005',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:14.646Z',
      updatedAt: '2020-07-02T13:56:14.696Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '178133ec-cd13-4723-9599-45595cbd638f',
      unisonId: 'COSH7NN3N7EB',
      title: 'FUZZYFLOUT NODRM',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:14.701Z',
      submitterWorkN: 'FUZZYFLOU006',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:14.702Z',
      updatedAt: '2020-07-02T13:56:14.744Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'd0e58d18-7797-4194-aef3-1434e6922773',
      unisonId: 'CO1GTV0T2QTM',
      title: 'FUZZYFLOUT NOSFX',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:14.750Z',
      submitterWorkN: 'FUZZYFLOU007',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:14.752Z',
      updatedAt: '2020-07-02T13:56:14.812Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '1b801827-0c0e-4eb5-9d28-f9cd8dc51877',
      unisonId: 'CO39W3JS10SO',
      title: 'FUZZYFLOUT PERC',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:14.819Z',
      submitterWorkN: 'FUZZYFLOU008',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:14.821Z',
      updatedAt: '2020-07-02T13:56:14.885Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '63c9d2d0-6b73-4bdc-a5f1-3ee74557a54b',
      unisonId: 'COL07ML3VP10',
      title: 'FLASHSHIP DMSBSS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:16.405Z',
      submitterWorkN: 'FLASHSHIP',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:16.406Z',
      updatedAt: '2020-07-02T13:56:16.462Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '2adae49e-2d72-4b4d-b34c-68bffd4d7880',
      unisonId: 'CO426TOC8IRF',
      title: 'FLASHSHIP FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:16.470Z',
      submitterWorkN: 'FLASHSHIP001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:16.472Z',
      updatedAt: '2020-07-02T13:56:16.531Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '2599cbb1-e280-47ed-a37d-d9f50f2fff2f',
      unisonId: 'CONT62VRIWAS',
      title: 'FLASHSHIP NOBRASS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:16.544Z',
      submitterWorkN: 'FLASHSHIP002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:16.546Z',
      updatedAt: '2020-07-02T13:56:16.613Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '4fe6809b-56c7-432b-a059-fc0c9f9ad68a',
      unisonId: 'COC2N8EEFUL2',
      title: 'FLASHSHIP NODMS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:16.618Z',
      submitterWorkN: 'FLASHSHIP003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:16.620Z',
      updatedAt: '2020-07-02T13:56:16.694Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '3c9f0b01-e518-4b29-b0b1-37c16ba94c6e',
      unisonId: 'COS73L4CVT3Y',
      title: 'FLASHSHIP NODMSBSS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:16.700Z',
      submitterWorkN: 'FLASHSHIP004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:16.701Z',
      updatedAt: '2020-07-02T13:56:16.763Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '78efbf63-3193-42d2-bb5b-789910035af7',
      unisonId: 'CO1JFTEJMJA8',
      title: 'FLASHSHIP NOGLOCKENSPIEL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:16.772Z',
      submitterWorkN: 'FLASHSHIP005',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:16.773Z',
      updatedAt: '2020-07-02T13:56:16.840Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '1d3ddeef-9e6c-4d3a-b10d-7b313aca39d1',
      unisonId: 'COZELACWEK45',
      title: 'FLASHSHIP NOMEL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:16.847Z',
      submitterWorkN: 'FLASHSHIP006',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:16.849Z',
      updatedAt: '2020-07-02T13:56:16.923Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '2218daca-9a50-4d38-bc12-bf05b853d1ce',
      unisonId: 'CONY7EXG4294',
      title: 'FLASHSHIP NOORGAN',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:16.934Z',
      submitterWorkN: 'FLASHSHIP007',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:16.938Z',
      updatedAt: '2020-07-02T13:56:16.992Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '22807bc3-7c0f-4909-a620-4356e4708136',
      unisonId: 'COGCH4Q151SZ',
      title: 'FLASHSHIP NOWOODWIND',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:17.000Z',
      submitterWorkN: 'FLASHSHIP008',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:17.001Z',
      updatedAt: '2020-07-02T13:56:17.064Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'd9727984-bbae-4f0e-bc56-9482b46bfb7e',
      unisonId: 'COMTSGYOO35V',
      title: 'FLAVOR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:17.086Z',
      submitterWorkN: 'FLAVOR',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:03:37',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:17.088Z',
      updatedAt: '2020-07-02T13:56:17.129Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '3677deef-6b4e-4727-b8ab-4301c6a549cb',
      unisonId: 'COUBBJC2R66Z',
      title: 'FLEX THAT X',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:17.140Z',
      submitterWorkN: 'FLEXTHATX',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:17.142Z',
      updatedAt: '2020-07-02T13:56:17.236Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '2b564e80-c69b-4785-9473-9b0ab7cb2391',
      unisonId: 'COOQ787XW73X',
      title: 'FLINGFLUNG DMSBS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:17.247Z',
      submitterWorkN: 'FLINGFLUN',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:17.249Z',
      updatedAt: '2020-07-02T13:56:17.317Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'ec35daf8-2da6-42ea-8fd3-a8206c848b4e',
      unisonId: 'CO5C9G6X31Z2',
      title: 'FLINGFLUNG FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:17.328Z',
      submitterWorkN: 'FLINGFLUN001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:17.331Z',
      updatedAt: '2020-07-02T13:56:17.409Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'ea5341f5-0437-4e43-81b5-0adfd7eec8b6',
      unisonId: 'COAJVU0FQZCY',
      title: 'FLINGFLUNG LITEMIX',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:17.414Z',
      submitterWorkN: 'FLINGFLUN002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:17.415Z',
      updatedAt: '2020-07-02T13:56:17.483Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '2b8f0ea8-996b-4674-a18c-3aa525bb8604',
      unisonId: 'COI2IF5JWJKR',
      title: 'FLINGFLUNG NOACGTRS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:17.502Z',
      submitterWorkN: 'FLINGFLUN003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:17.505Z',
      updatedAt: '2020-07-02T13:56:17.574Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'f5a06db4-5b21-4bec-825e-63f09da9dab9',
      unisonId: 'CO5P0YXIO7S2',
      title: 'FLINGFLUNG NOWDBL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:17.583Z',
      submitterWorkN: 'FLINGFLUN004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:17.584Z',
      updatedAt: '2020-07-02T13:56:17.635Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '0a4556b9-0bf4-4b03-b57d-0f5ac333f4aa',
      unisonId: 'COB6QWKJNCIM',
      title: 'FLIPFLOP DMSBS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:17.642Z',
      submitterWorkN: 'FLIPFLOPD',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:17.644Z',
      updatedAt: '2020-07-02T13:56:17.730Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '8c4179e7-5f5c-42d3-a4f7-3105b08360a8',
      unisonId: 'COM6EHP8YVE0',
      title: 'FLIPFLOP FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:17.736Z',
      submitterWorkN: 'FLIPFLOPF',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:17.738Z',
      updatedAt: '2020-07-02T13:56:17.846Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'aebaa4ad-8ca7-4e61-ba63-66b527811051',
      unisonId: 'COE1ONRBCTF3',
      title: 'FLIPFLOP LITE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:17.851Z',
      submitterWorkN: 'FLIPFLOPL',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:17.852Z',
      updatedAt: '2020-07-02T13:56:17.928Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'cfa81c1f-e0c1-401b-8d3f-7348d35f0005',
      unisonId: 'COPM1XBN60U4',
      title: 'FLIPFLOP NOACGTRS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:17.937Z',
      submitterWorkN: 'FLIPFLOPN',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:17.938Z',
      updatedAt: '2020-07-02T13:56:18.015Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'f9f160cc-7a8c-4b21-8870-99af1b612cf0',
      unisonId: 'COJZWVKDRXOV',
      title: 'FLIPFLOP NOSTRGS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:18.026Z',
      submitterWorkN: 'FLIPFLOPN001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:18.028Z',
      updatedAt: '2020-07-02T13:56:18.132Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'f52871c4-ef13-4a79-92e3-26b7f4747bfe',
      unisonId: 'COCOUMAAKTHY',
      title: 'FLIP A SIDE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:18.142Z',
      submitterWorkN: 'FLIPSIDE001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:06:15',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:18.144Z',
      updatedAt: '2020-07-02T13:56:18.201Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '5aa239be-8b02-4bb0-a330-27fbe473b011',
      unisonId: 'COLEHKK8YJ08',
      title: 'FLIPUOFF DRMSBASS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:18.208Z',
      submitterWorkN: 'FLIPUOFFD',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:18.209Z',
      updatedAt: '2020-07-02T13:56:18.258Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '4e863525-b3b3-4c5a-ad38-f055c971da7f',
      unisonId: 'COW4ED8DZS43',
      title: 'FLIPUOFF FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:18.265Z',
      submitterWorkN: 'FLIPUOFFF',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:18.266Z',
      updatedAt: '2020-07-02T13:56:18.322Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '4461c79a-3846-44dd-bf71-d6acc00746df',
      unisonId: 'CO4VXUQEIDDO',
      title: 'FLIPUOFF GTRBASS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:18.334Z',
      submitterWorkN: 'FLIPUOFFG',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:18.337Z',
      updatedAt: '2020-07-02T13:56:18.403Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '73dee463-7f18-4734-941d-c8463f557e28',
      unisonId: 'COMIG0S9SS4Q',
      title: 'FLIPUOFF NOMEL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:18.407Z',
      submitterWorkN: 'FLIPUOFFN',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:18.408Z',
      updatedAt: '2020-07-02T13:56:18.471Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '718d3787-79f0-454a-a91b-daa74af7db1b',
      unisonId: 'COA5VIRRR7UN',
      title: 'FLOATING',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:18.500Z',
      submitterWorkN: 'FLOATING001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:18.504Z',
      updatedAt: '2020-07-02T13:56:18.593Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '8dca0149-2e71-4b51-b739-378cbfca0376',
      unisonId: 'COAQQPJI1E5F',
      title: 'FLOATMYBOAT FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:18.603Z',
      submitterWorkN: 'FLOATMYBO',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:18.605Z',
      updatedAt: '2020-07-02T13:56:18.696Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '9813fcb5-2b26-4872-8a8d-1241b13f1d65',
      unisonId: 'COT642Z6BHJF',
      title: 'FLOATMYBOAT NODMS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:18.703Z',
      submitterWorkN: 'FLOATMYBO001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:18.706Z',
      updatedAt: '2020-07-02T13:56:18.768Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'ad64d285-5564-4a9b-b7ad-4815de2bad20',
      unisonId: 'CO78R2EGYVHB',
      title: 'FLOATMYBOAT NOWINDS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:18.777Z',
      submitterWorkN: 'FLOATMYBO002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:18.779Z',
      updatedAt: '2020-07-02T13:56:18.852Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '40a34a35-d119-47d5-93d9-edbb3f887cf1',
      unisonId: 'COTB5PWB39ZJ',
      title: 'FLORA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:18.868Z',
      submitterWorkN: 'FLORA001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:18.870Z',
      updatedAt: '2020-07-02T13:56:18.929Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'c4a58a8b-c20b-4595-94ec-10c709288311',
      unisonId: 'COI09CXJTCH4',
      title: 'FLUFFYCAT FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:18.936Z',
      submitterWorkN: 'FLUFFYCAT',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:18.937Z',
      updatedAt: '2020-07-02T13:56:19.062Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '2d939d42-31fc-4691-ae9e-7eefe04bc98e',
      unisonId: 'CO4K0YLMPEBT',
      title: 'FLUFFYCAT LITE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:19.072Z',
      submitterWorkN: 'FLUFFYCAT001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:19.073Z',
      updatedAt: '2020-07-02T13:56:19.173Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '02c77f3c-834c-4d40-b08d-f4bff371a732',
      unisonId: 'COH2FLUM1QHN',
      title: 'FLUFFYCAT NOSYNTH',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:19.181Z',
      submitterWorkN: 'FLUFFYCAT002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:19.183Z',
      updatedAt: '2020-07-02T13:56:19.252Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '8391b8de-4a32-443b-b823-6037cdb833f8',
      unisonId: 'COALFD6BPJQZ',
      title: 'FLUTTER FULLF',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:19.263Z',
      submitterWorkN: 'FLUTTERFU',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:19.265Z',
      updatedAt: '2020-07-02T13:56:19.336Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '7c0b0b88-c721-4b97-baf9-cc9c5a66a0c9',
      unisonId: 'COKHGTT1NBAO',
      title: 'FLUTTERLING',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:19.346Z',
      submitterWorkN: 'FLUTTERLI',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:19.347Z',
      updatedAt: '2020-07-02T13:56:19.392Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '49989b96-3e13-4b79-9e4d-21425ab97a51',
      unisonId: 'COIPE3N3516W',
      title: 'FLUTTER NOPERCF',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:19.398Z',
      submitterWorkN: 'FLUTTERNO',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:19.400Z',
      updatedAt: '2020-07-02T13:56:19.465Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '9d171c1f-96aa-4667-b7d4-6a9a37641245',
      unisonId: 'COFLU7R2W3BU',
      title: 'GOATSGOYEAH FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:19.717Z',
      submitterWorkN: 'GOATSGOYE',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:19.720Z',
      updatedAt: '2020-07-02T13:56:19.793Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '50a78a43-72ba-48a5-a7cd-58a5b59145b8',
      unisonId: 'COC49E35EMLA',
      title: 'FLUTTER NOPNOF',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:19.473Z',
      submitterWorkN: 'FLUTTERNO001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:19.478Z',
      updatedAt: '2020-07-02T13:56:19.551Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '367d017a-a62b-4613-93f5-65ab3c0ca8a1',
      unisonId: 'COJE4RADZ6PH',
      title: 'FLUTTER PERCBSF',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:19.562Z',
      submitterWorkN: 'FLUTTERPE',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:19.565Z',
      updatedAt: '2020-07-02T13:56:19.637Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '6d3dbf86-ebde-4e11-80ed-2315b2e5201a',
      unisonId: 'CO8RGRUPPUNE',
      title: 'FLUTTER PNOF',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:19.643Z',
      submitterWorkN: 'FLUTTERPN',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:19.646Z',
      updatedAt: '2020-07-02T13:56:19.716Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'ff4907c3-3069-44bd-bed8-8a0dbc10ae97',
      unisonId: 'COG2N6JFRPNU',
      title: 'FLUTTER PNOPERCF',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:19.724Z',
      submitterWorkN: 'FLUTTERPN001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:19.725Z',
      updatedAt: '2020-07-02T13:56:19.795Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '0238ee47-db65-42ef-bc52-7d9a0a979236',
      unisonId: 'COAHLTAE8KA4',
      title: 'FLY',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:19.805Z',
      submitterWorkN: 'FLY002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:03:33',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:19.807Z',
      updatedAt: '2020-07-02T13:56:19.876Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'b36a2a55-75c8-4191-9bff-e2179d3bb6ce',
      unisonId: 'COHN62AZ1KBS',
      title: 'FLYINGHOME FULLF',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:19.883Z',
      submitterWorkN: 'FLYINGHOM',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:19.884Z',
      updatedAt: '2020-07-02T13:56:19.956Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '18124526-3b2b-4837-8566-52f7b9528a5f',
      unisonId: 'CODZWW1330CI',
      title: 'FLYINGHOME LITEF',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:19.961Z',
      submitterWorkN: 'FLYINGHOM001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:19.962Z',
      updatedAt: '2020-07-02T13:56:20.069Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'fba2a8bf-d059-4fec-899e-1603f6eb20c7',
      unisonId: 'CO86X1G0PLEN',
      title: 'FLYINGHOME NOBRSF',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:20.075Z',
      submitterWorkN: 'FLYINGHOM002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:20.077Z',
      updatedAt: '2020-07-02T13:56:20.135Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '97bc940a-91d0-41a7-b1e4-2f1dedd85683',
      unisonId: 'COLQ47DJCXXN',
      title: 'FLYINGHOME NODMSF',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:20.141Z',
      submitterWorkN: 'FLYINGHOM003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:20.143Z',
      updatedAt: '2020-07-02T13:56:20.195Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '797690c1-d0f4-4d5c-b795-3f22b53619f8',
      unisonId: 'CO4TA1F4NMU8',
      title: 'FLYINGHOME NOSTRSF',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:20.201Z',
      submitterWorkN: 'FLYINGHOM004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:20.204Z',
      updatedAt: '2020-07-02T13:56:20.275Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '3c38aace-8d10-4fcc-b378-e205e4523900',
      unisonId: 'COJB5HHD6LKB',
      title: 'FLYINGHOME PERCF',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:20.289Z',
      submitterWorkN: 'FLYINGHOM005',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:20.291Z',
      updatedAt: '2020-07-02T13:56:20.372Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '0f619857-3022-4e49-9b68-ec0d8824bb76',
      unisonId: 'CO30LZ678Y8X',
      title: 'FLYINGPEPPER BSDMS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:20.386Z',
      submitterWorkN: 'FLYINGPEP',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:20.390Z',
      updatedAt: '2020-07-02T13:56:20.479Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'e9ac9efd-ca15-4a39-b5fd-9215215b6376',
      unisonId: 'COQT4Y0LMTTP',
      title: 'FLYINGPEPPER BSPIZZ',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:20.485Z',
      submitterWorkN: 'FLYINGPEP001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:20.487Z',
      updatedAt: '2020-07-02T13:56:20.570Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'c01a5d34-8074-4cf5-a043-c1d4014e6724',
      unisonId: 'CO0CS11VDL8L',
      title: 'FLYINGPEPPER DMS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:20.578Z',
      submitterWorkN: 'FLYINGPEP002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:20.580Z',
      updatedAt: '2020-07-02T13:56:20.660Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '88ffa58b-36a1-4c76-8af5-217f01d77b11',
      unisonId: 'CO0F2UA2SEB2',
      title: 'FLYINGPEPPER FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:20.666Z',
      submitterWorkN: 'FLYINGPEP003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:20.668Z',
      updatedAt: '2020-07-02T13:56:20.741Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '53c202fb-eb7f-4900-b398-2a853a66e6bc',
      unisonId: 'COJJHXD6BGJV',
      title: 'FLYINGPEPPER NODMS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:20.749Z',
      submitterWorkN: 'FLYINGPEP004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:20.752Z',
      updatedAt: '2020-07-02T13:56:20.828Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '3696060a-09e5-4a46-a84f-f90f9bdb1b21',
      unisonId: 'COCES4SL8RFL',
      title: 'HOPSCOTCH FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:28.115Z',
      submitterWorkN: 'HOPSCOTCH002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:28.118Z',
      updatedAt: '2020-07-02T13:56:28.204Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '8ebb14b0-c38f-4b80-b270-f587ee87a2b3',
      unisonId: 'CORHOFL2AK6U',
      title: 'HERETODAY FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:28.469Z',
      submitterWorkN: 'HERETODAY',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:28.472Z',
      updatedAt: '2020-07-02T13:56:28.540Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '0b81f7b6-61e1-4815-bc88-4efb1ce4995d',
      unisonId: 'COFLGXVXJHPR',
      title: 'HATFEATURE NOLEADSYNTH',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:33.304Z',
      submitterWorkN: 'HATFEATUR004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:33.306Z',
      updatedAt: '2020-07-02T13:56:33.394Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '2d28ad69-cb73-4fab-986f-d1f5834b703c',
      unisonId: 'CO755KO5GFLE',
      title: 'HAVEANDTOHOLD FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:33.785Z',
      submitterWorkN: 'HAVEANDTO001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:33.787Z',
      updatedAt: '2020-07-02T13:56:33.848Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '1587785b-9582-4df3-ae33-7c640e175e52',
      unisonId: 'COEGRX9QPF2A',
      title: "I CAN'T FLY",
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:31.832Z',
      submitterWorkN: 'ICANTFLY',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:31.833Z',
      updatedAt: '2020-07-02T13:56:31.883Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '3016603e-96c4-4f33-b867-16bd747e3b3f',
      unisonId: 'COBLWFLHPD8Q',
      title: 'INFECTIONACCESS NOGLOCKENSPIEL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:36.891Z',
      submitterWorkN: 'INFECTION005',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:36.892Z',
      updatedAt: '2020-07-02T13:56:36.943Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'c83b600e-93f0-41be-9161-4835b9c60c71',
      unisonId: 'CO5DWSQAMK9B',
      title: 'INFLUENCEGOLDENLARCH DRUMSPERC',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:37.387Z',
      submitterWorkN: 'INFLUENCE001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:37.389Z',
      updatedAt: '2020-07-02T13:56:37.445Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'df6d1851-9095-46c2-95b1-7aa2abf75e96',
      unisonId: 'COH721RM7E2L',
      title: 'INFLUENCEGOLDENLARCH FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:37.451Z',
      submitterWorkN: 'INFLUENCE002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:37.453Z',
      updatedAt: '2020-07-02T13:56:37.513Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'ea194850-a037-480c-ae21-385466ff5c9f',
      unisonId: 'COOLA9C1HP2U',
      title: 'INFLUENCEGOLDENLARCH LESSPERC',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:37.522Z',
      submitterWorkN: 'INFLUENCE003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:37.525Z',
      updatedAt: '2020-07-02T13:56:37.578Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'eaa90fdc-f42a-4ea8-b10a-23ad6cec901d',
      unisonId: 'COZ6PBLZ944M',
      title: 'INFLUENCEGOLDENLARCH LESSSYNTH',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:37.588Z',
      submitterWorkN: 'INFLUENCE004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:37.590Z',
      updatedAt: '2020-07-02T13:56:37.642Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '4469f779-fe4c-4422-8668-0fe6aa5345eb',
      unisonId: 'COE98FZ2EXVV',
      title: 'INFLUENCEGOLDENLARCH NODRUMS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:37.650Z',
      submitterWorkN: 'INFLUENCE005',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:37.652Z',
      updatedAt: '2020-07-02T13:56:37.714Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '558b4700-f5cb-49d9-834f-39aad2a5599b',
      unisonId: 'CORBWLO1L27D',
      title: 'INFLUENCED FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:37.720Z',
      submitterWorkN: 'INFLUENCE006',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:37.721Z',
      updatedAt: '2020-07-02T13:56:37.772Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'd35edb1f-46a9-4a17-9986-376e3a97446e',
      unisonId: 'COCU2MYFN6SO',
      title: 'INFLUENCED PAD',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:37.782Z',
      submitterWorkN: 'INFLUENCE007',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:37.784Z',
      updatedAt: '2020-07-02T13:56:37.864Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'e15985da-3539-4634-b398-de4367aef365',
      unisonId: 'CO97CLGAQ4I1',
      title: 'INFLUENCED PIANO',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:37.868Z',
      submitterWorkN: 'INFLUENCE008',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:37.869Z',
      updatedAt: '2020-07-02T13:56:37.911Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'af67ddf5-d0a2-4d88-919a-8b51afeab546',
      unisonId: 'COONTE340X4G',
      title: 'INFLUENCED STR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:37.918Z',
      submitterWorkN: 'INFLUENCE009',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:37.920Z',
      updatedAt: '2020-07-02T13:56:37.971Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '96b6b5af-8e8b-466e-a67d-9fd6023be8fb',
      unisonId: 'COEUGIZD1MUA',
      title: 'LETITFLY DMSBS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:46.608Z',
      submitterWorkN: 'LETITFLYD',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:46.610Z',
      updatedAt: '2020-07-02T13:56:46.661Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'e9268ef8-1bf7-4985-91d2-1530165333a2',
      unisonId: 'COKFNTUC1TT1',
      title: 'LETITFLY ELPNO',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:46.668Z',
      submitterWorkN: 'LETITFLYE',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:46.672Z',
      updatedAt: '2020-07-02T13:56:46.745Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '3348f426-beb3-467c-9ab0-30b2688274db',
      unisonId: 'CON1CTJ8B0XW',
      title: 'LETITFLY NOFXS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:46.751Z',
      submitterWorkN: 'LETITFLYN',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:46.753Z',
      updatedAt: '2020-07-02T13:56:46.808Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '6eb7d30d-dc68-4d09-8f34-ae2cd306e9be',
      unisonId: 'COERUT9A91TL',
      title: 'LETITFLY PADS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:46.817Z',
      submitterWorkN: 'LETITFLYP',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:46.819Z',
      updatedAt: '2020-07-02T13:56:46.866Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '248f9838-a99a-4f5e-a581-f368f841b8da',
      unisonId: 'COZVFLL9XV24',
      title: 'LETSSTART NODMS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:47.280Z',
      submitterWorkN: 'LETSSTART002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:47.282Z',
      updatedAt: '2020-07-02T13:56:47.357Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'ccb066f1-e148-4f59-97e0-bf8a0f48f084',
      unisonId: 'COZ00DIFLQCE',
      title: 'IRONICALLYTRIATHLON NOMELODY',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:42.009Z',
      submitterWorkN: 'IRONICALL004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:42.011Z',
      updatedAt: '2020-07-02T13:56:42.084Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'ff8eb211-b5f4-409b-9318-75b582979043',
      unisonId: 'CO1VFL7N529X',
      title: 'IRONSPINE PERCBSF',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:42.400Z',
      submitterWorkN: 'IRONSPINE005',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:42.402Z',
      updatedAt: '2020-07-02T13:56:42.475Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '50c1d0f7-9111-4a79-af04-e80353e8b4ad',
      unisonId: 'COOBG79EY7UL',
      title: 'LAUGHINGSTOCK FLPZDULC',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:46.470Z',
      submitterWorkN: 'LAUGHINGS',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:46.472Z',
      updatedAt: '2020-07-02T13:56:46.532Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '10536257-a3d1-4ecb-be35-795df2698dca',
      unisonId: 'COAH6BVGFLD9',
      title: 'ITSHERE FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:54.850Z',
      submitterWorkN: 'ITSHEREFU',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:54.852Z',
      updatedAt: '2020-07-02T13:56:54.945Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'a8102bf9-473a-4d98-a6cb-ea3d958da60f',
      unisonId: 'COEO92JX8FLR',
      title: 'I WANNA LIVE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:56.469Z',
      submitterWorkN: 'IWANNALIV001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:56.471Z',
      updatedAt: '2020-07-02T13:56:56.522Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '480e86b2-ae2e-4786-acf4-645d4d4185af',
      unisonId: 'COP3YFLKVRP8',
      title: 'LOSTREMORSE NOSTRSF',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:57.237Z',
      submitterWorkN: 'LOSTREMOR004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:57.239Z',
      updatedAt: '2020-07-02T13:56:57.324Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '6424f4cc-8071-44da-bdf7-88cedcc99875',
      unisonId: 'COUSJWNSYRKN',
      title: 'LYRICALFLAX FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:58.275Z',
      submitterWorkN: 'LYRICALFL',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:58.276Z',
      updatedAt: '2020-07-02T13:56:58.339Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '97e8387e-b1b3-4e72-aafa-bd8db8180832',
      unisonId: 'COJZBWP0ATP9',
      title: 'LYRICALFLAX NOCLAP',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:58.348Z',
      submitterWorkN: 'LYRICALFL001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:58.350Z',
      updatedAt: '2020-07-02T13:56:58.421Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'fd5f6285-b72e-4bd9-b731-66fbc5c014d4',
      unisonId: 'COBSN61BOPBZ',
      title: 'LYRICALFLAX NOLASERSYNTH',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:58.427Z',
      submitterWorkN: 'LYRICALFL002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:58.428Z',
      updatedAt: '2020-07-02T13:56:58.489Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'f8133f3d-a870-49b8-aa70-f3bdea399f05',
      unisonId: 'CO2HWVHSW26R',
      title: 'LYRICALFLAX NOLEADSYNTHHOOK',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:58.498Z',
      submitterWorkN: 'LYRICALFL003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:58.500Z',
      updatedAt: '2020-07-02T13:56:58.552Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '0b321f91-21d4-46f8-a873-be0a109932c8',
      unisonId: 'CO9UKHU2HOW4',
      title: 'LYRICALFLAX NOSYNTHPLUCK',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:58.560Z',
      submitterWorkN: 'LYRICALFL004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:58.563Z',
      updatedAt: '2020-07-02T13:56:58.618Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'dba23e61-b067-4f13-ba2c-f1dd3afcd5c6',
      unisonId: 'COFQ4FLQHQWK',
      title: 'LAMBADACORDOVAN GTRS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:56:58.780Z',
      submitterWorkN: 'LAMBADACO003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:56:58.781Z',
      updatedAt: '2020-07-02T13:56:58.841Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'e217ea67-7590-4a23-b7de-eae1db791044',
      unisonId: 'CO8GOAZAV14Q',
      title: 'MAJORELLEAERIAL NOFLUTE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:01.979Z',
      submitterWorkN: 'MAJORELLE002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:01.981Z',
      updatedAt: '2020-07-02T13:57:02.051Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '7650c1cf-a036-44f3-981d-b8f81d0c0fcb',
      unisonId: 'COATE5TR7QR4',
      title: 'JESTERJOKE NOFLUTE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:00.840Z',
      submitterWorkN: 'JESTERJOK003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:00.842Z',
      updatedAt: '2020-07-02T13:57:00.916Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'eef67bf4-d538-451d-a4cf-0585f9c966b6',
      unisonId: 'COV39IVTDFFL',
      title: 'MAROONLOCUST DRMSPRCBSS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:00.814Z',
      submitterWorkN: 'MAROONLOC001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:00.819Z',
      updatedAt: '2020-07-02T13:57:00.892Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'f2397f22-7839-42f2-9d80-3a06b4c3d2c0',
      unisonId: 'COGC870Y34UM',
      title: 'MAYFLOWER',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:01.988Z',
      submitterWorkN: 'MAYFLOWER',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:04:21',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:01.989Z',
      updatedAt: '2020-07-02T13:57:02.025Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '5b6130dc-aa9c-4dd3-a6f7-40874eb357ac',
      unisonId: 'COEFLR1RVKQI',
      title: 'MILITARYSHED NOMARIMBA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:05.569Z',
      submitterWorkN: 'MILITARYS005',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:05.571Z',
      updatedAt: '2020-07-02T13:57:05.630Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'bf75df03-02bc-428c-b172-40fbdbdbc5e5',
      unisonId: 'COTN0CA1KSQS',
      title: 'MYFAVOR NOFLUTES',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:05.374Z',
      submitterWorkN: 'MYFAVORNO003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:05.376Z',
      updatedAt: '2020-07-02T13:57:05.432Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'f5f830e9-ed1e-4265-8004-54cd5089e360',
      unisonId: 'COPFL1DCVA3G',
      title: 'MINETETHERBALL CLASSICAL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:06.540Z',
      submitterWorkN: 'MINETETHE',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:06.542Z',
      updatedAt: '2020-07-02T13:57:06.595Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'd6b218e2-c4a6-41d0-99ca-b87e7fcc0355',
      unisonId: 'COFLJC0GCX7J',
      title: 'NOTHING TO COVER',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:11.337Z',
      submitterWorkN: 'NOTHINGTO003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:03:34',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:11.339Z',
      updatedAt: '2020-07-02T13:57:11.395Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '4e81c029-9831-4f9b-844c-46739d132003',
      unisonId: 'CO6QRDWFL6SW',
      title: 'NEVERBEFORE LITE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:11.783Z',
      submitterWorkN: 'NEVERBEFO004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:11.786Z',
      updatedAt: '2020-07-02T13:57:11.858Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '5c5c9e78-4264-40dc-a039-1e414f59c07e',
      unisonId: 'COADFLV8MZNA',
      title: 'OPENROAD22 NOORGACCRDN',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:14.848Z',
      submitterWorkN: 'OPENROAD2004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:14.849Z',
      updatedAt: '2020-07-02T13:57:14.906Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '39a5be5c-aa7f-4e97-8c0d-2b7d715e39e7',
      unisonId: 'CO5VHW9UOU44',
      title: 'MOTHEREARTH FLUTE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:15.832Z',
      submitterWorkN: 'MOTHEREAR',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:15.834Z',
      updatedAt: '2020-07-02T13:57:15.924Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'eaa1759e-bbf6-4ff8-ac5b-be041950e9ce',
      unisonId: 'CO59I6R5H4RR',
      title: 'ONSALE NOFLTOBOE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:16.803Z',
      submitterWorkN: 'ONSALENOF',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:16.805Z',
      updatedAt: '2020-07-02T13:57:16.865Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'd3dec87f-c89a-4a85-aa81-e6a1f78970fc',
      unisonId: 'COS2DFLELWBH',
      title: 'NEWANTICS NOHARP',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:18.147Z',
      submitterWorkN: 'NEWANTICS002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:18.150Z',
      updatedAt: '2020-07-02T13:57:18.223Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'f3d10cc9-79cf-473e-8b8a-e7d985a3b119',
      unisonId: 'COVQ3R4YKK1Z',
      title: 'PEGLEGDANCE NOFLUTE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:19.166Z',
      submitterWorkN: 'PEGLEGDAN002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:19.167Z',
      updatedAt: '2020-07-02T13:57:19.245Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '79a8b7e7-0dc3-47d0-9273-61a0488cffda',
      unisonId: 'COJPO58A1LQH',
      title: 'PRINCESSWALTZ NOCLARINETFLUTE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:24.793Z',
      submitterWorkN: 'PRINCESSW001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:24.795Z',
      updatedAt: '2020-07-02T13:57:24.893Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '3d1c77d5-9728-4eb4-a439-74a7462828fe',
      unisonId: 'COY7HXWRZPR7',
      title: 'PRIZEOFLIFE FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:25.578Z',
      submitterWorkN: 'PRIZEOFLI',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:25.579Z',
      updatedAt: '2020-07-02T13:57:25.646Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '11b39a65-0a03-4848-ac29-14bf5bf84ec7',
      unisonId: 'COGKFZQ90CC3',
      title: 'PRIZEOFLIFE GTR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:25.657Z',
      submitterWorkN: 'PRIZEOFLI001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:25.660Z',
      updatedAt: '2020-07-02T13:57:25.736Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'a2e6aa87-276e-4299-a037-087dc4b13e8b',
      unisonId: 'COK0CGSXWOOM',
      title: 'PRIZEOFLIFE NOGTR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:25.746Z',
      submitterWorkN: 'PRIZEOFLI002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:25.748Z',
      updatedAt: '2020-07-02T13:57:25.836Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '0147c025-6a6b-49e4-ae13-e0862567a969',
      unisonId: 'CO03FNM0XDEB',
      title: 'PRIZEOFLIFE NOSTRINGS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:25.846Z',
      submitterWorkN: 'PRIZEOFLI003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:25.847Z',
      updatedAt: '2020-07-02T13:57:25.924Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '19311e68-17d1-4296-b373-1087eefb4c84',
      unisonId: 'COCJT1BETI23',
      title: 'PRIZEOFLIFE TONIC',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:25.936Z',
      submitterWorkN: 'PRIZEOFLI004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:25.938Z',
      updatedAt: '2020-07-02T13:57:26.006Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'de1a4e23-f56c-47d8-83e9-e7a8d42c55ea',
      unisonId: 'CO2ISFLS8ZMF',
      title: 'PARTICIPATION NODMS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:22.056Z',
      submitterWorkN: 'PARTICIPA003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:22.058Z',
      updatedAt: '2020-07-02T13:57:22.203Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '769b447d-1312-4430-a486-48243be22df9',
      unisonId: 'COX2NBT4EJ0Q',
      title: 'PIRATEPLAY NOFLUTE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:22.606Z',
      submitterWorkN: 'PIRATEPLA002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:22.608Z',
      updatedAt: '2020-07-02T13:57:22.667Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '0a8f6d37-b380-47d3-b8ad-c293aa1cdd28',
      unisonId: 'COB4SAO15YZY',
      title: 'RUSSETENCOURAGEMENT NOFLUTE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:34.201Z',
      submitterWorkN: 'RUSSETENC002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:34.202Z',
      updatedAt: '2020-07-02T13:57:34.247Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '4c308eb8-8d55-443c-8e3e-0f80f6131289',
      unisonId: 'CO55IFL0NVKG',
      title: 'SELDOMWATERPARK NOREVERB',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:37.729Z',
      submitterWorkN: 'SELDOMWAT001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:37.730Z',
      updatedAt: '2020-07-02T13:57:37.794Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '705b3f99-dfc4-4545-9d6c-26579612e9ca',
      unisonId: 'COYX3WVIVKIO',
      title: 'SANDYTURBAN NOFLSTR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:37.077Z',
      submitterWorkN: 'SANDYTURB003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:37.079Z',
      updatedAt: '2020-07-02T13:57:37.150Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '43bdcf0d-364b-4519-a534-3f66da120396',
      unisonId: 'COD1L4EZFLXH',
      title: 'REVOLVE FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:38.022Z',
      submitterWorkN: 'REVOLVEFU',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:38.023Z',
      updatedAt: '2020-07-02T13:57:38.119Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '0183e731-0802-42f2-a7f9-137b2c9c9b7f',
      unisonId: 'COIXSV244LF8',
      title: 'SHUFFLECHAMPAGNE FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:41.935Z',
      submitterWorkN: 'SHUFFLECH',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:41.937Z',
      updatedAt: '2020-07-02T13:57:41.992Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '4cc1b199-916b-4e61-bdf3-1dece4d99916',
      unisonId: 'COO6I7R2ENUQ',
      title: 'SHUFFLECHAMPAGNE LITE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:41.998Z',
      submitterWorkN: 'SHUFFLECH001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:41.999Z',
      updatedAt: '2020-07-02T13:57:42.050Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '09928f49-6738-4345-a065-8b7f4101dbf1',
      unisonId: 'CO7W82IN9JG4',
      title: 'SHUFFLECHAMPAGNE NOCLAP',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:42.058Z',
      submitterWorkN: 'SHUFFLECH002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:42.060Z',
      updatedAt: '2020-07-02T13:57:42.165Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '44f60672-df1d-4e57-84cd-634fa1c17816',
      unisonId: 'COUMAZEE61KP',
      title: 'SHUFFLECHAMPAGNE NODRUMS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:42.179Z',
      submitterWorkN: 'SHUFFLECH003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:42.181Z',
      updatedAt: '2020-07-02T13:57:42.254Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '7ed3875c-4d54-465b-86b0-5ef4325bd081',
      unisonId: 'COYHZ8YNYVUQ',
      title: 'SHUFFLECHAMPAGNE NOGTR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:42.259Z',
      submitterWorkN: 'SHUFFLECH004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:42.261Z',
      updatedAt: '2020-07-02T13:57:42.322Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'bcc4435c-573a-492e-aad6-577ad6f40895',
      unisonId: 'COKJYQ81UQYB',
      title: 'SHUFFLECHAMPAGNE NOGTRCLAP',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:42.330Z',
      submitterWorkN: 'SHUFFLECH005',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:42.332Z',
      updatedAt: '2020-07-02T13:57:42.387Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '39b0c7b0-d5ad-4201-a30f-1761c50985c5',
      unisonId: 'COB7ETA6OUVQ',
      title: 'SHUFFLETURQUOISE FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:42.400Z',
      submitterWorkN: 'SHUFFLETU',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:42.402Z',
      updatedAt: '2020-07-02T13:57:42.451Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '08552284-02e5-476d-95ac-ae6a125fb631',
      unisonId: 'CO074Z6VZGC4',
      title: 'SHUFFLETURQUOISE NOBASS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:42.456Z',
      submitterWorkN: 'SHUFFLETU001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:42.458Z',
      updatedAt: '2020-07-02T13:57:42.506Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '747424b6-16d3-4591-bc30-f05ff429b3c4',
      unisonId: 'COOGYK6XTEDY',
      title: 'SHUFFLETURQUOISE NOSNARENOPERC',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:42.512Z',
      submitterWorkN: 'SHUFFLETU002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:42.513Z',
      updatedAt: '2020-07-02T13:57:42.571Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'ef90be86-10d0-4283-b866-241a2f3db582',
      unisonId: 'COU3XEIFDCSO',
      title: 'SHUFFLETURQUOISE NOSYNTH',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:42.580Z',
      submitterWorkN: 'SHUFFLETU003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:42.581Z',
      updatedAt: '2020-07-02T13:57:42.652Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '1d629ad0-0445-4ca5-93c4-7105f5602a81',
      unisonId: 'CO9013WY0Y9V',
      title: 'SHUFFLETURQUOISE NOVOCAL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:42.661Z',
      submitterWorkN: 'SHUFFLETU004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:42.664Z',
      updatedAt: '2020-07-02T13:57:42.726Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '8a00a33f-050c-4f30-8d8e-a4965d77f16b',
      unisonId: 'COVXFLJVQ1AA',
      title: 'SIG B8 MR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:44.522Z',
      submitterWorkN: 'SIGB8MR',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:44.524Z',
      updatedAt: '2020-07-02T13:57:44.599Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '8a3764b8-027f-417b-beaa-19a62ca7da2d',
      unisonId: 'COLNFESZFLNQ',
      title: 'SLUSHYACORN NODRNS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:49.414Z',
      submitterWorkN: 'SLUSHYACO004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:49.415Z',
      updatedAt: '2020-07-02T13:57:49.473Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '8fbd4324-b454-4a95-b76d-18f88639facd',
      unisonId: 'CO9MTS261FL5',
      title: 'SMALLDUTIES NODMS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:49.828Z',
      submitterWorkN: 'SMALLDUTI002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:49.829Z',
      updatedAt: '2020-07-02T13:57:49.901Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '627512d8-c9bf-4f30-9586-ef41f3d96ead',
      unisonId: 'CO26GMWE26EZ',
      title: 'STEADILYFLOOD DMSBSS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:58:00.268Z',
      submitterWorkN: 'STEADILYF',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:58:00.269Z',
      updatedAt: '2020-07-02T13:58:00.329Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '200ccbbb-0729-4c8f-8ac7-08c2ae9b89a6',
      unisonId: 'COD656QR56B7',
      title: 'STEADILYFLOOD FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:58:00.336Z',
      submitterWorkN: 'STEADILYF001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:58:00.338Z',
      updatedAt: '2020-07-02T13:58:00.397Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '94135273-0b19-42e5-be5a-78f26e0f60b3',
      unisonId: 'COYZMCWC4641',
      title: 'STEADILYFLOOD LITEMIX',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:58:00.402Z',
      submitterWorkN: 'STEADILYF002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:58:00.403Z',
      updatedAt: '2020-07-02T13:58:00.460Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'd9f469ba-1434-4a8b-a6ef-67c2e9e929bb',
      unisonId: 'COETVF2D2NOF',
      title: 'STEADILYFLOOD NODRUMS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:58:00.465Z',
      submitterWorkN: 'STEADILYF003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:58:00.467Z',
      updatedAt: '2020-07-02T13:58:00.533Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'e08eba33-10c1-43e1-a7fd-991eae5555a0',
      unisonId: 'CO927WE1BEMB',
      title: 'STEADILYFLOOD NODRUMSBASS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:58:00.540Z',
      submitterWorkN: 'STEADILYF004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:58:00.542Z',
      updatedAt: '2020-07-02T13:58:00.609Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'caf50d8b-cf79-4ddc-bbe7-592e35a7ae22',
      unisonId: 'COK4515L0IXG',
      title: 'SINFULLUTZ NOFLUTE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:52.393Z',
      submitterWorkN: 'SINFULLUT007',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:52.396Z',
      updatedAt: '2020-07-02T13:57:52.464Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '994e763e-4233-4d53-8323-83650e354b00',
      unisonId: 'COPGCKHUG4ZG',
      title: 'SISTERMOON FLUTE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:52.583Z',
      submitterWorkN: 'SISTERMOO',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:52.585Z',
      updatedAt: '2020-07-02T13:57:52.672Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'e2b9ee86-8b52-46ea-af24-68c8806fe215',
      unisonId: 'CORRXFLOHUFC',
      title: 'SUMIMPOSE NOORGANS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:57:59.271Z',
      submitterWorkN: 'SUMIMPOSE007',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:57:59.273Z',
      updatedAt: '2020-07-02T13:57:59.358Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'c9d1a369-9fb8-4115-94db-2a54ae8518f7',
      unisonId: 'COCYSBSFWNTJ',
      title: 'STEADILYFLOOD NOLEADGTR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:58:00.616Z',
      submitterWorkN: 'STEADILYF005',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:58:00.624Z',
      updatedAt: '2020-07-02T13:58:00.683Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '701b4d25-235f-4cd7-98b0-54bd99817f7c',
      unisonId: 'COQ73XBIQ04A',
      title: 'STEADILYFLOOD NOSYNTH',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:58:00.691Z',
      submitterWorkN: 'STEADILYF006',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:58:00.693Z',
      updatedAt: '2020-07-02T13:58:00.749Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'b4020a57-ac86-4e07-8ab0-5ba7d8d83c6a',
      unisonId: 'CO8RQA25MJLU',
      title: 'STICKFLICK DMSBSS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:58:02.107Z',
      submitterWorkN: 'STICKFLIC',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:58:02.109Z',
      updatedAt: '2020-07-02T13:58:02.170Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '33b7b27f-4504-4770-9166-18b3477aa000',
      unisonId: 'COQFJ41RLYQF',
      title: 'STICKFLICK FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:58:02.179Z',
      submitterWorkN: 'STICKFLIC001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:58:02.183Z',
      updatedAt: '2020-07-02T13:58:02.291Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '34d57cde-1e9e-4d8c-a647-f235db78cdee',
      unisonId: 'CONJJBSWEP9Y',
      title: 'STICKFLICK NODMS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:58:02.297Z',
      submitterWorkN: 'STICKFLIC002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:58:02.299Z',
      updatedAt: '2020-07-02T13:58:02.346Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '73062b1e-5604-41c2-b01f-d39edcbb8713',
      unisonId: 'COJR1WH354PI',
      title: 'STICKFLICK NODOBRO',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:58:02.355Z',
      submitterWorkN: 'STICKFLIC003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:58:02.358Z',
      updatedAt: '2020-07-02T13:58:02.417Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '3d5b62e4-f447-47bd-9af0-2b165baa9619',
      unisonId: 'CO5420KJKLYQ',
      title: 'STICKFLICK NOHRMNCA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:58:02.424Z',
      submitterWorkN: 'STICKFLIC004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:58:02.426Z',
      updatedAt: '2020-07-02T13:58:02.481Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '8b9659c8-f721-4775-a87e-93381b0ab4bd',
      unisonId: 'COU2N376VFL7',
      title: 'SUPERCOOKS NODMS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:58:02.641Z',
      submitterWorkN: 'SUPERCOOK004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:58:02.642Z',
      updatedAt: '2020-07-02T13:58:02.703Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '99fe9108-845d-4843-93e8-408c7d485cdb',
      unisonId: 'COL55FLZ9YQL',
      title: 'SUSPECTDIG DMSBSS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:58:04.532Z',
      submitterWorkN: 'SUSPECTDI007',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:58:04.536Z',
      updatedAt: '2020-07-02T13:58:04.604Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '63906a02-ccb3-4a81-97db-c7c9b9c57cc2',
      unisonId: 'COFL76W3SKTZ',
      title: 'STRANGEDAYS PERC',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:58:05.653Z',
      submitterWorkN: 'STRANGEDA004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:58:05.656Z',
      updatedAt: '2020-07-02T13:58:05.706Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '6eb60173-c07e-4df5-9087-e06e2c72d310',
      unisonId: 'COQKPZU65FL9',
      title: 'SWORD NOTOMS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:58:11.502Z',
      submitterWorkN: 'SWORDNOTO',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:58:11.505Z',
      updatedAt: '2020-07-02T13:58:11.611Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '2aac2547-3d27-4aab-b4f1-b24f13afc5e3',
      unisonId: 'COIFLSIXC73V',
      title: 'TAKEATRIP GTRBASS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:58:13.354Z',
      submitterWorkN: 'TAKEATRIP002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:58:13.356Z',
      updatedAt: '2020-07-02T13:58:13.421Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '49f36d2c-4969-4fc8-b917-97bb1ff76c67',
      unisonId: 'CO12NJE8KOON',
      title: 'THUNDERGODS FLUTE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:58:16.717Z',
      submitterWorkN: 'THUNDERGO',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:58:16.718Z',
      updatedAt: '2020-07-02T13:58:16.808Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '367c5c7f-0ddc-4375-a5cd-391860c90365',
      unisonId: 'COFLD7CMIMTN',
      title: 'TICKTOCK NODMS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:58:17.152Z',
      submitterWorkN: 'TICKTOCKN',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:58:17.154Z',
      updatedAt: '2020-07-02T13:58:17.228Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '99160c59-bac8-458d-967a-565c4206e8c9',
      unisonId: 'COXTE1ESW733',
      title: 'WAFFLINGPRANCE DMSBSS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:59:45.177Z',
      submitterWorkN: 'WAFFLINGP',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:59:45.179Z',
      updatedAt: '2020-07-02T13:59:45.251Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '48a54e55-a4f3-4852-98a8-16ddb1120d39',
      unisonId: 'COW9LYIJ5KNF',
      title: 'WAFFLINGPRANCE FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:59:45.262Z',
      submitterWorkN: 'WAFFLINGP001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:59:45.264Z',
      updatedAt: '2020-07-02T13:59:45.334Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'd39fe811-d2df-4217-8482-5a2a476ada99',
      unisonId: 'CO511NCJA6J7',
      title: 'WAFFLINGPRANCE LITEMIX',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:59:45.343Z',
      submitterWorkN: 'WAFFLINGP002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:59:45.345Z',
      updatedAt: '2020-07-02T13:59:45.413Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'b6b65481-5795-4060-878d-b1116d95172b',
      unisonId: 'CO7PMQMIJ767',
      title: 'WAFFLINGPRANCE NOBANJO',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:59:45.422Z',
      submitterWorkN: 'WAFFLINGP003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:59:45.424Z',
      updatedAt: '2020-07-02T13:59:45.491Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '771d4ba1-3cc5-47c3-8446-078356cb5ef1',
      unisonId: 'COXGCFHF0MMQ',
      title: 'WAFFLINGPRANCE NODMSBSS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:59:45.499Z',
      submitterWorkN: 'WAFFLINGP004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:59:45.501Z',
      updatedAt: '2020-07-02T13:59:45.578Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'ed57daa5-4d16-42ed-9616-12bcec5e012d',
      unisonId: 'CO9FAFUTR3QG',
      title: 'WAFFLINGPRANCE NODOBRO',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:59:45.584Z',
      submitterWorkN: 'WAFFLINGP005',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:59:45.585Z',
      updatedAt: '2020-07-02T13:59:45.643Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '1c1cbc55-a59c-4c31-b769-996257d4ada6',
      unisonId: 'CO66FLWPE255',
      title: 'WHITETAILS NODMSBSS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T13:59:53.395Z',
      submitterWorkN: 'WHITETAIL002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T13:59:53.397Z',
      updatedAt: '2020-07-02T13:59:53.464Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'd55d438a-ddcb-46d3-b99c-267c16480b56',
      unisonId: 'COCH8NPW62FL',
      title: 'UHNOTSOMUCH PERCBASS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T14:00:03.592Z',
      submitterWorkN: 'UHNOTSOMU004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T14:00:03.593Z',
      updatedAt: '2020-07-02T14:00:03.640Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'd22ec064-7dc2-44b3-9ae1-5b5ff11f9c6d',
      unisonId: 'CODIF49B8AYN',
      title: 'WARSHIELD FLUTE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T14:00:00.391Z',
      submitterWorkN: 'WARSHIELD',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T14:00:00.394Z',
      updatedAt: '2020-07-02T14:00:00.511Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'f633c9d6-991e-479f-a68e-d87d988581ae',
      unisonId: 'COY3CGFL850N',
      title: 'WEALTHCONTINUE NOMEL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T14:00:02.996Z',
      submitterWorkN: 'WEALTHCON003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T14:00:02.998Z',
      updatedAt: '2020-07-02T14:00:03.053Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'aaa659b0-df1b-44c7-abf1-4e07fb107e3d',
      unisonId: 'COO3OOFLHVLY',
      title: 'WELLNESSNOW NOELGTR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T14:00:04.857Z',
      submitterWorkN: 'WELLNESSN004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T14:00:04.860Z',
      updatedAt: '2020-07-02T14:00:04.901Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '9b4ddff2-f15d-4148-8b5b-8bd8f25ebba6',
      unisonId: 'COFBP45ODIXJ',
      title: 'TRIATHLONMVP NOFLUTES',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T14:01:04.199Z',
      submitterWorkN: 'TRIATHLON005',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T14:01:04.201Z',
      updatedAt: '2020-07-02T14:01:04.266Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '163367f3-9558-4229-a6ad-f7eabd18e4f2',
      unisonId: 'COZRUVNGHXSG',
      title: 'TRIATHLONMVP PADSFLUTES',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T14:01:04.272Z',
      submitterWorkN: 'TRIATHLON006',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T14:01:04.274Z',
      updatedAt: '2020-07-02T14:01:04.331Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'a9bc7996-f91b-4a8c-9632-f94bece5af96',
      unisonId: 'CO63OVWFL7GE',
      title: 'TRIBALBEATS FLUTE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T14:01:04.346Z',
      submitterWorkN: 'TRIBALBEA',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T14:01:04.348Z',
      updatedAt: '2020-07-02T14:01:04.421Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '9d9f1a1b-0e55-4bae-a9c2-a611b8af6be7',
      unisonId: 'CON3VMMFLWBO',
      title: 'TRUCKINLAVA FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T14:01:06.062Z',
      submitterWorkN: 'TRUCKINLA',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T14:01:06.065Z',
      updatedAt: '2020-07-02T14:01:06.135Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '2d485fce-c401-4eae-b964-c3ef00910107',
      unisonId: 'CO1RT7FL2EXD',
      title: 'AGAIN AND AGAIN AND AGAIN',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T14:01:54.262Z',
      submitterWorkN: 'AGAINANDAG',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T14:01:54.268Z',
      updatedAt: '2020-07-02T14:01:54.399Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '0903b022-1b5f-4840-b7b0-b63c2f44bf19',
      unisonId: 'COQL0VDRBEL1',
      title: 'LETITFLY FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T14:01:56.641Z',
      submitterWorkN: 'LETITFLYFU',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T14:01:56.643Z',
      updatedAt: '2020-07-02T14:01:56.727Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '905ae74e-4297-40f4-b58f-b92b3efce457',
      unisonId: 'CODK48FLPA4Q',
      title: 'SWEETTHINGS FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-02T14:01:57.742Z',
      submitterWorkN: 'SWEETTHING03',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-02T14:01:57.745Z',
      updatedAt: '2020-07-02T14:01:57.825Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '3df45ccf-f2c9-4584-b263-724497657ac2',
      unisonId: 'CO4O1IIZ8HHB',
      title: 'AESG BLACK FLAGS NO VOCAL CHOP JV RH',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:15:44.445Z',
      submitterWorkN: 'AESGBLACK035',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:15:44.446Z',
      updatedAt: '2020-07-27T10:15:44.539Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'e9c0a0cf-5644-43bc-95c8-9e3ae6bdf1b9',
      unisonId: 'CORCTV1MHMN6',
      title: 'COMA FLOW',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:15:45.630Z',
      submitterWorkN: 'COMAFLOW',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:30:24',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:15:45.633Z',
      updatedAt: '2020-07-27T10:15:45.737Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '7d697d58-cbfd-4556-9848-386172df68a2',
      unisonId: 'CO0AAO5LSXX8',
      title: 'CONFLICTED',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:15:46.492Z',
      submitterWorkN: 'CONFLICTE',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:18:15',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:15:46.495Z',
      updatedAt: '2020-07-27T10:15:46.596Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '175714d3-20ae-41ec-8987-a294e6a43815',
      unisonId: 'CO28XZ37FL6K',
      title: 'THE BREEZE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:15:46.932Z',
      submitterWorkN: 'BREEZE002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:46:44',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:15:46.933Z',
      updatedAt: '2020-07-27T10:15:47.017Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'd39bd048-6a41-4fa5-b2b9-8dbf6e5c0f71',
      unisonId: 'COLU5QS64AFL',
      title: 'CHAMPAGNE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:15:50.654Z',
      submitterWorkN: 'CHAMPAGNE011',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:09:06',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:15:50.657Z',
      updatedAt: '2020-07-27T10:15:50.785Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '8d6f92c1-a7ed-4f01-813a-2bd893675a50',
      unisonId: 'CO4J4B7TJDU8',
      title: 'JUICYFLAVA DMSBS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:15:47.339Z',
      submitterWorkN: 'JUICYFLAV',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:15:47.343Z',
      updatedAt: '2020-07-27T10:15:47.466Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'dcd65af4-e215-4c1b-9a69-eee44939d600',
      unisonId: 'CO15GXZBYYN3',
      title: 'JUICYFLAVA FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:15:47.478Z',
      submitterWorkN: 'JUICYFLAV001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:15:47.480Z',
      updatedAt: '2020-07-27T10:15:47.619Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '3c5191a6-6119-4b38-93fe-ccd34e994e59',
      unisonId: 'COS6CH3UFB48',
      title: 'JUICYFLAVA LITE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:15:47.625Z',
      submitterWorkN: 'JUICYFLAV002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:15:47.626Z',
      updatedAt: '2020-07-27T10:15:47.798Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '8ecebbd1-ce8c-43f8-89b8-7a682c7944f3',
      unisonId: 'CO51497JMUUJ',
      title: 'JUICYFLAVA LITE2',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:15:47.809Z',
      submitterWorkN: 'JUICYFLAV003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:15:47.811Z',
      updatedAt: '2020-07-27T10:15:47.946Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '3ffccc84-d484-4927-ae73-1719fcf8d7c1',
      unisonId: 'CO5ZHU3WFL3N',
      title: 'DOWN ON MUSIC ROW',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:15:51.762Z',
      submitterWorkN: 'DOWNONMUS',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:13:09',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:15:51.764Z',
      updatedAt: '2020-07-27T10:15:51.861Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'dc914f03-b3ea-44cd-9b62-58584e799f8c',
      unisonId: 'CO26A1KSEWT5',
      title: 'SIG BATTLE CONFLICT TENSION FULL MIX MB',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:15:56.260Z',
      submitterWorkN: 'SIGBATTLE005',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:15:56.264Z',
      updatedAt: '2020-07-27T10:15:56.367Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '7da2717a-118f-4339-b230-1942cf1508fa',
      unisonId: 'CO7OSBGT82WZ',
      title: 'THE MAGIC FLUTE QUEEN OF THE NIGHT ARIA (TRAD.)',
      iswc: null,
      type: 'MTX',
      versionType: 'MOD',
      status: 'ACCEPTED',
      musicArrangement: 'UNS',
      lyricAdaptation: 'UNS',
      copyrightDate: '2020-07-27T10:15:53.618Z',
      submitterWorkN: 'MAGICFLUT',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:15:53.620Z',
      updatedAt: '2020-07-27T10:15:53.707Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '2815b0c6-1614-4cdf-bbf4-1d9a04e8e1fc',
      unisonId: 'CONCLI2TYFST',
      title: 'FLEUR DES RUES',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:15:56.531Z',
      submitterWorkN: 'FLEURDESR',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:15:56.534Z',
      updatedAt: '2020-07-27T10:15:56.595Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '2d7cd534-5403-42a7-a6bf-c4cc87185c8a',
      unisonId: 'COFS53WCSGRI',
      title: 'FLIGHTS OVER CALIFORNIA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:15:56.613Z',
      submitterWorkN: 'FLIGHTSOV',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:10:08',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:15:56.615Z',
      updatedAt: '2020-07-27T10:15:56.687Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '39cf7781-fd4c-4f2b-8421-faf94ce7d5d3',
      unisonId: 'COUFABC811YF',
      title: 'FLY US OUT',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:15:56.694Z',
      submitterWorkN: 'FLYUSOUT',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:15:56.696Z',
      updatedAt: '2020-07-27T10:15:56.748Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'b2cebee4-bcea-47dd-bb86-ca843a229d72',
      unisonId: 'CO4ZM53FT1H5',
      title: 'SHUFFLETWANG FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:16:01.019Z',
      submitterWorkN: 'SHUFFLETW',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:16:01.020Z',
      updatedAt: '2020-07-27T10:16:01.149Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'dafa679c-e23f-43d5-915d-ae1f49b3402f',
      unisonId: 'COL6NKF1IMG5',
      title: 'SHUFFLETWANG LITE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:16:01.156Z',
      submitterWorkN: 'SHUFFLETW001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:16:01.159Z',
      updatedAt: '2020-07-27T10:16:01.294Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'dfd60294-3b5f-484e-b43b-c1bbcee5127f',
      unisonId: 'COEMHGU5KL98',
      title: 'SHUFFLETWANG NOMEL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:16:01.299Z',
      submitterWorkN: 'SHUFFLETW002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:16:01.301Z',
      updatedAt: '2020-07-27T10:16:01.426Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'ce48661d-f460-4f15-9df4-8b9d871cecbb',
      unisonId: 'COEZ98ZJWAI0',
      title: 'SHUFFLETWANG NOPERC',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:16:01.436Z',
      submitterWorkN: 'SHUFFLETW003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:16:01.438Z',
      updatedAt: '2020-07-27T10:16:01.632Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'a50535b9-2b61-4a6d-aebe-0af9cb95b5f2',
      unisonId: 'COPLJMOAWEFL',
      title: 'SIG A CHEESE BALL FULL MIX BV JST',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:16:00.047Z',
      submitterWorkN: 'SIGCHEESE',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:16:00.048Z',
      updatedAt: '2020-07-27T10:16:00.155Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '3d145a34-44bf-42d5-86df-38885ae5b9f4',
      unisonId: 'CO1VI6IIZDCW',
      title: 'ON THE FLOOR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:16:00.746Z',
      submitterWorkN: 'ONTHEFLOO',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:16:00.747Z',
      updatedAt: '2020-07-27T10:16:00.860Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'b1ee3f74-8a89-4cd8-ab2b-78985d38d7f6',
      unisonId: 'COJE4WM1RDPC',
      title: 'SIG FLY GUY FULL MIX BW',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:16:04.488Z',
      submitterWorkN: 'SIGFLYGUY',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:16:04.491Z',
      updatedAt: '2020-07-27T10:16:04.574Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'd04b26da-7d7d-4bd0-b1c7-3d8289ad07f2',
      unisonId: 'COPZ1FL7P4KN',
      title: 'SIG ALL BIG TALK AND NO WALK FULL MIX RH',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:16:03.149Z',
      submitterWorkN: 'SIGALLBIG',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:16:03.151Z',
      updatedAt: '2020-07-27T10:16:03.238Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '81e37956-25dc-49ad-8918-671eb14cf306',
      unisonId: 'CO3HE0PUTT6L',
      title: 'REFLECTED',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:16:05.807Z',
      submitterWorkN: 'REFLECTED001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:36:34',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:16:05.809Z',
      updatedAt: '2020-07-27T10:16:05.887Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'b3581247-2a60-4284-9564-649d441c1b7a',
      unisonId: 'CO1T4AW1EXI8',
      title: 'WILDFLOWER',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:16:09.641Z',
      submitterWorkN: 'WILDFLOWE001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:16:09.642Z',
      updatedAt: '2020-07-27T10:16:09.766Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'dadc4a2a-922e-47d0-b14c-7b29086a70ba',
      unisonId: 'COUWBQGENPML',
      title: 'UNLIMITEDFLOURSUPPLY FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:16:12.272Z',
      submitterWorkN: 'UNLIMITED',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:16:12.275Z',
      updatedAt: '2020-07-27T10:16:12.344Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'b95b34b7-fb08-4122-af5a-a16242811922',
      unisonId: 'COKDC5S8ISWM',
      title: 'WALKOFLIFE DMSBS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:16:13.386Z',
      submitterWorkN: 'WALKOFLIF',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:16:13.388Z',
      updatedAt: '2020-07-27T10:16:13.532Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'aba306a6-8f5b-45ac-ae22-70a19fce1c69',
      unisonId: 'CO1CY2W4HECZ',
      title: 'WALKOFLIFE FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:16:13.543Z',
      submitterWorkN: 'WALKOFLIF001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:16:13.545Z',
      updatedAt: '2020-07-27T10:16:13.683Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '02b8daae-e202-4c9f-9c51-b97f7157b784',
      unisonId: 'COBVQDAK2CE0',
      title: 'WALKOFLIFE LITE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:16:13.694Z',
      submitterWorkN: 'WALKOFLIF002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:16:13.696Z',
      updatedAt: '2020-07-27T10:16:13.796Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '1addd4ce-bacc-4fe6-b752-d185428de3f5',
      unisonId: 'COYLW8CAXO8X',
      title: 'WALKOFLIFE NOPERC',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:16:13.803Z',
      submitterWorkN: 'WALKOFLIF003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:16:13.805Z',
      updatedAt: '2020-07-27T10:16:13.903Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '999256c6-9cac-4d2a-825e-07d1d96bb6b1',
      unisonId: 'COYX5LYCL66W',
      title: 'STAR FLYER',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:16:13.175Z',
      submitterWorkN: 'STARFLYER',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:12:10',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:16:13.177Z',
      updatedAt: '2020-07-27T10:16:13.250Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '070396b7-4cd5-4b16-8fba-e13480d67dd9',
      unisonId: 'COM0CRTSS2BC',
      title: 'WHITE FLAG',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:16:16.179Z',
      submitterWorkN: 'WHITEFLAG003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:16:16.181Z',
      updatedAt: '2020-07-27T10:16:16.251Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '61d15c3d-3324-4422-859b-8f0f6dcbf425',
      unisonId: 'COLIMGRAP7NO',
      title: 'SUNFLOWER',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-07-27T10:16:15.986Z',
      submitterWorkN: 'SUNFLOWER002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:49:46',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-07-27T10:16:15.989Z',
      updatedAt: '2020-07-27T10:16:16.070Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'abf7e982-8c31-4ac9-9462-7f9a4db05a6b',
      unisonId: 'COFCMFFLP6XT',
      title: 'ANOTHER LIFE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-02T13:05:48.932Z',
      submitterWorkN: 'ANOTHERLI001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:55:52',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-02T13:05:48.934Z',
      updatedAt: '2020-09-02T13:05:49.004Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '6675b32a-1e82-400e-8d73-c3e004d1210d',
      unisonId: 'CO9MCFLH5V52',
      title: 'BE IN HERE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-02T13:05:51.268Z',
      submitterWorkN: 'BEINHERE',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:31:28',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-02T13:05:51.271Z',
      updatedAt: '2020-09-02T13:05:51.462Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '619cae9c-7bb0-469b-9023-e46261be81ab',
      unisonId: 'COUGHQWEJZBF',
      title: 'FIREFLIES',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-02T13:05:57.579Z',
      submitterWorkN: 'FIREFLIES002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '01:00:58',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-02T13:05:57.581Z',
      updatedAt: '2020-09-02T13:05:57.665Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'df8faf71-71a4-41c1-bc57-81f5ffc0336a',
      unisonId: 'COOXRXPGB472',
      title: 'PASSAGEWAYFLASH FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-02T13:05:55.189Z',
      submitterWorkN: 'PASSAGEWA',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-02T13:05:55.191Z',
      updatedAt: '2020-09-02T13:05:55.291Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'da75a0d9-2441-4b76-821a-45e302a5d44c',
      unisonId: 'COFYDTYE4OFL',
      title: 'TUMBLEWEED TOWN',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-02T13:06:01.705Z',
      submitterWorkN: 'TUMBLEWEE005',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:28:26',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-02T13:06:01.706Z',
      updatedAt: '2020-09-02T13:06:01.796Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'a9a8fdd0-55a0-4e46-8f0e-be0a873bcad9',
      unisonId: 'COHVI75VQ57I',
      title: 'WILDFLOWER WALTZ',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-02T13:06:06.870Z',
      submitterWorkN: 'WILDFLOWE002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:22:20',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-02T13:06:06.873Z',
      updatedAt: '2020-09-02T13:06:06.960Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'fde05d5b-5d84-424b-b732-3f150e399298',
      unisonId: 'CO8FMWJMLH0V',
      title: 'WILLFLOWER',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-02T13:06:07.096Z',
      submitterWorkN: 'WILLFLOWE',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:25:22',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-02T13:06:07.099Z',
      updatedAt: '2020-09-02T13:06:07.196Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '0b8f7ed8-4d4f-499a-ab68-04c0f1ee210c',
      unisonId: 'COQHFZVJLABV',
      title: 'WINGS ON BUTTERFLIES',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-02T13:06:07.338Z',
      submitterWorkN: 'WINGSONBU',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:41:38',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-02T13:06:07.340Z',
      updatedAt: '2020-09-02T13:06:07.463Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '1c6c3f7e-4ed3-4535-8fa1-032c787ccaf3',
      unisonId: 'COFL5QSZGA4Q',
      title: 'IN IT TO WIN IT',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:26:50.473Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:26:50.476Z',
      updatedAt: '2020-09-07T12:26:50.585Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '3c11caa2-7a38-4643-972b-6562fddb103a',
      unisonId: 'CO4VKZFLMRLH',
      title: 'TIME & TIME AGAIN INST',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:26:51.410Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:26:51.412Z',
      updatedAt: '2020-09-07T12:26:51.518Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '75de4107-07f8-4b53-904d-eadbd420368a',
      unisonId: 'CODNKAUBPI8R',
      title: 'THE DRAGON FLIES (RADIO EDIT)',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:27:10.628Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:27:10.630Z',
      updatedAt: '2020-09-07T12:27:10.770Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '21f533fe-981b-4407-8f10-5e5dc855fe25',
      unisonId: 'CO1CKYBML1ND',
      title: 'THE DRAGON FLIES (RADIO EDIT INSTR)',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:27:10.861Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:27:10.864Z',
      updatedAt: '2020-09-07T12:27:10.959Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '770ad210-c8ed-4503-b219-86835ab80040',
      unisonId: 'CO9LVJIPD23V',
      title: 'FLY',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:27:30.255Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:27:30.258Z',
      updatedAt: '2020-09-07T12:27:30.368Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '7cb1e144-351b-4943-bf28-68c2c9299fcf',
      unisonId: 'COSEF3AUSHHW',
      title: 'FLY (INSTRUMENTAL)',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:27:32.521Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:27:32.523Z',
      updatedAt: '2020-09-07T12:27:32.630Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '3efefaef-bc44-4686-a69d-a13cbe5ac808',
      unisonId: 'COXST76HJ3P5',
      title: 'FLATLINE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:27:33.712Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:27:33.713Z',
      updatedAt: '2020-09-07T12:27:33.840Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '34e9169f-545c-4e5f-bc7a-768043ac9aba',
      unisonId: 'CO4KN5FLWAQ4',
      title: 'THE FALLING OUT',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:27:34.822Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:27:34.824Z',
      updatedAt: '2020-09-07T12:27:34.972Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '978cf867-63cc-4510-98b1-f72e44772e72',
      unisonId: 'COOIW3KN3IYM',
      title: 'FLATLINE ACOUSTIC INSTRUMENTAL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:27:36.076Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:27:36.077Z',
      updatedAt: '2020-09-07T12:27:36.197Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '45017320-c294-4564-af49-c714b8c1c8f1',
      unisonId: 'COMFTVENNPHL',
      title: 'FLATLINE INSTRUMENTAL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:27:36.295Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:27:36.297Z',
      updatedAt: '2020-09-07T12:27:36.417Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'afdf8cad-375c-46ee-af6b-988440967c14',
      unisonId: 'COXU72N8H5KZ',
      title: 'FLETCH LIVES AGAIN',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:27:43.985Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:27:43.987Z',
      updatedAt: '2020-09-07T12:27:44.083Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '1c2eac7f-14bc-44a6-9a01-3a212ce36776',
      unisonId: 'COC4JDFLIWZR',
      title: 'EMBERS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:27:55.365Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:27:55.367Z',
      updatedAt: '2020-09-07T12:27:55.477Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'ec5ac747-35cc-424e-a627-c6c5e30fbeca',
      unisonId: 'COMN656ZH1Q0',
      title: 'BLUE LIGHTS FLASHING',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:28:30.617Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:28:30.619Z',
      updatedAt: '2020-09-07T12:28:30.718Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '294cabc8-d1de-462b-bc27-08f8450b246a',
      unisonId: 'COK61RR305AX',
      title: 'FLEETING PULSES',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:28:32.512Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:28:32.515Z',
      updatedAt: '2020-09-07T12:28:32.616Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '0693fade-6b2d-4403-9afd-3b1c7d35f3e4',
      unisonId: 'COEMOYBR3YW7',
      title: 'A TIME TO REFLECT',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:28:36.441Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:28:36.443Z',
      updatedAt: '2020-09-07T12:28:36.547Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'c0e5b79c-2845-4e7e-a066-35cdc477ee00',
      unisonId: 'COE21BXSE53K',
      title: 'FLY BUCKET',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:28:37.151Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:28:37.153Z',
      updatedAt: '2020-09-07T12:28:37.253Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'c79124c5-28b7-4783-b634-91e709701879',
      unisonId: 'COXZPH7V5XFL',
      title: 'FREE FROM THE SHOCK',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:28:52.032Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:28:52.034Z',
      updatedAt: '2020-09-07T12:28:52.164Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '98f58cd4-22f0-418a-82ef-39b4e91720eb',
      unisonId: 'COUI4FLQ5641',
      title: 'MEET ME IN THE TRAP',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:28:53.600Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:28:53.602Z',
      updatedAt: '2020-09-07T12:28:53.727Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '4cd1edd4-7359-4f0c-aa50-7179e41c11b2',
      unisonId: 'COVACFLD92GK',
      title: 'ANTICIPATE THE DRAMA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:28:59.455Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:28:59.457Z',
      updatedAt: '2020-09-07T12:28:59.564Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '70e0895f-bf7a-4462-8d04-4b5330921d01',
      unisonId: 'CONMFL3JHGRR',
      title: 'RESOLVE THE CREEPING',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:29:06.867Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:29:06.873Z',
      updatedAt: '2020-09-07T12:29:07.038Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'a23df0f3-b4ea-4eed-80c0-798c9b5caa98',
      unisonId: 'COFLIOICL8KG',
      title: 'DISSAPEARANCE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:29:07.662Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:29:07.666Z',
      updatedAt: '2020-09-07T12:29:07.796Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'e0b81f57-f830-4e53-bf7b-27a5ed237a60',
      unisonId: 'CO1VQ4KOFE2T',
      title: 'DARK FLAMES BURNING',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:29:10.043Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:29:10.045Z',
      updatedAt: '2020-09-07T12:29:10.169Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'cd74216c-ec05-4f2c-a798-347219b26314',
      unisonId: 'COXKR3NX3D6X',
      title: 'FLORA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:29:40.897Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:29:40.898Z',
      updatedAt: '2020-09-07T12:29:41.006Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '098f365e-6680-46e1-946f-b191d2ea1eff',
      unisonId: 'COU2NA36PAU9',
      title: 'NIGHT OF THE JOHNSTOWN FLOOD',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:30:04.264Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:30:04.265Z',
      updatedAt: '2020-09-07T12:30:04.394Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '4e59f8b4-344f-4ac0-8f77-8f85114f6cb8',
      unisonId: 'CO2G2F1IYYBG',
      title: 'FLIRTATIOUS BAGGING',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:30:58.079Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:30:58.081Z',
      updatedAt: '2020-09-07T12:30:58.232Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '42dd056a-7ebd-4193-858a-f34657627a6a',
      unisonId: 'CON0LOVXPTCZ',
      title: 'WEIGHTLESS FLOATING',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:31:00.663Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:31:00.666Z',
      updatedAt: '2020-09-07T12:31:00.775Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '0a1533fc-73e9-490a-ad3e-dbd825386158',
      unisonId: 'COFM7BUPN2ES',
      title: 'EBB AND FLOW',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:31:16.688Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:31:16.690Z',
      updatedAt: '2020-09-07T12:31:16.808Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '978bdcbe-5022-4262-b8d7-e7039b995c52',
      unisonId: 'CO7PHA8L1DP9',
      title: 'FLICKER AND DIM',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:31:28.352Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:31:28.355Z',
      updatedAt: '2020-09-07T12:31:28.463Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '030a1c67-5e6b-4242-819f-96aa7ca37bc4',
      unisonId: 'COZDSFLD9DGB',
      title: 'OCEAN FISH',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:31:44.244Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:31:44.247Z',
      updatedAt: '2020-09-07T12:31:44.369Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'd242093a-9a40-48b0-ab04-3ba87bb53c9b',
      unisonId: 'COPSHBD4NS6P',
      title: 'FLOATING AWAY',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:31:48.755Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:31:48.757Z',
      updatedAt: '2020-09-07T12:31:48.890Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'ce781aa0-00b4-4366-86d3-67410ae0b153',
      unisonId: 'COURF8MTSYDG',
      title: 'ALLEY CAMOUFLAGE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:32:13.192Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:32:13.193Z',
      updatedAt: '2020-09-07T12:32:13.295Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'd6e7e8e4-e55b-4dc1-91b7-3a0f563c21c1',
      unisonId: 'CO2BH0FG0FLS',
      title: 'DEAD OR ALIVE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'CONFLICT',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:32:19.466Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:32:19.468Z',
      updatedAt: '2020-09-07T12:32:19.650Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '55f59cec-8420-4ae9-b5be-c290ae8d926a',
      unisonId: 'COZ7MR0BOKFB',
      title: 'CATCHING FIREFLIES',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:32:42.502Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:32:42.506Z',
      updatedAt: '2020-09-07T12:32:42.667Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'd67bb9e2-9e86-4fe6-b836-f89830b3b00a',
      unisonId: 'COVJHJAG0H3V',
      title: 'BUTTERFLY',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:32:43.505Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:32:43.508Z',
      updatedAt: '2020-09-07T12:32:43.677Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'c3143be0-f9c5-4c29-a85b-bf4133e6bd74',
      unisonId: 'COBTA9QJGGBU',
      title: 'ANGELS FLIGHT',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:32:50.768Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:32:50.771Z',
      updatedAt: '2020-09-07T12:32:50.920Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '0f2fee83-750a-4d9a-8a60-fd2667d3540e',
      unisonId: 'COIWW293CG6S',
      title: 'INNER CONFLICT',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:33:04.178Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:33:04.181Z',
      updatedAt: '2020-09-07T12:33:04.304Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '6d0716e6-cc95-49e6-92db-768312fbf6ba',
      unisonId: 'COEHXL9WD2GD',
      title: 'DOOR IN THE FLOOR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'CONFLICT',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:33:28.220Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:33:28.222Z',
      updatedAt: '2020-09-07T12:33:28.354Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'bfc79fa6-5f5b-4943-88e6-42152e0f70f0',
      unisonId: 'COFL39FY6S4V',
      title: 'YOUR CHOICE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:33:31.004Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:33:31.007Z',
      updatedAt: '2020-09-07T12:33:31.133Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'ebbdf869-ef08-47e9-a20d-bb359b83e9e1',
      unisonId: 'COPJ08W22RFL',
      title: 'LINGERING MEMORIES',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:33:50.233Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:33:50.236Z',
      updatedAt: '2020-09-07T12:33:50.358Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '5539c6f2-9271-480e-b378-be5ac9eebabf',
      unisonId: 'COZUGMHH35IF',
      title: 'FLY FISHIN',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'CONFLICT',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:33:57.126Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:33:57.127Z',
      updatedAt: '2020-09-07T12:33:57.257Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '8f70f15c-1df3-4ff2-b62c-f584764d6d10',
      unisonId: 'COBY1ELOD2O0',
      title: 'REFLECTIONS IN THE RAIN',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:33:58.640Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:33:58.642Z',
      updatedAt: '2020-09-07T12:33:58.800Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '3e99bf3e-fbcb-49ba-a13b-dacdc4431d2e',
      unisonId: 'COG8FL8WAVSQ',
      title: 'THESE ARE THE DAYS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:34:07.719Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:34:07.720Z',
      updatedAt: '2020-09-07T12:34:07.868Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '079ca434-f3d2-438f-87b8-6b1eab3319e0',
      unisonId: 'CO1UNMZCOD19',
      title: 'FLY HIGH',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:34:16.810Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:34:16.811Z',
      updatedAt: '2020-09-07T12:34:16.936Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '0d4a8ff3-798c-44d6-92de-0e55a63d9a65',
      unisonId: 'COSVEJ567EI2',
      title: 'FLY HIGH',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:34:17.017Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:34:17.019Z',
      updatedAt: '2020-09-07T12:34:17.156Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'f771b3a3-bb7c-4ebb-b2dd-2bacd8ec4833',
      unisonId: 'COVUB8W46OAK',
      title: 'FOAM FLOATER',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:34:22.014Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:34:22.016Z',
      updatedAt: '2020-09-07T12:34:22.091Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'ec40eab6-d65a-4c3f-881f-a89d46dd73ad',
      unisonId: 'COS335XZKT3R',
      title: 'REFLECT THE WORLD',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:34:23.820Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:34:23.822Z',
      updatedAt: '2020-09-07T12:34:23.947Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '339266f7-e7b8-487b-a3e3-0f5a4413106e',
      unisonId: 'COEB8YL5TBLB',
      title: 'FLY',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:34:31.317Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:34:31.318Z',
      updatedAt: '2020-09-07T12:34:31.459Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '27783980-94d0-492b-956e-dbc50837f357',
      unisonId: 'COFZFUOTIOX3',
      title: 'TAKE FLIGHT',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:34:36.025Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:34:36.028Z',
      updatedAt: '2020-09-07T12:34:36.151Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'cd3786dc-dd91-4e44-a809-0597cec660b2',
      unisonId: 'COHO2A73Z4HG',
      title: 'TAKE FLIGHT [REMIX]',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:34:36.873Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:34:36.875Z',
      updatedAt: '2020-09-07T12:34:37.001Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'a1851f65-122c-4c81-af44-791976dea962',
      unisonId: 'COFFLCVCEBB4',
      title: 'HEARTLESS CITY',
      iswc: 'T9132132924',
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:34:39.524Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:34:39.525Z',
      updatedAt: '2020-09-07T12:34:39.656Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '76c35867-04e9-49ca-89f2-fa9e2a6869d8',
      unisonId: 'COER0IFL09JS',
      title: 'LAST LIGHT',
      iswc: 'T-916562899-9',
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:34:43.440Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:34:43.442Z',
      updatedAt: '2020-09-07T12:34:43.542Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'f38372f3-b60d-496e-9df0-8f442db2a1f1',
      unisonId: 'COXT3YRIP45C',
      title: 'EVERYTHING FINDS THE FLOOR',
      iswc: 'T-913342467-6',
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:34:53.866Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:34:53.868Z',
      updatedAt: '2020-09-07T12:34:53.977Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'cd6f3d83-2ef9-4e94-b89a-bd320cdec1cc',
      unisonId: 'COFLJW2H7D4H',
      title: 'JUST ANOTHER DREAM',
      iswc: 'T-912995425-2',
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:35:00.492Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:35:00.495Z',
      updatedAt: '2020-09-07T12:35:00.592Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'f8d8f29f-2d3a-4d50-9f6e-ed2e6b1a9ff3',
      unisonId: 'COC82U29D0FL',
      title: 'TAKE MY HEART',
      iswc: 'T9203172694',
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:35:08.693Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:35:08.695Z',
      updatedAt: '2020-09-07T12:35:08.820Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'f64a71f9-8e72-421c-a4e8-7ee7584e4580',
      unisonId: 'CONFL9MW4029',
      title: 'ALPHA MAE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:35:19.360Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:35:19.362Z',
      updatedAt: '2020-09-07T12:35:19.481Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'd7de99d6-972c-4134-b737-81b94ddf58e2',
      unisonId: 'COPCRJ6M2DKC',
      title: 'FLAME',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:35:26.968Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:35:26.969Z',
      updatedAt: '2020-09-07T12:35:27.114Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'c13ff449-2ca0-4cb5-806c-63972ee681f7',
      unisonId: 'COPQ67DWZ1ZC',
      title: 'TAKE ME TO THE KILLING FLOOR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:35:39.498Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:35:39.500Z',
      updatedAt: '2020-09-07T12:35:39.597Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '3be01b9c-289d-477a-b15b-7b22fa545903',
      unisonId: 'COPLE20VYDFL',
      title: 'WILLOW',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:35:50.720Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:35:50.722Z',
      updatedAt: '2020-09-07T12:35:50.843Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'fb71a17e-2f91-438c-813d-934c81618263',
      unisonId: 'COIUR1XAXYLD',
      title: 'FIGHT OR FLIGHT',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:35:53.518Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:35:53.521Z',
      updatedAt: '2020-09-07T12:35:53.617Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '327ddb8a-acc6-4026-82d4-d50fc45463e3',
      unisonId: 'COPR7BBXUT6F',
      title: 'FLIGHTS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:36:29.966Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:36:29.968Z',
      updatedAt: '2020-09-07T12:36:30.076Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'd7518efe-6f89-4c9c-9ff3-7846eea60b6a',
      unisonId: 'CO16VHGJE5LI',
      title: 'FLIGHTS INSTR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:36:30.830Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:36:30.832Z',
      updatedAt: '2020-09-07T12:36:30.937Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '6280d1ce-937a-4608-8bc1-5fd4fdeb05cd',
      unisonId: 'COZFLNMDLR65',
      title: 'TRACY YOUNG',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:36:50.421Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:36:50.423Z',
      updatedAt: '2020-09-07T12:36:50.498Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'd0ed6ce3-c3ba-484f-9064-4a4f38c3400b',
      unisonId: 'CO3GVQMIFLZM',
      title: 'GIRL SONGS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:36:52.301Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:36:52.303Z',
      updatedAt: '2020-09-07T12:36:52.408Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '08957c33-ae0b-4d02-a403-a1f30ecb359a',
      unisonId: 'COFR4AR7WLX1',
      title: 'SLY FLY',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:37:08.222Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:37:08.225Z',
      updatedAt: '2020-09-07T12:37:08.336Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '75783f2b-70b5-441b-b2e1-d39ab1b49f6e',
      unisonId: 'COBY495NENIC',
      title: 'DIXIE SHUFFLER',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:37:12.284Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:37:12.286Z',
      updatedAt: '2020-09-07T12:37:12.383Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '0f221d0f-2b5c-4852-85fe-1b29eb3fc7c7',
      unisonId: 'COP4HHRHFL2G',
      title: 'DOWN BY THE HOLLER',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:37:14.318Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:37:14.319Z',
      updatedAt: '2020-09-07T12:37:14.411Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'aa57a9e0-64e0-4332-ae7b-ea59c7dca025',
      unisonId: 'COEAFLDHRSCQ',
      title: 'IF I TOLD YOU',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:37:16.073Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:37:16.074Z',
      updatedAt: '2020-09-07T12:37:16.157Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'd5d76754-b57d-489f-b02f-76c8306c5396',
      unisonId: 'COMLIQIJ69I5',
      title: 'BITTERSWEET FLIGHT',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:37:19.175Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:37:19.177Z',
      updatedAt: '2020-09-07T12:37:19.271Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '1cb04fb8-d20f-497d-95a9-dca27707961a',
      unisonId: 'COOFLIJRNFGR',
      title: 'TWINKLE DROP',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:37:21.738Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:37:21.740Z',
      updatedAt: '2020-09-07T12:37:21.833Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'a415429d-adad-464f-bf6c-3d6b55b16f8d',
      unisonId: 'COFLBAEWO9CW',
      title: 'PASSING THE TIME',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:37:24.459Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:37:24.460Z',
      updatedAt: '2020-09-07T12:37:24.544Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'dee23486-dda2-4854-a7c8-fd3c19e108ad',
      unisonId: 'CO49ZU71LQ8P',
      title: 'SHIFT TO SHUFFLE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:37:27.041Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:37:27.043Z',
      updatedAt: '2020-09-07T12:37:27.160Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'a5a3f7b0-2a0f-4927-91c5-12c302ade7d0',
      unisonId: 'COH3XLOXA5QU',
      title: 'SHOWTUNE SHUFFLE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:37:27.229Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:37:27.231Z',
      updatedAt: '2020-09-07T12:37:27.331Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'ad4c063c-fbc5-4963-8baf-9d0a944f3e9b',
      unisonId: 'COC2PGMJKTCL',
      title: 'SHUFFLE STEP',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:37:27.413Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:37:27.416Z',
      updatedAt: '2020-09-07T12:37:27.523Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '33ce2b43-b279-45b5-bbbe-6335400147d1',
      unisonId: 'COQ1BMEC3BH3',
      title: 'HOKEY POKEY SHUFFLE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:37:28.789Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:37:28.791Z',
      updatedAt: '2020-09-07T12:37:28.909Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '4ec5a25a-3a21-4e9a-9a79-0c37c3c09c4c',
      unisonId: 'COKT3LOX6AQV',
      title: 'FLUTE BASKET',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:37:32.946Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:37:32.947Z',
      updatedAt: '2020-09-07T12:37:33.041Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'd9c20d84-c9a1-4172-a768-465fe2714e9f',
      unisonId: 'CORHB9WQH8JR',
      title: 'LAST STAND OF THE FLAUTIST',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:37:33.753Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:37:33.755Z',
      updatedAt: '2020-09-07T12:37:33.865Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'b3a9b25d-54b4-4ba6-aa4b-361da3832c6d',
      unisonId: 'CO2I962NIU6L',
      title: 'A DARK NIGHT IN FLORIDA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:37:38.474Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:37:38.478Z',
      updatedAt: '2020-09-07T12:37:38.561Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '49891e7b-d3a7-4df8-9176-6e7333955267',
      unisonId: 'COCHFIUQWONM',
      title: 'COLD REFLECTIONS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:38:08.330Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:38:08.332Z',
      updatedAt: '2020-09-07T12:38:08.419Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '6df34298-9c9f-4a71-a8d6-b69b32b7dd13',
      unisonId: 'COMZCNBG2IAM',
      title: 'DIRT FLOOR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:38:08.812Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:38:08.814Z',
      updatedAt: '2020-09-07T12:38:08.935Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'de75f95b-7c0d-4b5b-8b9d-d4e9640c9a8c',
      unisonId: 'COK77JH1V58N',
      title: 'APPALACHIAN AFFLICTION',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:38:22.964Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:38:22.966Z',
      updatedAt: '2020-09-07T12:38:23.066Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '73b08cd1-edf4-4d3f-923a-4b512d8ce773',
      unisonId: 'COHRIT6HTHMF',
      title: 'SOUTH BOUND AND FLYING',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:38:26.679Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:38:26.681Z',
      updatedAt: '2020-09-07T12:38:26.777Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '4631f20c-b38d-44a6-8d4c-b09e2b25be20',
      unisonId: 'COZHL343BTO0',
      title: 'WILDFLOWER TOWN',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:38:28.074Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:38:28.077Z',
      updatedAt: '2020-09-07T12:38:28.151Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '8539cef8-fb17-44f3-aaf0-955f25be65bd',
      unisonId: 'COT18QFLPQ90',
      title: 'DIRT ROAD DRIVING',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:38:32.344Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:38:32.347Z',
      updatedAt: '2020-09-07T12:38:32.437Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'e395a97a-b941-4e8c-88b3-cb6cf551b052',
      unisonId: 'COSZ2PYJK5UF',
      title: 'RED REFLECTION POOL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:39:07.392Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:39:07.393Z',
      updatedAt: '2020-09-07T12:39:07.470Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '1d675246-06cd-44d1-bd8b-91d759b8b1a5',
      unisonId: 'COJA1EVXH67D',
      title: 'SAD AND REFLECTIVE SKETCH',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:39:32.876Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:39:32.878Z',
      updatedAt: '2020-09-07T12:39:32.978Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '8fbdc49d-e28a-47e1-98d4-a4f39871ca98',
      unisonId: 'CO5IJJYPCAFZ',
      title: 'REFLECTION SUITE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:39:36.339Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:39:36.342Z',
      updatedAt: '2020-09-07T12:39:36.441Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '4c98d793-40dc-4e1c-832e-e4a23ec34a41',
      unisonId: 'CO641SWUWAZN',
      title: 'FLIPPIN FLAPPERS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:39:37.735Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:39:37.737Z',
      updatedAt: '2020-09-07T12:39:37.845Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '9993ddd2-1051-48fd-baeb-bf60eddf8cdd',
      unisonId: 'CO23RFLIZWPY',
      title: 'MACHINE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:39:46.992Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:39:46.994Z',
      updatedAt: '2020-09-07T12:39:47.106Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '1b36b551-0204-4f87-82c1-986c965fd0b6',
      unisonId: 'COAHY6WPFLII',
      title: 'LO QUE ES AMAR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:40:11.239Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:40:11.241Z',
      updatedAt: '2020-09-07T12:40:11.338Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '9a905b6b-0cf5-4edf-b09b-e1f61df51b22',
      unisonId: 'COEY4TRCKLY8',
      title: 'FLY TRAP',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:40:29.042Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:40:29.044Z',
      updatedAt: '2020-09-07T12:40:29.126Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'd03774cf-5cdf-436b-9329-67a26bb782bb',
      unisonId: 'COMFKFLALLRS',
      title: 'THE CRACKDOWN',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:40:36.928Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:40:36.930Z',
      updatedAt: '2020-09-07T12:40:37.045Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'e4a34883-08be-4263-894a-6959830f5208',
      unisonId: 'COMEO26U6PSO',
      title: 'GOSPEL FLY AWAY',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:40:45.431Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:40:45.433Z',
      updatedAt: '2020-09-07T12:40:45.523Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'cf804185-e4e5-4fc7-84f1-604bbd4ee378',
      unisonId: 'CO4LEI2MAWVD',
      title: 'LIGHT FLIRTATION INST',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:40:49.146Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:40:49.149Z',
      updatedAt: '2020-09-07T12:40:49.247Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'f1c19ba0-eab6-44f3-be1a-e635afca84e3',
      unisonId: 'COFLW4WGWQYW',
      title: 'MALLET EVOLUTION',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:40:57.349Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:40:57.351Z',
      updatedAt: '2020-09-07T12:40:57.433Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'cd762b20-0eee-4893-a9fd-d8202f33ede5',
      unisonId: 'COA9FEEYCSMQ',
      title: 'REFLECTION',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:40:58.408Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:40:58.410Z',
      updatedAt: '2020-09-07T12:40:58.509Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '72f59311-9b7d-4836-9bb8-1522c0686e51',
      unisonId: 'COVG742ZG0ZC',
      title: 'UNDER THE INFLUENCE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:41:03.163Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:41:03.165Z',
      updatedAt: '2020-09-07T12:41:03.300Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '07791c66-e798-49af-a5ae-8131af9643db',
      unisonId: 'CO3IMMSA3TDN',
      title: 'CURRENTLY FLOWING',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:41:06.190Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:41:06.192Z',
      updatedAt: '2020-09-07T12:41:06.277Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '6d8076b4-7443-4ebb-b1b6-e22b6f90a626',
      unisonId: 'CO1N23CVI9N0',
      title: 'BUTTERFLY',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:41:16.828Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:41:16.830Z',
      updatedAt: '2020-09-07T12:41:16.915Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'd2129f9b-92e3-414b-a053-f13851813132',
      unisonId: 'COMMF6IOUZ1Q',
      title: 'ONNAFLOW',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:41:22.668Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:41:22.670Z',
      updatedAt: '2020-09-07T12:41:22.755Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '65f6cd22-a057-4b91-8ba2-441cffa93619',
      unisonId: 'COY7MDYI1H0C',
      title: 'FLIRT WITH ME',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:41:24.716Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:41:24.717Z',
      updatedAt: '2020-09-07T12:41:24.800Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '079ef910-7b65-4b6a-93de-5efc5aba18e9',
      unisonId: 'COGA2X918SZQ',
      title: 'SLIPPERY FLOORS V1',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:41:41.135Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:41:41.137Z',
      updatedAt: '2020-09-07T12:41:41.255Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '025d43b3-c84b-4da6-b529-ace4996a0b0e',
      unisonId: 'COVF4S90DMPN',
      title: 'SLIPPERY FLOORS V2',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:41:41.356Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:41:41.358Z',
      updatedAt: '2020-09-07T12:41:41.427Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'a59af765-26ea-459b-a2db-d48fc5893b82',
      unisonId: 'CO9YNLBYZ4PF',
      title: "FLASHBACK'S CONTRACT V1",
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:41:43.070Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:41:43.073Z',
      updatedAt: '2020-09-07T12:41:43.161Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '9d207e16-1ab0-456f-ad3f-612c8496d555',
      unisonId: 'CO4F1SOQVT0I',
      title: "FLASHBACK'S CONTRACT V2",
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:41:43.217Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:41:43.221Z',
      updatedAt: '2020-09-07T12:41:43.304Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '56803d85-4c7d-49b8-87ad-cfc4076e4276',
      unisonId: 'COPFKMJGX81U',
      title: "FLASHBACK'S CONTRACT V3",
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:41:43.363Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:41:43.365Z',
      updatedAt: '2020-09-07T12:41:43.441Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '307940b2-abcd-442e-9ea3-eb25bed2069c',
      unisonId: 'COCTFLX35M8T',
      title: 'DID NOT DIE IN VAIN',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:41:50.188Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:41:50.190Z',
      updatedAt: '2020-09-07T12:41:50.318Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'aa1e5136-c85e-4c52-a132-6e6e81cca21f',
      unisonId: 'COES5D9QWNIP',
      title: "FLASHBACK'S MISSION",
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:42:13.675Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:42:13.676Z',
      updatedAt: '2020-09-07T12:42:13.755Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '2f762efe-84d9-4613-ba63-e0fb53256ca7',
      unisonId: 'COVUX8JGB7OC',
      title: 'FLEETING MOMENTS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:42:22.234Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:42:22.236Z',
      updatedAt: '2020-09-07T12:42:22.321Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '8c4a92c4-9f58-42bd-8787-7cfeee747ed6',
      unisonId: 'COP3WX5OPW5P',
      title: 'FLYING BULLETS AND LIFE FLASHES',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:42:27.167Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:42:27.169Z',
      updatedAt: '2020-09-07T12:42:27.254Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '947aafcc-5b80-43c4-b200-5e37254d4f0a',
      unisonId: 'COZZS7UQZVEW',
      title: 'BLADE IN THE FLOWER V1',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:42:39.124Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:42:39.125Z',
      updatedAt: '2020-09-07T12:42:39.207Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '59eec2b6-04a6-4d5e-a628-b8e8674eee2a',
      unisonId: 'COB4ADLXZTPZ',
      title: 'BLADE IN THE FLOWER V2',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:42:39.252Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:42:39.253Z',
      updatedAt: '2020-09-07T12:42:39.357Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '49fc2b89-05ed-48a6-a01b-47bbf0de9607',
      unisonId: 'CO285Z4PO55O',
      title: 'BLADE IN THE FLOWER V3',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:42:39.411Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:42:39.414Z',
      updatedAt: '2020-09-07T12:42:39.511Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '53a66e8e-1164-4928-a3a8-4f19decd2fe4',
      unisonId: 'COCOIFX7OC7O',
      title: 'BLADE IN THE FLOWER V4',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:42:39.554Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:42:39.555Z',
      updatedAt: '2020-09-07T12:42:39.638Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'f7af37b3-eed2-44b5-b026-0c543716d38a',
      unisonId: 'CO5WCEX69RBZ',
      title: 'BLADE IN THE FLOWER V5',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:42:40.587Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:42:40.590Z',
      updatedAt: '2020-09-07T12:42:40.709Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'aa3b8ba4-0717-466c-a8ea-fc9085976b49',
      unisonId: 'COURH3XU3TUX',
      title: 'TRAVEL BY FLIGHT V1',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:42:49.417Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:42:49.419Z',
      updatedAt: '2020-09-07T12:42:49.528Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'a3cb526e-92b4-4d2f-b894-dcbbe62e6ee5',
      unisonId: 'COAHDRX47NT8',
      title: 'TRAVEL BY FLIGHT V2',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:42:49.585Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:42:49.588Z',
      updatedAt: '2020-09-07T12:42:49.676Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '363139fb-8ea1-4eb6-a27a-5c1cfb19b3b9',
      unisonId: 'COJI32T813DE',
      title: 'TRAVEL BY FLIGHT V3',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:42:49.779Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:42:49.780Z',
      updatedAt: '2020-09-07T12:42:49.867Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '09a52a7b-fc98-4b8a-bda0-d47b66344221',
      unisonId: 'CO5UG2H3NJ1I',
      title: 'TRAVEL BY FLIGHT V4',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:42:49.920Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:42:49.922Z',
      updatedAt: '2020-09-07T12:42:50.015Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '03363759-6a88-47f5-9054-bc256125c133',
      unisonId: 'COJEDLPTHAKB',
      title: 'TRAVEL BY FLIGHT V5',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:42:50.054Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:42:50.056Z',
      updatedAt: '2020-09-07T12:42:50.151Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '9b7ccbb1-cd53-48ca-a179-20abdf7fae30',
      unisonId: 'COCUMGXFLSM5',
      title: 'WARRIORS OF LEGEND V1',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:42:51.452Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:42:51.454Z',
      updatedAt: '2020-09-07T12:42:51.549Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '9bd7a1c3-ffef-421c-be76-008640753e01',
      unisonId: 'CO6IIUDE5TMS',
      title: 'FLYING FANTASIES',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:43:06.465Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:43:06.467Z',
      updatedAt: '2020-09-07T12:43:06.574Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '07769949-7fae-47d6-8663-48c0d786a28d',
      unisonId: 'COQT7XEBGFTV',
      title: 'RETURN TO FLASHBACK V1',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:43:07.778Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:43:07.780Z',
      updatedAt: '2020-09-07T12:43:07.866Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '20a0e50a-0fc7-412d-901f-f6dc14ef32bd',
      unisonId: 'CO1STXR1SEL0',
      title: 'RETURN TO FLASHBACK V2',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:43:07.907Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:43:07.908Z',
      updatedAt: '2020-09-07T12:43:07.981Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '1bea8081-56a9-4cf9-b54a-c319bfe11122',
      unisonId: 'COROBCQKB0UB',
      title: 'RETURN TO FLASHBACK V3',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:43:08.034Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:43:08.036Z',
      updatedAt: '2020-09-07T12:43:08.119Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '0687c60a-62e3-43d2-9884-f74968e612ee',
      unisonId: 'COLCWNAELG70',
      title: 'FLASHBACK',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:43:15.965Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:43:15.967Z',
      updatedAt: '2020-09-07T12:43:16.049Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'cd1646eb-f190-4967-a73b-566fe76f6c4b',
      unisonId: 'COT7Z6M6IU0E',
      title: 'FLIGHT OF THE HORNET',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:43:25.370Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:43:25.373Z',
      updatedAt: '2020-09-07T12:43:25.483Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'dba8858e-b7a1-4a81-9e55-8e5116126846',
      unisonId: 'COFLDYMUHQVG',
      title: "BY THE TIME WE'RE THIRTY",
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:43:41.143Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:43:41.145Z',
      updatedAt: '2020-09-07T12:43:41.222Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'be7f2f05-2dd9-4fe5-8b87-dd96f264187e',
      unisonId: 'COXGWG29PGFL',
      title: 'BREAKING THE RULES V3',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:43:46.828Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:43:46.830Z',
      updatedAt: '2020-09-07T12:43:46.917Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '2abd131e-ccba-4dfe-b75b-6975f21969f4',
      unisonId: 'COUFLWF8RUPJ',
      title: 'BOOM 03',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:43:53.191Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:43:53.193Z',
      updatedAt: '2020-09-07T12:43:53.315Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '8c244045-2526-49d3-9b61-4f2c635eb274',
      unisonId: 'COUESFD04JYI',
      title: 'DESTINED TO FLY',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:44:07.289Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:44:07.291Z',
      updatedAt: '2020-09-07T12:44:07.415Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'd9f91eea-d6c2-43c7-bee4-f3af6266c614',
      unisonId: 'COPARD6B2NKH',
      title: 'FLO WALKER',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:44:09.458Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:44:09.461Z',
      updatedAt: '2020-09-07T12:44:09.573Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '5860454d-c3e4-4d47-831f-c51afb95c39a',
      unisonId: 'COWW77KCS42X',
      title: "FEELIN' MIGHTY SHARPE IN NOTHIN' FLAT",
      iswc: 'T-908983857-3',
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:44:14.611Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:44:14.613Z',
      updatedAt: '2020-09-07T12:44:14.733Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'c608cf06-da00-4843-b21a-863a05298130',
      unisonId: 'CO00JVQO53WA',
      title: 'FLY AT WILL',
      iswc: 'T-910721101-8',
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:44:15.995Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:44:15.998Z',
      updatedAt: '2020-09-07T12:44:16.128Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '85ad476d-f6c9-464b-9577-6277ff127231',
      unisonId: 'COFM8YCF4XPH',
      title: 'FLABSLAB',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:44:29.422Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:44:29.424Z',
      updatedAt: '2020-09-07T12:44:29.572Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'f5763ce6-bc81-4279-b5aa-68beb60d3d6a',
      unisonId: 'CON7H2TJQRGM',
      title: 'FLICKER',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:44:51.115Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:44:51.116Z',
      updatedAt: '2020-09-07T12:44:51.220Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '1f699053-888e-45d4-830c-90676ec996bb',
      unisonId: 'CO6O3JYONZ6F',
      title: 'REFLECTIVE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:44:54.760Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:44:54.762Z',
      updatedAt: '2020-09-07T12:44:54.881Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '9002e797-37d3-44aa-b737-19eacc60df01',
      unisonId: 'COZE0ZMFL4UR',
      title: 'ARE YOU IN PAIN',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:45:17.277Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:45:17.279Z',
      updatedAt: '2020-09-07T12:45:17.405Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'a875d4e1-b6dd-492a-885c-b10806445ec1',
      unisonId: 'COSKV2IZCWX2',
      title: 'SEA SHORE SHUFFLE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:45:19.721Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:45:19.723Z',
      updatedAt: '2020-09-07T12:45:19.864Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'd79dcd64-325c-470e-8a62-d2fad1cfec9e',
      unisonId: 'CO3Y3AI71UB9',
      title: 'JUST FLOW YO',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:45:28.222Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:45:28.225Z',
      updatedAt: '2020-09-07T12:45:28.326Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'e385b8f9-c80f-44c1-b14f-1f8a7de59d5c',
      unisonId: 'CO4DC7F5LX5K',
      title: 'FIRE FLIGHT',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:45:40.842Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:45:40.845Z',
      updatedAt: '2020-09-07T12:45:40.939Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '58081e15-7942-42b2-b947-db1ec4169086',
      unisonId: 'COC6EXFLF6B2',
      title: 'ODE TO MUSIC',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:45:40.996Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:45:40.998Z',
      updatedAt: '2020-09-07T12:45:41.087Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'da425ccd-3686-4de5-bea4-bcafb27d3824',
      unisonId: 'COXRSJJ65VW4',
      title: 'FLEETING GREEN',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:46:13.469Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:46:13.472Z',
      updatedAt: '2020-09-07T12:46:13.578Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '6bb31e6c-2557-4942-8364-bf225d615680',
      unisonId: 'CO74UFLB065E',
      title: 'RACING MANIA',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:46:15.176Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:46:15.179Z',
      updatedAt: '2020-09-07T12:46:15.258Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '5343ccfa-c7b1-48d1-8ba5-de68180e6a2e',
      unisonId: 'CO906KNY788W',
      title: 'FLUTTERBYS V1 WVOX',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:46:18.532Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:46:18.534Z',
      updatedAt: '2020-09-07T12:46:18.625Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'a53225da-f9c0-4a5b-afad-c9aeec91572d',
      unisonId: 'CO4LNUWC3ZYA',
      title: 'FLASHBACK 1',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:46:27.916Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:46:27.918Z',
      updatedAt: '2020-09-07T12:46:28.026Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '03887656-6e70-4b34-8b6e-398a9842f57a',
      unisonId: 'CO0U1WGWMLUY',
      title: 'FLY INTO TOMORROW',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:46:30.497Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:46:30.501Z',
      updatedAt: '2020-09-07T12:46:30.602Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'c20fd926-d403-400b-b170-afea59c01169',
      unisonId: 'COX66TTYDFL4',
      title: 'PEN IT',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:46:32.170Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:46:32.174Z',
      updatedAt: '2020-09-07T12:46:32.251Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'ec49224e-6e47-4d80-a208-8f55c6e9fdc4',
      unisonId: 'COBX4N9JOFLL',
      title: 'ON THE RIM',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:46:39.557Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:46:39.560Z',
      updatedAt: '2020-09-07T12:46:39.678Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '2ded9b99-0a31-464a-b815-83cb466bddb5',
      unisonId: 'COO6OAFQQMQA',
      title: 'FLOOT LOOPS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:46:45.551Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:46:45.553Z',
      updatedAt: '2020-09-07T12:46:45.645Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'e294e288-1017-4d7c-b935-72ce9c2d4bbf',
      unisonId: 'COFQOFLJ40KI',
      title: 'NO NEWS GOOD NEWS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:46:53.376Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:46:53.377Z',
      updatedAt: '2020-09-07T12:46:53.476Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'cf417245-44d0-4426-bf60-1f422f880959',
      unisonId: 'COS74F4HGDR1',
      title: 'TOWER OF FLOWER',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:47:00.497Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:47:00.498Z',
      updatedAt: '2020-09-07T12:47:00.570Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '8a6a571e-b381-4ccd-bc21-f42993c66772',
      unisonId: 'COMHPV9AZHHN',
      title: 'TOWER OF FLOWER V3',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:47:00.611Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:47:00.612Z',
      updatedAt: '2020-09-07T12:47:00.678Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '82538334-6021-4321-807e-449a7adb108e',
      unisonId: 'COCRG1FL7U0W',
      title: 'PISTOL WHIPPED',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:47:12.592Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:47:12.594Z',
      updatedAt: '2020-09-07T12:47:12.689Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'd99d8fdc-b93e-4f8f-9395-174c9aa8c08f',
      unisonId: 'CON2LWJFL6HJ',
      title: 'MIND OF EVANS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:47:18.558Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:47:18.561Z',
      updatedAt: '2020-09-07T12:47:18.669Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '21b9ad58-6a95-4805-a6bb-4d3d6b998a67',
      unisonId: 'COHV03N2448O',
      title: 'REFLECTIONS OF THE DAY',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:47:18.730Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:47:18.733Z',
      updatedAt: '2020-09-07T12:47:18.851Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '9749b7fe-61e7-4991-aab7-29c950982a0d',
      unisonId: 'COF49RVR51QN',
      title: 'REFLECTIONS OF THE DAY',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:47:18.910Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:47:18.911Z',
      updatedAt: '2020-09-07T12:47:19.019Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'a1b9d04b-c155-4e7c-99c8-ffe3762130cd',
      unisonId: 'CO6ISY0IFBBV',
      title: 'FLUTTERBYS V1',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:47:20.994Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:47:20.996Z',
      updatedAt: '2020-09-07T12:47:21.086Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'c1552d98-4664-4162-afc8-46fb86c2791d',
      unisonId: 'COFXPQN2IYW5',
      title: 'FLUTTERBYS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:47:21.144Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:47:21.146Z',
      updatedAt: '2020-09-07T12:47:21.249Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '5bef4b8e-ebf8-42b1-ad8a-93577edc04d1',
      unisonId: 'COI8B3C4BHHI',
      title: 'FREEDOM FLIGHT X',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:47:26.899Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:47:26.900Z',
      updatedAt: '2020-09-07T12:47:26.992Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '03a59ed5-94d3-4771-b85c-3eea3c9ac511',
      unisonId: 'CO8HVE6R5CDI',
      title: 'SURFACE FLIGHT',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:47:27.055Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:47:27.056Z',
      updatedAt: '2020-09-07T12:47:27.129Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'f26e58b2-8065-4f92-a383-c37daaa380e8',
      unisonId: 'CO4D3FL9C9HJ',
      title: 'PEPPERMINT PADDY',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:47:28.917Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:47:28.919Z',
      updatedAt: '2020-09-07T12:47:29.033Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'd35f9747-66e1-45e1-91d5-fa9b73ed2199',
      unisonId: 'COIHO8I3H6FL',
      title: 'AMISS IN BANGLADESH',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:47:30.468Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:47:30.471Z',
      updatedAt: '2020-09-07T12:47:30.570Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '0f8e6d14-1e51-4477-aef8-37fb17989008',
      unisonId: 'CO1HH67OCA6P',
      title: 'FLYING DREAMS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:47:37.004Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:47:37.006Z',
      updatedAt: '2020-09-07T12:47:37.086Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'e942ab56-b6ac-4e70-906b-759bccbc1217',
      unisonId: 'COY9AZ9UZ3FL',
      title: 'SURFACE TENSION OP 4',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:47:43.855Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:47:43.856Z',
      updatedAt: '2020-09-07T12:47:43.969Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'b8106168-909c-47a2-9548-fbe35c316881',
      unisonId: 'CO09V73OQV5D',
      title: 'NOCTURNAL FLIGHT',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:47:45.433Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:47:45.434Z',
      updatedAt: '2020-09-07T12:47:45.514Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'f81cccd3-e03e-4dcd-b115-85ab904e5ab9',
      unisonId: 'CO1V4BDFARNM',
      title: 'FLIGHT TO FREEDOM OP 1',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:47:46.728Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:47:46.730Z',
      updatedAt: '2020-09-07T12:47:46.815Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '0a7ef345-a961-4ed8-abe4-b8f81b342e2a',
      unisonId: 'COQ2U6HU0840',
      title: 'FLIGHT TO FREEDOM OP 2',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:47:46.865Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:47:46.868Z',
      updatedAt: '2020-09-07T12:47:46.965Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'bad31b4e-2002-462b-99f4-01d8f0eb9c89',
      unisonId: 'COEGZQ3HTD9F',
      title: 'FLIGHT TO FREEDOM OP 3',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:47:47.024Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:47:47.027Z',
      updatedAt: '2020-09-07T12:47:47.126Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '40df3bb7-4f5c-478b-810c-a2ea1612350c',
      unisonId: 'COOE1BOR3JTD',
      title: 'FLIGHT TO FREEDOM OP 4',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:47:47.194Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:47:47.196Z',
      updatedAt: '2020-09-07T12:47:47.301Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '1c4009b4-f01e-40c7-975b-95582acc5bf7',
      unisonId: 'COLHFXVVC1HI',
      title: 'WINTER FLIGHT',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:47:48.971Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:47:48.973Z',
      updatedAt: '2020-09-07T12:47:49.056Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'afab3a80-15d5-4305-98f6-1d98a6663602',
      unisonId: 'CO31PTBLTVYV',
      title: 'KLUNG FLU',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:47:56.672Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:47:56.673Z',
      updatedAt: '2020-09-07T12:47:56.753Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '198b64af-6760-48d3-bd93-3da4e6d9b528',
      unisonId: 'COJ3AQC5U1SQ',
      title: 'FLOWERS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:47:58.376Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:47:58.378Z',
      updatedAt: '2020-09-07T12:47:58.480Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '883d39a4-3627-4c6a-b343-6310892b5ad4',
      unisonId: 'CO850I2DMPUO',
      title: 'NOW TAKE FLIGHT',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:48:01.353Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:48:01.354Z',
      updatedAt: '2020-09-07T12:48:01.438Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '9a748d40-fa81-43ab-b98b-224014f13a86',
      unisonId: 'COSBDH2Y0JFL',
      title: 'ITS SO BRIGHT',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:48:01.793Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:48:01.795Z',
      updatedAt: '2020-09-07T12:48:01.879Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '4cd056ae-e5f6-4373-b19b-1967f25fc255',
      unisonId: 'COMHUB9Y0D2W',
      title: 'FLYING BULLETS AND LIFE FLASHES',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:48:05.791Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:48:05.794Z',
      updatedAt: '2020-09-07T12:48:05.883Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'a7af773a-30c1-4d74-baa1-db4e01e28532',
      unisonId: 'CO1MY869V4BP',
      title: 'DRAGONFLIES',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:48:14.854Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:48:14.855Z',
      updatedAt: '2020-09-07T12:48:14.962Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '3a10dc35-c56c-4ef2-9b37-b49dcba30f55',
      unisonId: 'CORIVHQG72BI',
      title: 'FLASHBACK',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:48:15.020Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:48:15.022Z',
      updatedAt: '2020-09-07T12:48:15.111Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'd175e7f6-b64c-4178-b53e-4bc505c02c9f',
      unisonId: 'COAIR7A16VKK',
      title: 'TRAVEL BY FLIGHT',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:48:17.011Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:48:17.013Z',
      updatedAt: '2020-09-07T12:48:17.099Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '91b1e10e-0c4e-4ad6-bcd2-8042caed2771',
      unisonId: 'CORZ0E1K9TK6',
      title: 'INFLUENCE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:48:20.794Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:48:20.797Z',
      updatedAt: '2020-09-07T12:48:20.878Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '851edb0f-65c4-4f0c-b4cf-dc1cc107c98f',
      unisonId: 'CO2FLOA8RQGQ',
      title: 'CASTAIC LAKE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:48:23.726Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:48:23.728Z',
      updatedAt: '2020-09-07T12:48:23.825Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'e06f11c4-2b49-4339-b100-f3f4b353b6a0',
      unisonId: 'COYPJFLDIR27',
      title: 'ELECTRO EPIC',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:48:25.386Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:48:25.388Z',
      updatedAt: '2020-09-07T12:48:25.481Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'e427e59d-0957-4ec5-b2a7-b68c54308eb4',
      unisonId: 'COR8N9JR05DX',
      title: 'SKULLS AND RIFLES',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:48:43.589Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:48:43.591Z',
      updatedAt: '2020-09-07T12:48:43.667Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '65bff0c1-389f-45c9-ade3-71451d8ba5f0',
      unisonId: 'COKRZ9AGIVPB',
      title: 'WALKING IN FLIGHT',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:48:44.925Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:48:44.927Z',
      updatedAt: '2020-09-07T12:48:45.027Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '3eadac45-7e1f-4198-82c9-6c5d8067b445',
      unisonId: 'COVOLSJDV2RO',
      title: 'FLOATING',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:48:45.713Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:48:45.715Z',
      updatedAt: '2020-09-07T12:48:45.834Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'bd535cf0-4fad-486e-83fc-d490287fafdf',
      unisonId: 'COBA3JI1M8VF',
      title: 'LOVE AS A FLOWER BLOSSOMS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:48:47.844Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:48:47.847Z',
      updatedAt: '2020-09-07T12:48:47.938Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '268fcd3c-f735-4346-8891-55d4f7217bb8',
      unisonId: 'CO6A4WXTODEB',
      title: 'SOCIAL BUTTERFLY',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:48:55.915Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:48:55.917Z',
      updatedAt: '2020-09-07T12:48:56.000Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '55d91e94-b1b2-49dc-9b38-45ccaa5f9ea9',
      unisonId: 'COATC25JFL5U',
      title: 'PATTERNS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:48:58.400Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:48:58.402Z',
      updatedAt: '2020-09-07T12:48:58.484Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '988a02cb-fe10-4e5a-8672-32978f6e4aff',
      unisonId: 'COKW8VKH4CM2',
      title: 'OPEN FLOODGATE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:49:22.739Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:49:22.741Z',
      updatedAt: '2020-09-07T12:49:22.877Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '0faf3666-4df9-4fd8-80dc-04ac84b46755',
      unisonId: 'COH8SP66LAKK',
      title: 'I WANT TO FLY',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:49:23.590Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:49:23.592Z',
      updatedAt: '2020-09-07T12:49:23.674Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '9c8f70dd-085d-46b6-90ba-5159531d43ab',
      unisonId: 'CORZNUK0LWIL',
      title: 'I WANT TO FLY',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:49:24.866Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:49:24.868Z',
      updatedAt: '2020-09-07T12:49:24.967Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '19d9b8ce-cdbd-44e8-8327-e5e5c05a29f7',
      unisonId: 'COFLGXP5AP6N',
      title: 'P.I. SPY',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:49:32.592Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:49:32.595Z',
      updatedAt: '2020-09-07T12:49:32.720Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '4ec3bf2e-7be9-4fac-8689-91f02796a3e1',
      unisonId: 'COA9ZWJVFL3O',
      title: 'LOSING MY MIND',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:49:37.419Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:49:37.491Z',
      updatedAt: '2020-09-07T12:49:37.585Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '4d6f5d27-7298-4f4f-94f1-34942c186a36',
      unisonId: 'COFN4TFLTJS8',
      title: 'HIDDEN TEMPLE 1',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:49:39.186Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:49:39.187Z',
      updatedAt: '2020-09-07T12:49:39.300Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '443909ce-d98d-422c-9d92-847566e29cec',
      unisonId: 'CO43OMOCADM0',
      title: 'FLAMENCO FIRE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:49:43.902Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:49:43.904Z',
      updatedAt: '2020-09-07T12:49:43.994Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '4def120f-2f38-423d-93ac-d6adc66ab4e0',
      unisonId: 'CO3JBL4IZ7U7',
      title: 'FLAMENCO FIRE #2',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:49:44.051Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:49:44.055Z',
      updatedAt: '2020-09-07T12:49:44.161Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '1bb6898c-a24b-45d6-8280-6477cce0ba7b',
      unisonId: 'COFLHYB88KVZ',
      title: 'MODELS INC. 1',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:49:52.915Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:49:52.917Z',
      updatedAt: '2020-09-07T12:49:53.025Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '23b1be1d-f098-4863-92bb-5cefb2ff26e6',
      unisonId: 'CO6A3R529W1I',
      title: 'FLOWERS ALL AROUND',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:49:56.204Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:49:56.206Z',
      updatedAt: '2020-09-07T12:49:56.304Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '190b3813-4164-4e15-9cc1-f389a68550cb',
      unisonId: 'COHP8R2PPBFL',
      title: 'IF YOU WANNA PLAY',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T12:50:05.205Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T12:50:05.207Z',
      updatedAt: '2020-09-07T12:50:05.317Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'a28a6b59-095c-40dd-9c8c-e5e6a650cd62',
      unisonId: 'COTTWZ2M1AJP',
      title: 'ALLEY CAMOUFLAGE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:03:34.998Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:03:35.000Z',
      updatedAt: '2020-09-07T14:03:35.156Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '354f236a-f609-4ea8-923a-4d1602349f45',
      unisonId: 'CO5TGVFL3U8G',
      title: 'BUILDING MOMENTUM',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:03:52.094Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:03:52.095Z',
      updatedAt: '2020-09-07T14:03:52.267Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '1d2d2430-8acf-4d32-b55e-d0cd573e92d3',
      unisonId: 'COG8ON0FL4LR',
      title: 'FREAK CIRCUS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:03:55.715Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:03:55.716Z',
      updatedAt: '2020-09-07T14:03:55.858Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '46712394-c30b-47bb-af69-18c9873f43e4',
      unisonId: 'COTHBZ5SLUM6',
      title: 'CATCHING FIREFLIES',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:04:06.015Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:04:06.017Z',
      updatedAt: '2020-09-07T14:04:06.172Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '1bb93828-9ec6-4d76-85df-0230e7a497bc',
      unisonId: 'CO7P5BH7W03B',
      title: 'BUTTERFLY',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:04:07.140Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:04:07.143Z',
      updatedAt: '2020-09-07T14:04:07.321Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '692d79bc-1a18-42f7-b3ec-9c83de05c850',
      unisonId: 'COJHZZPD2Q2M',
      title: 'ANGELS FLIGHT',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:04:14.663Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:04:14.664Z',
      updatedAt: '2020-09-07T14:04:14.810Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '9c57ca30-3a5e-47f5-bd03-e484f9264e06',
      unisonId: 'CO5KR8MLQZ4O',
      title: 'INNER CONFLICT',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:04:30.469Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:04:30.471Z',
      updatedAt: '2020-09-07T14:04:30.609Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '2dd6daa7-c3e3-49e3-84ad-52a2848cf2ba',
      unisonId: 'COOD4EF5YZ0A',
      title: 'DOOR IN THE FLOOR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:04:54.164Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:04:54.167Z',
      updatedAt: '2020-09-07T14:04:54.308Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'ba292b66-880c-45eb-bb59-ad2419e58d47',
      unisonId: 'CO5HX7JT40C9',
      title: 'FLY FISHIN',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:05:24.623Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:05:24.626Z',
      updatedAt: '2020-09-07T14:05:24.776Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'b5e4e969-4032-428d-ab8b-420e20a07513',
      unisonId: 'COFU2SW8IRF3',
      title: 'REFLECTIONS IN THE RAIN',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:05:28.712Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:05:28.714Z',
      updatedAt: '2020-09-07T14:05:28.892Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '8ee46d0f-ed9e-4ad4-bd54-b8176242adde',
      unisonId: 'COJFFLO4EBJT',
      title: 'MOMENTS OF FEAR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:05:38.478Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:05:38.480Z',
      updatedAt: '2020-09-07T14:05:38.626Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '6bdf7ebd-a92d-4258-922a-a1d5ab81f430',
      unisonId: 'CO0XH1IIVF9Z',
      title: 'FLU SYMPTOM',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:05:47.556Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:05:47.558Z',
      updatedAt: '2020-09-07T14:05:47.640Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'cf79bc6c-eb07-429b-b120-4bebeaa22125',
      unisonId: 'COLRB21IGN9Y',
      title: 'FLAMING SPRAY PAINT',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:05:53.337Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:05:53.339Z',
      updatedAt: '2020-09-07T14:05:53.441Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '166dd886-0506-4b22-9d37-7317afed9cdb',
      unisonId: 'CORREG7MXPQ5',
      title: 'REFLECT SUNSET RED',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:05:53.493Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:05:53.495Z',
      updatedAt: '2020-09-07T14:05:53.613Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '8776eec6-9a67-4102-8ae7-39ddbd9f4b6d',
      unisonId: 'CO1BC4GGIG9S',
      title: 'FLYING SOLO',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:05:54.322Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:05:54.324Z',
      updatedAt: '2020-09-07T14:05:54.426Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '07cf4ec7-5f17-4b82-904f-751635690835',
      unisonId: 'COSGRYS7ZFLD',
      title: 'ALWAYS KNOWING ANSWERS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:06:21.871Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:06:21.873Z',
      updatedAt: '2020-09-07T14:06:21.988Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '899cefb0-4bc1-45fd-adac-e4809c7bd0ff',
      unisonId: 'CO5Q76M4SFLU',
      title: 'ASSERTION',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:06:26.364Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:06:26.366Z',
      updatedAt: '2020-09-07T14:06:26.474Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '8d2ddb08-0094-4304-b26f-e392173dec54',
      unisonId: 'COE3CUYWEPBB',
      title: 'ARAB INFLUENCE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:06:30.441Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:06:30.443Z',
      updatedAt: '2020-09-07T14:06:30.559Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '187dff2d-96d8-4c94-9715-fa3e53354e1a',
      unisonId: 'COIF69ILOI3L',
      title: 'FLYER',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:06:37.154Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:06:37.156Z',
      updatedAt: '2020-09-07T14:06:37.242Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '054bb8cf-a9ab-4626-9892-636cf801314e',
      unisonId: 'COLFXLCF4938',
      title: 'EXTREME FLIGHT',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:06:37.326Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:06:37.328Z',
      updatedAt: '2020-09-07T14:06:37.451Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'df9f253a-cbb4-4111-b6df-f043fc84f08f',
      unisonId: 'CO9UPPEBL01B',
      title: 'FLYING HIGH',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:06:48.767Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:06:48.769Z',
      updatedAt: '2020-09-07T14:06:48.841Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'c6b5c873-e662-41e6-985b-7751c410678b',
      unisonId: 'COPZBPHGK2W0',
      title: 'XMAS FLAKES',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:07:03.826Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:07:03.828Z',
      updatedAt: '2020-09-07T14:07:03.944Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'e8cf872f-7195-442c-9350-8e7a8bc2931f',
      unisonId: 'COP297PFL1E3',
      title: 'SALT AND PEPPER',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:07:04.289Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:07:04.291Z',
      updatedAt: '2020-09-07T14:07:04.356Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '6fefe7c3-28b8-4e61-98b1-0f297e4a2528',
      unisonId: 'CO0QC07H5VQC',
      title: 'WAFFLE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:07:04.804Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:07:04.806Z',
      updatedAt: '2020-09-07T14:07:04.905Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '0545fef8-4a15-4d5a-ae81-fad624940605',
      unisonId: 'COL7732N5KF5',
      title: 'OPEN FLOODGATE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:07:13.832Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:07:13.834Z',
      updatedAt: '2020-09-07T14:07:13.980Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '5ad3c890-478b-4fa3-a33c-a8f0c623feea',
      unisonId: 'COFLXBN0Y6RN',
      title: 'HEAD OVER HEELS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:07:14.357Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:07:14.360Z',
      updatedAt: '2020-09-07T14:07:14.530Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'a66f977a-3417-40c5-be1b-5aa3772a89c7',
      unisonId: 'CO9EPZ8E6SNP',
      title: 'I WANT TO FLY',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:07:18.616Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:07:18.619Z',
      updatedAt: '2020-09-07T14:07:18.713Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '212e30c9-f448-4a68-9a54-a22c6da60f3b',
      unisonId: 'COGYTQHC6E4A',
      title: 'I WANT TO FLY',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:07:18.768Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:07:18.770Z',
      updatedAt: '2020-09-07T14:07:18.866Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '22bf7469-93db-4077-8887-e528e3a976db',
      unisonId: 'CONC973IFLGO',
      title: 'COME ON',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:07:20.676Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:07:20.678Z',
      updatedAt: '2020-09-07T14:07:20.861Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'f801d2ed-8799-42b3-aa03-407c5bd63b62',
      unisonId: 'CONZRIFK1U31',
      title: 'FLAMENCO FIRE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:07:41.693Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:07:41.694Z',
      updatedAt: '2020-09-07T14:07:41.791Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'ff01be25-060f-4eb2-aa3f-074e75b06253',
      unisonId: 'COQX71QINI4Q',
      title: 'FLAMENCO FIRE 2',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:07:41.849Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:07:41.855Z',
      updatedAt: '2020-09-07T14:07:41.970Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '215d9598-5406-4e83-9d80-7d3a8883e526',
      unisonId: 'COIM13SRBO2G',
      title: 'FLOWERS ALL AROUND',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:07:56.609Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:07:56.612Z',
      updatedAt: '2020-09-07T14:07:56.713Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'cd08d39c-da26-4a63-b353-015fea094c36',
      unisonId: 'COFLYI1DD7PO',
      title: 'THE MASSIVE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:08:04.589Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:08:04.593Z',
      updatedAt: '2020-09-07T14:08:04.707Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'b2ba066b-461f-499d-9909-40c4ab2d040e',
      unisonId: 'CORUCLKFR3PJ',
      title: 'REFLECTION',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:08:06.396Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:08:06.398Z',
      updatedAt: '2020-09-07T14:08:06.499Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: '761bd3ec-3a8f-487c-b651-ead01c11f620',
      unisonId: 'COUD0TWLOLJY',
      title: 'HIT THE FLOOR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: null,
      lyricAdaptation: null,
      copyrightDate: '2020-09-07T14:08:14.097Z',
      submitterWorkN: null,
      copyrightNumber: null,
      musicalWorkDistributionCategory: null,
      duration: null,
      recordedIndicator: null,
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: null,
      compositeComponentCount: null,
      datePublicationPrintedEdition: null,
      exceptionalClause: null,
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: null,
      createdAt: '2020-09-07T14:08:14.099Z',
      updatedAt: '2020-09-07T14:08:14.252Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: null,
    },
    {
      id: 'c37b241d-f82f-4bb6-8089-5d7ed47f1815',
      unisonId: 'CON3GWBGFLKN',
      title: 'BEAVERIMPATIENCE NODMS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:58:44.176Z',
      submitterWorkN: 'BEAVERIMP005',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:58:44.179Z',
      updatedAt: '2020-09-17T10:58:44.333Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'b41380e0-661a-4dc1-92a1-a72ee8b42207',
      unisonId: 'COVZY7D1T4J7',
      title: "BITCHES 'N' WHORES DJ GENDERFLUID EURODANCE REMIX",
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:58:46.974Z',
      submitterWorkN: 'BITCHESNW002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:18:14',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:58:46.977Z',
      updatedAt: '2020-09-17T10:58:47.099Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '183c70f2-733f-4a7b-b5ce-8ba48480b688',
      unisonId: 'CO4XIJ7NMTOX',
      title: 'BLOOM FLOWER',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:58:47.765Z',
      submitterWorkN: 'BLOOMFLOW',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:15:12',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:58:47.769Z',
      updatedAt: '2020-09-17T10:58:47.871Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'f161509e-285b-434a-9bad-eae502846f2e',
      unisonId: 'CO8FL1H07KLG',
      title: 'CELESTEMYSTERY LITE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:58:44.659Z',
      submitterWorkN: 'CELESTEMY002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:58:44.662Z',
      updatedAt: '2020-09-17T10:58:44.784Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '8c91e29e-2601-4d72-b31a-20ab80b16817',
      unisonId: 'COUGVILKJ2G0',
      title: 'FLASH ORIGINAL MIX',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:58:47.941Z',
      submitterWorkN: 'FLASHORIG',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:43:37',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:58:47.944Z',
      updatedAt: '2020-09-17T10:58:48.066Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '3a21b72d-295e-4494-8874-585c23218f41',
      unisonId: 'CON3PLA4IFFB',
      title: 'FLEXIBLESOFTBALL DMSBS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:58:48.082Z',
      submitterWorkN: 'FLEXIBLES',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:58:48.085Z',
      updatedAt: '2020-09-17T10:58:48.208Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'e09d562b-3233-404d-8244-26a7d68cdde0',
      unisonId: 'COON7RM5JULZ',
      title: 'FLEXIBLESOFTBALL FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:58:48.216Z',
      submitterWorkN: 'FLEXIBLES001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:58:48.217Z',
      updatedAt: '2020-09-17T10:58:48.328Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '9648e2e4-e00c-462e-9ed2-cd4982efe774',
      unisonId: 'COAGQ5PYY1W0',
      title: 'FLEXIBLESOFTBALL LITE',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:58:48.338Z',
      submitterWorkN: 'FLEXIBLES002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:58:48.340Z',
      updatedAt: '2020-09-17T10:58:48.427Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '2fa123c7-9070-4214-9f6f-c848267f44f3',
      unisonId: 'COJB9OI5GYPX',
      title: 'FLOATING',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:58:48.463Z',
      submitterWorkN: 'FLOATING002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:58:48.466Z',
      updatedAt: '2020-09-17T10:58:48.630Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'a08a9626-3166-44f3-ba26-e41b6251d9ee',
      unisonId: 'COWVDH1F2IFL',
      title: 'CONTINUITYSIENNA FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:58:53.264Z',
      submitterWorkN: 'CONTINUIT023',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:58:53.265Z',
      updatedAt: '2020-09-17T10:58:53.349Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'eea1ced5-bbf6-40cc-a78d-a1976f779777',
      unisonId: 'COQT8QBYILFL',
      title: 'BOSCH',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:58:50.435Z',
      submitterWorkN: 'BOSCH001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:58:50.437Z',
      updatedAt: '2020-09-17T10:58:50.540Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '70eaa875-42ad-49b4-b92c-b924fbcc612e',
      unisonId: 'COZFLMHJYYQZ',
      title: 'DOCUMENTWELCOME FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:58:51.049Z',
      submitterWorkN: 'DOCUMENTW001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:58:51.052Z',
      updatedAt: '2020-09-17T10:58:51.162Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'b9c74dc6-4b27-4c57-969e-9cbe078ee32e',
      unisonId: 'COC45XUC6DEJ',
      title: 'CORNFLAKES',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:58:55.395Z',
      submitterWorkN: 'CORNFLAKE',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '01:01:56',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:58:55.398Z',
      updatedAt: '2020-09-17T10:58:55.518Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '6d3fa4d0-274e-4b9e-b561-1b42f2bfdd70',
      unisonId: 'COTZ4PKNCZ83',
      title: 'HOOK YU UP DJ GENDERFLUID REMIX',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:58:58.278Z',
      submitterWorkN: 'HOOKYUUPD',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:42:38',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:58:58.280Z',
      updatedAt: '2020-09-17T10:58:58.416Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'cbdd2996-20e6-4411-b7aa-47f7b0e51f90',
      unisonId: 'COH4YTSJ8YGY',
      title: 'HOW TO FLY',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:59:00.637Z',
      submitterWorkN: 'HOWTOFLY',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:14:10',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:59:00.638Z',
      updatedAt: '2020-09-17T10:59:00.754Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '25df7174-678e-4e6c-99f1-8d363ca6aeea',
      unisonId: 'COFLZQ1TBG1P',
      title: 'PDNIGHTONBALDMOUNTAINMUSSORGSKY LITE',
      iswc: null,
      type: 'MTX',
      versionType: 'MOD',
      status: 'ACCEPTED',
      musicArrangement: 'UNS',
      lyricAdaptation: 'UNS',
      copyrightDate: '2020-09-17T10:59:04.167Z',
      submitterWorkN: 'PDNIGHTON002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:59:04.168Z',
      updatedAt: '2020-09-17T10:59:04.329Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '51f1c5e3-5f49-4af3-ab94-6a75ba866414',
      unisonId: 'COKRMOGI5AAN',
      title: 'NO STOPPING FOR NICOTINE (FLOATING)',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:59:04.889Z',
      submitterWorkN: 'NOSTOPPIN001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:07:02',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:59:04.891Z',
      updatedAt: '2020-09-17T10:59:05.029Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '4df80127-a8fc-4411-abd6-7ecfdb350d7f',
      unisonId: 'COO39OFL6MWG',
      title: 'PUCEDISAPPROVAL NOSYNTH',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:59:02.794Z',
      submitterWorkN: 'PUCEDISAP007',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:59:02.799Z',
      updatedAt: '2020-09-17T10:59:02.933Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '2f09f0b6-6b1d-447c-8c47-f193b5c5b48b',
      unisonId: 'COTVK48Q8A5A',
      title: 'MAMBOFLUORESCENT FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:59:07.339Z',
      submitterWorkN: 'MAMBOFLUO',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:59:07.340Z',
      updatedAt: '2020-09-17T10:59:07.438Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'd1b26a12-6da4-486e-94f1-0394ec8fe943',
      unisonId: 'CONNIJLVSDHD',
      title: 'MAMBOFLUORESCENT NOACGTR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:59:07.445Z',
      submitterWorkN: 'MAMBOFLUO001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:59:07.448Z',
      updatedAt: '2020-09-17T10:59:07.533Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '94b16876-9c1e-4890-9cf9-0d18073a7b28',
      unisonId: 'COH4JEN183OM',
      title: 'MAMBOFLUORESCENT NOCLAP',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:59:07.544Z',
      submitterWorkN: 'MAMBOFLUO002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:59:07.547Z',
      updatedAt: '2020-09-17T10:59:07.621Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '72326b30-6a9c-4ed8-b672-548be2cc8f8b',
      unisonId: 'COTZ79RVX2AV',
      title: 'MAMBOFLUORESCENT NODOBRO',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:59:07.626Z',
      submitterWorkN: 'MAMBOFLUO003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:59:07.627Z',
      updatedAt: '2020-09-17T10:59:07.691Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'afb9ae36-1732-4488-88fe-ffffb629312d',
      unisonId: 'CO43YDCCB6Y6',
      title: 'MAMBOFLUORESCENT NODOBROGTR',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:59:07.697Z',
      submitterWorkN: 'MAMBOFLUO004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:59:07.699Z',
      updatedAt: '2020-09-17T10:59:07.757Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'af36f89c-c012-4b44-92e7-4c51235b179e',
      unisonId: 'CO02VWPHPO36',
      title: 'MAMBOFLUORESCENT NODRUMS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:59:07.762Z',
      submitterWorkN: 'MAMBOFLUO005',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:59:07.763Z',
      updatedAt: '2020-09-17T10:59:07.841Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '6d28e1f0-57c8-4087-8895-25d8952dd8c5',
      unisonId: 'CO0NTZW0TU09',
      title: 'MAMBOFLUORESCENT NOSHAKERSTOMPCLAP',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:59:07.847Z',
      submitterWorkN: 'MAMBOFLUO006',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:59:07.849Z',
      updatedAt: '2020-09-17T10:59:07.943Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'a6db9f97-9d76-48e5-86a9-84441866a360',
      unisonId: 'COAV00G4WYR5',
      title: 'MAMBOFLUORESCENT NOSTOMPLESSCLAP',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:59:07.951Z',
      submitterWorkN: 'MAMBOFLUO007',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:59:07.955Z',
      updatedAt: '2020-09-17T10:59:08.040Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'b18c760f-29f9-40a2-9fe3-2ed55066f385',
      unisonId: 'COE3TZ2TWDY2',
      title: 'MAMBOFLUORESCENT NOTOMSPLASHHARP',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:59:08.048Z',
      submitterWorkN: 'MAMBOFLUO008',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:59:08.050Z',
      updatedAt: '2020-09-17T10:59:08.141Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'cb1391a8-15b2-4545-8b88-c5a8fb7663b8',
      unisonId: 'COZXDCRYVFD5',
      title: 'KICKFLIP',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:59:10.496Z',
      submitterWorkN: 'KICKFLIP',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:33:31',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:59:10.499Z',
      updatedAt: '2020-09-17T10:59:10.605Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '527f0114-e6fc-45a5-b1fa-c2114cf355a0',
      unisonId: 'COFEEEQ4IRVS',
      title: 'RUSTINFLUENCE FULL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:59:11.998Z',
      submitterWorkN: 'RUSTINFLU',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:59:12.000Z',
      updatedAt: '2020-09-17T10:59:12.082Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '57526b47-cb5a-4e33-b6e7-3ac0cc86fd09',
      unisonId: 'COUQ6UBKX6SO',
      title: 'RUSTINFLUENCE LITEMIX01',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:59:12.092Z',
      submitterWorkN: 'RUSTINFLU001',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:59:12.095Z',
      updatedAt: '2020-09-17T10:59:12.190Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '04d0d8d1-b649-4b10-8d72-abfc385bc57c',
      unisonId: 'COZQ0K8E9YDE',
      title: 'RUSTINFLUENCE LITEMIX02',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:59:12.196Z',
      submitterWorkN: 'RUSTINFLU002',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:59:12.198Z',
      updatedAt: '2020-09-17T10:59:12.272Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: 'a474a356-c737-4d5e-a652-197b3350566a',
      unisonId: 'COLQDC1LELX0',
      title: 'RUSTINFLUENCE NOBASS',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:59:12.280Z',
      submitterWorkN: 'RUSTINFLU003',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:59:12.281Z',
      updatedAt: '2020-09-17T10:59:12.363Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
    {
      id: '4444f16b-520e-40f3-bf1a-4ec44b4d5bbb',
      unisonId: 'COQJ38QMXK7C',
      title: 'RUSTINFLUENCE NOMEL',
      iswc: null,
      type: 'MTX',
      versionType: 'ORI',
      status: 'ACCEPTED',
      musicArrangement: 'ORI',
      lyricAdaptation: 'ORI',
      copyrightDate: '2020-09-17T10:59:12.369Z',
      submitterWorkN: 'RUSTINFLU004',
      copyrightNumber: null,
      musicalWorkDistributionCategory: 'POP',
      duration: '00:00:00',
      recordedIndicator: 'Y',
      compositeType: null,
      excerptType: null,
      contactName: null,
      contactId: null,
      workType: null,
      grandRightsIndicator: false,
      compositeComponentCount: 0,
      datePublicationPrintedEdition: '0000-00-00',
      exceptionalClause: 'U',
      opusNumber: null,
      catalogueNumber: null,
      priorityFlag: 'U',
      createdAt: '2020-09-17T10:59:12.370Z',
      updatedAt: '2020-09-17T10:59:12.444Z',
      createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
      language: 'EN',
    },
  ],
  info: {
    count: 711,
    offset: '0',
    limit: '999999',
  },
};
