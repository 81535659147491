import { Serializable } from 'app/core/interfaces';
import { RightHolderDTO, RightHolder } from 'app/shared/models/rightHolder';
// eslint-disable-next-line import/no-cycle
import { WorkDTO } from 'app/shared/models';
// eslint-disable-next-line import/no-cycle
import { CollectionShare, CollectionShareDTO } from './collectionShare';

export interface OwnershipShareDTO {
  id?: string;
  performanceSplit?: number;
  mechanicalSplit?: number;
  synchronizationSplit?: number;
  role?: string;
  owner?: RightHolderDTO;
  ownerId?: string;
  composition?: WorkDTO;
  compositionId?: string;
  rightHolderName?: string;
  updatedAt?: string;
  createdAt?: string;

  share?: CollectionShareDTO;
  rightHolderId?: string;
}

export class OwnershipShare implements Serializable<OwnershipShare> {
  id?: string;
  performanceSplit?: number;
  mechanicalSplit?: number;
  synchronizationSplit?: number;
  role?: string;
  owner?: RightHolder;
  ownerId?: string;
  // work?: Work;
  compositionId?: string;
  rightHolderName?: string;
  updatedAt?: string;
  createdAt?: string;

  share?: CollectionShare;
  rightHolderId?: string;

  deserialize(input: OwnershipShareDTO): OwnershipShare {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.performanceSplit = input.performanceSplit || 0;
    this.mechanicalSplit = input.mechanicalSplit || 0;
    this.synchronizationSplit = input.synchronizationSplit || 0;
    this.role = input.role || '';
    this.owner = new RightHolder().deserialize(input.owner) || null;
    this.ownerId = input.ownerId || '';

    /* if (input.composition) {
      this.work = new Work().deserialize(input.composition);
    } else {
      this.work = null;
    } */

    this.compositionId = input.compositionId || '';
    this.rightHolderName = input.rightHolderName || '';
    this.updatedAt = input.updatedAt || '';
    this.createdAt = input.createdAt || '';

    this.share = new CollectionShare().deserialize(input.share) || null;
    this.rightHolderId = input.rightHolderId || '';

    return this;
  }

  deserializeArray(inputArray: Array<OwnershipShareDTO>): Array<OwnershipShare> {
    const ownershipshares: Array<OwnershipShare> = inputArray.map((input) =>
      new OwnershipShare().deserialize(input),
    );

    return ownershipshares;
  }
}
