import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Consumer, ConsumerDTO } from '../models';
import { InfoResponseAPI } from '../interfaces';

export interface ConsumerFilters {
  text?: string;
  platformId?: string;
}

export interface ConsumersResponse {
  consumers: Consumer[];
  info: InfoResponseAPI;
}

@Injectable({
  providedIn: 'root',
})
export class ConsumerService {
  readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  async getConsumers(
    offset: number,
    limit: number,
    filters?: ConsumerFilters,
  ): Promise<ConsumersResponse> {
    const url = `${this.apiUrl}/consumer`;
    const params = {
      offset: offset ? offset.toString() : '',
      limit: limit ? limit.toString() : '',
      text: filters?.text ? filters.text : '',
      platformId: filters?.platformId ? filters.platformId : '',
    };

    const { consumers: consumersApi, info } = await this.http
      .get<{ consumers: Array<ConsumerDTO>; info: InfoResponseAPI }>(url, { params })
      .toPromise();

    return {
      consumers: new Consumer().deserializeArray(consumersApi),
      info,
    };
  }

  async getConsumer(consumerId: string): Promise<Consumer> {
    const url = `${this.apiUrl}/consumer/${consumerId}/`;

    const { consumer: consumerApi } = await this.http
      .get<{ consumer: ConsumerDTO }>(url)
      .toPromise();

    return new Consumer().deserialize(consumerApi);
  }
}
