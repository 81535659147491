import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from 'environments/environment';
import { Session, User, UserDTO } from 'app/shared/models';
import { HttpClient } from '@angular/common/http';
import { USER_MOCK } from 'app/shared/mocks/user-mock';

interface AuthResponse {
  user: UserDTO;
  token: string;
}

interface ResetPasswordI {
  token: string;
  password: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly sessionSubject = new BehaviorSubject<Session>(null);
  readonly session$ = this.sessionSubject.asObservable();

  private readonly disclaimerAcceptedSubject = new BehaviorSubject<boolean>(false);
  readonly disclaimerAccepted$ = this.disclaimerAcceptedSubject.asObservable();

  get disclaimerAccepted(): boolean {
    return this.disclaimerAcceptedSubject.getValue();
  }

  set disclaimerAccepted(status: boolean) {
    this.disclaimerAcceptedSubject.next(status);
  }

  private readonly sessionKey = environment.sessionKey;
  private readonly apiUrl = environment.apiUrl;

  private localStorageService: Storage;

  constructor(private http: HttpClient) {
    this.localStorageService = localStorage;
    this.session = this.loadSessionData();
  }

  getCurrentSession(): Session {
    return this.sessionSubject.getValue();
  }

  private set session(session: Session) {
    this.sessionSubject.next(session);
    this.localStorageService.setItem(this.sessionKey, JSON.stringify(session));
  }

  private loadSessionData(): Session {
    const sessionStr = this.localStorageService.getItem(this.sessionKey);
    return sessionStr ? <Session>JSON.parse(sessionStr) : null;
  }

  getCurrentUser(): User | null {
    const session = this.loadSessionData();

    return session && session.client ? session.client : null;
  }

  getCurrentToken(): string | null {
    const session = this.loadSessionData();

    if (!session) {
      return null;
    }

    if (session.clientToken) {
      return session.clientToken;
    }

    if (session.adminToken) {
      return session.adminToken;
    }

    return null;
  }

  isAuthenticated(): boolean {
    return this.getCurrentToken() !== null;
  }

  removeSession(): void {
    this.session = null;
  }

  async login(email: string, password: string): Promise<Session> {
    let session: Session = null;

    if (environment.useMocks) {
      session = {
        client: new User().deserialize(USER_MOCK),
        clientToken: 'asdasdasdasd',
      };
    } else {
      const url = `${this.apiUrl}/auth/signin`;

      const response: AuthResponse = await this.http
        .post<AuthResponse>(url, { email, password })
        .toPromise();

      const { user, token } = response;

      session = {
        client: new User().deserialize(user),
        clientToken: token,
      };
    }

    this.session = session;
    return session;
  }

  async loginAdmin(email: string, password: string): Promise<User> {
    const url = `${this.apiUrl}/auth/admin/signin`;

    const { token, user } = await this.http
      .post<{ token: string; user: User }>(url, { email, password })
      .toPromise();
    let session = this.loadSessionData();

    if (session) {
      session.adminToken = token;
      session.admin = user;
    } else {
      session = {
        client: null,
        clientToken: null,
        adminToken: token,
        admin: user,
      };
    }

    this.session = session;

    return user;
  }

  async adminSelectUser(user: User): Promise<User> {
    const url = `${this.apiUrl}/auth/user/${user.id}/signin`;

    const { token, user: userAPI } = await this.http
      .post<{ token: string; user: User }>(url, {})
      .toPromise();

    const session = this.loadSessionData();
    session.clientToken = token;
    session.client = userAPI;
    this.session = session;

    return user;
  }

  async recoverPassword(email: string): Promise<void> {
    const url = `${this.apiUrl}/auth/recover-password`;

    await this.http.post(url, { email }).toPromise();
  }

  async resetPassword(data: ResetPasswordI): Promise<void> {
    const url = `${this.apiUrl}/auth/reset-password`;

    await this.http.post(url, data).toPromise();
  }

  removeSelectedUser(): void {
    const session = this.loadSessionData();

    if (session) {
      session.client = null;
      session.clientToken = null;
    }

    this.session = session;
  }

  isAdminLogged(): boolean {
    const session = this.loadSessionData();

    return (
      session && session.adminToken && session.adminToken !== null && session.adminToken !== ''
    );
  }

  logout(): void {
    this.removeSession();
  }
}
