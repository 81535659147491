import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from 'environments/environment';
import {
  Work,
  AlternateTitle,
  OwnershipShare,
  Territory,
  CollectionShare,
  WorkDTO,
  CueSheet,
  CueSheetDTO,
  ShareDTO,
  Share,
  TerritoryDTO,
  Trend,
  TrendDTO,
} from 'app/shared/models';

import {
  WorksResponseAPII,
  Role,
  Sequence,
  WorkFiltersI,
  InfoResponseAPI,
  WorkAPIResponse,
  AlternateTitlesResponse,
  AlternateTitleAPIResponse,
  SequencesAPIResponse,
  RolesAPIResponse,
  OwnershipShareUpdateAPIResponse,
  CollectionShareUpdatedAPIResponse,
  OwnershipSharesAPIResponse,
  OwnershipShareAPIResponse,
  TerritoriesAPIResponse,
  ValidateResponseI,
  WorkRoyaltiesPlatformI,
  WorkRoyaltiesCountryI,
  WorksSearchByTextResponseI,
  WorkSearchByTextResponseAPII,
  PlatformRoyaltiesApiI,
} from 'app/shared/interfaces';

import {
  WORKS_ALL_MOCK,
  ALTERNATE_TITLES_MOCK,
  OWNERSHIPSHARES_MOCK,
  OWNERSHIP_ROLES,
  ALL_ROLES,
  SEQUENCES_MOCK,
  TERRITORIES_MOCK,
  WORKS_ALL_MOCK_2,
  CUE_SHEETS_WORK_MOCK,
} from 'app/shared/mocks';
import { TrendPlayI, TypePlays } from './usage.service';

// import { tap, catchError } from 'rxjs/operators';

export interface WorkUsagesI {
  composition: WorkDTO;
  owners: string;
  plays: string;
}

interface WorkTerritoriesRoyaltiesI {
  earnings: number;
  territory: TerritoryDTO;
}

interface WorkCollectionShares {
  publishers: Array<Share>;
  writers: Array<Share>;
}

export interface TopEarningSong {
  plays: number;
  earnings: number;
  composition: WorkDTO;
}

export interface TopEarningSongsResponse {
  computing: boolean;
  info: InfoResponseAPI;
  works: TopEarningSong[];
}

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class WorkService {
  private readonly apiUrl: string = environment.apiUrl;

  private readonly workSubject = new BehaviorSubject<Work>(null);
  readonly currentWork$ = this.workSubject.asObservable();

  get currentWork(): Work {
    return this.workSubject.getValue();
  }

  set currentWork(work: Work) {
    this.workSubject.next(work);
  }

  constructor(private http: HttpClient) {}

  async getWork(workId: string): Promise<Work> {
    const url = `${this.apiUrl}/composition/${workId}/`;
    let workFetched: Work = null;

    if (environment.useMocks) {
      const work: WorkDTO = WORKS_ALL_MOCK.find((w: WorkDTO) => w.id === workId);
      workFetched = new Work().deserialize(work);
    } else {
      const response: WorkAPIResponse = await this.http
        .get<WorkAPIResponse>(url, httpOptions)
        .toPromise();
      const workAPI = response.composition;
      workFetched = new Work().deserialize(workAPI);
    }
    return workFetched;
  }

  async createWork(work: Work): Promise<Work> {
    const url = `${this.apiUrl}/composition/`;
    const { composition: workApi } = await this.http
      .post<{ composition: WorkDTO }>(url, work, httpOptions)
      .toPromise();

    return new Work().deserialize(workApi);
  }

  async updateWork(workId: string, work: Work): Promise<Work> {
    let workUpdated: Work = null;
    if (environment.useMocks) {
      workUpdated = work;
    } else {
      const url = `${this.apiUrl}/composition/${workId}/`;
      const response: WorkAPIResponse = await this.http
        .put<WorkAPIResponse>(url, work, httpOptions)
        .toPromise();
      const workAPI = response.composition;
      workUpdated = new Work().deserialize(workAPI);
    }
    return workUpdated;
  }

  async getTopEarningWorksFromRightHolder(
    offset: number,
    limit: number,
    timeFilter: string = null,
    typeFilter: string = null,
  ): Promise<TopEarningSongsResponse> {
    let filters = `?offset=${offset}&limit=${limit}`;
    if (timeFilter) {
      filters += `&period=${timeFilter}`;
    }
    if (typeFilter) {
      filters += `&type=${typeFilter}`;
    }

    const url = `${this.apiUrl}/composition/me/earnings/${filters}`;
    const response: TopEarningSongsResponse = await this.http
      .get<TopEarningSongsResponse>(url, httpOptions)
      .toPromise();

    return response;
  }

  async getWorksMostListenedByPlatform(
    offset: number,
    limit: number,
    type: string,
    period: string,
    platformId: string = '',
    platformName: string = '',
  ): Promise<{ workUsagesApi: WorkUsagesI[]; platformName: string; computing: boolean }> {
    const url = `${this.apiUrl}/composition/me/plays`;

    const params = {
      offset: offset ? offset.toString() : '',
      limit: limit ? limit.toString() : '',
      type: type ? type.toString() : '',
      period: period ? period.toString() : '',
      platformId: platformId ? platformId.toString() : '',
    };
    const { works: workUsagesApi, computing } = await this.http
      .get<{ works: WorkUsagesI[]; info: InfoResponseAPI; computing: boolean }>(url, {
        params,
        headers: new HttpHeaders({ 'X-Cancel-Wait': 'true' }),
      })
      .toPromise();

    return { workUsagesApi, platformName, computing };
  }

  async getWorks(
    offset: number,
    limit: number,
    filters?: WorkFiltersI,
  ): Promise<WorksResponseAPII> {
    let worksFetched: Work[];
    let infoResponse: InfoResponseAPI;

    if (environment.useMocks) {
      // Apply filters to mockups
      const allWorks = new Work()
        .deserializeArray(WORKS_ALL_MOCK)
        .filter((work: Work) => {
          if (!filters.text) {
            return true;
          }

          return work.title.includes(filters.text) || work.unisonId.includes(filters.text);
        })
        .filter((work: Work) => (filters.status ? work.status === filters.status : true));

      // Apply pagination on filtered works
      worksFetched = allWorks.slice(offset, offset + limit);

      infoResponse = {
        count: allWorks.length,
        offset: offset.toString(),
        limit: limit.toString(),
      };
    } else {
      let query = `?offset=${offset}&limit=${limit}`;
      if (filters && filters.status) {
        query += `&status=${filters.status}`;
      }
      if (filters && filters.author) {
        query += `&author=${filters.author}`;
      }
      if (filters && filters.title) {
        query += `&title=${filters.title}`;
      }
      if (filters && filters.text) {
        query += `&text=${filters.text}`;
      }
      if (filters && filters.catalog) {
        query += `&catalog=${filters.catalog}`;
      }
      if (filters && filters.unisonId) {
        query += `&unisonId=${filters.unisonId}`;
      }
      if (filters && filters.orderType) {
        query += `&orderType=${filters.orderType}`;
      }
      if (filters && filters.orderField) {
        query += `&orderField=${filters.orderField}`;
      }
      const url = `${this.apiUrl}/composition/me/${query}`;
      const { compositions, info } = await this.http
        .get<{ compositions: WorkDTO[]; info: InfoResponseAPI }>(url, httpOptions)
        .toPromise();

      worksFetched = new Work().deserializeArray(compositions);
      infoResponse = info;
    }

    return {
      works: worksFetched,
      filters,
      info: infoResponse,
    };
  }

  async searchWorksByText(
    offset: number,
    limit: number,
    text: string,
  ): Promise<WorksSearchByTextResponseI> {
    let worksFetched: Work[];
    let infoResponse: InfoResponseAPI;

    if (environment.useMocks) {
      // Apply filters to mockups
      const allWorks = new Work()
        .deserializeArray(WORKS_ALL_MOCK_2.compositions)
        .filter((work: Work) => {
          if (!text) {
            return true;
          }

          return work.title.includes(text) || work.unisonId.includes(text);
        });

      worksFetched = allWorks;

      infoResponse = {
        count: allWorks.length,
        offset: offset.toString(),
        limit: limit.toString(),
      };
    } else {
      let filters = `?offset=${offset}&limit=${limit}`;

      if (text) {
        filters += `&text=${text}`;
      }

      const url = `${this.apiUrl}/composition/me/${filters}`;
      const response: WorkSearchByTextResponseAPII = await this.http
        .get<WorkSearchByTextResponseAPII>(url, httpOptions)
        .toPromise();
      worksFetched = new Work().deserializeArray(response.compositions);

      infoResponse = {
        count: response.info.count,
        offset: response.info.offset,
        limit: response.info.limit,
      };
    }

    return {
      works: worksFetched,
      info: infoResponse,
    };
  }

  async getAlternateTitlesFromWork(workId: string): Promise<Array<AlternateTitle>> {
    let alternateTitlesFetched: AlternateTitle[] = [];

    if (environment.useMocks) {
      alternateTitlesFetched = new AlternateTitle().deserializeArray(ALTERNATE_TITLES_MOCK);
    } else {
      const url = `${this.apiUrl}/composition/${workId}/alternate-title/`;
      const response: AlternateTitlesResponse = await this.http
        .get<AlternateTitlesResponse>(url, httpOptions)
        .toPromise();
      const { alternateTitles } = response;
      alternateTitlesFetched = new AlternateTitle().deserializeArray(alternateTitles);
    }
    return alternateTitlesFetched;
  }

  async addAlternateTitleToWork(
    workId: string,
    alternateTitleToAdd: AlternateTitle,
  ): Promise<AlternateTitle> {
    let alternateTitleAdded: AlternateTitle = null;

    if (environment.useMocks) {
      alternateTitleAdded = new AlternateTitle().deserialize(alternateTitleToAdd);
    } else {
      const url = `${this.apiUrl}/composition/${workId}/alternate-title/`;
      const response: AlternateTitleAPIResponse = await this.http
        .post<AlternateTitleAPIResponse>(url, alternateTitleToAdd, httpOptions)
        .toPromise();
      const { alternateTitle } = response;
      alternateTitleAdded = new AlternateTitle().deserialize(alternateTitle);
    }
    return alternateTitleAdded;
  }

  async deleteAlternateTitleFromWork(workId: string, alternateTitleId: string): Promise<void> {
    const url = `${this.apiUrl}/composition/${workId}/alternate-title/${alternateTitleId}/`;
    await this.http.delete(url).toPromise();
  }

  async editAlternateTitle(workId: string, alternateTitle: AlternateTitle): Promise<void> {
    const url = `${this.apiUrl}/composition/${workId}/alternate-title/${alternateTitle.id}/`;
    await this.http.put(url, alternateTitle, httpOptions).toPromise();
  }

  async createOwnershipShare(
    workId: string,
    ownershipShare: OwnershipShare,
  ): Promise<OwnershipShare> {
    let ownershipShareCreated: OwnershipShare = null;

    if (environment.useMocks) {
      ownershipShareCreated = ownershipShare;
    } else {
      const url = `${this.apiUrl}/composition/${workId}/ownership-share/`;
      const response: OwnershipShareAPIResponse = await this.http
        .post<OwnershipShareAPIResponse>(url, ownershipShare, httpOptions)
        .toPromise();
      ownershipShareCreated = new OwnershipShare().deserialize(response.composition); // API returns composition as OwnershipShare
    }
    return ownershipShareCreated;
  }

  async addOwnershipSharesToWork(
    workId: string,
    ownershipShares: Array<OwnershipShare>,
  ): Promise<Array<OwnershipShare>> {
    const url = `${this.apiUrl}/composition/${workId}/ownership-share/bulk`;
    const response: OwnershipSharesAPIResponse = await this.http
      .post<OwnershipSharesAPIResponse>(url, { ownershipShares }, httpOptions)
      .toPromise();
    return new OwnershipShare().deserializeArray(response.ownershipShares);
  }

  async editOwnershipShare(
    workId: string,
    ownershipShare: OwnershipShare,
  ): Promise<OwnershipShare> {
    let ownershipShareEdited: OwnershipShare = null;

    if (environment.useMocks) {
      ownershipShareEdited = ownershipShare;
    } else {
      const url = `${this.apiUrl}/composition/${workId}/ownership-share/${ownershipShare.id}`;
      const response: OwnershipShareUpdateAPIResponse = await this.http
        .put<OwnershipShareUpdateAPIResponse>(url, ownershipShare)
        .toPromise();
      ownershipShareEdited = new OwnershipShare().deserialize(response.ownershipShare);
    }
    return ownershipShareEdited;
  }

  async deleteOwnershipShareFromWork(workId: string, ownershipShareId: string): Promise<void> {
    const url = `${this.apiUrl}/composition/${workId}/ownership-share/${ownershipShareId}`;
    await this.http.delete(url, httpOptions).toPromise();
  }

  async getOwnershipSharesFromWork(workId: string): Promise<OwnershipShare[]> {
    let ownershipSharesFetched: OwnershipShare[] = [];

    if (environment.useMocks) {
      ownershipSharesFetched = new OwnershipShare().deserializeArray(OWNERSHIPSHARES_MOCK);
    } else {
      const url = `${this.apiUrl}/composition/${workId}/ownership-share/`;
      const customHttpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Cancel-Wait': 'true',
        }),
      };
      const response: OwnershipSharesAPIResponse = await this.http
        .get<OwnershipSharesAPIResponse>(url, customHttpOptions)
        .toPromise();
      const { ownershipShares } = response;
      ownershipSharesFetched = new OwnershipShare().deserializeArray(ownershipShares);
    }
    return ownershipSharesFetched;
  }

  async getOwnershipSharesRoles(): Promise<Role[]> {
    let ownerShipRolesFetched: Role[] = [];
    if (environment.useMocks) {
      ownerShipRolesFetched = OWNERSHIP_ROLES;
    } else {
      const url = `${this.apiUrl}/composition/ownership-share/roles`;
      const response: RolesAPIResponse = await this.http.get<RolesAPIResponse>(url).toPromise();
      ownerShipRolesFetched = response.roles;
    }
    return ownerShipRolesFetched;
  }

  async getCollectionSharesFromWork(workId: string): Promise<WorkCollectionShares> {
    const url = `${this.apiUrl}/composition/${workId}/collection-share/`;
    const { tree } = await this.http
      .get<{ tree: { publishers: Array<ShareDTO>; writers: Array<ShareDTO> } }>(url, httpOptions)
      .toPromise();

    return {
      publishers: new Share().deserializeArray(tree.publishers),
      writers: new Share().deserializeArray(tree.writers),
    };
  }

  async getCollectionSharesRoles(
    parentRole: string,
    sonsRoles: Array<string> = [],
  ): Promise<Role[]> {
    let collectionSharesRolesFetched: Role[] = []; // TODO Unify ROLE interface
    const term = {
      parent: parentRole !== '' ? parentRole : undefined,
      sons: sonsRoles.length === 0 ? undefined : sonsRoles.join(),
    };

    if (environment.useMocks) {
      if (!term.parent || parentRole === undefined) {
        collectionSharesRolesFetched = ALL_ROLES;
      }
      if (['E'].indexOf(term.parent) !== -1) {
        collectionSharesRolesFetched = [
          { code: 'AM', name: 'Administrator' },
          { code: 'SE', name: 'Sub Publisher' },
          // { code: 'AQ', name: 'Acquirer' },
          { code: 'ES', name: 'Substituted Publisher' },
        ];
      } else if (['CA', 'C', 'A', 'AD', 'AR', 'SR', 'SA', 'TR'].indexOf(term.parent) !== -1) {
        collectionSharesRolesFetched = [{ code: 'PWR', name: 'Publisher for Writer' }];
      } else if (['AM', 'AQ'].indexOf(term.parent) !== -1) {
        collectionSharesRolesFetched = [
          { code: 'SE', name: 'Sub Publisher' },
          { code: 'ES', name: 'Substituted Publisher' },
        ];
      } else if (['SE'].indexOf(term.parent) !== -1) {
        collectionSharesRolesFetched = [{ code: 'ES', name: 'Substituted Publisher' }];
      } else if (['PA'].indexOf(term.parent) !== -1) {
        collectionSharesRolesFetched = [
          { code: 'AM', name: 'Administrator' },
          { code: 'SE', name: 'Sub Publisher' },
          // { code: 'AQ', name: 'Acquirer' },
          { code: 'ES', name: 'Substituted Publisher' },
          { code: 'PWR', name: 'Publisher for Writer' },
        ];
      }
    } else {
      const url = `${this.apiUrl}/composition/collection-share/roles`;
      if (term.parent || term.sons) {
        let params = new HttpParams();
        if (term.parent) {
          params = params.append('parent', term.parent);
        }
        if (term.sons) {
          params = params.append('sons', term.sons);
        }
        const response: RolesAPIResponse = await this.http
          .get<RolesAPIResponse>(url, { params })
          .toPromise();
        collectionSharesRolesFetched = response.roles;
      } else {
        const response: RolesAPIResponse = await this.http.get<RolesAPIResponse>(url).toPromise();
        collectionSharesRolesFetched = response.roles;
      }
    }

    return collectionSharesRolesFetched;
  }

  async getSequencesFromWork(id: string): Promise<Sequence[]> {
    let sequencesFetched: Sequence[] = [];
    if (environment.useMocks) {
      sequencesFetched = SEQUENCES_MOCK;
    } else {
      const url = `${this.apiUrl}/composition/${id}/sequence/`;
      const response: SequencesAPIResponse = await this.http
        .get<SequencesAPIResponse>(url, {
          headers: new HttpHeaders({ 'X-Cancel-Wait': 'true' }),
        })
        .toPromise();
      sequencesFetched = response.sequences;
    }
    return sequencesFetched;
  }

  async getSequenceFromWork(workId: string, sequenceId: string): Promise<WorkCollectionShares> {
    const url = `${this.apiUrl}/composition/${workId}/sequence/${sequenceId}`;
    const { sequence } = await this.http
      .get<{ sequence: { publishers: Array<ShareDTO>; writers: Array<ShareDTO> } }>(url)
      .toPromise();

    return {
      publishers: new Share().deserializeArray(sequence.publishers),
      writers: new Share().deserializeArray(sequence.writers),
    };
  }

  async deleteCollectionShareFromWork(id: string, collectionShareId: string): Promise<void> {
    const url = `${this.apiUrl}/composition/${id}/collection-share/${collectionShareId}`;
    await this.http.delete(url).toPromise();
  }

  async deleteShareFromWork(id: string, shareId: string): Promise<void> {
    const url = `${this.apiUrl}/composition/${id}/share/${shareId}`;
    await this.http.delete(url).toPromise();
  }

  async getTerritories(offset = 0, limit = 9999): Promise<Territory[]> {
    let territoriesFetched: Territory[] = [];
    if (environment.useMocks) {
      territoriesFetched = new Territory().deserializeArray(TERRITORIES_MOCK.territories);
    } else {
      const filters = `?offset=${offset}&limit=${limit}`;
      const url = `${this.apiUrl}/territory/${filters}`;
      const response: TerritoriesAPIResponse = await this.http
        .get<TerritoriesAPIResponse>(url)
        .toPromise();
      territoriesFetched = new Territory().deserializeArray(response.territories);
    }
    return territoriesFetched;
  }

  async validateCollectionShare(id: string, collectionShare: CollectionShare): Promise<boolean> {
    let validated: boolean;
    if (environment.useMocks) {
      validated = true;
    } else {
      const url = `${this.apiUrl}/composition/${id}/collection-share/validate`;
      const response: ValidateResponseI = await this.http
        .post<ValidateResponseI>(url, collectionShare, httpOptions)
        .toPromise();
      validated = response.validated;
    }
    return validated;
  }

  async addCollectionShareToWork(id: string, collectionShare: CollectionShare): Promise<void> {
    const url = `${this.apiUrl}/composition/${id}/collection-share/`;
    await this.http.post(url, collectionShare, httpOptions).toPromise();
  }

  async editCollectionShare(
    workId: string,
    collectionShare: CollectionShare,
  ): Promise<CollectionShare> {
    let collectionShareUpdated: CollectionShare;
    if (environment.useMocks) {
      collectionShareUpdated = collectionShare;
    } else {
      const url = `${this.apiUrl}/composition/${workId}/collection-share/${collectionShare.id}`;
      const response: CollectionShareUpdatedAPIResponse = await this.http
        .put<CollectionShareUpdatedAPIResponse>(url, collectionShare)
        .toPromise();
      collectionShareUpdated = new CollectionShare().deserialize(response.collectionShare);
    }
    return collectionShareUpdated;
  }

  async getWorkUsageBySource(
    id: string,
    sourceTypes: string[],
    timeFilter: string = null,
  ): Promise<TypePlays[]> {
    const params = {
      types: sourceTypes ? sourceTypes.join() : '',
      period: timeFilter ? timeFilter.toString() : '',
    };
    const url = `${this.apiUrl}/composition/${id}/plays`;
    const { plays } = await this.http
      .get<{ plays: TypePlays[] }>(url, { params })
      .toPromise();

    return plays;
  }

  async getWorkRoyaltyTrendsBySource(
    id: string,
    sourceTypes: string[],
    timeFilter: string = null,
  ): Promise<Trend[]> {
    const params = {
      types: sourceTypes ? sourceTypes.join() : '',
      period: timeFilter ? timeFilter.toString() : '',
    };

    const url = `${this.apiUrl}/composition/${id}/earnings/trend`;
    const { trend: trendsApi } = await this.http
      .get<{ trend: TrendDTO[] }>(url, { params })
      .toPromise();

    return new Trend().deserializeArray(trendsApi);
  }

  async getWorkPlaysTrendsBySource(
    id: string,
    sourceTypes: string[],
    timeFilter: string = null,
  ): Promise<TrendPlayI[]> {
    const params = {
      types: sourceTypes ? sourceTypes.join() : '',
      period: timeFilter ? timeFilter.toString() : '',
    };

    const url = `${this.apiUrl}/composition/${id}/plays/trend`;
    const { trend: trendsPlaysApi } = await this.http
      .get<{ trend: TrendPlayI[] }>(url, { params })
      .toPromise();

    return trendsPlaysApi;
  }

  async getWorkEarningsByPlatform(
    id: string,
    offset: number,
    limit: number,
    timeFilter: string = null,
    sourceType: string = 'digital',
  ): Promise<{ platforms: WorkRoyaltiesPlatformI[]; info: InfoResponseAPI }> {
    const url = `${this.apiUrl}/composition/${id}/earnings/platform/`;

    const params = {
      offset: offset ? offset.toString() : '',
      limit: limit ? limit.toString() : '',
      period: timeFilter ? timeFilter.toString() : '',
      type: sourceType ? sourceType.toString() : '',
    };
    const { platforms: platformsApi, info } = await this.http
      .get<{ info: InfoResponseAPI; platforms: PlatformRoyaltiesApiI[] }>(url, {
        params,
        headers: new HttpHeaders({ 'X-Cancel-Wait': 'true' }),
      })
      .toPromise();

    const platforms = platformsApi.map(
      (platformApi: PlatformRoyaltiesApiI): WorkRoyaltiesPlatformI => {
        return {
          source: platformApi.platform.name,
          royalties: platformApi.earnings,
        };
      },
    );

    return { platforms, info };
  }

  async getWorkEarningsByCountry(
    id: string,
    offset: number,
    limit: number,
    timeFilter: string = null,
  ): Promise<{ countryRoyalties: WorkRoyaltiesCountryI[]; info: InfoResponseAPI }> {
    const filter = '';
    let countryRoyalties: WorkRoyaltiesCountryI[] = [];
    const params = {
      offset: offset ? offset.toString() : '',
      limit: limit ? limit.toString() : '',
      period: timeFilter ? timeFilter.toString() : '',
    };

    const url = `${this.apiUrl}/composition/${id}/earnings/territory/${filter}`;
    const { info, territories: territoriesApi } = await this.http
      .get<{ info: InfoResponseAPI; territories: WorkTerritoriesRoyaltiesI[] }>(url, {
        params,
        headers: new HttpHeaders({ 'X-Cancel-Wait': 'true' }),
      })
      .toPromise();

    countryRoyalties = territoriesApi.map(
      (wTR: WorkTerritoriesRoyaltiesI): WorkRoyaltiesCountryI => {
        return {
          country: wTR.territory.name,
          royalties: wTR.earnings,
        };
      },
    );

    return { countryRoyalties, info };
  }

  async getCueSheetsFromWork(workId: string): Promise<Array<CueSheet>> {
    let cueSheetsFetched: Array<CueSheet> = [];
    if (environment.useMocks) {
      cueSheetsFetched = new CueSheet().deserializeArray(CUE_SHEETS_WORK_MOCK);
    } else {
      const url = `${this.apiUrl}/composition/${workId}/cue-sheets/`; // TODO: Endpoint
      const response = await this.http
        .get<{ cueSheets: Array<CueSheetDTO> }>(url, httpOptions)
        .toPromise();
      cueSheetsFetched = new CueSheet().deserializeArray(response.cueSheets);
    }
    return cueSheetsFetched;
  }

  downloadSelectedWorksAsCSV(workIds: Array<string>): Observable<any> {
    const url = `${this.apiUrl}/composition/csv`;
    const params = {
      cShares: 'true',
      ids: workIds.join(','),
    };

    return this.http.get(url, {
      observe: 'events', // 'body'
      params,
      responseType: 'blob',
      reportProgress: true,
    });
  }

  downloadSelectedWorksAsCWR(workIds: Array<string>): Observable<any> {
    const url = `${this.apiUrl}/composition/cwr`;
    const params = {
      cShares: 'true',
      ids: workIds.join(','),
    };

    return this.http.get(url, {
      observe: 'events', // 'body'
      params,
      responseType: 'blob',
      reportProgress: true,
    });
  }

  // TODO ::REVIEW handleError and log -> LoggingService if need?

  // private handleError(error: HttpErrorResponse): Observable<any> {
  //   console.error('An error ocurred', error);
  //   return observableThrowError(error);
  // }

  // private log = (message: string) => {
  //   console.log(`CompositionService: ${message}`);
  // };
}
