import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
// SimpleChanges,

declare let jQuery;

export interface ModalInfoInterface {
  title?: string;
  statusText?: string;
  alertMessage?: string;
  currentProgress?: number;
  show?: boolean;
  showCloseButton?: boolean;
  showCancelButton?: boolean;
}

@Component({
  selector: 'app-modal-progress',
  templateUrl: './modal-progress.component.html',
  styleUrls: ['./modal-progress.component.scss'],
})
export class ModalProgressComponent implements OnInit, OnChanges {
  @Input() modalInfo: ModalInfoInterface;
  @Output() cancelAction = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  // ngOnChanges(changes: SimpleChanges): void {
  ngOnChanges(): void {
    if (!this.modalInfo) {
      return;
    }

    if (this.modalInfo.show) {
      this.showModal();
    } else {
      this.hideModal();
    }
  }

  showModal(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    jQuery('#staticBackdrop').modal('show');
  }

  hideModal(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    jQuery('#staticBackdrop').modal('hide');
  }

  emitCancel(): void {
    this.cancelAction.emit(true);
  }

  showFooter(): boolean {
    if (!this.modalInfo) {
      return false;
    }

    return this.modalInfo.showCancelButton || this.modalInfo.showCloseButton;
  }
}
