import { Serializable } from 'app/core/interfaces';

export interface CueSheetDTO {
  id?: string;
  title?: string;
  productionType?: string;
  productionName?: string;
  usedWorks?: number;
}

export class CueSheet implements Serializable<CueSheet> {
  id?: string;
  title?: string;
  productionType?: string;
  productionName?: string;
  usedWorks?: number;

  deserialize(input: CueSheetDTO): CueSheet {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.title = input.title || '';
    this.productionType = input.productionType || '';
    this.productionName = input.productionName || '';
    this.usedWorks = input.usedWorks || 0;

    return this;
  }

  deserializeArray(inputArray: Array<CueSheetDTO>): Array<CueSheet> {
    const cueSheets: Array<CueSheet> = inputArray.map((input) => new CueSheet().deserialize(input));

    return cueSheets;
  }
}
