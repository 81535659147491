import { WorkUsageI } from 'app/shared/interfaces';

export const USAGES_DIGITAL_PLAYS_Y: Array<WorkUsageI> = [
  {
    source: 'Apple Music',
    plays: 300000,
  },
  {
    source: 'Beatport',
    plays: 250000,
  },
  {
    source: 'Others',
    plays: 100000,
  },
  {
    source: 'Deezer',
    plays: 400000,
  },
  {
    source: 'Spotify',
    plays: 1000000,
  },
];

export const USAGES_DIGITAL_PLAYS_QT: Array<WorkUsageI> = [
  {
    source: 'Apple Music',
    plays: 20000,
  },
  {
    source: 'Beatport',
    plays: 10000,
  },
  {
    source: 'Others',
    plays: 15000,
  },
  {
    source: 'Deezer',
    plays: 40000,
  },
  {
    source: 'Spotify',
    plays: 500000,
  },
];

export const USAGES_DIGITAL_PLAYS_M: Array<WorkUsageI> = [
  {
    source: 'Apple Music',
    plays: 10000,
  },
  {
    source: 'Beatport',
    plays: 2000,
  },
  {
    source: 'Others',
    plays: 5000,
  },
  {
    source: 'Deezer',
    plays: 8000,
  },
  {
    source: 'Spotify',
    plays: 15000,
  },
];
