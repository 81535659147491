/* eslint-disable import/no-cycle */
import { Serializable } from 'app/core/interfaces';
import { Share, ShareDTO } from './share';
// import { CollectionShare, CollectionShareDTO } from './collectionShare';
// import { Share, ShareDTO } from './share';

export enum UsageShareStatus {
  LIQUIDATED = 'liquidated',
  PAYMENT_PENDING = 'payment_pending',
  LIQUIDATION_PENDING = 'liquidation_pending',
}

export interface UsageShareDTO {
  id: string;
  catalogId: string;
  shareId: string;
  // share?: ShareDTO;
  collectionShareId: string;
  // collectionShare: CollectionShareDTO;
  compositionUsageId: string;
  split: number;
  status: UsageShareStatus;
  userId: string;
  amount: number;
  createdAt: string;
  updatedAt: string;
  invoiceId: string;
  recordingUsageId: string;
  share: ShareDTO;
}

export class UsageShare implements Serializable<UsageShare> {
  id: string;
  catalogId: string;
  // collectionShare: CollectionShare;
  collectionShareId: string;
  compositionUsageId: string;
  split: number;
  status: UsageShareStatus;
  userId: string;
  amount: number;
  shareId: string;
  // share?: Share;
  createdAt: string;
  updatedAt: string;
  invoiceId: string;
  recordingUsageId: string;
  share: Share;

  deserialize(input: UsageShareDTO): UsageShare {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.status = input.status || UsageShareStatus.LIQUIDATED;
    // this.collectionShare = input.collectionShare
    //   ? new CollectionShare().deserialize(input.collectionShare)
    //   : null;
    this.collectionShareId = input.collectionShareId || '';
    this.compositionUsageId = input.compositionUsageId || '';
    this.catalogId = input.catalogId || '';
    this.split = input.split || 0;
    this.userId = input.userId || '';
    this.amount = input.amount || 0;
    this.shareId = input.shareId || '';
    this.share = input.share ? new Share().deserialize(input.share) : null;
    this.createdAt = input.createdAt || '';
    this.updatedAt = input.updatedAt || '';
    this.invoiceId = input.invoiceId || '';
    this.recordingUsageId = input.recordingUsageId || '';

    return this;
  }

  deserializeArray(inputArray: Array<UsageShareDTO>): Array<UsageShare> {
    const collectionShareLiquidations: Array<UsageShare> = inputArray.map((input) =>
      new UsageShare().deserialize(input),
    );

    return collectionShareLiquidations;
  }

  clone(): UsageShare {
    const clonedInstance: UsageShare = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this,
    );

    return clonedInstance;
  }
}
