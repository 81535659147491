export const SEQUENCES_API_RESPONSE_MOCK = {
  sequence: {
    editors: [
      {
        rightHolder: [
          {
            rightHolderId: '1530c333-8448-4e7a-a78a-ee30a3c26823',
            compositionId: '83723b76-dc71-4d91-b2e5-74432eef9264',
            territoryId: 4,
            parentId: null,
            catalogId: null,
            inclusionExclusionIndicator: 'I',
            performanceSplit: 50,
            mechanicalSplit: 50,
            synchronizationSplit: 0,
            performanceSocietyId: null,
            mechanicalSocietyId: null,
            synchronizationSocietyId: null,
            role: 'E',
            sequenceNumber: 1,
            rightHolderName: 'test',
            submitterAgreementNumber: null,
            societyAssignedAgreementNumber: null,
            id: '8b63fc64-6d24-45e1-87fd-cccb76758b3b',
            rightHolder: {
              name: 'test',
              ipi: '123456',
              userId: null,
              unisonId: 'RK39HQSJ4',
              id: '1530c333-8448-4e7a-a78a-ee30a3c26823',
            },
          },
        ],
        sons: [],
      },
      {
        rightHolder: [
          {
            rightHolderId: '1c4a3e69-03d8-4103-bb75-ad745ff41aa0',
            compositionId: '83723b76-dc71-4d91-b2e5-74432eef9264',
            territoryId: 2136,
            parentId: null,
            catalogId: 'da574ef3-7c7a-4998-9020-faa29e7b2300',
            inclusionExclusionIndicator: 'I',
            performanceSplit: 25,
            mechanicalSplit: 25,
            synchronizationSplit: 25,
            performanceSocietyId: null,
            mechanicalSocietyId: null,
            synchronizationSocietyId: null,
            role: 'E',
            sequenceNumber: 1,
            rightHolderName: 'Trance school',
            submitterAgreementNumber: null,
            societyAssignedAgreementNumber: null,
            id: '0f3ac318-b993-4889-ad5a-3ef58afa0987',
            rightHolder: {
              name: 'Trance school',
              ipi: '599540102',
              userId: '2185f474-be05-46cb-a45e-dd267f195830',
              unisonId: 'R0QL0RQI3',
              id: '1c4a3e69-03d8-4103-bb75-ad745ff41aa0',
              user: {
                id: '2185f474-be05-46cb-a45e-dd267f195830',
                email: 'support@tranceschool.com',
                firstName: 'Trance',
                lastName: 'School',
                businessName: 'Trance School SL',
                role: 'right_holder',
                nif: null,
                address: null,
                city: null,
                postalCode: null,
                country: null,
                stripeAccountId: null,
                commissionDigital: 10,
                commissionRadioTV: 10,
                commissionLive: 9,
                commissionBackground: 20,
                password: '$2b$10$Z3t4VWtjPeH4peAOZKWmFO/DrTKnwYpdiO8sZ1/u9eOLgwO4y4eXi',
                status: 'active',
                token: null,
                createdAt: '2020-08-16T10:22:53.724Z',
                updatedAt: '2020-08-16T10:22:53.724Z',
                financialAdminId: null,
              },
            },
          },
        ],
        sons: [
          {
            rightHolder: [
              {
                rightHolderId: '44cebd06-a674-42ab-af98-48f67503d5f1',
                compositionId: '83723b76-dc71-4d91-b2e5-74432eef9264',
                territoryId: 2136,
                parentId: '0f3ac318-b993-4889-ad5a-3ef58afa0987',
                catalogId: null,
                inclusionExclusionIndicator: 'I',
                performanceSplit: 75,
                mechanicalSplit: 75,
                synchronizationSplit: 0,
                performanceSocietyId: null,
                mechanicalSocietyId: null,
                synchronizationSocietyId: null,
                role: 'AM',
                sequenceNumber: 1,
                rightHolderName: 'Raw talent publisher',
                submitterAgreementNumber: null,
                societyAssignedAgreementNumber: null,
                id: '7bae68a8-e6bf-4ee0-9fa9-273871122178',
                rightHolder: {
                  name: 'Raw talent publisher',
                  ipi: '521982334',
                  userId: null,
                  unisonId: 'R30IHDTHX',
                  id: '44cebd06-a674-42ab-af98-48f67503d5f1',
                },
              },
            ],
            sons: [],
          },
        ],
      },
    ],
    writers: [
      {
        rightHolder: [
          {
            rightHolderId: 'ae738506-1d90-4e12-a1dc-b063c7c6167b',
            compositionId: '83723b76-dc71-4d91-b2e5-74432eef9264',
            territoryId: 2136,
            parentId: null,
            catalogId: null,
            inclusionExclusionIndicator: 'I',
            performanceSplit: 0,
            mechanicalSplit: 0,
            synchronizationSplit: 25,
            performanceSocietyId: null,
            mechanicalSocietyId: null,
            synchronizationSocietyId: null,
            role: 'CA',
            sequenceNumber: 1,
            rightHolderName: 'Derek Andrade',
            submitterAgreementNumber: null,
            societyAssignedAgreementNumber: null,
            id: '725f364d-45f3-40cb-aee6-cdbf1f8bdb62',
            rightHolder: {
              name: 'Derek Andrade',
              ipi: '355932144',
              userId: null,
              unisonId: 'RMNI64ULS',
              id: 'ae738506-1d90-4e12-a1dc-b063c7c6167b',
            },
          },
        ],
        sons: [],
      },
      {
        rightHolder: [
          {
            rightHolderId: '444a29e4-63aa-4b8f-a749-acaf36000490',
            compositionId: '83723b76-dc71-4d91-b2e5-74432eef9264',
            territoryId: 2136,
            parentId: null,
            catalogId: null,
            inclusionExclusionIndicator: 'I',
            performanceSplit: 0,
            mechanicalSplit: 0,
            synchronizationSplit: 50,
            performanceSocietyId: null,
            mechanicalSocietyId: null,
            synchronizationSocietyId: null,
            role: 'CA',
            sequenceNumber: 1,
            rightHolderName: 'LOUD',
            submitterAgreementNumber: null,
            societyAssignedAgreementNumber: null,
            id: 'bd9b40f4-57a2-44d5-8a16-f363aaf606fd',
            rightHolder: {
              name: 'LOUD',
              ipi: '955938923',
              userId: null,
              unisonId: 'RM5LZ3D7G',
              id: '444a29e4-63aa-4b8f-a749-acaf36000490',
            },
          },
        ],
        sons: [],
      },
    ],
  },
};
