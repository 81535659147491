import { Serializable } from 'app/core/interfaces';

// eslint-disable-next-line import/no-cycle
import { OwnershipShare, OwnershipShareDTO } from './ownershipShare';

export interface EarningWorkDTO {
  id?: string;
  unisonId?: string;
  title?: string;
  earnings?: Array<number>;

  ownershipShares?: OwnershipShareDTO[];
}

export class EarningWork implements Serializable<EarningWork> {
  id?: string;
  unisonId?: string;
  title?: string;
  earnings?: Array<number>;

  ownershipShares?: OwnershipShare[];

  deserialize(input: EarningWorkDTO): EarningWork {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.unisonId = input.unisonId || '';
    this.title = input.title || '';
    this.earnings = input.earnings || [];

    if (input.ownershipShares) {
      this.ownershipShares = new OwnershipShare().deserializeArray(input.ownershipShares);
    } else {
      this.ownershipShares = null;
    }

    return this;
  }

  deserializeArray(inputArray: Array<EarningWorkDTO>): Array<EarningWork> {
    const earningWorks: Array<EarningWork> = inputArray.map((input) =>
      new EarningWork().deserialize(input),
    );

    return earningWorks;
  }
}
