import { NavItemI } from 'app/core/interfaces';

import { environment } from 'environments/environment';

export const navigation: Array<NavItemI> = [
  // Admin nav items
  {
    name: 'Home',
    url: '/home',
  },
  {
    name: 'Works',
    url: '/works',
    children: [
      {
        name: 'Repertoire',
        url: '/works/repertoire',
      },
      {
        name: 'Catalogs',
        url: '/works/catalogs',
      },
    ],
  },
  {
    name: 'Music Usage',
    url: '/music-usage',
    children: [
      {
        name: 'Summary',
        url: '/music-usage/summary',
      },
      {
        name: 'TV - Radio',
        url: '/music-usage/tv-radio',
      },
      {
        name: 'Background',
        url: '/music-usage/background',
      },
      {
        name: 'Live',
        url: '/music-usage/live',
      },
      {
        name: 'Digital',
        url: '/music-usage/digital',
      },
    ],
  },
  {
    name: 'Earnings',
    url: '/earnings',
    children: [
      {
        name: 'Royalties',
        url: '/earnings/royalties',
      },
      {
        name: 'Statements',
        url: '/earnings/statements',
      },
    ],
  },
  {
    name: 'Uses of Works',
    url: '/uses-of-works',
    children: [
      {
        name: 'List of usage reports',
        url: '/uses-of-works/list-of-usage-reports',
      },
      {
        name: 'Report use',
        url: '/uses-of-works/report-use',
        hidden: true,
      },
    ],
  },
  {
    name: 'Help Tickets',
    url: '/help/tickets',
  },
  {
    name: 'Test',
    url: '/test',
    hidden: !environment.development,
  },
];
