import { TicketsResponseAPII } from 'app/shared/interfaces';

// export const TICKETS_MOCK: Array<TicketDTO> = [
//   // {
//   //   id: 'TKVM222GGL5N',
//   //   unisonId: 'TKVM222GGL5N',
//   //   status: 'cerrado',
//   //   topic: 'Splits error',
//   //   description: 'Collection shares splits are',
//   //   compositionId: '',
//   //   updatedAt: '2020-07-23T11:47:02.253Z',
//   //   createdAt: '2020-07-23T11:47:02.253Z',
//   //   composition:
//   //   {
//   //     id: '34df-dfs3-4598-ewrt',
//   //     unisonId: '34df-dfs3-4598-ewrt',
//   //     title: 'FLEEING CHANCES',
//   //   },
//   // },
//   // {
//   //   id: 'TKVM123XXL5N',
//   //   unisonId: 'TKVM123XXL5N',
//   //   status: 'abierto',
//   //   topic: 'Collection shares',
//   //   description: 'Collection shares splits are',
//   //   compositionId: '',
//   //   updatedAt: '2020-05-23T11:47:02.253Z',
//   //   createdAt: '2020-05-23T11:47:02.253Z',
//   //   composition:
//   //   {
//   //     id: '34df-dfs3-4598-ewrt',
//   //     unisonId: '34df-dfs3-4598-ewrt',
//   //     title: 'REMEMBER MY STEPS',
//   //   },
//   // },
//   // {
//   //   id: 'TKVM567YYL3N',
//   //   unisonId: 'TKVM567YYL3N',
//   //   status: 'abierto',
//   //   topic: 'OwnerShip shares',
//   //   description: 'Collection shares splits are',
//   //   compositionId: '',
//   //   updatedAt: '2020-06-23T11:47:02.253Z',
//   //   createdAt: '2020-06-23T11:47:02.253Z',
//   //   composition:
//   //   {
//   //     id: '34df-dfs3-4598-ewrt',
//   //     unisonId: '34df-dfs3-4598-ewrt',
//   //     title: 'CHANCES FLEEING',
//   //   },
//   // },
//   // {
//   //   id: 'XAVM999YYL1L',
//   //   unisonId: 'XAVM999YYL1L',
//   //   status: 'cerrado',
//   //   topic: 'Splits error',
//   //   description: 'Collection shares splits are',
//   //   compositionId: '',
//   //   updatedAt: '2020-02-23T11:47:02.253Z',
//   //   createdAt: '2020-02-23T11:47:02.253Z',
//   //   composition:
//   //   {
//   //     id: '34df-dfs3-4598-ewrt',
//   //     unisonId: '34df-dfs3-4598-ewrt',
//   //     title: 'REMEMBER MY VOICE',
//   //   },
//   // },
// ];

export const TICKETS_MOCK_ME: TicketsResponseAPII = {
  tickets: [
    {
      topic: 'Collection shares',
      description: 'Collection shares splits are incorrect',
      status: 'closed',
      id: '54635633-f41e-47da-94f9-cb6d8916da04',
      unisonId: 'TK1X135C456B',
      compositionId: '45edb24c-a6ab-11e8-98d0-529269fb1459',
      createdAt: '2020-06-25T10:43:20.380Z',
      updatedAt: '2020-06-25T10:43:20.380Z',
      composition: {
        id: '45edb24c-a6ab-11e8-98d0-529269fb1459',
        unisonId: 'CO1V029Q184C',
        title: 'FLEEING CHANCES',
        iswc: 'T2649413281',
        type: 'MTX',
        versionType: 'ORI',
        status: 'ACCEPTED',
        musicArrangement: null,
        lyricAdaptation: null,
        copyrightDate: '2020-06-25T10:43:18.354Z',
        submitterWorkN: null,
        copyrightNumber: null,
        musicalWorkDistributionCategory: null,
        duration: null,
        recordedIndicator: null,
        compositeType: null,
        excerptType: null,
        contactName: null,
        contactId: null,
        workType: null,
        grandRightsIndicator: null,
        compositeComponentCount: null,
        datePublicationPrintedEdition: null,
        exceptionalClause: null,
        opusNumber: null,
        catalogueNumber: null,
        priorityFlag: null,
        createdAt: '2020-06-25T10:43:20.378Z',
        updatedAt: '2020-06-25T10:43:20.378Z',
        createdBy: null,
        language: 'EN',
      },
      messages: [
        {
          id: 'a593c0e6-dd49-4562-beca-0473e7b0e189',
          text: 'It is solved now.',
          createdAt: '2020-06-25T10:50:20.383Z',
          updatedAt: '2020-06-25T10:50:20.383Z',
          ticketId: '54635633-f41e-47da-94f9-cb6d8916da04',
          rightHolderId: '775673d3-b0d8-4ec8-a14a-58e0fdf7ae9d',
          rightHolder: {
            id: '775673d3-b0d8-4ec8-a14a-58e0fdf7ae9d',
            name: 'Administrator from unison',
            ipi: 856292576,
            unisonId: 'RA548VCJ4',
            createdAt: '2020-06-25T10:43:20.375Z',
            updatedAt: '2020-06-25T10:43:20.375Z',
            userId: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
            performanceSocietyId: null,
            mechanicalSocietyId: null,
            synchronizationSocietyId: null,
          },
        },
        {
          id: '3d5473b0-9925-4a16-be60-72675080ea6f',
          text: 'I think it happened when i tried to upload my CWR.',
          createdAt: '2020-06-25T10:30:20.383Z',
          updatedAt: '2020-06-25T10:30:20.383Z',
          ticketId: '54635633-f41e-47da-94f9-cb6d8916da04',
          rightHolderId: '1c4a3e69-03d8-4103-bb75-ad745ff41aa0',
          rightHolder: {
            id: '1c4a3e69-03d8-4103-bb75-ad745ff41aa0',
            name: 'Trance school',
            ipi: 599540102,
            unisonId: 'R0QL0RQI3',
            createdAt: '2020-06-25T10:43:20.375Z',
            updatedAt: '2020-09-07T14:07:11.105Z',
            userId: '2185f474-be05-46cb-a45e-dd267f195830',
            performanceSocietyId: 'd01433ad-631b-413f-b1c6-0b215a0621d0',
            mechanicalSocietyId: 'd01433ad-631b-413f-b1c6-0b215a0621d0',
            synchronizationSocietyId: 'd01433ad-631b-413f-b1c6-0b215a0621d0',
          },
        },
        {
          id: 'dc11812e-b61c-4513-8866-337f48743f6c',
          text: 'What is this conflict about? How can we fix it? Thanks',
          createdAt: '2020-06-25T10:00:20.382Z',
          updatedAt: '2020-06-25T10:00:20.382Z',
          ticketId: '54635633-f41e-47da-94f9-cb6d8916da04',
          rightHolderId: 'e7dfb2c2-9681-4388-8622-8c6d1d505d57',
          rightHolder: {
            id: 'e7dfb2c2-9681-4388-8622-8c6d1d505d57',
            name: 'JGrau productions',
            ipi: 244969234,
            unisonId: 'RJXUFQGNU',
            createdAt: '2020-06-25T10:43:20.375Z',
            updatedAt: '2020-06-25T10:43:20.375Z',
            userId: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
            performanceSocietyId: null,
            mechanicalSocietyId: null,
            synchronizationSocietyId: null,
          },
        },
      ],
    },
    {
      topic: 'Ownership shares',
      description: 'Ownership shares splits are incorrect',
      status: 'open',
      id: 'e3280282-801d-4125-9b3c-dc684dcab2be',
      unisonId: 'TKVM222GGL5N',
      compositionId: '8d604d57-28a9-4618-8f80-cd3aaba4185a',
      createdAt: '2020-06-25T10:43:20.380Z',
      updatedAt: '2020-06-25T10:43:20.380Z',
      composition: {
        id: '8d604d57-28a9-4618-8f80-cd3aaba4185a',
        unisonId: 'CO897V8978UC',
        title: 'REMEMBER MY STEPS',
        iswc: 'T7469544981',
        type: 'MTX',
        versionType: 'ORI',
        status: 'CONFLICT',
        musicArrangement: null,
        lyricAdaptation: null,
        copyrightDate: '2020-06-25T10:43:18.354Z',
        submitterWorkN: null,
        copyrightNumber: null,
        musicalWorkDistributionCategory: null,
        duration: null,
        recordedIndicator: null,
        compositeType: null,
        excerptType: null,
        contactName: null,
        contactId: null,
        workType: null,
        grandRightsIndicator: null,
        compositeComponentCount: null,
        datePublicationPrintedEdition: null,
        exceptionalClause: null,
        opusNumber: null,
        catalogueNumber: null,
        priorityFlag: null,
        createdAt: '2020-06-25T10:43:20.378Z',
        updatedAt: '2020-06-25T10:43:20.378Z',
        createdBy: null,
        language: 'EN',
      },
      messages: [],
    },
    {
      topic: 'Collection shares',
      description: 'Collection shares splits are incorrect',
      status: 'open',
      id: '01cad226-71d8-4e8a-b2a0-c39fe35e2e88',
      unisonId: 'TKRMR2JC85CU',
      compositionId: 'c9390921-983b-4683-9eb5-26b0bea0c00a',
      createdAt: '2020-06-30T19:48:14.457Z',
      updatedAt: '2020-06-30T19:48:14.457Z',
      composition: {
        id: 'c9390921-983b-4683-9eb5-26b0bea0c00a',
        unisonId: 'COQZ9G2VKSCF',
        title: 'TEST',
        iswc: null,
        type: 'MUS',
        versionType: 'ORI',
        status: 'CONFLICT',
        musicArrangement: null,
        lyricAdaptation: null,
        copyrightDate: '2020-06-30T19:43:49.999Z',
        submitterWorkN: null,
        copyrightNumber: null,
        musicalWorkDistributionCategory: null,
        duration: null,
        recordedIndicator: null,
        compositeType: null,
        excerptType: null,
        contactName: null,
        contactId: null,
        workType: null,
        grandRightsIndicator: null,
        compositeComponentCount: null,
        datePublicationPrintedEdition: null,
        exceptionalClause: null,
        opusNumber: null,
        catalogueNumber: null,
        priorityFlag: null,
        createdAt: '2020-06-30T19:43:50.006Z',
        updatedAt: '2020-06-30T19:48:15.275Z',
        createdBy: 'f823067c-43e4-4a3b-bcec-7648b3818abd',
        language: null,
      },
      messages: [],
    },
  ],
  info: {
    count: 3,
    offset: '0',
    limit: '10',
  },
};
