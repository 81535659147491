import { Serializable } from 'app/core/interfaces';

import { RightHolder, RightHolderDTO } from './rightHolder';
// eslint-disable-next-line import/no-cycle
import { Work, WorkDTO } from './work';

export interface UsageReportDTO {
  id?: string;
  name?: string;
  useType?: string;
  rightHolders?: RightHolderDTO[];
  usedWorks?: number;

  composition?: WorkDTO;
  compositionId?: string;
  productionReportId?: string;
  recordingId?: string;
  sequenceNumber?: number;
  status?: string;
  timeIn?: string;
  timeOut?: string;
  duration?: number;
  type?: string;

  createdAd?: string;
  updatedAt?: string;
}

export class UsageReport implements Serializable<UsageReport> {
  id?: string;
  name?: string;
  useType?: string;
  rightHolders?: RightHolder[];
  usedWorks?: number;

  work?: Work;
  compositionId?: string;
  productionReportId?: string;
  recordingId?: string;
  sequenceNumber?: number;
  status?: string;
  timeIn?: string;
  timeOut?: string;
  duration?: number;
  type?: string;

  createdAd?: string;
  updatedAt?: string;

  deserialize(input: UsageReportDTO): UsageReport {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.name = input.name || '';
    this.useType = input.useType || '';
    this.rightHolders = input.rightHolders
      ? new RightHolder().deserializeArray(input.rightHolders)
      : [];
    this.usedWorks = input.usedWorks || 0;

    this.work = input.composition ? new Work().deserialize(input.composition) : null;

    this.compositionId = input.compositionId || '';
    this.productionReportId = input.productionReportId || '';
    this.recordingId = input.recordingId || '';
    this.sequenceNumber = input.sequenceNumber || 0;
    this.status = input.status || '';
    this.timeIn = input.timeIn || '';
    this.timeOut = input.timeOut || '';
    this.duration = input.duration || 0;
    this.type = input.type || '';

    this.createdAd = input.createdAd || '';
    this.updatedAt = input.updatedAt || '';

    return this;
  }

  deserializeArray(inputArray: Array<UsageReportDTO>): Array<UsageReport> {
    const usageReports: Array<UsageReport> = inputArray.map((input) =>
      new UsageReport().deserialize(input),
    );

    return usageReports;
  }

  clone(): UsageReport {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const clonedInstance: UsageReport = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this,
    );

    return clonedInstance;
  }
}
