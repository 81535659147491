import { Serializable } from 'app/core/interfaces';
// eslint-disable-next-line import/no-cycle
import {
  CollectionShareLiquidation,
  CollectionShareLiquidationDTO,
} from './collectionShareLiquidation';
// eslint-disable-next-line import/no-cycle
import { Statement, StatementDTO } from './statement';

export interface CatalogLiquidationDTO {
  id: string;
  catalogId: string;
  amount: number;
  shares: number;
  name: string; // catalog name
  role: string;
  clientStatus: string;
  unisonStatus: string;
  dateFrom: string;
  dateTo: string;
  clientNumberId: number; // Codi de l'statement de client -> unison
  clientPrefixId: string; // Prefix de l'statement de client -> unison
  unisonNumberId: number; // Prefix de l'statement de unison -> client
  unisonPrefixId: string; // Prefix de l'statement de unison -> client
  collectionShareLiquidations: Array<CollectionShareLiquidationDTO>;

  clientStatement: StatementDTO;
}

export class CatalogLiquidation implements Serializable<CatalogLiquidation> {
  id: string;
  catalogId: string;
  amount: number;
  shares: number;
  name: string; // catalog name
  role: string;
  clientStatus: string;
  unisonStatus: string;
  dateFrom: string;
  dateTo: string;
  clientNumberId: number; // Codi de l'statement de client -> unison
  clientPrefixId: string; // Prefix de l'statement de client -> unison
  unisonNumberId: number; // Prefix de l'statement de unison -> client
  unisonPrefixId: string; // Prefix de l'statement de unison -> client
  collectionShareLiquidations: Array<CollectionShareLiquidation>;
  clientStatement: Statement;

  deserialize(input: CatalogLiquidationDTO): CatalogLiquidation {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.catalogId = input.catalogId || '';
    this.amount = input.amount || 0;
    this.shares = input.shares || 0;
    this.name = input.name || '';
    this.role = input.role || '';
    this.clientStatus = input.clientStatus || '';
    this.unisonStatus = input.unisonStatus || '';
    this.dateFrom = input.dateFrom || '';
    this.dateTo = input.dateTo || '';
    this.clientNumberId = input.clientNumberId || 0;
    this.clientPrefixId = input.clientPrefixId || '';
    this.unisonNumberId = input.unisonNumberId || 0;
    this.unisonPrefixId = input.unisonPrefixId || '';
    this.collectionShareLiquidations = input.collectionShareLiquidations
      ? new CollectionShareLiquidation().deserializeArray(input.collectionShareLiquidations)
      : [];

    this.clientStatement = input.clientStatement
      ? new Statement().deserialize(input.clientStatement)
      : null;

    return this;
  }

  deserializeArray(inputArray: Array<CatalogLiquidationDTO>): Array<CatalogLiquidation> {
    const catalogLiquidations: Array<CatalogLiquidation> = inputArray.map((input) =>
      new CatalogLiquidation().deserialize(input),
    );

    return catalogLiquidations;
  }

  clone(): CatalogLiquidation {
    const clonedInstance: CatalogLiquidation = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this,
    );

    return clonedInstance;
  }
}
