import { WorkDTO } from 'app/shared/models';

export const WORKS_USAGES_LIVES_MOCK_Y: Array<WorkDTO> = [
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 85000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 75000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
];
export const WORKS_USAGES_LIVES_MOCK_M: Array<WorkDTO> = [
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 5000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 4000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 3500,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 2000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
];
export const WORKS_USAGES_LIVES_MOCK_QT: Array<WorkDTO> = [
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 15000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 10000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 8000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 5000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
];

export const WORKS_USAGES_LIVE_MOCK: Array<WorkDTO> = [
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 85000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 75000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Streaming',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    city: 'Barcelona',
    date: new Date('2016-02-04 18:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    city: 'Barcelona',
    date: new Date('2019-03-05 19:44:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    city: 'Barcelona',
    date: new Date('2020-01-04 17:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    city: 'Barcelona',
    date: new Date('2020-04-04 20:33:41'),
    event: 'Event name',
    venue: 'Venue name',
    useType: 'Live',
  },
];
