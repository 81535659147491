/* eslint-disable import/no-cycle */
import { Serializable } from 'app/core/interfaces';
import { WorkUsage, WorkUsageDTO } from './workUsage';
import { Consumer, ConsumerDTO } from './consumer';
import { RecordingUsage, RecordingUsageDTO } from './recordingUsage';
import { Statement, StatementDTO } from './statement';

export class ClaimDTO {
  id: string;
  updatedAt: string;
  createdAt: string;
  consumer: ConsumerDTO;
  dateFrom: string;
  dateTo: string;
  consumerId: string;
  statement: StatementDTO;
  statementId: string;
  recordingUsages: RecordingUsageDTO[];
  compositionUsages: WorkUsageDTO[];
  workUsages: WorkUsageDTO[];
  totalRecordingUsages: number;
  totalCompositionUsages: number;
}

export class Claim implements Serializable<Claim> {
  id: string;
  updatedAt: string;
  createdAt: string;
  consumer: Consumer;
  dateFrom: string;
  dateTo: string;
  consumerId: string;
  statement: Statement;
  statementId: string;
  recordingUsages: RecordingUsage[];
  workUsages: WorkUsage[];
  totalRecordingUsages: number;
  totalCompositionUsages: number;

  deserialize(input: ClaimDTO): Claim {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.updatedAt = input.updatedAt || '';
    this.createdAt = input.createdAt || '';
    this.dateFrom = input.dateFrom || '';
    this.dateTo = input.dateTo || '';
    this.consumerId = input.consumerId || '';
    this.totalRecordingUsages = input.totalRecordingUsages || 0;
    this.totalCompositionUsages = input.totalCompositionUsages || 0;
    this.consumer = new Consumer().deserialize(input.consumer);
    this.statement = new Statement().deserialize(input.statement);
    this.statementId = input.statementId || '';
    this.recordingUsages = new RecordingUsage().deserializeArray(input.recordingUsages);

    if (input.compositionUsages) {
      this.workUsages = new WorkUsage().deserializeArray(input.compositionUsages);
    }

    if (input.workUsages) {
      this.workUsages = new WorkUsage().deserializeArray(input.workUsages);
    }

    return this;
  }

  deserializeArray(inputArray: Array<ClaimDTO>): Array<Claim> {
    const workUsages: Claim[] = inputArray.map((input) => new Claim().deserialize(input));

    return workUsages;
  }

  clone(): Claim {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const clonedInstance: Claim = Object.assign(Object.create(Object.getPrototypeOf(this)), this);

    return clonedInstance;
  }
}
