import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'environments/environment';
import { InfoResponseAPI, PlatformPlaysI } from 'app/shared/interfaces';
import { ConsumerDTO, Platform } from '../models';

export interface PlatformResponse {
  platforms: Array<Platform>;
  info: InfoResponseAPI;
}

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  async getUserPlatformsByPlays(
    offset: number,
    limit: number,
    period: string = '',
    type: string = '',
  ): Promise<{ platformPlays: PlatformPlaysI[]; computing: boolean }> {
    let filters = `?offset=${offset}&limit=${limit}`;

    if (period !== '') {
      filters += `&period=${period}`;
    }
    if (type !== '') {
      filters += `&type=${type}`;
    }

    const url = `${this.apiUrl}/platform/me/plays/${filters}`;

    const { platforms: platformsApi, computing } = await this.http
      .get<{ platforms: PlatformPlaysI[]; info: InfoResponseAPI; computing: boolean }>(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Cancel-Wait': 'true',
        }),
      })
      .toPromise();

    return { platformPlays: platformsApi, computing };
  }

  async getPlatforms(
    offset: number = 0,
    limit: number = 9999,
    text: string = '',
  ): Promise<PlatformResponse> {
    const filters = `?offset=${offset}&limit=${limit}&text=${text}`;

    const url = `${this.apiUrl}/platform/${filters}`;

    const { platforms: platformsApi, info } = await this.http
      .get<{ platforms: Array<ConsumerDTO>; info: InfoResponseAPI }>(url)
      .toPromise();

    return {
      platforms: new Platform().deserializeArray(platformsApi),
      info,
    };
  }
}
