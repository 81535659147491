import { Serializable } from 'app/core/interfaces';

export interface AlternateTitleDTO {
  id?: string;
  name?: string;
  // work: WorkAPI;
  compositionId?: string;
  type?: string;
  language?: string;
  updatedAt?: string;
  createdAt?: string;
}
export class AlternateTitle implements Serializable<AlternateTitle> {
  id: string;
  name: string;
  // work: Work;
  workId: string;
  type: string;
  language: string;
  updatedAt: string;
  createdAt: string;

  deserialize(input: AlternateTitleDTO): AlternateTitle {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.name = input.name || '';
    // this.work = new Work().deserialize(input.work) || null;
    this.workId = input.compositionId || '';
    this.type = input.type || '';
    this.language = input.language || '';
    this.updatedAt = input.updatedAt || '';
    this.createdAt = input.createdAt || '';

    return this;
  }

  deserializeArray(inputArray: Array<AlternateTitleDTO>): Array<AlternateTitle> {
    const alternateTitles: AlternateTitle[] = inputArray.map((input) =>
      new AlternateTitle().deserialize(input),
    );

    return alternateTitles;
  }
}
