import { PlaysVsDownloadResponseDataI } from 'app/shared/interfaces';

export const PLAYS_VS_DOWNLOAD_DIGITAL_Y: PlaysVsDownloadResponseDataI = {
  period: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  data: [
    {
      data: [4000, 2000, 2500, 3800, 3000, 3500, 4500, 5000, 3600, 2000, 2400, 4600],
      type: 'Plays',
    },
    {
      data: [4400, 3000, 3000, 3400, 3600, 3100, 2500, 3500, 4000, 2900, 2600, 4100],
      type: 'Downloads',
    },
  ],
};

export const PLAYS_VS_DOWNLOAD_DIGITAL_QT: PlaysVsDownloadResponseDataI = {
  period: ['Jul', 'Aug', 'Sep', 'Oct'],
  data: [
    {
      data: [400, 200, 250, 380],
      type: 'Plays',
    },
    {
      data: [440, 300, 300, 340],
      type: 'Downloads',
    },
  ],
};

export const PLAYS_VS_DOWNLOAD_DIGITAL_M: PlaysVsDownloadResponseDataI = {
  period: ['Oct'],
  data: [
    {
      data: [100],
      type: 'Plays',
    },
    {
      data: [150],
      type: 'Downloads',
    },
  ],
};
