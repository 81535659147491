export const environment = {
  production: false,
  demo: true,
  staging: false,
  development: false,
  maintenance: false,
  maintenanceAllowAdmin: true,
  useMocks: false,
  sessionKey: 'UNISON_SESSION',
  apiUrl: 'https://api.demo.unisonrights.online/api',
  resetApiUrl: 'https://api.demo.unisonrights.online',
  sentry: {
    dsn: 'https://dfd416f957dd406fb3dbfcec73bbe24b@o64931.ingest.sentry.io/5255051',
    tracesSampleRate: 0,
    tracingOrigins: ['https://demo.unisonrights.online'],
  },
};
