import { CatalogDTO } from 'app/shared/models';

export const CATALOGS_USER_MOCK: CatalogDTO[] = [
  {
    id: 'asdaf-asdaf-asfsdagg-drhtwrh',
    name: 'Catalog 1',
    role: 'SE',
    collectingDate: '2020-08-16T10:22:53.779Z',
    agreementType: 'General Agreement',
    status: 'active',
    rightHolders: [
      {
        name: 'Administrator from unison',
        ipi: 856292576,
        userId: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
        unisonId: 'RA548VCJ4',
        id: '775673d3-b0d8-4ec8-a14a-58e0fdf7ae9d',
        user: {
          id: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
          email: 'dev@nakima.es',
          firstName: 'Administrator',
          lastName: 'from nakima',
          businessName: null,
          role: 'admin',
          nif: null,
          address: null,
          city: null,
          postalCode: null,
          country: null,
          stripeAccountId: null,
          commissionDigital: 10,
          commissionRadioTV: 10,
          commissionLive: 9,
          commissionBackground: 20,
          status: 'active',
          financialAdminId: null,
        },
      },
    ],
  },
  {
    id: 'asdaf-asdaf-asfsdagg-dfsaf',
    name: 'Catalog 2',
    role: 'SE',
    collectingDate: '2020-08-16T10:22:53.779Z',
    agreementType: 'General Agreement',
    status: 'active',
    rightHolders: [
      {
        name: 'Administrator from unison',
        ipi: 856292576,
        userId: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
        unisonId: 'RA548VCJ4',
        id: '775673d3-b0d8-4ec8-a14a-58e0fdf7ae9d',
        user: {
          id: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
          email: 'dev@nakima.es',
          firstName: 'Administrator',
          lastName: 'from nakima',
          businessName: null,
          role: 'admin',
          nif: null,
          address: null,
          city: null,
          postalCode: null,
          country: null,
          stripeAccountId: null,
          commissionDigital: 10,
          commissionRadioTV: 10,
          commissionLive: 9,
          commissionBackground: 20,
          status: 'active',
          financialAdminId: null,
        },
      },
    ],
  },
  {
    id: 'asdaf-asdaf-asfsdagg-dfsasadf',
    name: 'Catalog 3',
    role: 'SE',
    collectingDate: '2020-08-16T10:22:53.779Z',
    agreementType: 'General Agreement',
    status: 'active',
    rightHolders: [
      {
        name: 'Administrator from unison',
        ipi: 856292576,
        userId: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
        unisonId: 'RA548VCJ4',
        id: '775673d3-b0d8-4ec8-a14a-58e0fdf7ae9d',
        user: {
          id: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
          email: 'dev@nakima.es',
          firstName: 'Administrator',
          lastName: 'from nakima',
          businessName: null,
          role: 'admin',
          nif: null,
          address: null,
          city: null,
          postalCode: null,
          country: null,
          stripeAccountId: null,
          commissionDigital: 10,
          commissionRadioTV: 10,
          commissionLive: 9,
          commissionBackground: 20,
          status: 'active',
          financialAdminId: null,
        },
      },
    ],
  },
  {
    id: 'asdaf-asdaf-asfsdagg-dfsadsaf',
    name: 'Catalog 4',
    role: 'SE',
    collectingDate: '2020-08-16T10:22:53.779Z',
    agreementType: 'General Agreement',
    status: 'active',
    rightHolders: [
      {
        name: 'Administrator from unison',
        ipi: 856292576,
        userId: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
        unisonId: 'RA548VCJ4',
        id: '775673d3-b0d8-4ec8-a14a-58e0fdf7ae9d',
        user: {
          id: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
          email: 'dev@nakima.es',
          firstName: 'Administrator',
          lastName: 'from nakima',
          businessName: null,
          role: 'admin',
          nif: null,
          address: null,
          city: null,
          postalCode: null,
          country: null,
          stripeAccountId: null,
          commissionDigital: 10,
          commissionRadioTV: 10,
          commissionLive: 9,
          commissionBackground: 20,
          status: 'active',
          financialAdminId: null,
        },
      },
    ],
  },
  {
    id: 'asdaf-asdaf-asfsdagg-dfasdsaf',
    name: 'Catalog 5',
    role: 'SE',
    collectingDate: '2020-08-16T10:22:53.779Z',
    agreementType: 'General Agreement',
    status: 'disabled',
    rightHolders: [
      {
        name: 'Administrator from unison',
        ipi: 856292576,
        userId: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
        unisonId: 'RA548VCJ4',
        id: '775673d3-b0d8-4ec8-a14a-58e0fdf7ae9d',
        user: {
          id: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
          email: 'dev@nakima.es',
          firstName: 'Administrator',
          lastName: 'from nakima',
          businessName: null,
          role: 'admin',
          nif: null,
          address: null,
          city: null,
          postalCode: null,
          country: null,
          stripeAccountId: null,
          commissionDigital: 10,
          commissionRadioTV: 10,
          commissionLive: 9,
          commissionBackground: 20,
          status: 'active',
          financialAdminId: null,
        },
      },
    ],
  },
  {
    id: 'asdaf-asdaf-asfsdagg-dfshgfaf',
    name: 'Catalog 6',
    role: 'SE',
    collectingDate: '2020-08-16T10:22:53.779Z',
    agreementType: 'General Agreement',
    status: 'disabled',
    rightHolders: [
      {
        name: 'Administrator from unison',
        ipi: 856292576,
        userId: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
        unisonId: 'RA548VCJ4',
        id: '775673d3-b0d8-4ec8-a14a-58e0fdf7ae9d',
        user: {
          id: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
          email: 'dev@nakima.es',
          firstName: 'Administrator',
          lastName: 'from nakima',
          businessName: null,
          role: 'admin',
          nif: null,
          address: null,
          city: null,
          postalCode: null,
          country: null,
          stripeAccountId: null,
          commissionDigital: 10,
          commissionRadioTV: 10,
          commissionLive: 9,
          commissionBackground: 20,
          status: 'active',
          financialAdminId: null,
        },
      },
    ],
  },
  {
    id: 'asdaf-asdaf-asfsdagg-dffnsaf',
    name: 'Catalog 6',
    role: 'SE',
    collectingDate: '2020-08-16T10:22:53.779Z',
    agreementType: 'General Agreement',
    status: 'collecting',
    rightHolders: [
      {
        name: 'Administrator from unison',
        ipi: 856292576,
        userId: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
        unisonId: 'RA548VCJ4',
        id: '775673d3-b0d8-4ec8-a14a-58e0fdf7ae9d',
        user: {
          id: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
          email: 'dev@nakima.es',
          firstName: 'Administrator',
          lastName: 'from nakima',
          businessName: null,
          role: 'admin',
          nif: null,
          address: null,
          city: null,
          postalCode: null,
          country: null,
          stripeAccountId: null,
          commissionDigital: 10,
          commissionRadioTV: 10,
          commissionLive: 9,
          commissionBackground: 20,
          status: 'active',
          financialAdminId: null,
        },
      },
    ],
  },
  {
    id: 'asdaf-asdsadaf-asfsdagg-dfsaf',
    name: 'Catalog 7',
    role: 'SE',
    collectingDate: '2020-08-16T10:22:53.779Z',
    agreementType: 'General Agreement',
    status: 'active',
    rightHolders: [
      {
        name: 'Administrator from unison',
        ipi: 856292576,
        userId: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
        unisonId: 'RA548VCJ4',
        id: '775673d3-b0d8-4ec8-a14a-58e0fdf7ae9d',
        user: {
          id: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
          email: 'dev@nakima.es',
          firstName: 'Administrator',
          lastName: 'from nakima',
          businessName: null,
          role: 'admin',
          nif: null,
          address: null,
          city: null,
          postalCode: null,
          country: null,
          stripeAccountId: null,
          commissionDigital: 10,
          commissionRadioTV: 10,
          commissionLive: 9,
          commissionBackground: 20,
          status: 'active',
          financialAdminId: null,
        },
      },
    ],
  },
  {
    id: 'asdaf-asdaf-aewtg-dfsaf',
    name: 'Catalog 8',
    role: 'SE',
    collectingDate: '2020-08-16T10:22:53.779Z',
    agreementType: 'General Agreement',
    status: 'active',
    rightHolders: [
      {
        name: 'Administrator from unison',
        ipi: 856292576,
        userId: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
        unisonId: 'RA548VCJ4',
        id: '775673d3-b0d8-4ec8-a14a-58e0fdf7ae9d',
        user: {
          id: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
          email: 'dev@nakima.es',
          firstName: 'Administrator',
          lastName: 'from nakima',
          businessName: null,
          role: 'admin',
          nif: null,
          address: null,
          city: null,
          postalCode: null,
          country: null,
          stripeAccountId: null,
          commissionDigital: 10,
          commissionRadioTV: 10,
          commissionLive: 9,
          commissionBackground: 20,
          status: 'active',
          financialAdminId: null,
        },
      },
    ],
  },
  {
    id: 'asdaf-asdaf-asfgbgfgg-dfsaf',
    name: 'Catalog 9',
    role: 'SE',
    collectingDate: '2020-08-16T10:22:53.779Z',
    agreementType: 'General Agreement',
    status: 'active',
    rightHolders: [
      {
        name: 'Administrator from unison',
        ipi: 856292576,
        userId: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
        unisonId: 'RA548VCJ4',
        id: '775673d3-b0d8-4ec8-a14a-58e0fdf7ae9d',
        user: {
          id: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
          email: 'dev@nakima.es',
          firstName: 'Administrator',
          lastName: 'from nakima',
          businessName: null,
          role: 'admin',
          nif: null,
          address: null,
          city: null,
          postalCode: null,
          country: null,
          stripeAccountId: null,
          commissionDigital: 10,
          commissionRadioTV: 10,
          commissionLive: 9,
          commissionBackground: 20,
          status: 'active',
          financialAdminId: null,
        },
      },
    ],
  },
  {
    id: 'asdaf-asdaf-asfsdagg-dfdsff',
    name: 'Catalog 10',
    role: 'SE',
    collectingDate: '2020-08-16T10:22:53.779Z',
    agreementType: 'General Agreement',
    status: 'active',
    rightHolders: [
      {
        name: 'Administrator from unison',
        ipi: 856292576,
        userId: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
        unisonId: 'RA548VCJ4',
        id: '775673d3-b0d8-4ec8-a14a-58e0fdf7ae9d',
        user: {
          id: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
          email: 'dev@nakima.es',
          firstName: 'Administrator',
          lastName: 'from nakima',
          businessName: null,
          role: 'admin',
          nif: null,
          address: null,
          city: null,
          postalCode: null,
          country: null,
          stripeAccountId: null,
          commissionDigital: 10,
          commissionRadioTV: 10,
          commissionLive: 9,
          commissionBackground: 20,
          status: 'active',
          financialAdminId: null,
        },
      },
    ],
  },
  {
    id: 'asdaf-asdaf-asfsdagg-dfzxcaf',
    name: 'Catalog 11',
    role: 'SE',
    collectingDate: '2020-08-16T10:22:53.779Z',
    agreementType: 'General Agreement',
    status: 'active',
    rightHolders: [
      {
        name: 'Administrator from unison',
        ipi: 856292576,
        userId: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
        unisonId: 'RA548VCJ4',
        id: '775673d3-b0d8-4ec8-a14a-58e0fdf7ae9d',
        user: {
          id: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
          email: 'dev@nakima.es',
          firstName: 'Administrator',
          lastName: 'from nakima',
          businessName: null,
          role: 'admin',
          nif: null,
          address: null,
          city: null,
          postalCode: null,
          country: null,
          stripeAccountId: null,
          commissionDigital: 10,
          commissionRadioTV: 10,
          commissionLive: 9,
          commissionBackground: 20,
          status: 'active',
          financialAdminId: null,
        },
      },
    ],
  },
];
