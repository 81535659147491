import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/modules/auth/services';
import { BehaviorSubject, Subscription } from 'rxjs';

const PLATFORM_LANGUAGE = 'unisonPlatfomLanguage';
@Injectable({
  providedIn: 'root',
})
export class PlatformLanguageService {
  private readonly platformLanguageSubject = new BehaviorSubject<string>(null);
  readonly platformLanguage$ = this.platformLanguageSubject.asObservable();

  get platformLanguage(): string {
    return this.platformLanguageSubject.getValue();
  }

  set platformLanguage(language: string) {
    if (!language) {
      return;
    }
    this.platformLanguageSubject.next(language);
    this.localStorageService.setItem(PLATFORM_LANGUAGE, language.toLowerCase());
  }

  private localStorageService: Storage;

  private subscription: Subscription;

  constructor(private translate: TranslateService, private authService: AuthService) {
    this.localStorageService = localStorage;
    const browserLang = this.translate.getBrowserLang();

    const sessionLanguage = this.authService.getCurrentUser()?.language;

    this.subscription = this.authService.session$.subscribe((session) => {
      if (session) {
        this.platformLanguage = session.client?.language;
        return;
      }
      this.platformLanguage = browserLang;
    });

    const localLanguage = this.getCurrentPlatformLanguage();

    if (localLanguage) {
      this.platformLanguage = localLanguage;
      return;
    }
    if (sessionLanguage) {
      this.platformLanguage = sessionLanguage;
      return;
    }
    this.platformLanguage = browserLang;
  }

  getCurrentPlatformLanguage(): string {
    const platformLanguage = this.localStorageService.getItem(PLATFORM_LANGUAGE);
    return platformLanguage || 'es';
  }
}
