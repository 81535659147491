<nav aria-label="breadcrumb" class="app-breadcrumbs">
  <ol class="breadcrumb px-0 py-3 mb-1">
    <li
      class="breadcrumb-item"
      *ngFor="let breadcrumb of breadcrumbs; let last = last"
      [class.active]="last"
    >
      <a [routerLink]="breadcrumb.url" routerLinkActive="active" *ngIf="!last">
        {{ breadcrumb.label | translate }}
      </a>
      <span *ngIf="last">
        {{ breadcrumb.label | translate }}
      </span>
    </li>
  </ol>
</nav>
