import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/modules/auth/services';
import { User } from 'app/shared/models';
import { MustShowFinancialDataBarService, UserService } from 'app/shared/services';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

const FINANCIAL_DATA_URL = '/profile/financial-data';

@Component({
  selector: 'app-empty-financial-data',
  templateUrl: './empty-financial-data.component.html',
  styleUrls: ['./empty-financial-data.component.scss'],
})
export class EmptyFinancialDataComponent implements OnInit, OnDestroy {
  user: User;

  isInFinancialData: boolean;
  mustShowBar: boolean;

  private subscription: Subscription;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private mustShowFinancialDataService: MustShowFinancialDataBarService,
    private toastService: ToastrService,
    private translateService: TranslateService,
    private router: Router,
  ) {
    this.isInFinancialData = false;

    router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.isInFinancialData = this.checkIsInFinancialData(this.router.url);
      }
    });
  }

  ngOnInit(): void {
    this.user = this.authService.getCurrentUser();
    if (this.user?.id) {
      void this.getFinancialData();
    }

    this.subscription = this.mustShowFinancialDataService.mustShowFinancialDataBar$.subscribe(
      this.hasToShowFinancialDataBar.bind(this),
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async getFinancialData(): Promise<void> {
    try {
      await this.userService.getFinancialData();
    } catch (error) {
      this.toastService.error(
        this.translateService.instant('Unexpected error'),
        this.translateService.instant('Oops!'),
      );
    }
  }

  hasToShowFinancialDataBar(showBar: boolean): void {
    this.mustShowBar = showBar;
  }

  checkIsInFinancialData(url: string): boolean {
    return url === FINANCIAL_DATA_URL;
  }
}
