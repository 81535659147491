import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from 'app/modules/auth/services';
import { User } from 'app/shared/models/user';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-admin-bar',
  templateUrl: './admin-bar.component.html',
  styleUrls: ['./admin-bar.component.scss'],
})
export class AdminBarComponent implements OnInit {
  isAdminLogged = false;
  user: User;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastService: ToastrService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.isAdminLogged = this.authService.isAdminLogged();
    this.user = this.authService.getCurrentUser();
  }

  logout(): void {
    this.authService.logout();
    void this.router.navigate(['/auth/login-admin']).then(() => {
      this.toastService.info(
        this.translateService.instant('Session closed successfully!'),
        this.translateService.instant('OK!'),
      );
    });
  }
}
