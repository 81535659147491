import world from '@highcharts/map-collection/custom/world.geo.json';
import europe from '@highcharts/map-collection/custom/europe.geo.json';
import northAmerica from '@highcharts/map-collection/custom/north-america.geo.json';
import southAmerica from '@highcharts/map-collection/custom/south-america.geo.json';
import africa from '@highcharts/map-collection/custom/africa.geo.json';
import asia from '@highcharts/map-collection/custom/asia.geo.json';
import oceania from '@highcharts/map-collection/custom/oceania.geo.json';

export interface ContinentI {
  code: string;
  name: string;
  map: any;
  tis: number;
}

export const CONTINENTS_MAP_MOCK = [
  {
    code: 'europe',
    name: 'CONTINENTS.EUROPE',
    map: europe,
    tis: 2120,
  },
  {
    code: 'northAmerica',
    name: 'CONTINENTS.NORTH-AMERICA',
    map: northAmerica,
    tis: 2129,
  },
  {
    code: 'southAmerica',
    name: 'CONTINENTS.SOUTH-AMERICA',
    map: southAmerica,
    tis: 2132,
  },
  {
    code: 'asia',
    name: 'CONTINENTS.ASIA',
    map: asia,
    tis: 2106,
  },
  {
    code: 'africa',
    name: 'CONTINENTS.AFRICA',
    map: africa,
    tis: 2100,
  },
  {
    code: 'oceania',
    name: 'CONTINENTS.OCEANIA',
    map: oceania,
    tis: 2130,
  },
  {
    code: 'world',
    name: 'CONTINENTS.WORLD',
    map: world,
    tis: 2136,
  },
];
