import { FinancialDataDTO } from 'app/shared/models';

enum FinancialDataType {
  COMPANY = 'company',
  INDIVIDUAL = 'individual',
}

export const FINANCIALDATA_BUSINESS: FinancialDataDTO = {
  businessInformation: {
    name: 'BUSINESS NAKIMA',
    CIF: '12345678',
    address: 'NUMANCIA 23',
    postalCode: '08003',
    city: 'Barcelona',
    countryId: 724,
  },
  bankInfo: {
    countryId: 724,
    currencyId: 'EUR',
    bankAccount: '11111111111111111111111111111',
    currency: {
      name: 'Euro',
      id: 'EUR',
    },
  },
  representativeInformation: {
    name: 'NAKIMA',
    surname: 'NAKIMA SURNAME',
    birthdate: '25/04/1999',
    nif: '',
    address: 'NUMANCIA 33',
    postalCode: '08003',
    city: 'Barcelona',
    countryId: 724,
  },
  type: FinancialDataType.COMPANY,
};

export const FINANCIALDATA_INDIVIDUAL: FinancialDataDTO = {
  businessInformation: {
    name: '',
    CIF: '',
    address: '',
    postalCode: '',
    city: '',
    countryId: null,
  },
  bankInfo: {
    countryId: 724,
    currencyId: 'EUR',
    bankAccount: '5555555555555555555',

    currency: {
      name: 'Euro',
      id: 'EUR',
    },
  },
  representativeInformation: {
    name: 'NAKIMA INDI',
    surname: 'INDIVIDUAL',
    birthdate: '25/09/2015',
    nif: '22222222222',
    address: 'AAAAA',
    postalCode: '08002',
    city: 'Barcelona',
    countryId: 724,
  },
  type: FinancialDataType.INDIVIDUAL,
};

// export const FINANCIALDATA_EMPTY: FinancialDataDTO = {

// };
