import { Component } from '@angular/core';

import 'chartjs-chart-geo';

@Component({
  selector: 'app-root',
  template: `
    <ngx-spinner
      [fullScreen]="true"
      bdColor="rgba(255,255,255,0.8)"
      size="medium"
      color="#000000"
      size="medium"
    >
    </ngx-spinner>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent {}
