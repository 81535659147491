import { WorkRoyaltiesCountryI } from 'app/shared/interfaces';

export const WORK_EARNINGS_COUNTRY_Y: Array<WorkRoyaltiesCountryI> = [
  {
    country: 'Spain',
    royalties: 25345,
  },
  {
    country: 'France',
    royalties: 23000,
  },
  {
    country: 'Italy',
    royalties: 18000,
  },
  {
    country: 'United Kingdom',
    royalties: 12500,
  },
];
export const WORK_EARNINGS_COUNTRY_QT: Array<WorkRoyaltiesCountryI> = [
  {
    country: 'France',
    royalties: 4500,
  },
  {
    country: 'Italy',
    royalties: 4100,
  },
  {
    country: 'Spain',
    royalties: 3800,
  },
  {
    country: 'United Kingdom',
    royalties: 2300,
  },
];
export const WORK_EARNINGS_COUNTRY_M: Array<WorkRoyaltiesCountryI> = [
  {
    country: 'United Kingdom',
    royalties: 1124,
  },
  {
    country: 'Spain',
    royalties: 934,
  },
  {
    country: 'Italy',
    royalties: 500,
  },
  {
    country: 'France',
    royalties: 200,
  },
];
