import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Performer, PerformerDTO } from '../models';
import { InfoResponseAPI } from '../interfaces';

export interface PerformerResponse {
  performers: Performer[];
  info: InfoResponseAPI;
}

@Injectable({
  providedIn: 'root',
})
export class PerformerService {
  private readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  async getAllPerformers(text: string | number = '' || 0): Promise<PerformerResponse> {
    const filters = `?text=${text}`;

    const url = `${this.apiUrl}/performer/${filters}`;
    const { performers: performersApi, info } = await this.http
      .get<{ performers: Array<PerformerDTO>; info: InfoResponseAPI }>(url)
      .toPromise();
    return {
      performers: new Performer().deserializeArray(performersApi),
      info,
    };
  }

  async createPerformer(performer: Performer): Promise<Performer> {
    const url = `${this.apiUrl}/performer/`;

    const { performer: performerApi } = await this.http
      .post<{ performer: PerformerDTO }>(url, performer)
      .toPromise();

    return new Performer().deserialize(performerApi);
  }

  async createPerformerBatch(performers: Performer[]): Promise<void> {
    const url = `${this.apiUrl}/performer/batch`;

    await this.http.post(url, { performers }).toPromise();
  }

  async addPerformerToRecording(recordingId: string, performerId: string): Promise<Performer> {
    const url = `${this.apiUrl}/recording/${recordingId}/performer/${performerId}`;
    const response = await this.http.post<{ performer: PerformerDTO }>(url, {}).toPromise();

    return new Performer().deserialize(response.performer);
  }

  async deletePerformerFromRecording(recordingId: string, performerId: string): Promise<void> {
    const url = `${this.apiUrl}/recording/${recordingId}/performer/${performerId}/`;

    await this.http.delete(url).toPromise();
  }
}
