import { EarningsByWorkResponseAPII } from 'app/shared/interfaces';

export const EARNINGS_BY_WORK: EarningsByWorkResponseAPII = {
  earningsByWork: [
    {
      id: '194a6d89-a0ff-46f7-8243-23b3abaa6857',
      unisonId: 'CO33Z83GPWLM',
      title: 'Noe',
      earnings: [10, 20, 15, 25, 30, 100],
      // language: null,
      // iswc: null,
      // type: 'MUS',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
      // createdAt: '2020-08-16T15:28:15.261Z',
      // updatedAt: '2020-08-16T15:28:15.261Z',
      // copyrightDate: '2020-08-16T15:28:15.240Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: '1fa46247-01aa-46d7-a470-46cc3bff3691',
      unisonId: 'COWR6UX6IXA6',
      title: 'Xavi2',
      earnings: [10, 20, 15, 25, 30, 100],
      // language: 'EN',
      // iswc: null,
      // type: 'MTX',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
      // createdAt: '2020-08-16T10:27:41.010Z',
      // updatedAt: '2020-08-16T10:27:41.010Z',
      // copyrightDate: '2020-08-16T10:27:41.001Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: '24b3317d-858f-4ff9-9928-28354abcd357',
      unisonId: 'CO83T84228GC',
      title: 'LA CASA AZUL',
      earnings: [10, 20, 15, 25, 30, 100],
      // language: 'ES',
      // iswc: 'T1243791956',
      // type: 'TXT',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: null,
      // createdAt: '2020-08-16T10:22:53.744Z',
      // updatedAt: '2020-08-16T10:22:53.744Z',
      // copyrightDate: '2020-08-16T10:22:51.136Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [
        {
          id: '843dad5a-43bb-48dd-9ab3-75a7add25b3e',
          performanceSplit: 35,
          mechanicalSplit: 35,
          synchronizationSplit: 35,
          role: 'CA',
          rightHolderName: 'Norman cleric',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: '24b3317d-858f-4ff9-9928-28354abcd357',
          ownerId: 'c26b2a33-2e69-4872-937f-4720e8eac524',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: 'c26b2a33-2e69-4872-937f-4720e8eac524',
            name: 'Norman cleric',
            ipi: 185782901,
            unisonId: 'R48XPKNR7',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
        {
          id: '6266b700-aba0-4249-bee6-89b784702778',
          performanceSplit: 65,
          mechanicalSplit: 65,
          synchronizationSplit: 65,
          role: 'A',
          rightHolderName: 'Manuel Cebollo',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: '24b3317d-858f-4ff9-9928-28354abcd357',
          ownerId: 'e6b48091-0014-47b8-97fe-9140d8c330d6',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: 'e6b48091-0014-47b8-97fe-9140d8c330d6',
            name: 'Manuel Cebollo',
            ipi: 864975981,
            unisonId: 'RPFN5O1HG',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
      ],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: '45edb24c-a6ab-11e8-98d0-529269fb1459',
      unisonId: 'CO1V029Q184C',
      title: 'FLEEING CHANCES',
      earnings: [10, 20, 15, 25, 30, 100],
      // language: 'EN',
      // iswc: 'T2649413281',
      // type: 'MTX',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: null,
      // createdAt: '2020-08-16T10:22:53.744Z',
      // updatedAt: '2020-08-16T10:22:53.744Z',
      // copyrightDate: '2020-08-16T10:22:51.136Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [
        {
          id: '953a697e-1360-4e9a-bf33-8c71ab8f30f3',
          performanceSplit: 50,
          mechanicalSplit: 50,
          synchronizationSplit: 50,
          role: 'CA',
          rightHolderName: 'Sioned Marin',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: '45edb24c-a6ab-11e8-98d0-529269fb1459',
          ownerId: '89c3d995-96f9-41e2-aae3-f59e6447436c',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: '89c3d995-96f9-41e2-aae3-f59e6447436c',
            name: 'Sioned Marin',
            ipi: 437219252,
            unisonId: 'RGLKATRPY',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
        {
          id: 'e0a2a6fb-5f75-4384-8849-9e2f1c3f2f92',
          performanceSplit: 50,
          mechanicalSplit: 50,
          synchronizationSplit: 50,
          role: 'A',
          rightHolderName: 'Derek Andrade',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: '45edb24c-a6ab-11e8-98d0-529269fb1459',
          ownerId: 'ae738506-1d90-4e12-a1dc-b063c7c6167b',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: 'ae738506-1d90-4e12-a1dc-b063c7c6167b',
            name: 'Derek Andrade',
            ipi: 355932144,
            unisonId: 'RMNI64ULS',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
      ],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: '5b45bfbd-7ded-4616-b8ce-22c4a440b568',
      unisonId: 'CO1K5G7NXCLC',
      title: 'SuperMan',
      earnings: [10, 20, 15, 25, 30, 100],
      // language: 'EN',
      // iswc: null,
      // type: 'MTX',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
      // createdAt: '2020-08-16T10:26:48.141Z',
      // updatedAt: '2020-08-16T10:26:48.141Z',
      // copyrightDate: '2020-08-16T10:26:48.127Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: '83723b76-dc71-4d91-b2e5-74432eef9264',
      unisonId: 'CO224T9872RC',
      title: 'KARTH PROTEK',
      earnings: [10, 20, 15, 25, 30, 100],
      // language: 'EN',
      // iswc: 'T2519478837',
      // type: 'MUS',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: null,
      // createdAt: '2020-08-16T10:22:53.744Z',
      // updatedAt: '2020-08-16T10:22:53.744Z',
      // copyrightDate: '2020-08-16T10:22:51.136Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [
        {
          id: 'b15f8a54-8c57-4a57-8a4d-e211821ec848',
          performanceSplit: 80,
          mechanicalSplit: 80,
          synchronizationSplit: 80,
          role: 'CA',
          rightHolderName: 'LOUD',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: '83723b76-dc71-4d91-b2e5-74432eef9264',
          ownerId: '444a29e4-63aa-4b8f-a749-acaf36000490',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: '444a29e4-63aa-4b8f-a749-acaf36000490',
            name: 'LOUD',
            ipi: 955938923,
            unisonId: 'RM5LZ3D7G',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
        {
          id: 'c2597630-94ca-43d3-8e62-e5a369981bfd',
          performanceSplit: 20,
          mechanicalSplit: 20,
          synchronizationSplit: 20,
          role: 'CA',
          rightHolderName: 'Derek Andrade',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: '83723b76-dc71-4d91-b2e5-74432eef9264',
          ownerId: 'ae738506-1d90-4e12-a1dc-b063c7c6167b',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: 'ae738506-1d90-4e12-a1dc-b063c7c6167b',
            name: 'Derek Andrade',
            ipi: 355932144,
            unisonId: 'RMNI64ULS',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
      ],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: '8d604d57-28a9-4618-8f80-cd3aaba4185a',
      unisonId: 'CO897V8978UC',
      title: 'REMEMBER MY STEPS',
      earnings: [10, 20, 15, 25, 30, 100],
      // language: 'EN',
      // iswc: 'T7469544981',
      // type: 'MTX',
      // versionType: 'ORI',
      // status: 'CONFLICT',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: null,
      // createdAt: '2020-08-16T10:22:53.744Z',
      // updatedAt: '2020-08-16T10:22:53.744Z',
      // copyrightDate: '2020-08-16T10:22:51.136Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [
        {
          id: '1f701eed-a654-4e83-86f1-f9998a22a517',
          performanceSplit: 50,
          mechanicalSplit: 50,
          synchronizationSplit: 50,
          role: 'CA',
          rightHolderName: 'Rosa Flynn',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: '8d604d57-28a9-4618-8f80-cd3aaba4185a',
          ownerId: 'bfa5e27b-5771-4c5d-a178-0dfbd7b45c1a',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: 'bfa5e27b-5771-4c5d-a178-0dfbd7b45c1a',
            name: 'Rosa Flynn',
            ipi: 168618681,
            unisonId: 'RH0ZWF44F',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
      ],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: '91d4c71b-c990-4e69-a0b9-cfe49849b11c',
      unisonId: 'CO9477S752CG',
      title: 'LOS SILVIDOS',
      earnings: [10, 20, 15, 25, 30, 100],
      // language: 'ES',
      // iswc: 'T9151357056',
      // type: 'MTX',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: null,
      // createdAt: '2020-08-16T10:22:53.744Z',
      // updatedAt: '2020-08-16T10:22:53.744Z',
      // copyrightDate: '2020-08-16T10:22:51.136Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [
        {
          id: 'b3f80976-9b98-42a7-95fb-d21976301256',
          performanceSplit: 80,
          mechanicalSplit: 80,
          synchronizationSplit: 80,
          role: 'CA',
          rightHolderName: 'Manuel Cebollo',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: '91d4c71b-c990-4e69-a0b9-cfe49849b11c',
          ownerId: 'e6b48091-0014-47b8-97fe-9140d8c330d6',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: 'e6b48091-0014-47b8-97fe-9140d8c330d6',
            name: 'Manuel Cebollo',
            ipi: 864975981,
            unisonId: 'RPFN5O1HG',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
        {
          id: '40e88d1f-f537-4398-957c-7c95d5621516',
          performanceSplit: 20,
          mechanicalSplit: 20,
          synchronizationSplit: 20,
          role: 'CA',
          rightHolderName: 'Norman cleric',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: '91d4c71b-c990-4e69-a0b9-cfe49849b11c',
          ownerId: 'c26b2a33-2e69-4872-937f-4720e8eac524',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: 'c26b2a33-2e69-4872-937f-4720e8eac524',
            name: 'Norman cleric',
            ipi: 185782901,
            unisonId: 'R48XPKNR7',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
      ],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: 'a2d2b025-62ef-4166-a76e-b7c490914d6c',
      unisonId: 'CO8S22511N4C',
      title: "I'M BAD",
      earnings: [10, 20, 15, 25, 30, 100],
      // language: 'EN',
      // iswc: 'T5741285476',
      // type: 'MTX',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: null,
      // createdAt: '2020-08-16T10:22:53.744Z',
      // updatedAt: '2020-08-16T10:22:53.744Z',
      // copyrightDate: '2020-08-16T10:22:51.136Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [
        {
          id: 'f1a9b8e3-b1d4-434a-a11e-008bdc6229d1',
          performanceSplit: 50,
          mechanicalSplit: 50,
          synchronizationSplit: 50,
          role: 'C',
          rightHolderName: 'Raw talent publisher',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: 'a2d2b025-62ef-4166-a76e-b7c490914d6c',
          ownerId: '44cebd06-a674-42ab-af98-48f67503d5f1',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: '44cebd06-a674-42ab-af98-48f67503d5f1',
            name: 'Raw talent publisher',
            ipi: 521982334,
            unisonId: 'R30IHDTHX',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
        {
          id: 'feae817b-0ec8-407f-8c0a-9dca325c152c',
          performanceSplit: 50,
          mechanicalSplit: 50,
          synchronizationSplit: 50,
          role: 'CA',
          rightHolderName: 'Norman cleric',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: 'a2d2b025-62ef-4166-a76e-b7c490914d6c',
          ownerId: 'c26b2a33-2e69-4872-937f-4720e8eac524',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: 'c26b2a33-2e69-4872-937f-4720e8eac524',
            name: 'Norman cleric',
            ipi: 185782901,
            unisonId: 'R48XPKNR7',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
      ],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: 'a494cc53-d413-4598-bcc2-865ad7d257fb',
      unisonId: 'COG9RU7CKWBA',
      title: 'SuperMan2',
      earnings: [10, 20, 15, 25, 30, 100],
      // language: 'EN',
      // iswc: null,
      // type: 'MTX',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
      // createdAt: '2020-08-16T10:27:16.585Z',
      // updatedAt: '2020-08-16T10:27:16.585Z',
      // copyrightDate: '2020-08-16T10:27:16.580Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: 'b8b8435e-80bb-4435-91c3-f9445842022f',
      unisonId: 'COXQ0KR2L4TY',
      title: 'iron1',
      earnings: [10, 20, 15, 25, 30, 100],
      // language: 'EN',
      // iswc: null,
      // type: 'MTX',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
      // createdAt: '2020-08-16T10:27:09.726Z',
      // updatedAt: '2020-08-16T10:27:09.726Z',
      // copyrightDate: '2020-08-16T10:27:09.716Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: 'c3afb07b-400c-48af-b460-90f66f063df5',
      unisonId: 'CO21R3455N5C',
      title: 'ALTERED CHOICES',
      earnings: [10, 20, 15, 25, 30, 100],
      // language: 'EN',
      // iswc: 'T7617866646',
      // type: 'MUS',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: null,
      // createdAt: '2020-08-16T10:22:53.744Z',
      // updatedAt: '2020-08-16T10:22:53.744Z',
      // copyrightDate: '2020-08-16T10:22:51.136Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [
        {
          id: '145c2d44-9c86-46b5-bdf2-64a2c544dae3',
          performanceSplit: 25,
          mechanicalSplit: 25,
          synchronizationSplit: 25,
          role: 'CA',
          rightHolderName: 'LOUD',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: 'c3afb07b-400c-48af-b460-90f66f063df5',
          ownerId: '444a29e4-63aa-4b8f-a749-acaf36000490',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: '444a29e4-63aa-4b8f-a749-acaf36000490',
            name: 'LOUD',
            ipi: 955938923,
            unisonId: 'RM5LZ3D7G',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
        {
          id: '0b32208c-09a2-493a-be66-96ecf4f97632',
          performanceSplit: 75,
          mechanicalSplit: 75,
          synchronizationSplit: 75,
          role: 'A',
          rightHolderName: 'Derek Andrade',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: 'c3afb07b-400c-48af-b460-90f66f063df5',
          ownerId: 'ae738506-1d90-4e12-a1dc-b063c7c6167b',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: 'ae738506-1d90-4e12-a1dc-b063c7c6167b',
            name: 'Derek Andrade',
            ipi: 355932144,
            unisonId: 'RMNI64ULS',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
      ],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: 'd698dc15-9a37-4c27-80be-8fd72e46356d',
      unisonId: 'COVTYKAYVBUC',
      title: 'CAVERN',
      earnings: [10, 20, 15, 25, 30, 100],
      // language: 'EN',
      // iswc: null,
      // type: 'MTX',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
      // createdAt: '2020-08-16T10:27:27.838Z',
      // updatedAt: '2020-08-16T10:27:27.838Z',
      // copyrightDate: '2020-08-16T10:27:27.833Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: 'ff6fcf14-e900-434c-b153-01db5b551d33',
      unisonId: 'CO15K24A121C',
      title: 'RODEO SMILE',
      earnings: [10, 20, 15, 25, 30, 100],
      // language: 'EN',
      // iswc: 'T1134854546',
      // type: 'MTX',
      // versionType: 'ORI',
      // status: 'CONFLICT',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: null,
      // createdAt: '2020-08-16T10:22:53.744Z',
      // updatedAt: '2020-08-16T10:22:53.744Z',
      // copyrightDate: '2020-08-16T10:22:51.136Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [
        {
          id: 'f5bfa63e-a6ac-11e8-98d0-529269fb1459',
          performanceSplit: 50,
          mechanicalSplit: 50,
          synchronizationSplit: 50,
          role: 'CA',
          rightHolderName: 'Norman cleric',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: 'ff6fcf14-e900-434c-b153-01db5b551d33',
          ownerId: 'c26b2a33-2e69-4872-937f-4720e8eac524',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: 'c26b2a33-2e69-4872-937f-4720e8eac524',
            name: 'Norman cleric',
            ipi: 185782901,
            unisonId: 'R48XPKNR7',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
      ],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: '194a6d89-a0ff-46f7-8243-23b3abaa6857',
      unisonId: 'CO33Z83GPWLM',
      title: 'Noe',
      earnings: [10, 20, 15, 25, 30, 100],
      // language: null,
      // iswc: null,
      // type: 'MUS',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: 'd30fada3-f4d7-4adb-bb10-ca93b37469da',
      // createdAt: '2020-08-16T15:28:15.261Z',
      // updatedAt: '2020-08-16T15:28:15.261Z',
      // copyrightDate: '2020-08-16T15:28:15.240Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: '1fa46247-01aa-46d7-a470-46cc3bff3691',
      unisonId: 'COWR6UX6IXA6',
      title: 'Xavi2',
      earnings: [10, 20, 15, 25, 30, 100],
      // language: 'EN',
      // iswc: null,
      // type: 'MTX',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
      // createdAt: '2020-08-16T10:27:41.010Z',
      // updatedAt: '2020-08-16T10:27:41.010Z',
      // copyrightDate: '2020-08-16T10:27:41.001Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: '24b3317d-858f-4ff9-9928-28354abcd357',
      unisonId: 'CO83T84228GC',
      title: 'LA CASA AZUL',
      earnings: [10, 20, 15, 25, 30, 100],
      // language: 'ES',
      // iswc: 'T1243791956',
      // type: 'TXT',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: null,
      // createdAt: '2020-08-16T10:22:53.744Z',
      // updatedAt: '2020-08-16T10:22:53.744Z',
      // copyrightDate: '2020-08-16T10:22:51.136Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [
        {
          id: '843dad5a-43bb-48dd-9ab3-75a7add25b3e',
          performanceSplit: 35,
          mechanicalSplit: 35,
          synchronizationSplit: 35,
          role: 'CA',
          rightHolderName: 'Norman cleric',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: '24b3317d-858f-4ff9-9928-28354abcd357',
          ownerId: 'c26b2a33-2e69-4872-937f-4720e8eac524',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: 'c26b2a33-2e69-4872-937f-4720e8eac524',
            name: 'Norman cleric',
            ipi: 185782901,
            unisonId: 'R48XPKNR7',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
        {
          id: '6266b700-aba0-4249-bee6-89b784702778',
          performanceSplit: 65,
          mechanicalSplit: 65,
          synchronizationSplit: 65,
          role: 'A',
          rightHolderName: 'Manuel Cebollo',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: '24b3317d-858f-4ff9-9928-28354abcd357',
          ownerId: 'e6b48091-0014-47b8-97fe-9140d8c330d6',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: 'e6b48091-0014-47b8-97fe-9140d8c330d6',
            name: 'Manuel Cebollo',
            ipi: 864975981,
            unisonId: 'RPFN5O1HG',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
      ],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: '45edb24c-a6ab-11e8-98d0-529269fb1459',
      unisonId: 'CO1V029Q184C',
      title: 'FLEEING CHANCES',
      earnings: [10, 20, 15, 25, 30, 100],
      // language: 'EN',
      // iswc: 'T2649413281',
      // type: 'MTX',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: null,
      // createdAt: '2020-08-16T10:22:53.744Z',
      // updatedAt: '2020-08-16T10:22:53.744Z',
      // copyrightDate: '2020-08-16T10:22:51.136Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [
        {
          id: '953a697e-1360-4e9a-bf33-8c71ab8f30f3',
          performanceSplit: 50,
          mechanicalSplit: 50,
          synchronizationSplit: 50,
          role: 'CA',
          rightHolderName: 'Sioned Marin',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: '45edb24c-a6ab-11e8-98d0-529269fb1459',
          ownerId: '89c3d995-96f9-41e2-aae3-f59e6447436c',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: '89c3d995-96f9-41e2-aae3-f59e6447436c',
            name: 'Sioned Marin',
            ipi: 437219252,
            unisonId: 'RGLKATRPY',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
        {
          id: 'e0a2a6fb-5f75-4384-8849-9e2f1c3f2f92',
          performanceSplit: 50,
          mechanicalSplit: 50,
          synchronizationSplit: 50,
          role: 'A',
          rightHolderName: 'Derek Andrade',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: '45edb24c-a6ab-11e8-98d0-529269fb1459',
          ownerId: 'ae738506-1d90-4e12-a1dc-b063c7c6167b',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: 'ae738506-1d90-4e12-a1dc-b063c7c6167b',
            name: 'Derek Andrade',
            ipi: 355932144,
            unisonId: 'RMNI64ULS',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
      ],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: '5b45bfbd-7ded-4616-b8ce-22c4a440b568',
      unisonId: 'CO1K5G7NXCLC',
      title: 'SuperMan',
      earnings: [10, 20, 15, 25, 30, 100],
      // language: 'EN',
      // iswc: null,
      // type: 'MTX',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
      // createdAt: '2020-08-16T10:26:48.141Z',
      // updatedAt: '2020-08-16T10:26:48.141Z',
      // copyrightDate: '2020-08-16T10:26:48.127Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: '83723b76-dc71-4d91-b2e5-74432eef9264',
      unisonId: 'CO224T9872RC',
      title: 'KARTH PROTEK',
      earnings: [10, 20, 15, 25, 30, 100],
      // language: 'EN',
      // iswc: 'T2519478837',
      // type: 'MUS',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: null,
      // createdAt: '2020-08-16T10:22:53.744Z',
      // updatedAt: '2020-08-16T10:22:53.744Z',
      // copyrightDate: '2020-08-16T10:22:51.136Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [
        {
          id: 'b15f8a54-8c57-4a57-8a4d-e211821ec848',
          performanceSplit: 80,
          mechanicalSplit: 80,
          synchronizationSplit: 80,
          role: 'CA',
          rightHolderName: 'LOUD',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: '83723b76-dc71-4d91-b2e5-74432eef9264',
          ownerId: '444a29e4-63aa-4b8f-a749-acaf36000490',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: '444a29e4-63aa-4b8f-a749-acaf36000490',
            name: 'LOUD',
            ipi: 955938923,
            unisonId: 'RM5LZ3D7G',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
        {
          id: 'c2597630-94ca-43d3-8e62-e5a369981bfd',
          performanceSplit: 20,
          mechanicalSplit: 20,
          synchronizationSplit: 20,
          role: 'CA',
          rightHolderName: 'Derek Andrade',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: '83723b76-dc71-4d91-b2e5-74432eef9264',
          ownerId: 'ae738506-1d90-4e12-a1dc-b063c7c6167b',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: 'ae738506-1d90-4e12-a1dc-b063c7c6167b',
            name: 'Derek Andrade',
            ipi: 355932144,
            unisonId: 'RMNI64ULS',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
      ],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: '8d604d57-28a9-4618-8f80-cd3aaba4185a',
      unisonId: 'CO897V8978UC',
      title: 'REMEMBER MY STEPS',
      earnings: [10, 20, 15, 25, 30, 100],
      // language: 'EN',
      // iswc: 'T7469544981',
      // type: 'MTX',
      // versionType: 'ORI',
      // status: 'CONFLICT',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: null,
      // createdAt: '2020-08-16T10:22:53.744Z',
      // updatedAt: '2020-08-16T10:22:53.744Z',
      // copyrightDate: '2020-08-16T10:22:51.136Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [
        {
          id: '1f701eed-a654-4e83-86f1-f9998a22a517',
          performanceSplit: 50,
          mechanicalSplit: 50,
          synchronizationSplit: 50,
          role: 'CA',
          rightHolderName: 'Rosa Flynn',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: '8d604d57-28a9-4618-8f80-cd3aaba4185a',
          ownerId: 'bfa5e27b-5771-4c5d-a178-0dfbd7b45c1a',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: 'bfa5e27b-5771-4c5d-a178-0dfbd7b45c1a',
            name: 'Rosa Flynn',
            ipi: 168618681,
            unisonId: 'RH0ZWF44F',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
      ],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: '91d4c71b-c990-4e69-a0b9-cfe49849b11c',
      unisonId: 'CO9477S752CG',
      title: 'LOS SILVIDOS',
      earnings: [10, 20, 15, 25, 30, 100],
      // language: 'ES',
      // iswc: 'T9151357056',
      // type: 'MTX',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: null,
      // createdAt: '2020-08-16T10:22:53.744Z',
      // updatedAt: '2020-08-16T10:22:53.744Z',
      // copyrightDate: '2020-08-16T10:22:51.136Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [
        {
          id: 'b3f80976-9b98-42a7-95fb-d21976301256',
          performanceSplit: 80,
          mechanicalSplit: 80,
          synchronizationSplit: 80,
          role: 'CA',
          rightHolderName: 'Manuel Cebollo',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: '91d4c71b-c990-4e69-a0b9-cfe49849b11c',
          ownerId: 'e6b48091-0014-47b8-97fe-9140d8c330d6',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: 'e6b48091-0014-47b8-97fe-9140d8c330d6',
            name: 'Manuel Cebollo',
            ipi: 864975981,
            unisonId: 'RPFN5O1HG',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
        {
          id: '40e88d1f-f537-4398-957c-7c95d5621516',
          performanceSplit: 20,
          mechanicalSplit: 20,
          synchronizationSplit: 20,
          role: 'CA',
          rightHolderName: 'Norman cleric',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: '91d4c71b-c990-4e69-a0b9-cfe49849b11c',
          ownerId: 'c26b2a33-2e69-4872-937f-4720e8eac524',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: 'c26b2a33-2e69-4872-937f-4720e8eac524',
            name: 'Norman cleric',
            ipi: 185782901,
            unisonId: 'R48XPKNR7',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
      ],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: 'a2d2b025-62ef-4166-a76e-b7c490914d6c',
      unisonId: 'CO8S22511N4C',
      title: "I'M BAD",
      earnings: [10, 20, 15, 25, 30, 100],
      // language: 'EN',
      // iswc: 'T5741285476',
      // type: 'MTX',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: null,
      // createdAt: '2020-08-16T10:22:53.744Z',
      // updatedAt: '2020-08-16T10:22:53.744Z',
      // copyrightDate: '2020-08-16T10:22:51.136Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [
        {
          id: 'f1a9b8e3-b1d4-434a-a11e-008bdc6229d1',
          performanceSplit: 50,
          mechanicalSplit: 50,
          synchronizationSplit: 50,
          role: 'C',
          rightHolderName: 'Raw talent publisher',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: 'a2d2b025-62ef-4166-a76e-b7c490914d6c',
          ownerId: '44cebd06-a674-42ab-af98-48f67503d5f1',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: '44cebd06-a674-42ab-af98-48f67503d5f1',
            name: 'Raw talent publisher',
            ipi: 521982334,
            unisonId: 'R30IHDTHX',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
        {
          id: 'feae817b-0ec8-407f-8c0a-9dca325c152c',
          performanceSplit: 50,
          mechanicalSplit: 50,
          synchronizationSplit: 50,
          role: 'CA',
          rightHolderName: 'Norman cleric',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: 'a2d2b025-62ef-4166-a76e-b7c490914d6c',
          ownerId: 'c26b2a33-2e69-4872-937f-4720e8eac524',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: 'c26b2a33-2e69-4872-937f-4720e8eac524',
            name: 'Norman cleric',
            ipi: 185782901,
            unisonId: 'R48XPKNR7',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
      ],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: 'a494cc53-d413-4598-bcc2-865ad7d257fb',
      unisonId: 'COG9RU7CKWBA',
      title: 'SuperMan2',
      earnings: [10, 20, 15, 25, 30, 100],
      // language: 'EN',
      // iswc: null,
      // type: 'MTX',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
      // createdAt: '2020-08-16T10:27:16.585Z',
      // updatedAt: '2020-08-16T10:27:16.585Z',
      // copyrightDate: '2020-08-16T10:27:16.580Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: 'b8b8435e-80bb-4435-91c3-f9445842022f',
      unisonId: 'COXQ0KR2L4TY',
      title: 'iron1',
      earnings: [10, 20, 15, 25, 30, 100],
      // language: 'EN',
      // iswc: null,
      // type: 'MTX',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
      // createdAt: '2020-08-16T10:27:09.726Z',
      // updatedAt: '2020-08-16T10:27:09.726Z',
      // copyrightDate: '2020-08-16T10:27:09.716Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: 'c3afb07b-400c-48af-b460-90f66f063df5',
      unisonId: 'CO21R3455N5C',
      title: 'ALTERED CHOICES',
      earnings: [10, 20, 15, 25, 30, 100],
      // language: 'EN',
      // iswc: 'T7617866646',
      // type: 'MUS',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: null,
      // createdAt: '2020-08-16T10:22:53.744Z',
      // updatedAt: '2020-08-16T10:22:53.744Z',
      // copyrightDate: '2020-08-16T10:22:51.136Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [
        {
          id: '145c2d44-9c86-46b5-bdf2-64a2c544dae3',
          performanceSplit: 25,
          mechanicalSplit: 25,
          synchronizationSplit: 25,
          role: 'CA',
          rightHolderName: 'LOUD',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: 'c3afb07b-400c-48af-b460-90f66f063df5',
          ownerId: '444a29e4-63aa-4b8f-a749-acaf36000490',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: '444a29e4-63aa-4b8f-a749-acaf36000490',
            name: 'LOUD',
            ipi: 955938923,
            unisonId: 'RM5LZ3D7G',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
        {
          id: '0b32208c-09a2-493a-be66-96ecf4f97632',
          performanceSplit: 75,
          mechanicalSplit: 75,
          synchronizationSplit: 75,
          role: 'A',
          rightHolderName: 'Derek Andrade',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: 'c3afb07b-400c-48af-b460-90f66f063df5',
          ownerId: 'ae738506-1d90-4e12-a1dc-b063c7c6167b',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: 'ae738506-1d90-4e12-a1dc-b063c7c6167b',
            name: 'Derek Andrade',
            ipi: 355932144,
            unisonId: 'RMNI64ULS',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
      ],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: 'd698dc15-9a37-4c27-80be-8fd72e46356d',
      unisonId: 'COVTYKAYVBUC',
      title: 'CAVERN',
      earnings: [10, 20, 15, 25, 30, 100],
      // language: 'EN',
      // iswc: null,
      // type: 'MTX',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
      // createdAt: '2020-08-16T10:27:27.838Z',
      // updatedAt: '2020-08-16T10:27:27.838Z',
      // copyrightDate: '2020-08-16T10:27:27.833Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: 'ff6fcf14-e900-434c-b153-01db5b551d33',
      unisonId: 'CO15K24A121C',
      title: 'RODEO SMILE',
      earnings: [10, 20, 15, 25, 30, 100],
      // language: 'EN',
      // iswc: 'T1134854546',
      // type: 'MTX',
      // versionType: 'ORI',
      // status: 'CONFLICT',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: null,
      // createdAt: '2020-08-16T10:22:53.744Z',
      // updatedAt: '2020-08-16T10:22:53.744Z',
      // copyrightDate: '2020-08-16T10:22:51.136Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [
        {
          id: 'f5bfa63e-a6ac-11e8-98d0-529269fb1459',
          performanceSplit: 50,
          mechanicalSplit: 50,
          synchronizationSplit: 50,
          role: 'CA',
          rightHolderName: 'Norman cleric',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: 'ff6fcf14-e900-434c-b153-01db5b551d33',
          ownerId: 'c26b2a33-2e69-4872-937f-4720e8eac524',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: 'c26b2a33-2e69-4872-937f-4720e8eac524',
            name: 'Norman cleric',
            ipi: 185782901,
            unisonId: 'R48XPKNR7',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
      ],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
  ],
  yearEarnings: [2016, 2017, 2018, 2019, 2020],
  info: {
    count: 28,
    offset: '0',
    limit: '999999',
  },
};
export const EARNINGS_BY_WORK_TOP: EarningsByWorkResponseAPII = {
  earningsByWork: [
    {
      id: '24b3317d-858f-4ff9-9928-28354abcd357',
      unisonId: 'CO83T84228GC',
      title: 'LA CASA AZUL',
      earnings: [10000, 20000, 15000, 25000, 30000, 100000],
      // language: 'ES',
      // iswc: 'T1243791956',
      // type: 'TXT',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: null,
      // createdAt: '2020-08-16T10:22:53.744Z',
      // updatedAt: '2020-08-16T10:22:53.744Z',
      // copyrightDate: '2020-08-16T10:22:51.136Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [
        {
          id: '843dad5a-43bb-48dd-9ab3-75a7add25b3e',
          performanceSplit: 35,
          mechanicalSplit: 35,
          synchronizationSplit: 35,
          role: 'CA',
          rightHolderName: 'Norman cleric',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: '24b3317d-858f-4ff9-9928-28354abcd357',
          ownerId: 'c26b2a33-2e69-4872-937f-4720e8eac524',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: 'c26b2a33-2e69-4872-937f-4720e8eac524',
            name: 'Norman cleric',
            ipi: 185782901,
            unisonId: 'R48XPKNR7',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
        {
          id: '6266b700-aba0-4249-bee6-89b784702778',
          performanceSplit: 65,
          mechanicalSplit: 65,
          synchronizationSplit: 65,
          role: 'A',
          rightHolderName: 'Manuel Cebollo',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: '24b3317d-858f-4ff9-9928-28354abcd357',
          ownerId: 'e6b48091-0014-47b8-97fe-9140d8c330d6',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: 'e6b48091-0014-47b8-97fe-9140d8c330d6',
            name: 'Manuel Cebollo',
            ipi: 864975981,
            unisonId: 'RPFN5O1HG',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
      ],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: '45edb24c-a6ab-11e8-98d0-529269fb1459',
      unisonId: 'CO1V029Q184C',
      title: 'FLEEING CHANCES',
      earnings: [9000, 18000, 12000, 23000, 27000, 89000],
      // language: 'EN',
      // iswc: 'T2649413281',
      // type: 'MTX',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: null,
      // createdAt: '2020-08-16T10:22:53.744Z',
      // updatedAt: '2020-08-16T10:22:53.744Z',
      // copyrightDate: '2020-08-16T10:22:51.136Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [
        {
          id: '953a697e-1360-4e9a-bf33-8c71ab8f30f3',
          performanceSplit: 50,
          mechanicalSplit: 50,
          synchronizationSplit: 50,
          role: 'CA',
          rightHolderName: 'Sioned Marin',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: '45edb24c-a6ab-11e8-98d0-529269fb1459',
          ownerId: '89c3d995-96f9-41e2-aae3-f59e6447436c',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: '89c3d995-96f9-41e2-aae3-f59e6447436c',
            name: 'Sioned Marin',
            ipi: 437219252,
            unisonId: 'RGLKATRPY',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
        {
          id: 'e0a2a6fb-5f75-4384-8849-9e2f1c3f2f92',
          performanceSplit: 50,
          mechanicalSplit: 50,
          synchronizationSplit: 50,
          role: 'A',
          rightHolderName: 'Derek Andrade',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: '45edb24c-a6ab-11e8-98d0-529269fb1459',
          ownerId: 'ae738506-1d90-4e12-a1dc-b063c7c6167b',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: 'ae738506-1d90-4e12-a1dc-b063c7c6167b',
            name: 'Derek Andrade',
            ipi: 355932144,
            unisonId: 'RMNI64ULS',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
      ],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: '5b45bfbd-7ded-4616-b8ce-22c4a440b568',
      unisonId: 'CO1K5G7NXCLC',
      title: 'SuperMan',
      earnings: [8000, 17000, 11000, 22000, 26000, 84000],
      // language: 'EN',
      // iswc: null,
      // type: 'MTX',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
      // createdAt: '2020-08-16T10:26:48.141Z',
      // updatedAt: '2020-08-16T10:26:48.141Z',
      // copyrightDate: '2020-08-16T10:26:48.127Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: '83723b76-dc71-4d91-b2e5-74432eef9264',
      unisonId: 'CO224T9872RC',
      title: 'KARTH PROTEK',
      earnings: [7000, 16000, 10000, 21000, 25000, 79000],
      // language: 'EN',
      // iswc: 'T2519478837',
      // type: 'MUS',
      // versionType: 'ORI',
      // status: 'ACCEPTED',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: null,
      // createdAt: '2020-08-16T10:22:53.744Z',
      // updatedAt: '2020-08-16T10:22:53.744Z',
      // copyrightDate: '2020-08-16T10:22:51.136Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [
        {
          id: 'b15f8a54-8c57-4a57-8a4d-e211821ec848',
          performanceSplit: 80,
          mechanicalSplit: 80,
          synchronizationSplit: 80,
          role: 'CA',
          rightHolderName: 'LOUD',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: '83723b76-dc71-4d91-b2e5-74432eef9264',
          ownerId: '444a29e4-63aa-4b8f-a749-acaf36000490',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: '444a29e4-63aa-4b8f-a749-acaf36000490',
            name: 'LOUD',
            ipi: 955938923,
            unisonId: 'RM5LZ3D7G',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
        {
          id: 'c2597630-94ca-43d3-8e62-e5a369981bfd',
          performanceSplit: 20,
          mechanicalSplit: 20,
          synchronizationSplit: 20,
          role: 'CA',
          rightHolderName: 'Derek Andrade',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: '83723b76-dc71-4d91-b2e5-74432eef9264',
          ownerId: 'ae738506-1d90-4e12-a1dc-b063c7c6167b',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: 'ae738506-1d90-4e12-a1dc-b063c7c6167b',
            name: 'Derek Andrade',
            ipi: 355932144,
            unisonId: 'RMNI64ULS',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
      ],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
    {
      id: '8d604d57-28a9-4618-8f80-cd3aaba4185a',
      unisonId: 'CO897V8978UC',
      title: 'REMEMBER MY STEPS',
      earnings: [6000, 15000, 9000, 20000, 24000, 74000],
      // language: 'EN',
      // iswc: 'T7469544981',
      // type: 'MTX',
      // versionType: 'ORI',
      // status: 'CONFLICT',
      // musicArrangement: null,
      // lyricAdaptation: null,
      // createdBy: null,
      // createdAt: '2020-08-16T10:22:53.744Z',
      // updatedAt: '2020-08-16T10:22:53.744Z',
      // copyrightDate: '2020-08-16T10:22:51.136Z',
      // submitterWorkN: null,
      // copyrightNumber: null,
      // musicalWorkDistributionCategory: null,
      // duration: null,
      // recordedIndicator: null,
      // compositeType: null,
      // excerptType: null,
      // contactName: null,
      // contactId: null,
      // workType: null,
      // grandRightsIndicator: null,
      // compositeComponentCount: null,
      // datePublicationPrintedEdition: null,
      // exceptionalClause: null,
      // opusNumber: null,
      // catalogueNumber: null,
      // priorityFlag: null,
      ownershipShares: [
        {
          id: '1f701eed-a654-4e83-86f1-f9998a22a517',
          performanceSplit: 50,
          mechanicalSplit: 50,
          synchronizationSplit: 50,
          role: 'CA',
          rightHolderName: 'Rosa Flynn',
          createdAt: '2020-08-16T10:22:53.779Z',
          updatedAt: '2020-08-16T10:22:53.779Z',
          compositionId: '8d604d57-28a9-4618-8f80-cd3aaba4185a',
          ownerId: 'bfa5e27b-5771-4c5d-a178-0dfbd7b45c1a',
          // performanceSocietyId: null,
          // mechanicalSocietyId: null,
          // synchronizationSocietyId: null,
          owner: {
            id: 'bfa5e27b-5771-4c5d-a178-0dfbd7b45c1a',
            name: 'Rosa Flynn',
            ipi: 168618681,
            unisonId: 'RH0ZWF44F',
            // oldUnisonId: null,
            createdAt: '2020-08-16T10:22:53.729Z',
            updatedAt: '2020-08-16T10:22:53.729Z',
            userId: null,
          },
        },
      ],
      // royaltiesPaid: 0,
      // royaltiesPending: 0,
    },
  ],
  yearEarnings: [2016, 2017, 2018, 2019, 2020],
  info: {
    count: 5,
    offset: '0',
    limit: '999999',
  },
};
