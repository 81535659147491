<div class="admin-bar" *ngIf="isAdminLogged">
  <div class="container">
    <div class="row">
      <div class="col-8">
        <span *ngIf="user">
          ADMIN CONNECTED AS: {{user.firstName}} {{user.lastName}} ({{user.email}})
        </span>
        <span *ngIf="!user">
          ADMIN CONNECTED WITHOUT USER SELECTED
        </span>
      </div>
      <div class="col-4 text-right">
        <a routerLink="/auth/select-user">
          {{ 'Select a new User' | translate }}
        </a>
        <a class="ml-3 clickable" (click)="logout()">
          {{ 'Logout' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
