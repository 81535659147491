import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MustShowFinancialDataBarService {
  constructor() {}

  private readonly mustShowFinancialDataBarSubject = new BehaviorSubject<boolean>(true);
  readonly mustShowFinancialDataBar$ = this.mustShowFinancialDataBarSubject.asObservable();

  get mustShowFinancialDataBar(): boolean {
    return this.mustShowFinancialDataBarSubject.getValue();
  }

  set mustShowFinancialDataBar(financialData: boolean) {
    this.mustShowFinancialDataBarSubject.next(financialData);
  }
}
