import { Serializable } from 'app/core/interfaces';

export interface DocumentUnisonDTO {
  id?: string;
  name?: string;
  date?: string;
  test1?: string;
  test2?: string;
}
export class DocumentUnison implements Serializable<DocumentUnison> {
  id: string;
  name: string;
  date: string;
  test1: string;
  test2: string;

  deserialize(input: DocumentUnisonDTO): DocumentUnison {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.name = input.name || '';
    this.date = input.date || '';
    this.test1 = input.test1 || '';
    this.test2 = input.test2 || '';

    return this;
  }

  deserializeArray(inputArray: Array<DocumentUnisonDTO>): Array<DocumentUnison> {
    const documents: DocumentUnison[] = inputArray.map((input) =>
      new DocumentUnison().deserialize(input),
    );

    return documents;
  }
}
