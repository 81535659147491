import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { ROYALTIES_SOURCE_Y, ROYALTIES_SOURCE_QT, ROYALTIES_SOURCE_M } from 'app/shared/mocks';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InfoResponseAPI, RoyaltiesPlatformI, WorkFiltersI } from 'app/shared/interfaces';
import { Platform, Work, WorkRoyalties, WorkRoyaltiesDTO } from 'app/shared/models';

const TIMES = ['y', 'qt', 'm'];
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

interface RoyaltiesByPlatformAPIResponse {
  royalties: Array<RoyaltiesPlatformI>;
}

export interface EarningsByPlatformTrendElement {
  platform: Platform;
  total: number;
  months: Array<{
    month: string;
    earnings: number;
  }>;
}

interface EarningsByPlatformTrend {
  trend: Array<EarningsByPlatformTrendElement>;
}

interface EarningsByWorkResponse {
  earningsByWork: Array<Work>;
  yearEarnings: Array<number>;
  info: InfoResponseAPI;
}

export interface EarningsFiltersI {
  territoryId?: string;
  platformId?: Array<string>;
}

@Injectable({
  providedIn: 'root',
})
export class EarningsService {
  private readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  async getRoyaltiesByDigitalPlatform(
    period: string,
    types: string[],
    filters?: EarningsFiltersI,
  ): Promise<EarningsByPlatformTrend> {
    const url = `${this.apiUrl}/platform/me/earnings/trend`;
    const params = {
      period: period ? period.toString() : '',
      types: types.join(),
      territoryId: filters?.territoryId ? filters?.territoryId : '',
      platformId: filters?.platformId.length > 0 ? filters?.platformId.join() : [],
    };
    const response: EarningsByPlatformTrend = await this.http
      .get<EarningsByPlatformTrend>(url, { params })
      .toPromise();

    return response;
  }

  async getRoyaltiesBySource(timeFilter: string = null): Promise<RoyaltiesPlatformI[]> {
    let filter = '';
    let royaltiesBySource: Array<RoyaltiesPlatformI> = [];
    if (environment.useMocks) {
      if (timeFilter === TIMES[0]) {
        royaltiesBySource = ROYALTIES_SOURCE_Y;
      } else if (timeFilter === TIMES[1]) {
        royaltiesBySource = ROYALTIES_SOURCE_QT;
      } else if (timeFilter === TIMES[2]) {
        royaltiesBySource = ROYALTIES_SOURCE_M;
      }
    } else {
      if (timeFilter) {
        filter += `&period=${timeFilter}`;
      }
      const url = `${this.apiUrl}/earnings-source/${filter}`; // TODO: Endpoint
      const response: RoyaltiesByPlatformAPIResponse = await this.http
        .get<RoyaltiesByPlatformAPIResponse>(url, httpOptions)
        .toPromise();
      royaltiesBySource = response.royalties;
    }
    return royaltiesBySource;
  }

  async getWorksEarningsByYear(
    offset: number,
    limit: number,
    startYear: number,
    endYear: number,
    filters: WorkFiltersI,
  ): Promise<// EarningsByWorkResponseAPII |
  { workRoyalties: WorkRoyalties[]; info: InfoResponseAPI }> {
    let queryString = `?offset=${offset}&limit=${limit}`;

    if (startYear) {
      queryString += `&startYear=${startYear}`;
    }
    if (endYear) {
      queryString += `&endYear=${endYear}`;
    }
    if (filters && filters.text) {
      queryString += `&text=${filters.text}`;
    }

    const url = `${this.apiUrl}/composition/me/earnings/yearly/${queryString}`;

    const { works: workRoyaltiesApi, info } = await this.http
      .get<{ works: WorkRoyaltiesDTO[]; info: InfoResponseAPI }>(url)
      .toPromise();

    const workRoyalties = new WorkRoyalties().deserializeArray(workRoyaltiesApi);
    return {
      workRoyalties,
      info,
    };
  }
}
