import { Serializable } from 'app/core/interfaces';
// eslint-disable-next-line import/no-cycle
import { Work, WorkDTO } from './work';
import { Message, MessageDTO } from './message';

export enum TicketStatus {
  CLOSED = 'closed',
  OPEN = 'open',
}
export interface TicketDTO {
  id: string;
  unisonId: string;
  status: string;
  topic: string;
  description: string;
  compositionId: string;
  updatedAt: string;
  createdAt: string;
  composition?: WorkDTO;
  messages?: MessageDTO[];
}

export class Ticket implements Serializable<Ticket> {
  id: string;
  unisonId: string;
  status: string;
  topic: string;
  description: string;
  workId: string;
  updatedAt: string;
  createdAt: string;
  work?: Work;
  messages?: Message[];

  deserialize(input: TicketDTO): Ticket {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.unisonId = input.unisonId || '';
    this.status = input.status || '';
    this.topic = input.topic || '';
    this.description = input.description || '';
    this.workId = input.compositionId || '';
    this.updatedAt = input.updatedAt || '';
    this.createdAt = input.createdAt || '';
    this.work = input.composition ? new Work().deserialize(input.composition) : null;
    this.messages = input.messages ? new Message().deserializeArray(input.messages) : [];

    return this;
  }

  deserializeArray(inputArray: Array<TicketDTO>): Array<Ticket> {
    const tickets: Array<Ticket> = inputArray.map((input) => new Ticket().deserialize(input));

    return tickets;
  }
}
