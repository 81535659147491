import { OwnershipShareDTO } from 'app/shared/models';

export const OWNERSHIPSHARES_MOCK: Array<OwnershipShareDTO> = [
  {
    ownerId: '89c3d995-96f9-41e2-aae3-f59e6447436c',
    compositionId: '45edb24c-a6ab-11e8-98d0-529269fb1459',
    performanceSplit: 50,
    mechanicalSplit: 50,
    synchronizationSplit: 50,
    // performanceSocietyId: null,
    // mechanicalSocietyId: null,
    // synchronizationSocietyId: null,
    role: 'CA',
    rightHolderName: 'Sioned Marin',
    id: '953a697e-1360-4e9a-bf33-8c71ab8f30f3',
    owner: {
      id: '89c3d995-96f9-41e2-aae3-f59e6447436c',
      name: 'Sioned Marin',
      ipi: 437219252,
      unisonId: 'RGLKATRPY',
      // oldUnisonId: null,
      createdAt: '2020-08-16T10:22:53.729Z',
      updatedAt: '2020-08-16T10:22:53.729Z',
      userId: null,
      // user: null,
    },
  },
  {
    ownerId: 'ae738506-1d90-4e12-a1dc-b063c7c6167b',
    compositionId: '45edb24c-a6ab-11e8-98d0-529269fb1459',
    performanceSplit: 50,
    mechanicalSplit: 50,
    synchronizationSplit: 50,
    // performanceSocietyId: null,
    // mechanicalSocietyId: null,
    // synchronizationSocietyId: null,
    role: 'A',
    rightHolderName: 'Derek Andrade',
    id: 'e0a2a6fb-5f75-4384-8849-9e2f1c3f2f92',
    owner: {
      id: 'ae738506-1d90-4e12-a1dc-b063c7c6167b',
      name: 'Derek Andrade',
      ipi: 355932144,
      unisonId: 'RMNI64ULS',
      // oldUnisonId: null,
      createdAt: '2020-08-16T10:22:53.729Z',
      updatedAt: '2020-08-16T10:22:53.729Z',
      userId: null,
      // user: null,
    },
  },
];
