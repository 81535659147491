import { Serializable } from 'app/core/interfaces';
// eslint-disable-next-line import/no-cycle
import { WorkDTO, Work } from './work';

export interface YearEarning {
  year: string;
  earnings: number;
}

export class WorkRoyaltiesDTO {
  composition?: WorkDTO;
  work?: WorkDTO;
  owners?: string;
  total?: number;
  years?: YearEarning[];
}

export class WorkRoyalties implements Serializable<WorkRoyalties> {
  work?: Work;
  owners?: string;
  total?: number;
  years?: YearEarning[];

  deserialize(input: WorkRoyaltiesDTO): WorkRoyalties {
    if (!input) {
      return this;
    }

    this.work = input.composition ? new Work().deserialize(input.composition) : null;

    if (input.work) {
      this.work = new Work().deserialize(input.work) || null;
    }
    this.owners = input.owners;
    this.total = input.total || -1;
    this.years = input.years;

    return this;
  }

  deserializeArray(inputArray: Array<WorkRoyaltiesDTO>): Array<WorkRoyalties> {
    const worksRoyalties: Array<WorkRoyalties> = inputArray.map((input) =>
      new WorkRoyalties().deserialize(input),
    );

    return worksRoyalties;
  }

  clone(): WorkRoyalties {
    const clonedInstance: WorkRoyalties = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this,
    );

    return clonedInstance;
  }

  get totals(): number {
    const { years } = this;

    const total: number = years
      .map((year: YearEarning) => year.earnings)
      .reduce((a, b) => a + b, 0);
    return total;
  }
}
