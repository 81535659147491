import { Serializable } from 'app/core/interfaces';

export interface MonthlyRoyaltiesTrend {
  month: string;
  earnings: number;
}

export class TrendDTO {
  type: string;
  months: MonthlyRoyaltiesTrend[];
}

export class Trend implements Serializable<Trend> {
  type: string;
  months: MonthlyRoyaltiesTrend[];

  deserialize(input: TrendDTO): Trend {
    if (!input) {
      return this;
    }

    this.type = input.type || '';
    this.months = input.months ? input.months : [];

    return this;
  }

  deserializeArray(inputArray: Array<TrendDTO>): Array<Trend> {
    const trends: Trend[] = inputArray.map((input) => new Trend().deserialize(input));

    return trends;
  }

  clone(): Trend {
    const clonedInstance: Trend = Object.assign(Object.create(Object.getPrototypeOf(this)), this);

    return clonedInstance;
  }
}
