import { Serializable } from 'app/core/interfaces';

export interface LanguageDTO {
  value: string;
  name: string;
}

export class Language implements Serializable<Language> {
  value: string;
  name: string;

  deserialize(input: LanguageDTO): Language {
    if (!input) {
      return this;
    }

    this.name = input.name || '';
    this.value = input.value || null;

    return this;
  }

  deserializeArray(inputArray: Array<LanguageDTO>): Array<Language> {
    const languages: Language[] = inputArray.map((input) => new Language().deserialize(input));

    return languages;
  }

  clone(): Language {
    const clonedInstance: Language = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this,
    );

    return clonedInstance;
  }
}
