import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import {
  CatalogLiquidation,
  CatalogLiquidationDTO,
  Liquidation,
  LiquidationDTO,
  StatementStatus,
  UsageTypes,
} from 'app/shared/models';
import { InfoResponseAPI } from 'app/shared/interfaces';
import { LIQUIDATIONS_MOCK, LIQUIDATIONS_MOCK_PART } from 'app/shared/mocks';

export interface LiquidationsResponse {
  liquidations: Liquidation[];
  info: InfoResponseAPI;
}

export interface LiquidationsFilterI {
  status?: StatementStatus | '';
  type?: UsageTypes | '' | string;
  dateFrom?: string;
  dateTo?: string;
  clientStatus?: StatementStatus | '';
}

@Injectable()
export class LiquidationService {
  readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  private readonly liquidationSubject = new BehaviorSubject<Liquidation>(null);
  readonly currentLiquidation$ = this.liquidationSubject.asObservable();

  get currentLiquidation(): Liquidation {
    return this.liquidationSubject.getValue();
  }

  set currentLiquidation(liquidation: Liquidation) {
    this.liquidationSubject.next(liquidation);
  }

  async getLiquidations(
    offset: number,
    limit: number,
    filters?: LiquidationsFilterI,
  ): Promise<LiquidationsResponse> {
    // eslint-disable-next-line max-len
    // const queryString = `?offset=${offset}&limit=${limit}&type=${filters?.type}&status=${filters?.status}&from=${filters?.from}&to=${filters?.to}`;

    if (environment.useMocks) {
      const allLiquidations = new Liquidation().deserializeArray(LIQUIDATIONS_MOCK);

      const liquidationsFetched = allLiquidations.slice(offset, offset + limit);

      return {
        liquidations: liquidationsFetched,
        info: {
          count: allLiquidations.length,
          offset: offset.toString(),
          limit: limit.toString(),
        },
      };
    }
    const url = `${this.apiUrl}/liquidation/me/`;

    const params = {
      offset: offset ? offset.toString() : '',
      limit: limit ? limit.toString() : '',
      ...filters,
    };

    const { liquidations: LiquidationsApi, info } = await this.http
      .get<{ liquidations: Array<LiquidationDTO>; info: InfoResponseAPI }>(url, { params })
      .toPromise();

    return {
      liquidations: new Liquidation().deserializeArray(LiquidationsApi),
      info,
    };
  }

  async getLiquidation(liquidationId: string): Promise<Liquidation> {
    if (environment.useMocks) {
      const liquidationMock = LIQUIDATIONS_MOCK_PART.filter(
        (liquidation: LiquidationDTO) => liquidation.id === liquidationId,
      );
      return new Liquidation().deserialize(liquidationMock[0]);
    }

    const url = `${this.apiUrl}/liquidation/${liquidationId}/`;

    const { liquidation: liquidationApi } = await this.http
      .get<{ liquidation: LiquidationDTO }>(url)
      .toPromise();

    return new Liquidation().deserialize(liquidationApi);
  }

  async deleteLiquidation(liquidationdId: string): Promise<void> {
    const url = `${this.apiUrl}/liquidation/${liquidationdId}/`;

    await this.http.delete<void>(url).toPromise();
  }

  async getCatalogLiquidations(
    liquidationId: string,
  ): Promise<{ catalogLiquidations: CatalogLiquidation[] }> {
    const url = `${this.apiUrl}/liquidation/${liquidationId}/catalog`;

    const { catalogLiquidations: catalogLiquidationsApi } = await this.http
      .get<{ catalogLiquidations: Array<CatalogLiquidationDTO> }>(url)
      .toPromise();

    return {
      catalogLiquidations: new CatalogLiquidation().deserializeArray(catalogLiquidationsApi),
    };
  }

  async getCatalogShares(
    liquidationId: string,
    catalogId: string,
  ): Promise<{ catalogLiquidation: CatalogLiquidation }> {
    const url = `${this.apiUrl}/liquidation/${liquidationId}/catalog/${catalogId}`;

    const { catalogLiquidation: catalogLiquidationApi } = await this.http
      .get<{ catalogLiquidation: CatalogLiquidationDTO }>(url)
      .toPromise();

    return {
      catalogLiquidation: new CatalogLiquidation().deserialize(catalogLiquidationApi),
    };
  }

  downloadCatalogLiquidationToCSV(liquidationId: string, catalogId: string): Observable<any> {
    const url = `${this.apiUrl}/liquidation/${liquidationId}/catalog/${catalogId}/csv`;

    return this.http.get(url, {
      observe: 'events', // 'body'
      responseType: 'blob',
      reportProgress: false,
    });
  }

  downloadCatalogLiquidationsToCSV(
    liquidationId: string,
    catalogIds: Array<string>,
  ): Observable<any> {
    const url = `${this.apiUrl}/liquidation/${liquidationId}/catalog/csv`;

    // const params = {
    //   ids: catalogIds.join(),
    // };

    return this.http.get(url, {
      observe: 'events', // 'body'
      params: { ids: catalogIds.join() },
      responseType: 'blob',
      reportProgress: false,
    });
  }

  async generateLiquidationCSV(liquidationId: string): Promise<void> {
    const url = `${this.apiUrl}/liquidation/${liquidationId}/csv`;
    await this.http.get(url).toPromise();
  }

  async generateLiquidationEXCEL(liquidationId: string): Promise<void> {
    const url = `${this.apiUrl}/liquidation/${liquidationId}/excel`;
    await this.http.get(url).toPromise();
  }

  async generateLiquidationLightReportCSV(liquidationId: string): Promise<void> {
    const url = `${this.apiUrl}/liquidation/${liquidationId}/light-csv`;
    await this.http.get(url).toPromise();
  }

  downloadStatementPdfById(statementId: string): Observable<any> {
    const url = `${this.apiUrl}/statement/${statementId}/pdf`;

    return this.http.get(url, {
      observe: 'events', // 'body'
      responseType: 'blob',
      reportProgress: false,
    });
  }

  // updateLiquidationStatus(id, data) {
  //   const url = `${this.apiUrl}/liquidation/${id}/status`;
  //   return this.http.put(url, data).pipe(
  //     tap(() => this.log(`updated status from liquidation with id = ${id}`)),
  //     catchError(this.handleError),
  //   );
  // }

  // generateLiquidations() {
  //   const url = `${this.apiUrl}/liquidation/emit/`;
  //   // Canviar per post
  //   return this.http.post(url, null).pipe(
  //     tap(() => this.log('generated liquidations')),
  //     catchError(this.handleError),
  //   );
  // }
}
