import { WorkDTO } from 'app/shared/models';

export const WORKS_USAGES_BACKGROUND_MOCK: Array<WorkDTO> = [
  {
    id: '45edb24c-a6ab-11e8-98d0-529269fb1459',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    date: new Date('2016-02-04 18:33:41'),
  },
  {
    id: '96276ef0-6ad9-48bd-af2d-c18a87a5ee77',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    date: new Date('2019-03-05 19:44:41'),
  },
  {
    id: '316ba330-6d0c-4f1d-917e-b1ac8a8e4626',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    date: new Date('2020-01-04 17:33:41'),
  },
  {
    id: '38b57c44-2670-4cb1-870e-e2dd24011f17',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    date: new Date('2020-04-04 20:33:41'),
  },
  {
    id: '7dec9c49-906b-4a9f-a832-853029ff2917',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    date: new Date('2016-02-04 18:33:41'),
  },
  {
    id: '06b6ab38-b68a-4d41-bc06-53ff6195410f',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    date: new Date('2019-03-05 19:44:41'),
  },
  {
    id: 'ba9fea2f-1a11-4bb3-97ba-68e47e0d5b8e',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    date: new Date('2020-01-04 17:33:41'),
  },
  {
    id: 'afffe2d6-3016-4b22-a505-e6b0705a07ec',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    date: new Date('2020-04-04 20:33:41'),
  },
  {
    id: '931736af-6a8d-408b-8144-d377aa2044a0',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    date: new Date('2016-02-04 18:33:41'),
  },
  {
    id: 'ee47415f-f08c-4916-9c96-ed2424e9ed03',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    date: new Date('2019-03-05 19:44:41'),
  },
  {
    id: '04318f69-bb60-4753-95d5-24b374e86002',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    date: new Date('2020-01-04 17:33:41'),
  },
  {
    id: 'e59be95c-dfff-4ea3-9f75-2c94dbbddde3',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    date: new Date('2020-04-04 20:33:41'),
  },
  {
    id: '168207ea-12a4-4be1-9dbf-2a0e7c2cd34c',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    date: new Date('2016-02-04 18:33:41'),
  },
  {
    id: 'cdaea2a4-049c-4a97-8772-4123d70522b0',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    date: new Date('2019-03-05 19:44:41'),
  },
  {
    id: '3a7231ee-1322-4b66-89e1-2af1fb4d1a51',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    date: new Date('2020-01-04 17:33:41'),
  },
  {
    id: 'b99dc3bc-73bb-45c4-8bb2-53fe3d613cf1',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    date: new Date('2020-04-04 20:33:41'),
  },
  {
    id: '64d120a3-d109-4ef7-9751-8e8aa2df49e6',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    date: new Date('2016-02-04 18:33:41'),
  },
  {
    id: '5fd9d979-3fa0-49e1-be4e-8a6f58c1bea7',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    date: new Date('2019-03-05 19:44:41'),
  },
  {
    id: '2d349453-0329-4fc8-9b85-183876fd61e3',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    date: new Date('2020-01-04 17:33:41'),
  },
  {
    id: '128988a6-f96d-4083-ab65-5d8a64f65eca',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    date: new Date('2020-04-04 20:33:41'),
  },
  {
    id: 'c9363d7e-322a-4060-972c-eb7c2fba8684',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    date: new Date('2016-02-04 18:33:41'),
  },
  {
    id: '53f101f4-98c8-434e-9c24-c28c124e3b40',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    date: new Date('2019-03-05 19:44:41'),
  },
  {
    id: '14ef25d6-734b-4f7c-adc9-d98c1d254d58',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    date: new Date('2020-01-04 17:33:41'),
  },
  {
    id: 'e208420f-6550-472c-89e9-d036a6ed3e4f',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    date: new Date('2020-04-04 20:33:41'),
  },
  {
    id: 'dcca5306-92c6-48f4-8622-81c4ba7686fb',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    date: new Date('2016-02-04 18:33:41'),
  },
  {
    id: '4fc57796-b974-40a9-a7f7-cb0636aafd04',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    date: new Date('2019-03-05 19:44:41'),
  },
  {
    id: '7bb1856b-b3d6-4d1b-b7ca-4e5f70adbc49',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    date: new Date('2020-01-04 17:33:41'),
  },
  {
    id: 'e0e5bc9e-5bbc-41a4-8343-0e4119566991',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    date: new Date('2020-04-04 20:33:41'),
  },
  {
    id: '6c08f10d-a1a5-49e2-8142-e648cd779d66',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    date: new Date('2016-02-04 18:33:41'),
  },
  {
    id: '56dfe890-f0f3-4263-97f9-021fed63dfac',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    date: new Date('2019-03-05 19:44:41'),
  },
  {
    id: '2176978b-7e53-4fa1-b1bd-accd4e43fda1',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    date: new Date('2020-01-04 17:33:41'),
  },
  {
    id: '5fbccec7-1681-4ff7-b757-7b2db63f4be9',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    date: new Date('2020-04-04 20:33:41'),
  },
  {
    id: '2f973929-2631-46fa-b4a7-f2491d339483',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    date: new Date('2016-02-04 18:33:41'),
  },
  {
    id: 'd90c8be8-422e-4da4-8d8d-3bcbdcd9063c',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    date: new Date('2019-03-05 19:44:41'),
  },
  {
    id: '1f873ce3-f80a-4e2f-8d73-1e20b84f0e05',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    date: new Date('2020-01-04 17:33:41'),
  },
  {
    id: '85590204-5b36-4f7c-ab9e-9d8f03a6565e',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    date: new Date('2020-04-04 20:33:41'),
  },
  {
    id: '850b2d92-3511-44e2-97bf-28fe93c06eb8',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    date: new Date('2016-02-04 18:33:41'),
  },
  {
    id: 'b7bea467-be3b-4fb0-8daa-9a3e397edf64',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    date: new Date('2019-03-05 19:44:41'),
  },
  {
    id: 'c8b25801-5007-4501-be55-03c7e589daec',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    date: new Date('2020-01-04 17:33:41'),
  },
  {
    id: '60b4787e-50c4-4272-a869-0f0bf4442929',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    date: new Date('2020-04-04 20:33:41'),
  },
  {
    id: '167f34cd-b674-4aa9-bcad-665663a47b03',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    date: new Date('2016-02-04 18:33:41'),
  },
  {
    id: '25f075f9-00b1-4697-a18f-7ae8125d99ae',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    date: new Date('2019-03-05 19:44:41'),
  },
  {
    id: '1b1bc110-ac7d-474b-8ec3-f2fabf5609d6',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    date: new Date('2020-01-04 17:33:41'),
  },
  {
    id: '97a97cad-329c-4245-acad-40ecbb52f860',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    date: new Date('2020-04-04 20:33:41'),
  },
  {
    id: 'ddd43b81-af5e-41c7-a44b-cf42eb5d186b',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    date: new Date('2016-02-04 18:33:41'),
  },
  {
    id: 'debae3d6-8f38-4b28-96da-c4d9f4a4ece6',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    date: new Date('2019-03-05 19:44:41'),
  },
  {
    id: '30c18a35-b665-467e-add7-7d26fd25f840',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    date: new Date('2020-01-04 17:33:41'),
  },
  {
    id: '05a66666-31a0-411a-9901-820aaa345c5a',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    date: new Date('2020-04-04 20:33:41'),
  },
  {
    id: '8680d4d9-b8fe-4624-a2da-5f7e0f2c5b33',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
    consumer: 'Zara',
    country: 'Spain',
    date: new Date('2016-02-04 18:33:41'),
  },
  {
    id: 'e72bb431-9825-4b5a-8fc9-00c38ad198ff',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'H&M',
    country: 'Italy',
    date: new Date('2019-03-05 19:44:41'),
  },
  {
    id: '7e77ec8d-a958-4093-9b7b-0ad72435512a',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
    consumer: 'Tesco',
    country: 'United Kingdom',
    date: new Date('2020-01-04 17:33:41'),
  },
  {
    id: '2f4c3f82-38a1-4e3c-8209-1057ffd2b9a2',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
    consumer: 'Auchamp',
    country: 'France',
    date: new Date('2020-04-04 20:33:41'),
  },
];
