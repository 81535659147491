import { WorkUsageI } from 'app/shared/interfaces';

export const WORK_USAGES_Y: Array<WorkUsageI> = [
  {
    source: 'Radio/TV',
    plays: 10324,
  },
  {
    source: 'Background music',
    plays: 2334,
  },
  {
    source: 'Live',
    plays: 20000,
  },
  {
    source: 'Digital',
    plays: 35000,
  },
];
export const WORK_USAGES_QT: Array<WorkUsageI> = [
  {
    source: 'Radio/TV',
    plays: 5324,
  },
  {
    source: 'Background music',
    plays: 1334,
  },
  {
    source: 'Live',
    plays: 10000,
  },
  {
    source: 'Digital',
    plays: 23000,
  },
];
export const WORK_USAGES_M: Array<WorkUsageI> = [
  {
    source: 'Radio/TV',
    plays: 1324,
  },
  {
    source: 'Background music',
    plays: 834,
  },
  {
    source: 'Live',
    plays: 1000,
  },
  {
    source: 'Digital',
    plays: 5000,
  },
];
