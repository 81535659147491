<div class="sidebar-container d-flex flex-column h-100">
  <a routerLink="/">
    <img
      class="logo mb-4"
      src="/assets/images/logo-unison-white.png"
      alt="Unison"
    />
  </a>
  <app-sidebar-menu></app-sidebar-menu>
  <div class="legal-menu d-block mt-auto">
    <a class="legal-item-link py-0" routerLink="/legal/legal-disclaimer">{{"AUTH_DISCLAIMER.LEGAL_DISCLAIMER_SIDEBAR" | translate}}</a>
    <a class="legal-item-link py-0" routerLink="/legal/legal-advice">{{"LEGAL_ADVICE.LEGAL_NOTICE" | translate}}</a>
    <a class="legal-item-link py-0" routerLink="/legal/privacy-policy">{{"PRIVACY_POLICY.PRIVACY_POLICY" | translate}}</a>
    <a class="legal-item-link py-0" routerLink="/legal/cookies-policy">{{"COOKIES_POLICY.COOKIES_POLICY" | translate}}</a>
  </div>
</div>

