import { WorkRoyaltiesPlatformI } from 'app/shared/interfaces';

export const WORK_EARNINGS_Y: Array<WorkRoyaltiesPlatformI> = [
  {
    source: 'Spotify',
    royalties: 10324,
  },
  {
    source: 'Deezer',
    royalties: 2334,
  },
  {
    source: 'Beatport',
    royalties: 20000,
  },
  {
    source: 'Apple Music',
    royalties: 35000,
  },
];
export const WORK_EARNINGS_QT: Array<WorkRoyaltiesPlatformI> = [
  {
    source: 'Spotify',
    royalties: 5324,
  },
  {
    source: 'Deezer',
    royalties: 1334,
  },
  {
    source: 'Beatport',
    royalties: 10000,
  },
  {
    source: 'Apple Music',
    royalties: 23000,
  },
];
export const WORK_EARNINGS_M: Array<WorkRoyaltiesPlatformI> = [
  {
    source: 'Spotify',
    royalties: 1324,
  },
  {
    source: 'Deezer',
    royalties: 834,
  },
  {
    source: 'Beatport',
    royalties: 1000,
  },
  {
    source: 'Apple Music',
    royalties: 5000,
  },
];
