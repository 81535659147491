import { WorkDTO } from 'app/shared/models';

export const WORK_SINGLE_MOCK: WorkDTO = {
  id: '45edb24c-a6ab-11e8-98d0-529269fb1459',
  unisonId: 'CO1V029Q184C',
  title: 'FLEEING CHANCES',
  iswc: 'T2649413281',
  type: 'MTX',
  versionType: 'ORI',
  status: 'ACCEPTED',
  musicArrangement: null,
  lyricAdaptation: null,
  // copyrightDate: '2020-08-16T10:22:51.136Z',
  // submitterWorkN: null,
  // copyrightNumber: null,
  // musicalWorkDistributionCategory: null,
  duration: null,
  // recordedIndicator: null,
  // compositeType: null,
  // excerptType: null,
  // contactName: null,
  // contactId: null,
  workType: null,
  // grandRightsIndicator: null,
  // compositeComponentCount: null,
  // datePublicationPrintedEdition: null,
  // exceptionalClause: null,
  // opusNumber: null,
  // catalogueNumber: null,
  // priorityFlag: null,
  createdAt: '2020-08-16T10:22:53.744Z',
  updatedAt: '2020-08-16T10:22:53.744Z',
  // createdBy: null,
  language: 'EN',
  // creator: null,
};
