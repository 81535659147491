import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reunderscapi',
})
export class RemoveUnderscoreAndCapitalizePipe implements PipeTransform {
  transform(value: string /* , args: string */): string {
    if (value) {
      const capitalized = `${value.charAt(0).toUpperCase()}${value.slice(1).toLowerCase()}`;
      return capitalized.replace('_', ' ');
    }

    return value;
  }
}
