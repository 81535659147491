import { WorkUsageI } from 'app/shared/interfaces';

export const USAGES_LIVE_PLAYS_Y: Array<WorkUsageI> = [
  {
    source: 'Live',
    plays: 1600,
  },
  {
    source: 'Live Streaming',
    plays: 360,
  },
];

export const USAGES_LIVE_PLAYS_QT: Array<WorkUsageI> = [
  {
    source: 'Live',
    plays: 560,
  },
  {
    source: 'Live Streaming',
    plays: 120,
  },
];

export const USAGES_LIVE_PLAYS_M: Array<WorkUsageI> = [
  {
    source: 'Live',
    plays: 140,
  },
  {
    source: 'Live Streaming',
    plays: 30,
  },
];
