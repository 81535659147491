import { Serializable } from 'app/core/interfaces';
import { Consumer, ConsumerDTO } from './consumer';
// eslint-disable-next-line import/no-cycle
import { Recording, RecordingDTO } from './recording';
import { Territory, TerritoryDTO } from './territory';
// eslint-disable-next-line import/no-cycle
import { UsageShare, UsageShareDTO } from './usageShare';
// eslint-disable-next-line import/no-cycle
import { WorkDTO, Work } from './work';

export enum UsageStatus {
  CONFIRMATION_PENDING = 'confirmation_pending',
  INVOICE_PENDING = 'invoice_pending',
  INVOICED = 'invoiced',
}

export enum UsageTypes {
  RADIO_TV = 'radiotv',
  BACKGROUND_MUSIC = 'background_music',
  DIGITAL = 'digital',
  LIVE = 'live',
}

export interface UsageDTO {
  id: string;
  amount: number;
  artistName: string;

  consumerId: string;
  datetime: string;
  invoiceId: string;
  recording: RecordingDTO;
  recordingId: string;
  territory: TerritoryDTO;
  territoryId: string;
  type: UsageTypes;
  status: UsageStatus;
  legacyId: string;
  dateFrom: string;
  dateTo: string;
  duration: number;
  createdAt: string;
  updatedAt: string;
  work?: WorkDTO;

  composition?: WorkDTO;
  consumer: ConsumerDTO;
  owners: string;
  plays: number;

  usageShares: Array<UsageShareDTO>;
}

export class Usage implements Serializable<Usage> {
  id: string;
  amount: number;
  artistName: string;

  consumerId: string;
  datetime: string;
  invoiceId: string;
  recording: Recording;
  recordingId: string;
  territory: Territory;
  territoryId: string;
  type: UsageTypes;
  legacyId: string;
  dateFrom: string;
  dateTo: string;
  duration: number;
  createdAt: string;
  updatedAt: string;
  status: UsageStatus;

  work: Work;
  consumer: Consumer;
  ownersToShow: string;
  plays: number;

  usageShares: Array<UsageShare>;

  deserialize(input: UsageDTO): Usage {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.amount = input.amount || 0;
    this.artistName = input.artistName || '';

    this.consumerId = input.consumerId || '';
    this.datetime = input.datetime || '';
    this.invoiceId = input.invoiceId || '';
    this.recording = new Recording().deserialize(input.recording);
    this.recordingId = input.recordingId || '';
    this.territory = new Territory().deserialize(input.territory);
    this.territoryId = input.territoryId || '';
    this.type = input.type || UsageTypes.DIGITAL;
    this.status = input.status || UsageStatus.CONFIRMATION_PENDING;
    this.legacyId = input.legacyId || '';
    this.dateFrom = input.dateFrom || '';
    this.dateTo = input.dateTo || '';
    this.duration = input.duration || 0;
    this.createdAt = input.createdAt || '';
    this.updatedAt = input.updatedAt || '';

    if (input.composition) {
      this.work = new Work().deserialize(input.composition);
    }

    if (input.work) {
      this.work = new Work().deserialize(input.work);
    }

    this.consumer = input.consumer ? new Consumer().deserialize(input.consumer) : null;
    this.plays = input.plays || 0;
    this.ownersToShow = input.owners || '';

    this.usageShares = input.usageShares
      ? new UsageShare().deserializeArray(input.usageShares)
      : [];

    return this;
  }

  get owners(): string {
    const { usageShares } = this;

    const rightHoldersNames: Array<string> = usageShares.map(
      (usageShare) => usageShare.share?.rightHolder?.name,
    );

    return [...new Set(rightHoldersNames)].join(' & ');
  }

  deserializeArray(inputArray: Array<UsageDTO>): Array<Usage> {
    const usages: Array<Usage> = inputArray.map((input) => new Usage().deserialize(input));

    return usages;
  }

  clone(): Usage {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const clonedInstance: Usage = Object.assign(Object.create(Object.getPrototypeOf(this)), this);

    return clonedInstance;
  }
}
