import { UserDTO } from 'app/shared/models/user';

export const USER_MOCK: UserDTO = {
  id: '9ca7b23f-05fd-4140-b463-d04be0589c08',
  email: 'rtarre@nakima.es',
  firstName: 'Robert',
  lastName: 'Tarré',
  businessName: null,
  role: 'right_holder',
  nif: null,
  address: null,
  city: null,
  postalCode: null,
  country: null,
  commissionDigital: 10,
  commissionRadioTV: 10,
  commissionLive: 9,
  commissionBackground: 20,
  status: 'active',
  // token: 'q8IZ3kqSL362aCsc7YoH9vl626QxP5',
  // financialAdminId: null,
  rightHolders: [
    {
      id: 'e7dfb2c2-9681-4388-8622-8c6d1d505d57',
      name: 'JGrau productions',
      ipi: 244969234,
      unisonId: 'RJXUFQGNU',
      createdAt: '2020-06-25T10:43:20.375Z',
      updatedAt: '2020-06-25T10:43:20.375Z',
      userId: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
      performanceSocietyId: null,
      mechanicalSocietyId: null,
      synchronizationSocietyId: null,
    },
    {
      id: '8e15bdeb-e549-46c2-80d9-f19b1b8eb736',
      name: 'Test',
      ipi: 1234512345,
      unisonId: 'RK3CPPBMV',
      createdAt: '2020-07-01T14:26:02.468Z',
      updatedAt: '2020-07-01T14:26:02.468Z',
      userId: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
      performanceSocietyId: null,
      mechanicalSocietyId: null,
      synchronizationSocietyId: null,
    },
    {
      id: '2c620523-3470-4e27-b6ff-b4e3866955aa',
      name: 'test nat',
      ipi: 666626,
      unisonId: 'R5QT567HU',
      createdAt: '2020-07-01T14:26:21.653Z',
      updatedAt: '2020-07-01T14:26:21.653Z',
      userId: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
      performanceSocietyId: null,
      mechanicalSocietyId: null,
      synchronizationSocietyId: null,
    },
  ],
};
