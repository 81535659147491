import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class CancelHttpService {
  private pendingHTTPRequests$ = new Subject<void>();

  constructor() {}

  public cancelPendingRequests(): void {
    this.pendingHTTPRequests$.next();
  }

  public onCancelPendingRequests(): any {
    return this.pendingHTTPRequests$.asObservable();
  }
}
