import { WorkDTO } from 'app/shared/models';

export const WORKS_ROYALTIES_MOCK: Array<WorkDTO> = [
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'KARTH PROTEK',
    workUsages: 1000,
    recordingUsages: 1752,
    totalEarnings: 1021,
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'ALTERED CHOICES',
    workUsages: 2000,
    recordingUsages: 1538,
    totalEarnings: 440,
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'REMEMBER MY STEPS',
    workUsages: 10,
    recordingUsages: 35,
    totalEarnings: 592,
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'FLEEING CHANCES',
    workUsages: 1000,
    recordingUsages: 1643,
    totalEarnings: 418,
  },
];
