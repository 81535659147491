import { Serializable } from 'app/core/interfaces';
import { Performer, PerformerDTO } from './performer';
// eslint-disable-next-line import/no-cycle
import { Work, WorkDTO } from './work';
// import { RecordingUsage } from './recordingUsage';
// import { Performer } from './performer';

export interface RecordingDTO {
  id: string;
  unisonId: string;
  firstReleaseDate: string;
  firstReleaseDuration: string;
  firstAlbumTitle: string;
  firstAlbumLabel: string;
  firstReleaseCatalogNumber: string;
  ean: string;
  recordingFormat: string;
  mediaType: string;
  isrc: string;
  title: string;
  work?: WorkDTO;
  workId: string;
  compositionId: string;
  composition?: WorkDTO;
  recordingTechnique: string;
  // recordingUsages: RecordingUsage[];
  performers: PerformerDTO[];
  updatedAt?: string;
  createdAt?: string;
}
export class Recording implements Serializable<Recording> {
  id: string;
  unisonId: string;
  firstReleaseDate: string;
  firstReleaseDuration: string;
  firstAlbumTitle: string;
  firstAlbumLabel: string;
  firstReleaseCatalogNumber: string;
  ean: string;
  recordingFormat: string;
  recordingTechnique: string;
  mediaType: string;
  isrc: string;
  title: string;
  // work: Work;
  workId: string;
  work: Work;
  performers: Performer[];
  // recordingUsages: RecordingUsage[];
  // performers: Performer[];
  // updatedAt: string;
  // createdAt: string;

  deserialize(input: RecordingDTO): Recording {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.unisonId = input.unisonId || '';
    this.firstReleaseDate = input.firstReleaseDate || '';
    this.firstReleaseDuration = input.firstReleaseDuration || '';
    this.firstAlbumTitle = input.firstAlbumTitle || '';
    this.firstAlbumLabel = input.firstAlbumLabel || '';
    this.firstReleaseCatalogNumber = input.firstReleaseCatalogNumber || '';
    this.ean = input.ean || '';
    this.recordingFormat = input.recordingFormat || '';
    this.mediaType = input.mediaType || '';
    this.isrc = input.isrc || '';
    this.title = input.title || '';
    this.workId = input.workId || '';
    this.performers = input.performers ? new Performer().deserializeArray(input.performers) : [];
    if (input.work) {
      this.work = input.work ? new Work().deserialize(input.work) : null;
    }
    if (input.composition) {
      this.work = input.composition ? new Work().deserialize(input.composition) : null;
    }
    this.workId = input.compositionId || '';
    // this.recordingUsages = input.recordingUsages || '';
    // this.performers = input.performers ? new Performer().deserializeArray(input.performers) || [];
    // this.updatedAt = input.updatedAt || '';
    // this.createdAt = input.createdAt || '';

    return this;
  }

  deserializeArray(inputArray: Array<RecordingDTO>): Array<Recording> {
    const recordings: Array<Recording> = inputArray.map((input) =>
      new Recording().deserialize(input),
    );

    return recordings;
  }

  clone(): Recording {
    const clonedInstance: Recording = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this,
    );

    return clonedInstance;
  }

  get performersNames(): string {
    const { performers } = this;

    const rightHoldersNames: Array<string> = performers.map(
      (performer) => `${performer.firstName} ${performer.lastName}`,
    );

    return rightHoldersNames.join(' & ');
  }
}
