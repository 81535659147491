import { Serializable } from 'app/core/interfaces';

// eslint-disable-next-line import/no-cycle
import { CollectionShare, CollectionShareDTO } from './collectionShare';
import { User, UserDTO } from './user';
import { RightHolder, RightHolderDTO } from './rightHolder';
import { Territory, TerritoryDTO } from './territory';
// eslint-disable-next-line import/no-cycle
import { Share, ShareDTO } from './share';

export enum CatalogStatusTypes {
  ACTIVE = 'active',
  DISABLED = 'disabled',
  COLLECTING = 'collecting',
}
export interface CatalogDTO {
  id?: string;
  name?: string;
  role?: string;
  description?: string;
  parent?: RightHolderDTO;
  grandParent?: RightHolderDTO;
  owner?: UserDTO;
  collectionShares?: CollectionShareDTO[];
  territories?: TerritoryDTO[];
  shares?: Array<ShareDTO>;
  updatedAt?: string;
  createdAt?: string;

  collectingDate?: string;
  agreementType?: string;
  status?: string;
  rightHolders?: RightHolderDTO[];
}

export class Catalog implements Serializable<Catalog> {
  id?: string;
  name?: string;
  role?: string;
  description?: string;
  parent?: RightHolder;
  grandParent?: RightHolder;
  owner?: User;
  collectionShares?: CollectionShare[];
  updatedAt?: string;
  createdAt?: string;
  territories: Territory[];
  shares: Array<Share>;

  collectingDate?: string;
  agreementType?: string;
  status?: string;
  rightHolders?: RightHolder[];

  deserialize(input: CatalogDTO): Catalog {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.name = input.name || '';
    this.role = input.role || '';
    this.description = input.description || '';

    this.parent = input.parent ? new RightHolder().deserialize(input.parent) : null;
    this.grandParent = input.grandParent ? new RightHolder().deserialize(input.grandParent) : null;
    this.owner = input.owner ? new User().deserialize(input.owner) : null;
    this.territories = input.territories ? new Territory().deserializeArray(input.territories) : [];
    this.shares = input.shares ? new Share().deserializeArray(input.shares) : [];

    this.collectionShares = input.collectionShares
      ? new CollectionShare().deserializeArray(input.collectionShares)
      : [];

    this.updatedAt = input.updatedAt || '';
    this.createdAt = input.createdAt || '';

    this.collectingDate = input.collectingDate || '';
    this.agreementType = input.agreementType || '';
    this.status = input.status || '';
    this.rightHolders = input.rightHolders
      ? new RightHolder().deserializeArray(input.rightHolders)
      : [];

    return this;
  }

  get representedCountries(): string {
    const { territories } = this;

    if (!territories || !territories.length) {
      return '';
    }

    return territories.map((territory) => territory.name).join(', ');
  }

  get catalogRightHolders(): string {
    const { shares } = this;

    const rightHoldersNames: Array<string> = shares.map((share) => share.rightHolder.name);

    return [...new Set(rightHoldersNames)].join(', ');
  }

  get numOfWorks(): number {
    const { shares } = this;

    const workIds: Array<string> = shares.map((share) => share.workId);

    return [...new Set(workIds)].length;
  }

  deserializeArray(inputArray: Array<CatalogDTO>): Array<Catalog> {
    const catalogs: Array<Catalog> = inputArray.map((input) => new Catalog().deserialize(input));

    return catalogs;
  }

  clone(): Catalog {
    const clonedInstance: Catalog = Object.assign(Object.create(Object.getPrototypeOf(this)), this);

    return clonedInstance;
  }
}
