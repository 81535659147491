<nav class="navbar navbar-expand-lg navbar-light px-0">
  <fa-icon
    class="sidebar-toggler-open show d-lg-none"
    [icon]="faBars"
    appSidebarToggler
  ></fa-icon>
  <fa-icon
    class="sidebar-toggler-close d-lg-none"
    [icon]="faTimes"
    appSidebarToggler
  ></fa-icon>
  <div class="ml-auto">
    <ul class="nav right-nav">
      <li class="nav-item">
        <!-- <a class="btn btn-logout" routerLink="/notifications">
          <fa-icon [icon]="faBell"></fa-icon>
          <span class="badge badge-pill badge-danger">&nbsp;</span>
        </a> -->
        <div class="btn-group">
          <button
            type="button"
            class="btn btn-logout dropdown-toggle notifications"
            data-toggle="dropdown"
            (click)="refreshOpenTickets()"
          >
            <fa-icon [icon]="faBell"></fa-icon>
            <span
              class="badge badge-pill badge-danger"
              [hidden]="tickets.length === 0"
              >&nbsp;</span
            >
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            <p class="mb-0 px-2">
              {{ "Open tickets" | translate }}
            </p>
            <div class="dropdown-divider"></div>
            <app-open-tickets [tickets]="tickets"></app-open-tickets>
          </div>
        </div>
      </li>
      <li class="nav-item">
        <div class="btn-group">
          <button
            type="button"
            class="btn btn-logout dropdown-toggle"
            data-toggle="dropdown"
          >
            <fa-icon [icon]="faUser"></fa-icon>
            <i class="fas fa-user"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            <a class="dropdown-item" routerLink="/profile/">
              {{ "Profile" | translate }}
            </a>
            <a class="dropdown-item" routerLink="/profile/financial-data">
              {{ "Financial data" | translate }}
            </a>
            <a class="dropdown-item" routerLink="/profile/documents">
              {{ "Documents" | translate }}
            </a>
            <div class="dropdown-divider"></div>
            <button type="button" class="dropdown-item" (click)="logout()">
              {{ "Log out" | translate }}
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</nav>
