import { Serializable } from 'app/core/interfaces';

import { RightHolder, RightHolderDTO } from 'app/shared/models/rightHolder';

export interface WorkListenedDTO {
  id: string;
  unisonId: string;
  title: string;
  usages?: number;

  rightHolders: RightHolderDTO[];

  date?: Date;
  consumer?: string;
  country?: string;
  city?: string;
  event?: string;
  venue?: string;
  type?: string;
}

export class WorkListened implements Serializable<WorkListened> {
  id: string;
  unisonId: string;
  title: string;
  usages?: number;

  rightHolders: RightHolder[];

  date?: Date;
  consumer?: string;
  country?: string;
  city?: string;
  event?: string;
  venue?: string;
  type?: string;

  deserialize(input: WorkListenedDTO): WorkListened {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.unisonId = input.unisonId || '';
    this.title = input.title || '';
    this.usages = input.usages || 0;

    this.rightHolders = new RightHolder().deserializeArray(input.rightHolders) || [];
    this.date = input.date || new Date();
    this.consumer = input.consumer || '';
    this.country = input.country || '';
    this.city = input.city || '';
    this.event = input.event || '';
    this.venue = input.venue || '';
    this.type = input.type || '';

    return this;
  }

  deserializeArray(inputArray: Array<WorkListenedDTO>): Array<WorkListened> {
    const worksListened: Array<WorkListened> = inputArray.map((input) =>
      new WorkListened().deserialize(input),
    );

    return worksListened;
  }
}
