export const LANGUAGES = [
  { name: '(Afan) Oromo', value: 'OM' },
  { name: 'Abkhazian', value: 'AB' },
  { name: 'Afar', value: 'AA' },
  { name: 'Afrikaans', value: 'AF' },
  { name: 'Albanian', value: 'SQ' },
  { name: 'Amharic', value: 'AM' },
  { name: 'Arabic', value: 'AR' },
  { name: 'Armenian', value: 'HY' },
  { name: 'Assamese', value: 'AS' },
  { name: 'Aymara', value: 'AY' },
  { name: 'Azerbaijani', value: 'AZ' },
  { name: 'Bashkir', value: 'BA' },
  { name: 'Basque', value: 'EU' },
  { name: 'Bengali', value: 'BN' },
  { name: 'Bhutani', value: 'DZ' },
  { name: 'Bihari', value: 'BH' },
  { name: 'Bislama', value: 'BI' },
  { name: 'Breton', value: 'BR' },
  { name: 'Bulgarian', value: 'BG' },
  { name: 'Burmese', value: 'MY' },
  { name: 'Byelorussian', value: 'BE' },
  { name: 'Cambodian', value: 'KM' },
  { name: 'Catalan', value: 'CA' },
  { name: 'Chinese', value: 'ZH' },
  { name: 'Corsican', value: 'CO' },
  { name: 'Croatian', value: 'HR' },
  { name: 'Czech', value: 'CS' },
  { name: 'Danish', value: 'DA' },
  { name: 'Dutch', value: 'NL' },
  { name: 'English', value: 'EN' },
  { name: 'Esperanto', value: 'EO' },
  { name: 'Estonian', value: 'ET' },
  { name: 'Faeroese', value: 'FO' },
  { name: 'Farsi', value: 'FA' },
  { name: 'Fiji', value: 'FJ' },
  { name: 'Finnish', value: 'FI' },
  { name: 'French', value: 'FR' },
  { name: 'Frisian', value: 'FY' },
  { name: 'Galician', value: 'GL' },
  { name: 'Georgian', value: 'KA' },
  { name: 'German', value: 'DE' },
  { name: 'Greek', value: 'EL' },
  { name: 'Greenlandic', value: 'KL' },
  { name: 'Guarani', value: 'GN' },
  { name: 'Gujarati', value: 'GU' },
  { name: 'Hausa', value: 'HA' },
  { name: 'Hawaii', value: 'HW' },
  { name: 'Hebrew', value: 'IW' },
  { name: 'Hindi', value: 'HI' },
  { name: 'Hungarian', value: 'HU' },
  { name: 'Icelandic', value: 'IS' },
  { name: 'Indonesian', value: 'IN' },
  { name: 'Interlingua', value: 'IA' },
  { name: 'Interlingue', value: 'IE' },
  { name: 'Inupiak', value: 'IK' },
  { name: 'Irish', value: 'GA' },
  { name: 'Italian', value: 'IT' },
  { name: 'Japanese', value: 'JA' },
  { name: 'Javanese', value: 'JW' },
  { name: 'Kannada', value: 'KN' },
  { name: 'Kashmiri', value: 'KS' },
  { name: 'Kazakh', value: 'KK' },
  { name: 'Kinyarwanda', value: 'RW' },
  { name: 'Kirghiz', value: 'KY' },
  { name: 'Kirundi', value: 'RN' },
  { name: 'Korean', value: 'KO' },
  { name: 'Kurdish', value: 'KU' },
  { name: 'Laothian', value: 'LO' },
  { name: 'Latin', value: 'LA' },
  { name: 'Latvian', value: 'LV' },
  { name: 'Lingala', value: 'LN' },
  { name: 'Lithuanian', value: 'LT' },
  { name: 'Macedonian', value: 'MK' },
  { name: 'Malagasy', value: 'MG' },
  { name: 'Malay', value: 'MS' },
  { name: 'Malayalam', value: 'ML' },
  { name: 'Maltese', value: 'MT' },
  { name: 'Maori', value: 'MI' },
  { name: 'Marathi', value: 'MR' },
  { name: 'Moldavian', value: 'MO' },
  { name: 'Mongolian', value: 'MN' },
  { name: 'Nauru', value: 'NA' },
  { name: 'Ndebele', value: 'ND' },
  { name: 'Nepali', value: 'NE' },
  { name: 'North Sotho', value: 'NS' },
  { name: 'Norwegian', value: 'NO' },
  { name: 'Occitan', value: 'OC' },
  { name: 'Oriya', value: 'OR' },
  { name: 'Oromo', value: 'OM' },
  { name: 'Pashto', value: 'PS' },
  { name: 'Polish', value: 'PL' },
  { name: 'Portuguese', value: 'PT' },
  { name: 'Punjabi', value: 'PA' },
  { name: 'Quechua', value: 'QU' },
  { name: 'Rhaeto-Romance', value: 'RM' },
  { name: 'Romanian', value: 'RO' },
  { name: 'Russian', value: 'RU' },
  { name: 'Samoan', value: 'SM' },
  { name: 'Sangro', value: 'SG' },
  { name: 'Sanskrit', value: 'SA' },
  { name: 'Scots Gaelic', value: 'GD' },
  { name: 'Serbian', value: 'SR' },
  { name: 'Serbo-Croatian', value: 'SH' },
  { name: 'Sesotho', value: 'ST' },
  { name: 'Setswana', value: 'TN' },
  { name: 'Shona', value: 'SN' },
  { name: 'Sindhi', value: 'SD' },
  { name: 'Singhalese', value: 'SI' },
  { name: 'Siswati', value: 'SS' },
  { name: 'Slovak', value: 'SK' },
  { name: 'Slovenian', value: 'SL' },
  { name: 'Somali', value: 'SO' },
  { name: 'Spanish', value: 'ES' },
  { name: 'Sudanese', value: 'SU' },
  { name: 'Swahili', value: 'SW' },
  { name: 'Swedish', value: 'SV' },
  { name: 'Tagalog', value: 'TL' },
  { name: 'Tajik', value: 'TG' },
  { name: 'Tamil', value: 'TA' },
  { name: 'Tatar', value: 'TT' },
  { name: 'Telugu', value: 'TE' },
  { name: 'Thai', value: 'TH' },
  { name: 'Tibetan', value: 'BO' },
  { name: 'Tigrinya', value: 'TI' },
  { name: 'Tonga', value: 'TO' },
  { name: 'Tsonga', value: 'TS' },
  { name: 'Turkish', value: 'TR' },
  { name: 'Turkmen', value: 'TK' },
  { name: 'Twi', value: 'TW' },
  { name: 'Ukranian', value: 'UK' },
  { name: 'Urdu', value: 'UR' },
  { name: 'Uzbek', value: 'UZ' },
  { name: 'Venda', value: 'VE' },
  { name: 'Vietnamese', value: 'VI' },
  { name: 'Volapuk', value: 'VO' },
  { name: 'Welsh', value: 'CY' },
  { name: 'Wolof', value: 'WO' },
  { name: 'Xhosa', value: 'XH' },
  { name: 'Yiddish', value: 'JI' },
  { name: 'Yoruba', value: 'YO' },
  { name: 'Zulu', value: 'ZU' },
];
