import { Serializable } from 'app/core/interfaces';

export enum ConsumerType {
  RADIO = 'radio',
  TV = 'tv',
  BACKGROUND_MUSIC = 'background_music',
  DIGITAL = 'digital',
  LIVE = 'live',
}

export interface ConsumerDTO {
  id: string;
  name: string;
  keyname: string;
  businessName: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  email: string;
  type: ConsumerType;
  nif: string;
  // recordingUsages: RecordingUsage[];
  // compositionUsages: CompositionUsage[];
  // invoices: Invoice[];
  rateId: string;
  updatedAt: string;
  createdAt: string;
  gatheredRoyalties: number;
  pendingRoyalties: number;
}

export class Consumer implements Serializable<Consumer> {
  id: string;
  name: string;
  keyname: string;
  businessName: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  email: string;
  type: ConsumerType;
  nif: string;
  // recordingUsages: RecordingUsage[];
  // compositionUsages: CompositionUsage[];
  // invoices: Invoice[];
  rateId: string;
  updatedAt: string;
  createdAt: string;
  gatheredRoyalties: number;
  pendingRoyalties: number;

  deserialize(input: ConsumerDTO): Consumer {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.name = input.name || '';
    this.keyname = input.keyname || '';
    this.businessName = input.businessName || '';
    this.address = input.address || '';
    this.postalCode = input.postalCode || '';
    this.city = input.city || '';
    this.country = input.country || '';
    this.email = input.email || '';
    this.nif = input.nif || '';
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    this.type = (input.type as ConsumerType) || ConsumerType.RADIO;
    // this.recordingUsages = input.recordingUsages || '';
    // this.compositionUsages = input.compositionUsages || '';
    // this.invoices = input.invoices || '';
    this.rateId = input.rateId || '';
    this.updatedAt = input.updatedAt || '';
    this.createdAt = input.createdAt || '';
    this.gatheredRoyalties = input.gatheredRoyalties || 0;
    this.pendingRoyalties = input.pendingRoyalties || 0;

    return this;
  }

  deserializeArray(inputArray: Array<ConsumerDTO>): Array<Consumer> {
    const consumers: Consumer[] = inputArray.map((input) => new Consumer().deserialize(input));

    return consumers;
  }

  clone(): Consumer {
    const clonedInstance: Consumer = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this,
    );

    return clonedInstance;
  }
}
