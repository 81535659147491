import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { AuthService } from 'app/modules/auth/services';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceGuard implements CanActivate {
  private readonly maintenance = environment.maintenance;
  private readonly maintenanceAllowAdmin = environment.maintenanceAllowAdmin;

  constructor(private router: Router, private authService: AuthService) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isAdminLogged = this.authService.isAdminLogged();

    if (!this.maintenance || (this.maintenanceAllowAdmin && isAdminLogged)) {
      return true;
    }

    return this.router.createUrlTree(['/maintenance']);
  }
}
