import { Serializable } from 'app/core/interfaces';

export interface SocietyDTO {
  id?: string;
  code?: number;
  name?: string;
  updatedAt?: string;
  createdAt?: string;
}

export class Society implements Serializable<Society> {
  id: string;
  code: number;
  name: string;
  updatedAt: string;
  createdAt: string;

  deserialize(input: SocietyDTO): Society {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.name = input.name || '';
    this.code = input.code || null;
    this.updatedAt = input.updatedAt || '';
    this.createdAt = input.createdAt || '';

    return this;
  }

  deserializeArray(inputArray: Array<SocietyDTO>): Array<Society> {
    const Societies: Society[] = inputArray.map((input) => new Society().deserialize(input));

    return Societies;
  }

  clone(): Society {
    const clonedInstance: Society = Object.assign(Object.create(Object.getPrototypeOf(this)), this);

    return clonedInstance;
  }
}
