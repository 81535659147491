import { Serializable } from 'app/core/interfaces';
// eslint-disable-next-line import/no-cycle
import { RightHolderDTO, RightHolder } from './rightHolder';

export interface UserDTO {
  id: string;
  role: string;
  email: string;
  firstName: string;
  lastName: string;
  businessName: string;
  nif: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
  commissionBackground: number;
  commissionDigital: number;
  commissionLive: number;
  commissionRadioTV: number;
  status: string;
  stripeAccountId?: string;
  financialAdminId?: string;
  rightHolders?: RightHolderDTO[];
  language?: string;
  hasDigitalDistribution?: boolean;
}

export class User implements Serializable<User> {
  id: string;
  role: string;
  email: string;
  firstName: string;
  lastName: string;
  businessName: string;
  nif: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
  commissionBackground: number;
  commissionDigital: number;
  commissionLive: number;
  commissionRadioTV: number;
  status: string;
  // catalogs: Catalog[];
  // liquidations: Liquidation[];
  // rightHolder: RightHolder;
  rightHolders: RightHolder[];
  language: string;
  hasDigitalDistribution: boolean;

  deserialize(input: UserDTO): User {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.role = input.role || '';
    this.email = input.email || '';
    this.firstName = input.firstName || '';
    this.lastName = input.lastName || '';
    this.businessName = input.businessName || '';
    this.nif = input.nif || '';
    this.address = input.address || '';
    this.city = input.city || '';
    this.postalCode = input.postalCode || '';
    this.country = input.country || '';
    this.commissionBackground = input.commissionBackground || 0;
    this.commissionDigital = input.commissionDigital || 0;
    this.commissionLive = input.commissionLive || 0;
    this.commissionRadioTV = input.commissionRadioTV || 0;
    this.status = input.status || '';
    this.hasDigitalDistribution = input.hasDigitalDistribution || false;

    if (input.rightHolders) {
      this.rightHolders = new RightHolder().deserializeArray(input.rightHolders);
    } else {
      this.rightHolders = [];
    }

    this.language = input.language ? input.language : 'EN';

    return this;
  }

  deserializeArray(inputArray: Array<UserDTO>): Array<User> {
    const elecsumGreenNews: Array<User> = inputArray.map((input) => new User().deserialize(input));

    return elecsumGreenNews;
  }
}
