import { Serializable } from 'app/core/interfaces';
// eslint-disable-next-line import/no-cycle
import { Statement, StatementDTO } from './statement';
import { User, UserDTO } from './user';

export enum DocumentTypes {
  CONTRACT_FIRM = 'contract_firm',
  CATALOG_FIRM = 'catalog_firm',
  CLAIM_STATEMENT = 'claim_statement',
  LIQUIDATION_STATEMENT = 'liquidation_statement',
}

export interface DocumentDTO {
  id: string;
  path: string;
  type: DocumentTypes;
  filename: string;
  mimetype: string;
  size: string;
  encoding: string;
  originalname: string;
  visible: boolean;
  name: string;
  description: string;
  resourceId: string;
  statement: StatementDTO;
  userId: string;
  user: UserDTO;
  cretedAt: string;
  updatedAt: string;
}

export class Document implements Serializable<Document> {
  id: string;
  path: string;
  type: DocumentTypes;
  filename: string;
  mimetype: string;
  size: string;
  encoding: string;
  originalname: string;
  visible: boolean;
  name: string;
  description: string;
  resourceId: string;
  userId: string;
  cretedAt: string;
  updatedAt: string;
  statement: Statement;
  user: User;

  deserialize(input: DocumentDTO): Document {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.path = input.path || '';
    this.type = input.type || DocumentTypes.CONTRACT_FIRM;
    this.filename = input.filename || '';
    this.mimetype = input.mimetype || '';
    this.size = input.size || '';
    this.encoding = input.encoding || '';
    this.originalname = input.originalname || '';
    this.visible = input.visible || false;
    this.name = input.name || '';
    this.description = input.description || '';
    this.resourceId = input.resourceId || '';
    this.userId = input.userId || '';
    this.cretedAt = input.cretedAt || '';
    this.updatedAt = input.updatedAt || '';

    this.statement = input.statement ? new Statement().deserialize(input.statement) : null;
    this.user = input.user ? new User().deserialize(input.user) : null;

    return this;
  }

  deserializeArray(inputArray: Array<DocumentDTO>): Array<Document> {
    const territories: Array<Document> = inputArray.map((input) =>
      new Document().deserialize(input),
    );

    return territories;
  }

  clone(): Document {
    const clonedInstance: Document = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this,
    );

    return clonedInstance;
  }
}
