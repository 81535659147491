export const TERRITORIES_MOCK = {
  territories: [
    {
      tis: 4,
      name: 'AFGHANISTAN',
    },
    {
      tis: 8,
      name: 'ALBANIA',
    },
    {
      tis: 12,
      name: 'ALGERIA',
    },
    {
      tis: 20,
      name: 'ANDORRA',
    },
    {
      tis: 24,
      name: 'ANGOLA',
    },
    {
      tis: 28,
      name: 'ANTIGUA AND BARBUDA',
    },
    {
      tis: 31,
      name: 'AZERBAIJAN',
    },
    {
      tis: 32,
      name: 'ARGENTINA',
    },
    {
      tis: 36,
      name: 'AUSTRALIA',
    },
    {
      tis: 40,
      name: 'AUSTRIA',
    },
    {
      tis: 44,
      name: 'BAHAMAS',
    },
    {
      tis: 48,
      name: 'BAHRAIN',
    },
    {
      tis: 50,
      name: 'BANGLADESH',
    },
    {
      tis: 51,
      name: 'ARMENIA',
    },
    {
      tis: 52,
      name: 'BARBADOS',
    },
    {
      tis: 56,
      name: 'BELGIUM',
    },
    {
      tis: 64,
      name: 'BHUTAN',
    },
    {
      tis: 68,
      name: 'BOLIVIA',
    },
    {
      tis: 70,
      name: 'BOSNIA AND HERZEGOVINA',
    },
    {
      tis: 72,
      name: 'BOTSWANA',
    },
    {
      tis: 76,
      name: 'BRAZIL',
    },
    {
      tis: 84,
      name: 'BELIZE',
    },
    {
      tis: 90,
      name: 'SOLOMON ISLANDS',
    },
    {
      tis: 96,
      name: 'BRUNEI DARUSSALAM',
    },
    {
      tis: 100,
      name: 'BULGARIA',
    },
    {
      tis: 104,
      name: 'MYANMAR',
    },
    {
      tis: 108,
      name: 'BURUNDI',
    },
    {
      tis: 112,
      name: 'BELARUS',
    },
    {
      tis: 116,
      name: 'CAMBODIA',
    },
    {
      tis: 120,
      name: 'CAMEROON',
    },
    {
      tis: 124,
      name: 'CANADA',
    },
    {
      tis: 132,
      name: 'CAPE VERDE',
    },
    {
      tis: 140,
      name: 'CENTRAL AFRICAN REPUBLIC',
    },
    {
      tis: 144,
      name: 'SRI LANKA',
    },
    {
      tis: 148,
      name: 'CHAD',
    },
    {
      tis: 152,
      name: 'CHILE',
    },
    {
      tis: 156,
      name: 'CHINA',
    },
    {
      tis: 158,
      name: 'TAIWAN, PROVINCE OF CHINA',
    },
    {
      tis: 170,
      name: 'COLOMBIA',
    },
    {
      tis: 174,
      name: 'COMOROS',
    },
    {
      tis: 178,
      name: 'CONGO',
    },
    {
      tis: 180,
      name: 'CONGO, THE DEMOCRATIC REPUBLIC OF THE',
    },
    {
      tis: 188,
      name: 'COSTA RICA',
    },
    {
      tis: 191,
      name: 'CROATIA',
    },
    {
      tis: 192,
      name: 'CUBA',
    },
    {
      tis: 196,
      name: 'CYPRUS',
    },
    {
      tis: 203,
      name: 'CZECH REPUBLIC',
    },
    {
      tis: 204,
      name: 'BENIN',
    },
    {
      tis: 208,
      name: 'DENMARK',
    },
    {
      tis: 212,
      name: 'DOMINICA',
    },
    {
      tis: 214,
      name: 'DOMINICAN REPUBLIC',
    },
    {
      tis: 218,
      name: 'ECUADOR',
    },
    {
      tis: 222,
      name: 'EL SALVADOR',
    },
    {
      tis: 226,
      name: 'EQUATORIAL GUINEA',
    },
    {
      tis: 231,
      name: 'ETHIOPIA',
    },
    {
      tis: 232,
      name: 'ERITREA',
    },
    {
      tis: 233,
      name: 'ESTONIA',
    },
    {
      tis: 242,
      name: 'FIJI',
    },
    {
      tis: 246,
      name: 'FINLAND',
    },
    {
      tis: 250,
      name: 'FRANCE',
    },
    {
      tis: 258,
      name: 'FRENCH POLYNESIA',
    },
    {
      tis: 262,
      name: 'DJIBOUTI',
    },
    {
      tis: 266,
      name: 'GABON',
    },
    {
      tis: 268,
      name: 'GEORGIA',
    },
    {
      tis: 270,
      name: 'GAMBIA',
    },
    {
      tis: 276,
      name: 'GERMANY',
    },
    {
      tis: 288,
      name: 'GHANA',
    },
    {
      tis: 296,
      name: 'KIRIBATI',
    },
    {
      tis: 300,
      name: 'GREECE',
    },
    {
      tis: 308,
      name: 'GRENADA',
    },
    {
      tis: 320,
      name: 'GUATEMALA',
    },
    {
      tis: 324,
      name: 'GUINEA',
    },
    {
      tis: 328,
      name: 'GUYANA',
    },
    {
      tis: 332,
      name: 'HAITI',
    },
    {
      tis: 336,
      name: 'HOLY SEE (VATICAN CITY STATE)',
    },
    {
      tis: 340,
      name: 'HONDURAS',
    },
    {
      tis: 344,
      name: 'HONG KONG',
    },
    {
      tis: 348,
      name: 'HUNGARY',
    },
    {
      tis: 352,
      name: 'ICELAND',
    },
    {
      tis: 356,
      name: 'INDIA',
    },
    {
      tis: 360,
      name: 'INDONESIA',
    },
    {
      tis: 364,
      name: 'IRAN, ISLAMIC REPUBLIC OF',
    },
    {
      tis: 368,
      name: 'IRAQ',
    },
    {
      tis: 372,
      name: 'IRELAND',
    },
    {
      tis: 376,
      name: 'ISRAEL',
    },
    {
      tis: 380,
      name: 'ITALY',
    },
    {
      tis: 384,
      name: "COTE D'IVOIRE",
    },
    {
      tis: 388,
      name: 'JAMAICA',
    },
    {
      tis: 392,
      name: 'JAPAN',
    },
    {
      tis: 398,
      name: 'KAZAKHSTAN',
    },
    {
      tis: 400,
      name: 'JORDAN',
    },
    {
      tis: 404,
      name: 'KENYA',
    },
    {
      tis: 408,
      name: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
    },
    {
      tis: 410,
      name: 'KOREA, REPUBLIC OF',
    },
    {
      tis: 414,
      name: 'KUWAIT',
    },
    {
      tis: 417,
      name: 'KYRGYZSTAN',
    },
    {
      tis: 418,
      name: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
    },
    {
      tis: 422,
      name: 'LEBANON',
    },
    {
      tis: 426,
      name: 'LESOTHO',
    },
    {
      tis: 428,
      name: 'LATVIA',
    },
    {
      tis: 430,
      name: 'LIBERIA',
    },
    {
      tis: 434,
      name: 'LIBYAN ARAB JAMAHIRIYA',
    },
    {
      tis: 438,
      name: 'LIECHTENSTEIN',
    },
    {
      tis: 440,
      name: 'LITHUANIA',
    },
    {
      tis: 442,
      name: 'LUXEMBOURG',
    },
    {
      tis: 446,
      name: 'MACAO',
    },
    {
      tis: 450,
      name: 'MADAGASCAR',
    },
    {
      tis: 454,
      name: 'MALAWI',
    },
    {
      tis: 458,
      name: 'MALAYSIA',
    },
    {
      tis: 462,
      name: 'MALDIVES',
    },
    {
      tis: 466,
      name: 'MALI',
    },
    {
      tis: 470,
      name: 'MALTA',
    },
    {
      tis: 478,
      name: 'MAURITANIA',
    },
    {
      tis: 480,
      name: 'MAURITIUS',
    },
    {
      tis: 484,
      name: 'MEXICO',
    },
    {
      tis: 492,
      name: 'MONACO',
    },
    {
      tis: 496,
      name: 'MONGOLIA',
    },
    {
      tis: 498,
      name: 'MOLDOVA, REPUBLIC OF',
    },
    {
      tis: 499,
      name: 'MONTENEGRO',
    },
    {
      tis: 504,
      name: 'MOROCCO',
    },
    {
      tis: 508,
      name: 'MOZAMBIQUE',
    },
    {
      tis: 512,
      name: 'OMAN',
    },
    {
      tis: 516,
      name: 'NAMIBIA',
    },
    {
      tis: 520,
      name: 'NAURU',
    },
    {
      tis: 524,
      name: 'NEPAL',
    },
    {
      tis: 528,
      name: 'NETHERLANDS',
    },
    {
      tis: 540,
      name: 'NEW CALEDONIA',
    },
    {
      tis: 548,
      name: 'VANUATU',
    },
    {
      tis: 554,
      name: 'NEW ZEALAND',
    },
    {
      tis: 558,
      name: 'NICARAGUA',
    },
    {
      tis: 562,
      name: 'NIGER',
    },
    {
      tis: 566,
      name: 'NIGERIA',
    },
    {
      tis: 578,
      name: 'NORWAY',
    },
    {
      tis: 583,
      name: 'MICRONESIA, FEDERATED STATES OF',
    },
    {
      tis: 584,
      name: 'MARSHALL ISLANDS',
    },
    {
      tis: 585,
      name: 'PALAU',
    },
    {
      tis: 586,
      name: 'PAKISTAN',
    },
    {
      tis: 591,
      name: 'PANAMA',
    },
    {
      tis: 598,
      name: 'PAPUA NEW GUINEA',
    },
    {
      tis: 600,
      name: 'PARAGUAY',
    },
    {
      tis: 604,
      name: 'PERU',
    },
    {
      tis: 608,
      name: 'PHILIPPINES',
    },
    {
      tis: 616,
      name: 'POLAND',
    },
    {
      tis: 620,
      name: 'PORTUGAL',
    },
    {
      tis: 624,
      name: 'GUINEA-BISSAU',
    },
    {
      tis: 626,
      name: 'TIMOR-LESTE',
    },
    {
      tis: 630,
      name: 'PUERTO RICO',
    },
    {
      tis: 634,
      name: 'QATAR',
    },
    {
      tis: 642,
      name: 'ROMANIA',
    },
    {
      tis: 643,
      name: 'RUSSIAN FEDERATION',
    },
    {
      tis: 646,
      name: 'RWANDA',
    },
    {
      tis: 659,
      name: 'SAINT KITTS AND NEVIS',
    },
    {
      tis: 662,
      name: 'SAINT LUCIA',
    },
    {
      tis: 670,
      name: 'SAINT VINCENT AND THE GRENADINES',
    },
    {
      tis: 674,
      name: 'SAN MARINO',
    },
    {
      tis: 678,
      name: 'SAO TOME AND PRINCIPE',
    },
    {
      tis: 682,
      name: 'SAUDI ARABIA',
    },
    {
      tis: 686,
      name: 'SENEGAL',
    },
    {
      tis: 688,
      name: 'SERBIA',
    },
    {
      tis: 690,
      name: 'SEYCHELLES',
    },
    {
      tis: 694,
      name: 'SIERRA LEONE',
    },
    {
      tis: 702,
      name: 'SINGAPORE',
    },
    {
      tis: 703,
      name: 'SLOVAKIA',
    },
    {
      tis: 704,
      name: 'VIET NAM',
    },
    {
      tis: 705,
      name: 'SLOVENIA',
    },
    {
      tis: 706,
      name: 'SOMALIA',
    },
    {
      tis: 710,
      name: 'SOUTH AFRICA',
    },
    {
      tis: 716,
      name: 'ZIMBABWE',
    },
    {
      tis: 724,
      name: 'SPAIN',
    },
    {
      tis: 728,
      name: 'SOUTH SUDAN',
    },
    {
      tis: 729,
      name: 'SUDAN',
    },
    {
      tis: 732,
      name: 'WESTERN SAHARA',
    },
    {
      tis: 740,
      name: 'SURINAME',
    },
    {
      tis: 748,
      name: 'SWAZILAND',
    },
    {
      tis: 752,
      name: 'SWEDEN',
    },
    {
      tis: 756,
      name: 'SWITZERLAND',
    },
    {
      tis: 760,
      name: 'SYRIAN ARAB REPUBLIC',
    },
    {
      tis: 762,
      name: 'TAJIKISTAN',
    },
    {
      tis: 764,
      name: 'THAILAND',
    },
    {
      tis: 768,
      name: 'TOGO',
    },
    {
      tis: 776,
      name: 'TONGA',
    },
    {
      tis: 780,
      name: 'TRINIDAD AND TOBAGO',
    },
    {
      tis: 784,
      name: 'UNITED ARAB EMIRATES',
    },
    {
      tis: 788,
      name: 'TUNISIA',
    },
    {
      tis: 792,
      name: 'TURKEY',
    },
    {
      tis: 795,
      name: 'TURKMENISTAN',
    },
    {
      tis: 798,
      name: 'TUVALU',
    },
    {
      tis: 800,
      name: 'UGANDA',
    },
    {
      tis: 804,
      name: 'UKRAINE',
    },
    {
      tis: 807,
      name: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF',
    },
    {
      tis: 818,
      name: 'EGYPT',
    },
    {
      tis: 826,
      name: 'UNITED KINGDOM',
    },
    {
      tis: 834,
      name: 'TANZANIA, UNITED REPUBLIC OF',
    },
    {
      tis: 840,
      name: 'UNITED STATES',
    },
    {
      tis: 854,
      name: 'BURKINA FASO',
    },
    {
      tis: 858,
      name: 'URUGUAY',
    },
    {
      tis: 860,
      name: 'UZBEKISTAN',
    },
    {
      tis: 862,
      name: 'VENEZUELA',
    },
    {
      tis: 882,
      name: 'SAMOA',
    },
    {
      tis: 887,
      name: 'YEMEN',
    },
    {
      tis: 894,
      name: 'ZAMBIA',
    },
    {
      tis: 2100,
      name: 'AFRICA',
    },
    {
      tis: 2101,
      name: 'AMERICA',
    },
    {
      tis: 2102,
      name: 'AMERICAN CONTINENT',
    },
    {
      tis: 2103,
      name: 'ANTILLES',
    },
    {
      tis: 2104,
      name: 'APEC COUNTRIES',
    },
    {
      tis: 2105,
      name: 'ASEAN COUNTRIES',
    },
    {
      tis: 2106,
      name: 'ASIA',
    },
    {
      tis: 2107,
      name: 'AUSTRALASIA',
    },
    {
      tis: 2108,
      name: 'BALKANS',
    },
    {
      tis: 2109,
      name: 'BALTIC STATES',
    },
    {
      tis: 2110,
      name: 'BENELUX',
    },
    {
      tis: 2111,
      name: 'BRITISH ISLES',
    },
    {
      tis: 2112,
      name: 'BRITISH WEST INDIES',
    },
    {
      tis: 2113,
      name: 'CENTRAL AMERICA',
    },
    {
      tis: 2114,
      name: 'COMMONWEALTH',
    },
    {
      tis: 2115,
      name: 'COMMONWEALTH AFRICAN TERRITORIES',
    },
    {
      tis: 2116,
      name: 'COMMONWEALTH ASIAN TERRITORIES',
    },
    {
      tis: 2117,
      name: 'COMMONWEALTH AUSTRALASIAN TERRITORIES',
    },
    {
      tis: 2118,
      name: 'COMMONWEALTH OF INDEPENDENT STATES',
    },
    {
      tis: 2119,
      name: 'EASTERN EUROPE',
    },
    {
      tis: 2120,
      name: 'EUROPE',
    },
    {
      tis: 2121,
      name: 'EUROPEAN ECONOMIC AREA',
    },
    {
      tis: 2122,
      name: 'EUROPEAN CONTINENT',
    },
    {
      tis: 2123,
      name: 'EUROPEAN UNION',
    },
    {
      tis: 2124,
      name: 'GSA COUNTRIES',
    },
    {
      tis: 2125,
      name: 'MIDDLE EAST',
    },
    {
      tis: 2126,
      name: 'NAFTA COUNTRIES',
    },
    {
      tis: 2127,
      name: 'NORDIC COUNTRIES',
    },
    {
      tis: 2128,
      name: 'NORTH AFRICA',
    },
    {
      tis: 2129,
      name: 'NORTH AMERICA',
    },
    {
      tis: 2130,
      name: 'OCEANIA',
    },
    {
      tis: 2131,
      name: 'SCANDINAVIA',
    },
    {
      tis: 2132,
      name: 'SOUTH AMERICA',
    },
    {
      tis: 2133,
      name: 'SOUTH EAST ASIA',
    },
    {
      tis: 2134,
      name: 'WEST INDIES',
    },
    {
      tis: 2136,
      name: 'WORLD',
    },
  ],
  info: {
    count: 237,
    offset: '0',
    limit: '9999',
  },
};
