import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RightHolder, RightHolderDTO } from 'app/shared/models';
import { InfoResponseAPI } from 'app/shared/interfaces';

export interface RightHoldersResponse {
  rightHolders: RightHolder[];
  info: InfoResponseAPI;
}

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class RightHolderService {
  private readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  async getRightHolders(
    offset: number,
    limit: number,
    text: string,
  ): Promise<RightHoldersResponse> {
    const filters = `?offset=${offset}&limit=${limit}&text=${text}`;

    const url = `${this.apiUrl}/user/right-holder/${filters}`;
    const response = await this.http
      .get<{ users: RightHolderDTO[]; info: InfoResponseAPI }>(url, httpOptions)
      .toPromise();
    const { users, info } = response;

    return {
      rightHolders: new RightHolder().deserializeArray(users),
      info,
    };
  }

  async createUserRightHolder(userId: string, rightHolder: RightHolder): Promise<RightHolder> {
    const url = `${this.apiUrl}/user/${userId}/right-holder`;
    const response = await this.http
      .post<{ rightHolder: RightHolderDTO }>(url, rightHolder, httpOptions)
      .toPromise();

    return new RightHolder().deserialize(response.rightHolder);
  }

  async deleteUserRightHolder(userId: string, rightholderId: string): Promise<void> {
    const url = `${this.apiUrl}/user/${userId}/right-holder/${rightholderId}`;
    await this.http.delete(url).toPromise();
  }

  async editRightHolder(rightholder: RightHolder): Promise<void> {
    const url = `${this.apiUrl}/user/right-holder/${rightholder.id}`;
    await this.http.put(url, rightholder).toPromise();
  }

  async createRightHolder(rightHolder: RightHolder): Promise<RightHolder> {
    const url = `${this.apiUrl}/user/right-holder`;
    const response = await this.http
      .post<{ rightHolder: RightHolderDTO }>(url, rightHolder, httpOptions)
      .toPromise();

    return new RightHolder().deserialize(response.rightHolder);
  }

  async createRightHolderBatch(rightHolders: RightHolder[]): Promise<void> {
    const url = `${this.apiUrl}/user/right-holder/batch`;

    await this.http.post(url, { rightHolders }).toPromise();
  }

  async deleteRightHolder(rightholder: RightHolder): Promise<void> {
    const url = `${this.apiUrl}/user/right-holder/${rightholder.id}/`;
    await this.http.delete(url).toPromise();
  }

  async mergeRightHolders(
    rightHolderIds: Array<string>,
    unisonId?: string,
    name?: string,
  ): Promise<void> {
    const url = `${this.apiUrl}/user/right-holder/merge`;
    const data = {
      rightHolderIds,
      unisonId,
      name,
    };

    await this.http.post(url, data).toPromise();
  }
}
