import { WorkDTO } from 'app/shared/models';

export const WORKS_USAGES_BROADCAST_MOCK: Array<WorkDTO> = [
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Remember my step',
    usages: 100000,
    source: 'TV',
    broadcastingStation: 'ARTE',
    rightHolders: [
      {
        name: 'Rosa Flynn',
      },
    ],
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: "I'm bad",
    usages: 75000,
    source: 'Radio',
    broadcastingStation: 'KEXP',
    rightHolders: [
      {
        name: 'Raw talent publisher',
      },
      {
        name: 'Norman cleric',
      },
    ],
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Altered Choices',
    usages: 80000,
    source: 'TV',
    broadcastingStation: 'Sky',
    rightHolders: [
      {
        name: 'LOUD',
      },
      {
        name: 'Derek Andrade',
      },
    ],
  },
  {
    id: '34df-dfs3-4598-ewrt',
    unisonId: '34df-dfs3-4598-ewrt',
    title: 'Los Silbidos',
    usages: 95000,
    source: 'Radio',
    broadcastingStation: 'BBC 1',
    rightHolders: [
      {
        name: 'Manuel Cebollo',
      },
      {
        name: 'Norman cleric',
      },
    ],
  },
];
