import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Language, LanguageDTO } from '../models';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  async getAllLanguages(): Promise<Language[]> {
    const url = `${this.apiUrl}/language/`;

    const { languages: languagesApi } = await this.http
      .get<{ languages: Array<LanguageDTO> }>(url)
      .toPromise();
    return new Language().deserializeArray(languagesApi);
  }

  async getConfigurableLanguages(): Promise<Language[]> {
    const url = `${this.apiUrl}/language/?configurable=true`;
    const { languages: languagesApi } = await this.http
      .get<{ languages: Array<LanguageDTO> }>(url)
      .toPromise();
    return new Language().deserializeArray(languagesApi);
  }
}
