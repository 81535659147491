import { WorkTrendsAPI } from 'app/shared/interfaces';

export const WORK_TRENDS_Y: WorkTrendsAPI[] = [
  {
    data: [25000, 40000, 46000, 48000, 50000, 52000, 55000, 58000, 60000, 64000, 65000, 75000],
    dataLabels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    label: 'Radio/TV',
  },
  {
    data: [
      28000,
      48000,
      56000,
      75000,
      86000,
      95000,
      102000,
      119000,
      138000,
      145700,
      150000,
      120000,
    ],
    dataLabels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    label: 'Live',
  },
  {
    data: [38000, 48000, 65000, 76000, 80000, 88000, 92000, 105000, 110000, 120000, 125000, 145000],
    dataLabels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    label: 'Digital',
  },
  {
    data: [18000, 29000, 35000, 40000, 40000, 41000, 42000, 43000, 44000, 45000, 46000, 56000],
    dataLabels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    label: 'Background music',
  },
];

export const WORK_TRENDS_BACK_Y: WorkTrendsAPI[] = [
  {
    data: [25000, 40000, 46000, 48000, 50000, 52000, 55000, 58000, 60000, 64000, 65000, 75000],
    dataLabels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    label: 'TV',
  },
  {
    data: [
      28000,
      48000,
      56000,
      75000,
      86000,
      95000,
      102000,
      119000,
      138000,
      145700,
      150000,
      120000,
    ],
    dataLabels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    label: 'Live',
  },
  {
    data: [38000, 48000, 65000, 76000, 80000, 88000, 92000, 105000, 110000, 120000, 125000, 145000],
    dataLabels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    label: 'Radio',
  },
];

export const WORK_TRENDS_PLAYS_Y: WorkTrendsAPI[] = [
  {
    data: [25000, 40000, 46000, 48000, 50000, 52000, 55000, 58000, 60000, 64000, 65000, 75000],
    dataLabels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    label: 'Background',
  },
];

export const WORK_TRENDS_LIVE_PLAYS_Y: WorkTrendsAPI[] = [
  {
    data: [25000, 40000, 46000, 48000, 50000, 52000, 55000, 58000, 60000, 64000, 65000, 75000],
    dataLabels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    label: 'Live',
  },
];
// export const WORK_TRENDS_Y: ChartDataSets[] = [
//   { data: [25000, 40000, 46000, 48000, 50000, 52000, 55000, 58000, 60000, 64000, 65000, 75000], label: 'Radio/TV', fill: 'origin' },
//   { data: [28000, 48000, 56000, 75000, 86000, 95000, 102000, 119000, 138000, 145700, 150000, 120000], label: 'Live', fill: -1 },
//   { data: [38000, 48000, 65000, 76000, 80000, 88000, 92000, 105000, 110000, 120000, 125000, 145000], label: 'Digital', fill: -2 },
//   { data: [18000, 29000, 35000, 40000, 40000, 41000, 42000, 43000, 44000, 45000, 46000, 56000], label: 'Background music', fill: -3 },
// ];

// export const WORK_TRENDS_QT: ChartDataSets[] = [ // TODO not 12 values, 3 months?
//   { data: [10000, 12000, 5000, 7000, 4000, 2000, 1000, 7000, 3000, 5600, 6500, 200],
//     labels: [0,1,2,3],
//     source: 'Radio/TV',
//   },
//   { data: [2000, 4080, 500, 750, 860, 950, 1000, 1000, 1380, 1457, 5000, 200],
//     labels: [0,1,2,3],
//     source: 'Live',
//   },
//   { data: [8000, 5000, 5000, 7000, 800, 5000, 6500, 7800, 1200, 1200, 5000, 200],
//     labels: [0,1,2,3],
//     source: 'Digital',
//   },
//   { data: [1100, 1400, 2900, 4200, 1900, 3800, 4200, 4700, 4000, 4500, 4600, 200],
//     labels: [0,1,2,3],
//     source: 'Background music',
//   },
// ];
export const WORK_TRENDS_QT: WorkTrendsAPI[] = [
  // TODO not 12 values, 3 months?
  {
    data: [10000, 12000, 5000, 7000, 4000, 2000, 1000, 7000, 3000, 5600, 6500, 200],
    dataLabels: [0, 1, 2, 3],
    label: 'Radio/TV',
  },
  {
    data: [2000, 4080, 500, 750, 860, 950, 1000, 1000, 1380, 1457, 5000, 200],
    dataLabels: [0, 1, 2, 3],
    label: 'Live',
  },
  {
    data: [8000, 5000, 5000, 7000, 800, 5000, 6500, 7800, 1200, 1200, 5000, 200],
    dataLabels: [0, 1, 2, 3],
    label: 'Digital',
  },
  {
    data: [1100, 1400, 2900, 4200, 1900, 3800, 4200, 4700, 4000, 4500, 4600, 200],
    dataLabels: [0, 1, 2, 3],
    label: 'Background music',
  },
];
export const WORK_TRENDS_BACK_QT: WorkTrendsAPI[] = [
  // TODO not 12 values, 3 months?
  {
    data: [10000, 12000, 5000, 7000, 4000, 2000, 1000, 7000, 3000, 5600, 6500, 200],
    dataLabels: [0, 1, 2, 3],
    label: 'TV',
  },
  {
    data: [2000, 4080, 500, 750, 860, 950, 1000, 1000, 1380, 1457, 5000, 200],
    dataLabels: [0, 1, 2, 3],
    label: 'Live',
  },
  {
    data: [8000, 5000, 5000, 7000, 800, 5000, 6500, 7800, 1200, 1200, 5000, 200],
    dataLabels: [0, 1, 2, 3],
    label: 'Radio',
  },
];
export const WORK_TRENDS_PLAYS_QT: WorkTrendsAPI[] = [
  // TODO not 12 values, 3 months?
  {
    data: [10000, 12000, 5000, 7000, 4000, 2000, 1000, 7000, 3000, 5600, 6500, 200],
    dataLabels: [0, 1, 2, 3],
    label: 'Background',
  },
];

export const WORK_TRENDS_LIVE_PLAYS_QT: WorkTrendsAPI[] = [
  // TODO not 12 values, 3 months?
  {
    data: [10000, 12000, 5000, 7000, 4000, 2000, 1000, 7000, 3000, 5600, 6500, 200],
    dataLabels: [0, 1, 2, 3],
    label: 'Live',
  },
];

export const WORK_TRENDS_M: WorkTrendsAPI[] = [
  // TODO not 12 values, daily..
  {
    data: [250, 400, 460, 480, 500, 520, 550, 580, 600, 640, 650, 200],
    label: 'Radio/TV',
    dataLabels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
  {
    data: [280, 480, 560, 750, 860, 950, 1020, 1190, 1380, 1457, 150, 200],
    label: 'Live',
    dataLabels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
  {
    data: [380, 480, 650, 760, 8, 880, 920, 1050, 11, 12, 1250, 200],
    label: 'Digital',
    dataLabels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
  {
    data: [180, 290, 350, 4, 4, 410, 420, 430, 440, 450, 460, 200],
    label: 'Background music',
    dataLabels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
];

export const WORK_TRENDS_BACK_M: WorkTrendsAPI[] = [
  // TODO not 12 values, daily..
  {
    data: [250, 400, 460, 480, 500, 520, 550, 580, 600, 640, 650, 200],
    label: 'TV',
    dataLabels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
  {
    data: [280, 480, 560, 750, 860, 950, 1020, 1190, 1380, 1457, 150, 200],
    label: 'Live',
    dataLabels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
  {
    data: [380, 480, 650, 760, 8, 880, 920, 1050, 11, 12, 1250, 200],
    label: 'Radio',
    dataLabels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
];

export const WORK_TRENDS_PLAYS_M: WorkTrendsAPI[] = [
  // TODO not 12 values, daily..
  {
    data: [250, 400, 460, 480, 500, 520, 550, 580, 600, 640, 650, 200],
    label: 'Background',
    dataLabels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
];

export const WORK_TRENDS_LIVE_PLAYS_M: WorkTrendsAPI[] = [
  // TODO not 12 values, daily..
  {
    data: [250, 400, 460, 480, 500, 520, 550, 580, 600, 640, 650, 200],
    label: 'Live',
    dataLabels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
];
