import { Platform, PlatformDTO } from '../models';

const DIGITAL_PLATFORMS_DTO_MOCK: PlatformDTO[] = [
  {
    id: '988b5cec-5857-4765-8a1a-a41510bb6279',
    name: 'Apple Music',
  },
  {
    id: 'f3fd37d7-383e-4ddb-bd7f-91825649b054',
    name: 'Deezer',
  },
  {
    id: 'a2af978b-8796-40a4-99da-21a0a94bd580',
    name: 'Spotify',
  },
  {
    id: '353243a6-eb54-40ad-82d5-a5913efa7564',
    name: 'Amazon Music',
  },
  {
    id: '2122660e-50ef-43a5-8316-92d15da7645e',
    name: 'Beatport',
  },
];

export const DIGITAL_PLATFORMS_WITH_ID: Platform[] = new Platform().deserializeArray(
  DIGITAL_PLATFORMS_DTO_MOCK,
);
