export const OWNERSHIP_ROLES = [
  { code: 'E', name: 'Editor / Original Publisher' },
  { code: 'CA', name: 'Composer / Author' },
  { code: 'C', name: 'Composer, Writer' },
  { code: 'A', name: 'Author, Writer, Author of Lyrics' },
  { code: 'AD', name: 'Adaptor' },
  { code: 'AR', name: 'Arranger' },
  { code: 'SR', name: 'Sub Arranger' },
  { code: 'SA', name: 'Sub Author' },
  { code: 'TR', name: 'Translator' },
];

export const COLLECTIONSHARE_ROLES_ROOT = [
  { name: 'Editor', code: 'E' },
  { name: 'Composer & Author', code: 'CA' },
  { name: 'Composer', code: 'C' },
  { name: 'Author', code: 'A' },
  { name: 'Adaptor', code: 'AD' },
  { name: 'Arranger', code: 'AR' },
  { name: 'Sub-arranger', code: 'SR' },
  { name: 'Sub-author', code: 'SA' },
  { name: 'Translator', code: 'TR' },
  { name: 'Income Participant', code: 'PA' },
];

export const COLLECTIONSHARE_ROLES_LEAF = [
  { name: 'Administrator', code: 'AM' },
  { name: 'Subeditor', code: 'SE' },
  { code: 'AQ', name: 'Acquirer' },
  { name: 'Publisher for Writer', code: 'PWR' },
  { name: 'Substituted Publisher', code: 'ES' },
];

export const CHILD_ROLES = [
  { name: 'Administrator', code: 'AM' },
  { name: 'Subeditor', code: 'SE' },
  { name: 'Publisher for Writer', code: 'PWR' },
];

export const ALL_ROLES = [
  { code: 'E', name: 'Editor / Original Publisher' },
  { code: 'CA', name: 'Composer / Author' },
  { code: 'C', name: 'Composer, Writer' },
  { code: 'A', name: 'Author, Writer, Author of Lyrics' },
  { code: 'AD', name: 'Adaptor' },
  { code: 'AR', name: 'Arranger' },
  { code: 'SR', name: 'Sub Arranger' },
  { code: 'SA', name: 'Sub Author' },
  { code: 'TR', name: 'Translator' },
  { code: 'AQ', name: 'Acquirer' },
  { code: 'PA', name: 'Income Participant' },
  { code: 'AM', name: 'Administrator' },
  { code: 'SE', name: 'Sub Publisher' },
  { code: 'AQ', name: 'Acquirer' },
  { code: 'PWR', name: 'Publisher for Writer' },
  { code: 'ES', name: 'Substituted Publisher' },
];

export enum AvailableRoles {
  EDITOR_ORIGINAL_PUBLISHER = 'E',
  COMPOSER_AUTHOR = 'CA',
  COMPOSER_WRITER = 'C',
  AUTHOR_WRITER_AUTHOR_OF_LYRICS = 'A',
  ADAPTOR = 'AD',
  ARRANGER = 'AR',
  SUB_ARRANGER = 'SR',
  SUB_AUTHOR = 'SA',
  TRANSLATOR = 'TR',
  INCOME_PARTICIPANT = 'PA',
  ADMINISTRATOR = 'AM',
  SUB_EDITOR = 'SE',
  ACQUIRER = 'AQ',
  PUBLISHER_FOR_WRITER = 'PWR',
  SUBSTITUTED_PUBLISHER = 'ES',
}
