import { DocumentUnisonDTO } from 'app/shared/models';

export const DOCUMENTS_UNISON_TEST: DocumentUnisonDTO[] = [
  {
    id: 'Test',
    name: 'Test',
    date: 'Test',
    test1: 'Test',
    test2: 'Test',
  },
  {
    id: 'Test_1',
    name: 'Test_1',
    date: 'Test_1',
    test1: 'Test_1',
    test2: 'Test_1',
  },
  {
    id: 'Test_2',
    name: 'Test_2',
    date: 'Test_2',
    test1: 'Test_2',
    test2: 'Test_2',
  },
  {
    id: 'Test_3',
    name: 'Test_3',
    date: 'Test_3',
    test1: 'Test_3',
    test2: 'Test_3',
  },
  {
    id: 'Test_4',
    name: 'Test_4',
    date: 'Test_4',
    test1: 'Test_4',
    test2: 'Test_4',
  },
  {
    id: 'Test_5',
    name: 'Test_5',
    date: 'Test_5',
    test1: 'Test_5',
    test2: 'Test_5',
  },
  {
    id: 'Test_6',
    name: 'Test_6',
    date: 'Test_6',
    test1: 'Test_6',
    test2: 'Test_6',
  },
  {
    id: 'Test_7',
    name: 'Test_7',
    date: 'Test_7',
    test1: 'Test_7',
    test2: 'Test_7',
  },
  {
    id: 'Test_8',
    name: 'Test_8',
    date: 'Test_8',
    test1: 'Test_8',
    test2: 'Test_8',
  },
  {
    id: 'Test_9',
    name: 'Test_9',
    date: 'Test_9',
    test1: 'Test_9',
    test2: 'Test_9',
  },
  {
    id: 'Test_10',
    name: 'Test_10',
    date: 'Test_10',
    test1: 'Test_10',
    test2: 'Test_10',
  },
  {
    id: 'Test_11',
    name: 'Test_11',
    date: 'Test_11',
    test1: 'Test_11',
    test2: 'Test_11',
  },
];
