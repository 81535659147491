import { Component, Input, OnInit } from '@angular/core';
import { Ticket } from 'app/shared/models';

const NUM_TICKETS = [10, 25, 50];

@Component({
  selector: 'app-open-tickets',
  templateUrl: './open-tickets.component.html',
  styleUrls: ['./open-tickets.component.scss'],
})
export class OpenTicketsComponent implements OnInit {
  @Input() tickets: Ticket[] = [];
  offset = 0;
  limit = NUM_TICKETS[0];

  constructor() {}

  ngOnInit(): void {}
}
