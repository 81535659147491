import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-united',
  templateUrl: './app-header-united.component.html',
  styleUrls: ['./app-header-united.component.scss'],
})
export class AppHeaderUnitedComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
