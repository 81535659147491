import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, race } from 'rxjs';
import { Router, ActivationEnd } from '@angular/router';
import { first, last, map } from 'rxjs/operators';
import { CancelHttpService } from './cancel-http.service';

export class ViewChangedError extends Error {}

@Injectable()
export class CancelHttpInterceptor implements HttpInterceptor {
  constructor(router: Router, private cancelHttpService: CancelHttpService) {
    router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        this.cancelHttpService.cancelPendingRequests();
      }
    });
  }

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    if (req.headers.get('X-CANCEL-WAIT')) {
      return race([
        this.cancelHttpService.onCancelPendingRequests().pipe(first()),
        next.handle(req).pipe(last()),
      ]).pipe(
        map((res) => {
          if (!res) {
            throw new ViewChangedError('View changed');
          }
          return res;
        }),
      );
    }
    return next.handle(req);
  }
}
