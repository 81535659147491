export const MEDIATYPES = [
  { name: '45rpm 17cm Single', value: 'S' },
  { name: '45rpm 17cm EP', value: 'EP' },
  { name: '45rpm (12 inches) Maxi Single', value: 'DS' },
  { name: 'Disco Single Remix (12 inches)', value: 'RDS' },
  { name: 'Disco Maxi Single Remix (7 inches)', value: 'RMS' },
  { name: 'LP 33rpm 17cm EP', value: 'EPM' },
  { name: 'LP 33rpm 25cm', value: 'MLP' },
  { name: 'LP 33rpm 30cm', value: 'LP' },
  { name: 'LP Compilation', value: 'LP2' },
  { name: 'LP identical to a CD', value: 'LP3' },
  { name: 'LP Compilation identical to a CD Compilation', value: 'LP4' },
  { name: 'CD Single 2 tracks', value: 'SCD' },
  { name: 'CD Singles 3&5 inches', value: 'CDS' },
  { name: 'CD Maxi Single', value: 'CDM' },
  { name: 'CD Maxi Single Remix', value: 'RCD' },
  { name: 'CD-LP (5 inches)', value: 'CD' },
  { name: 'CD Compilation', value: 'CD2' },
  { name: 'SACD', value: 'SA' },
  { name: 'SACD Compilation', value: 'SA2' },
  { name: 'CD Extra Single 2 tracks', value: 'CES' },
  { name: 'CD Extra Single', value: 'CXS' },
  { name: 'CD Extra Maxi Single', value: 'CXM' },
  { name: 'CD Extra Maxi Remix', value: 'RCE' },
  { name: 'CD Extra EP', value: 'CEP' },
  { name: 'CD Extra LP', value: 'CE' },
  { name: 'CD Extra Compilation', value: 'CE2' },
  { name: 'MC Single', value: 'SMC' },
  { name: 'MC Single identical to a CDS', value: 'SM2' },
  { name: 'MC Maxi', value: 'MMC' },
  { name: 'MC EP', value: 'EMC' },
  { name: 'MC Remix', value: 'RMC' },
  { name: 'MC', value: 'MCP' },
  { name: 'MC LP', value: 'MC' },
  { name: 'MC Compilation', value: 'MC2' },
  { name: 'MC identical to a CD', value: 'MC3' },
  { name: 'MC Compilation identical to a CD Compilation', value: 'MC4' },
  { name: 'Double MC', value: 'DMC' },
  { name: 'MD Single / Maxi Single', value: 'MDS' },
  { name: 'MD Maxi Remix', value: 'MDR' },
  { name: 'MD EP', value: 'MDP' },
  { name: 'MD', value: 'MD' },
  { name: 'MD Compilation', value: 'MD2' },
  { name: 'DCC', value: 'DC' },
  { name: 'DCC Compilation', value: 'DC2' },
  { name: 'DVD Audio', value: 'DV1' },
  { name: 'DVD Video', value: 'DV2' },
  { name: 'DVD Rom', value: 'DV3' },
  { name: 'DVD Single', value: 'DV4' },
  { name: 'Downloading of a title', value: 'DW' },
  { name: 'Downloading of a Single / Maxi Single', value: 'DM' },
  { name: 'Downloading of LP', value: 'DL' },
];
