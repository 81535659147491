import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Ticket, TicketStatus } from 'app/shared/models';
import { TicketService } from 'app/shared/services';
import { TicketsResponse } from 'app/shared/interfaces';
import { faBell, faUser, faSignOutAlt, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

import { AuthService } from 'app/modules/auth/services';

const NUM_TICKETS = [10, 25, 50];
@Component({
  selector: 'app-header-menu',
  templateUrl: './app-header-menu.component.html',
  styleUrls: ['./app-header-menu.component.scss'],
})
export class AppHeaderMenuComponent implements OnInit {
  faBell = faBell;
  faUser = faUser;
  faSignOutAlt = faSignOutAlt;
  faBars = faBars;
  faTimes = faTimes;

  tickets: Ticket[] = [];
  offset = 0;
  limit = NUM_TICKETS[0];
  readonly TicketStatus: typeof TicketStatus = TicketStatus;
  ticketsSearchText = '';
  searchStatus = '';

  constructor(
    private authService: AuthService,
    private ticketService: TicketService,
    private router: Router,
  ) {
    this.searchStatus = TicketStatus.OPEN;
  }

  async ngOnInit(): Promise<void> {
    await this.getOpenTickets();
  }

  logout(): Promise<boolean> {
    this.authService.logout();
    return this.router.navigate(['/auth']);
  }

  async refreshOpenTickets(): Promise<void> {
    await this.getOpenTickets();
  }

  async getOpenTickets(): Promise<void> {
    try {
      const response: TicketsResponse = await this.ticketService.getTickets(
        this.offset,
        this.limit,
        {
          text: this.ticketsSearchText,
          status: this.searchStatus,
        },
      );
      this.tickets = response.tickets;

      // this.totalTickets = response.info.count;
      // this.totalPages = Math.ceil(this.totalTickets / this.limit);
    } catch (error) {
      // TODO Handle error getOpenTikets
    }
  }
}
