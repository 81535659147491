import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/modules/auth/services';

@Component({
  selector: 'app-legal-disclaimer',
  templateUrl: './app-legal-disclaimer.component.html',
  styleUrls: ['./app-legal-disclaimer.component.scss'],
})
export class AppLegalDisclaimerComponent implements OnInit {
  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit(): void {}

  acceptDisclaimer(): void {
    this.authService.disclaimerAccepted = true;
    void this.router.navigate(['/']);
  }

  isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }
}
